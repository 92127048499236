'use strict';

angular.module('bitnudgeApp')
    .controller('MainCtrl', function ($scope,  Auth, $state, p0) {

        var me = Auth.getCurrentUser();
        console.log('MainCtrl me: ', me)
        console.log('MainCtrl me.systemRole: ', me.systemRole)
        if(me.systemRole === 'lmsAdmin') {
            $state.go('lms.adminDashboard');
        }else if(me.systemRole === 'seniorManager'){
            $state.go('userFlow.dashboard');
        }else{
            if (!me.instructionsRead && p0.config.showInstructions){
                $state.go('instructions');
            }
            else{
                if (p0.config.showStep2) {
                    $state.go('user.step2');
                } else {
                    // SALESKEY: As per the requirement
                    // $state.go('lms.listLead', params);
                    if(p0.isAgentOnBoardingVerificationUser){
                        $state.go("lms.listLead",{ status: "active", quickMenu: true })
                    }else{
                        $state.go('userFlow.dashboard');
                    }
                }
            }
        }
    });
