const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/allProspectsFilters.html');

angular.module('bitnudgeApp').service('prospectService', ['$http', '$q', '$mdDialog', 'Auth', 'UtilService', 'p0', '$rootScope', '$filter', 'WorkflowService', 'toaster',
	function ($http, $q, $mdDialog, Auth, UtilService, p0, $rootScope, $filter, WorkflowService, toaster) {
		var currentUser = '',
			modalObj = {};
		let self = this;
		this.setUploadModalObject = function (obj) {
			modalObj = obj;
		};

		this.getUploadModalObject = function () {
			return modalObj;
		};

		this.setCurrentUser = function (user) {
			currentUser = user;
		};

		this.getCurrentUser = function () {
			return currentUser;
		};

		this.getProspects = function (filterObj, extraParams, pagination, category, isCustomerView, sortingObj, onlyPartial) {
			const baseUrl = onlyPartial? '/api/prospectUser/list/partial': '/api/prospectUser/list';
			const postBody = {category};
			console.log('filterObj.externalParams: ', filterObj.externalParams);
			if (filterObj.externalParams && !_.isEmpty(filterObj.externalParams)) {
				postBody.externalParams = JSON.stringify(filterObj.externalParams);
				postBody.productId = 'Others';
				postBody.userId = extraParams.userId;
				postBody.currentMonthlyCycleId = extraParams.currentMonthlyCycleId;
				postBody.selectedCycle = extraParams.selectedCycle;
			} else {
				if (filterObj.urn) {
					postBody.urn = filterObj.urn.toString();
				}
				if (extraParams.userId) {
					postBody.userId = extraParams.userId.toString();
				}
				if (extraParams.currentMonthlyCycle) {
					postBody.startDate = new Date(extraParams.currentMonthlyCycle.startDate).getTime();
					postBody.endDate = new Date(extraParams.currentMonthlyCycle.endDate).getTime();
				}
				if (extraParams.selectedCycle) {
					postBody.dateFilterStartDate = new Date(extraParams.selectedCycle.startDate).getTime();
					postBody.dateFilterEndDate = new Date(extraParams.selectedCycle.endDate).getTime();
				}
				if (filterObj.searchParam) {
					postBody.searchParam = btoa(filterObj.searchParam);
				}
				if (filterObj.selectedStatuses) {
					postBody.statuses = filterObj.selectedStatuses;
				}
				if (filterObj.actNowFilterKey) {
					postBody.actNowKey = filterObj.actNowFilterKey;
				}
				if (filterObj.prospectDateKey) {
					postBody.prospectDateKey = filterObj.prospectDateKey;
				}
				if (filterObj.selectedLevels) {
					postBody.levels = filterObj.selectedLevels;
				}
				if (isCustomerView) {
					postBody.isCustomerView = true;
				}
				if(filterObj.workflowIds){
					postBody.workflowIds = filterObj.workflowIds;
				}
				if (!filterObj.includeArchived) {
					postBody.archived = false;
				}
			}
			if(filterObj.unassignedView){
				postBody.unassignedView = filterObj.unassignedView;
			}
			postBody.offset = pagination.offset;
			postBody.limit = pagination.limit;
			if (sortingObj && !_.isUndefined(sortingObj)) {
				postBody.sortKey = sortingObj.sortKey;
				postBody.sortOrder = sortingObj.sortOrder;
			}
			if (onlyPartial && extraParams.includeUserId)
				postBody.includeUserId = extraParams.includeUserId
			return $http.post(baseUrl, postBody);
		}

		this.archive = function (urn) {
			const deferred = $q.defer();
			$http.post('/api/prospectUser/archive',{ urn})
			.then(function ({data}) {
				deferred.resolve(data.status);
			}, function({data}) {
				return toaster.error("Agent Deactivation", `Agent deactivation is failed: ${data.message}`)
			});
			return deferred.promise;
		};

		this.reactivate = function (urn) {
			const deferred = $q.defer();
			$http.post('/api/prospectUser/reactivate',{ urn}).then(function ({data}) {
				deferred.resolve(data.status);
			}, function({data}) {
				return toaster.error("Agent Reactivation", `Agent reactivation is failed: ${data.message}`)
			});
			return deferred.promise;
		};

		this.followProspect= function (id) {
			var deferred = $q.defer();
			$http.post('/api/prospectUser/follow',{id}).then(function (responce) {
				responce = responce.data;
				deferred.resolve(responce);
			});
			return deferred.promise;
		};
		this.unFollowProspect = function (id) {
			var deferred = $q.defer();
			$http.post('/api/prospectUser/unFollow', {id}).then(function (responce) {
				responce = responce.data;
				deferred.resolve(responce);
			});
			return deferred.promise;
		};

		this.showAllFilterModal = function (filters, selectedRole, isReferralsView, onApplyFilterCall, workflowColorCodes, workflowStagesCodes, callback) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: 'allProspectFilterCtrl',
				clickOutsideToClose: true,
				fullscreen: false,
				resolve: {
					filters: function () {
						return filters;
					},
					selectedRole: function () {
						return selectedRole;
					},
					callback: function () {
						return callback;
					},
					workflowColorCodes: function () {
						return workflowColorCodes;
					},
					workflowStagesCodes: function () {
						return workflowStagesCodes;
					},
					isReferralsView: function () {
						return isReferralsView
					},
					onApplyFilterCall: function () {
						return onApplyFilterCall;
					}
				}
			});
		};
		var _this = this;

		this.getFilters = function () {
			return $http.post('api/prospectUser/getFiltersList').then(function (result) {
				console.log('result: ', result)
				return result.data;
			});
		};

		const sortKeys = [
			{key: 'name', name: 'Name'},
			{key: 'createdAt', name: 'Created'},
			{key: 'updatedAt', name: 'Update'}
		]

		const sortOrders = [
			{key: 'asc', name:'Ascending'},
			{key: 'desc', name:'Descending'},
		]

		this.getActiveFrontlines = function () {
			return $http.post('api/users/getActiveFrontLineUsers', {limit:100, offset:0}).then(function (result) {
				return result.data;
			});
		};

		this.getAllSeniorManagers = function (userId) {
			return $http.post('api/users/getAllSeniorManagers', {userId}).then(function (result) {
				return result.data;
			})
		};

		this.setExtrasInfo = function (prospects, currMonth) {
			let me = Auth.getCurrentUser();
			let today = new Date();
			let todayStart = new Date(today.setHours(0, 0, 0, 0));
			let yesterDayStart = new Date(today);
			yesterDayStart = new Date(yesterDayStart.setDate(yesterDayStart.getDate() - 1));
			let todayEnd = new Date(today.setHours(23, 59, 59, 59));

			let productiveCalls = [],
				productiveMeetings = [],
				touchpoints = [],
				completedInteractions = [];
			let hbCalls = [],
				hbMeetings = [];

			_.each(prospects, function (prospect) {

				var prospectDate = new Date(prospect.creationDate);
				if (prospectDate > todayStart) {
					prospect.creationText = 'Today';
					prospect.todayOrYday = true;
				} else if (prospectDate > yesterDayStart && prospectDate < todayStart) {
					prospect.creationText = 'Yesterday';
					prospect.todayOrYday = true;
				} else {
					prospect.todayOrYday = false;
					prospect.creationText = $filter('date')(prospectDate, 'mediumDate');
				}
				if (new Date(currMonth.startDate) <= prospectDate && prospectDate <= new Date(currMonth.endDate)) {
					prospect.isNew = true;
					if (prospect.status && prospect.status.toLowerCase() == 'not yet contacted')
					prospect.showBold = true;
					else
					prospect.showBold = false;
				} else
					prospect.isNew = false;
				//set followUp date from scheduled interactions
				if (prospect.shortInteractions || prospect.interactions) {
					let nextFollowup;
					let missedFollowup;
					if(prospect.shortInteractions){
						nextFollowup = prospect.shortInteractions.nextFollowup
						missedFollowup = prospect.shortInteractions.missedFollowup
					}
					if (( nextFollowup || missedFollowup) && (!_.isEmpty(nextFollowup) || !_.isEmpty(missedFollowup) )) {
						const { scheduledAt } = !_.isEmpty(nextFollowup)? nextFollowup: missedFollowup;
						let timestamp = Date.parse(prospect.followUpDate);
						if (!isNaN(timestamp)) {
							prospect.followUpDate = new Date(timestamp);
						}

						//setting followUpDate status and swot-color
						var noOfDays = Math.round(Math.abs(todayStart - prospect.followUpDate) / (1000 * 60 * 60 * 24));
						var now = new Date();
						prospect.followUpMissed = false;
						if (prospect.followUpDate < now) {
							prospect.followUpMissed = true;
							prospect.followUpDateStatus = {
								class: "swot-red",
								text: 'Missed '
							}
						} else if (prospect.followUpDate >= now && prospect.followUpDate < todayEnd) {
							var dateText = $filter('date')(prospect.followUpDate, 'MMM dd, yyyy hh:mm a');
							prospect.followUpDateStatus = {
								class: "green",
								text: dateText
							}
						} else {
							var dateText = $filter('date')(prospect.followUpDate, 'MMM dd, yyyy hh:mm a');
							prospect.followUpDateStatus = {
								class: "swot-green",
								text: dateText
							};
						}
					}

					touchpoints = [];
					productiveCalls = [];
					productiveMeetings = [];
					completedInteractions = [];
					hbCalls = [];
					hbMeetings = [];
					prospect.callHBCount = -1;
					prospect.metHBCount = -1;

					completedInteractions = _.filter(prospect.interactions, {
						status: 'completed'
					});
					productiveCalls = _.filter(completedInteractions, (interaction) => { 
						if(interaction.type === 'call'){
							if(interaction.outcome === 'contacted' || interaction.outcome === 'willingToRegister' || interaction.outcome === 'present'){
								return true
							}
						}
						});
					productiveMeetings = _.filter(completedInteractions, {
						type: 'meeting',
						outcome: 'met'
					});

					touchpoints = productiveCalls.concat(productiveMeetings);

					productiveCalls = _.sortBy(productiveCalls, 'completedAt');
					productiveMeetings = _.sortBy(productiveMeetings, 'completedAt');
					touchpoints - _.sortBy(touchpoints, 'completedAt');

					//setting interactions counts
					prospect.completedInteractions = completedInteractions.length;
					prospect.callInteractions = productiveCalls.length;
					prospect.meetingInteractions = productiveMeetings.length;

					//setting last interaction dates
					prospect.lastCallInteractionDate = prospect.callInteractions ? productiveCalls[prospect.callInteractions - 1].completedAt : null;
					prospect.lastMeetingInteractionDate = prospect.meetingInteractions ? productiveMeetings[prospect.meetingInteractions - 1].completedAt : null;
					prospect.lastTouchPoint = touchpoints.length ? touchpoints[touchpoints.length - 1].completedAt : null;

				} else {
					prospect.completedInteractions = 0;
					prospect.callInteractions = 0;
					prospect.meetingInteractions = 0;
				}

				if (prospect.archived) {
					prospect.showMissed = false;
				} else {
					prospect.showMissed = true;
				}
				
				if (($rootScope.isMiddleManager || $rootScope.isSeniorManager ) && prospect.userId == undefined) {
					prospect.noHandler = true;
				}

				if (me.roleType === 'seniorManager') {
					prospect.isEditable = true
				}
				else if (me.roleType === 'manager') {
					let index = _.findIndex(me.children, {
						childId: prospect.userId._id
					})
					if (index > -1 || me._id === prospect.userId._id) {
						prospect.isEditable = true
					}
				} else if (prospect.userId && (me._id === prospect.userId._id)) {
					prospect.isEditable = true
				}
				if($rootScope.isAgentOnBoardingVerificationUser){
					prospect.isEditable = true;
				}

				if (!$rootScope.isFrontline && prospect.userId != undefined && prospect.userId._id == me._id) {
					prospect.assignedToManager = true;
				}

				prospect.followedByMe =  prospect.followers && prospect.followers.find(u => u.userId === me._id);
			});
			return prospects;
		};

		this.getProspect = function (prospectId) {
			return $http.post('/api/prospectUser/show',{id: prospectId} );
		};

		this.createProspect = function (data) {
			return $http.post('/api/prospectUser/create', data);
		};

		this.getNextStates = function (urn) {
			return WorkflowService.getNextStates('api/prospectUser/getNextStates', {urn})
		};

		this.getDocumentsCache = function (context, workflowId) {
			return $http.post(`/api/documents/documentsCache`, {workflowId, context});
		};

		this.gotoState = function (prospect) {
			return WorkflowService.gotoState('api/prospectUser/update', prospect)
		};

		this.getProspectHistory = function (id) {
			return $http.post('/api/prospectUser/history', {id});
		};

		this.getInteractions = function () {
			return $http.post('/api/interactions/types');
		};

		this.saveInteraction = function (interactedData) {
			return $http.post('/api/interactions/schedule', interactedData);
		};

		this.getFutureFollowUps = function (urn) {
			return $http.post('/api/prospectUser/futureFollowUps',{urn});
		};

		this.getMyTeam = function (userId) {
			return $http.post('api/users/getActiveFrontLineUsers', {limit:100, offset:0});
		};

		this.addNote = function(urn, notes){
			return $http.post('/api/prospectUser/notes',{urn, notes});
		}

		this.getAttendanceLabel = function(isFrontline){
			let attendanceLabel = 'Loan Advisor present for the meeting';
			if(p0.strings){
				try{
					if (isFrontline && p0.strings.MEETING_ATTENDANCE_TRACK_FL.value) {
						attendanceLabel = p0.strings.MEETING_ATTENDANCE_TRACK_FL.value;
					}else if(!isFrontline && p0.strings.MEETING_ATTENDANCE_TRACK_NON_FL.value){
						attendanceLabel = p0.strings.MEETING_ATTENDANCE_TRACK_NON_FL.value;
					}
				}catch(err){
					attendanceLabel = "Loan Advisor present for the meeting"
				}
				return attendanceLabel;
			}
		};

		//TODO: Currentlt propectUsers and leads are using source schema
		// we need to separate this when we get full list of allowed propsectUser sources
		this.fetchProspectSourceList = function () {
			return $http.post('/api/leads/sources');
		}
		this.getPlayTypeUsers = (playTypeId) => {
			const url = "/api/users/playTypeUsers";
			const deferred = $q.defer();
			$http.post(url, {playTypeId, limit: 100, offset: 0}).then(
				result => {
					if(result.data.status){
						deferred.resolve(result.data.users);
					}else{
						deferred.reject(result.data.errors[0].message);
					}
				},
				error => {
					console.log(error);
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.getUserById = (userId) => {
			
			const url = "/api/users/getUserById";
			const deferred = $q.defer();
			$http.post(url,{id:userId}).then(
				result => {
					result = result.data;
					if(result.status){
						deferred.resolve(result.data);
					}else{
						deferred.reject(result.data.errors[0].message);
					}
				},
				error => {
					console.log(error);
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
		
		this.updateProspectInfo = (urn, info) => {
			return $http.post(`/api/prospectUser/updateInfo`, {urn,...info})
		}

		this.getDocEformInfo = (prospectId) => {
			return $http.post(`/api/prospectUser/saleskey/getDashboardDataFromSaleskey`,{urn: prospectId});
		}

		this.getApplicationFormLink = (urn) => {
			return $http.post('/api/prospectUser/saleskey/application/link', { urn })
		}
        
        this.getInteractionAttachments = ({workflowId, type}) => {
            return $http.post('/api/interactions/getAttachments', {workflowId, type})
        }
		
		return this;
	}
]);
