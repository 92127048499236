'use strict';

angular.module('bitnudgeApp')
    .controller('dateFiltersCtrl', function ($scope, $rootScope, p0, JobRole,$state) {
        const self = this;

        const setCorrectMaxDate = () => {
            //setting the correct max date in case system is not updated and lastsync date is lesser than current month start date
            if(new Date($rootScope.lastUpdatedAtDate) < new Date($scope.minDate)){
                $scope.maxDate = new Date($scope.selectedCycle.endDate)
            }

            //eliminating any future date in the calender
            if($scope.maxDate > new Date()){
                $scope.maxDate = new Date()
            }
        }

        this.init = function(){
            $scope.config = p0.config;
            $scope.flexValue = 100;

            if ($scope.config.weeklyCycle && $scope.showWeeklyCycle && $scope.showDailyCycle) {
                $scope.flexValue = 33;
            } else if (($scope.config.weeklyCycle && $scope.showWeeklyCycle) || $scope.showDailyCycle) {
                $scope.flexValue = 50;
            }

            var allCycles = _.filter(p0.allCycles, function (cycle) {
                return cycle.cycleType !== 'campaign';
            });

			const minDailyStartDate = _.map(_.filter(allCycles, (c) => c.cycleType === 'daily'), 'startDate');
			$scope.minDailyCycleDate = new Date(_.min(minDailyStartDate));
			$scope.minDate = $scope.minDailyCycleDate;
            $scope.maxDate = new Date($rootScope.lastUpdatedAtDate);

            $scope.cycleChange = {
                monthly : function (cycle) {
                    if($scope.selectedCycle && $scope.selectedCycle._id == cycle._id) return;
                    $scope.selectedMonthlyCycle = cycle;
                    $scope.selectedCycle = cycle;

                    const localMinDate = new Date($scope.selectedCycle.startDate);
					$scope.minDate = _.max([$scope.minDailyCycleDate, localMinDate]);
                    $scope.maxDate = (new Date($rootScope.lastUpdatedAtDate) > new Date($scope.selectedCycle.endDate)) ? new Date($scope.selectedCycle.endDate): new Date($rootScope.lastUpdatedAtDate);
                    
                    setCorrectMaxDate();

                    self.monthlyCycleChange();
                    if ($scope.onChange) {
                        $scope.onChange($scope.selectedCycle);
                    }
                    $scope.selectedDailyCycle = null;
                },
                weekly : function (cycle) {
                    $scope.selectedWeeklyCycle = cycle;
                    self.weeklyCycleChange();

					const localMinDate = new Date($scope.selectedCycle.startDate);
					$scope.minDate = _.max([$scope.minDailyCycleDate, localMinDate]);

                    $scope.maxDate = new Date($rootScope.lastUpdatedAtDate) > new Date($scope.selectedCycle.endDate) ? new Date($scope.selectedCycle.endDate): new Date($rootScope.lastUpdatedAtDate);
                    
                    setCorrectMaxDate()

                    if ($scope.onChange) {
                        $scope.onChange($scope.selectedCycle);
                    }
                    $scope.selectedDailyCycle = null;
                },
                daily: function (date) {
                    self.dailyCycleChange(date.toJSON());
                    if ($scope.onChange) {
                        $scope.onChange($scope.selectedCycle);
                    }
                }
            };
        }; 

        this.setMonthlyDropdown = function() {
            var playTypeId = (($scope.selectedPlayType && $scope.selectedPlayType._id != 'all') ? $scope.selectedPlayType._id : null);
            var jobDetails = JobRole.getDetails($scope.selectedRole.id, playTypeId);
            var allMonthlyCycles = _.filter(p0.allCycles, function(cycle){
                return jobDetails.monthlyCycleType == cycle.subType && cycle.cycleType == 'monthly';
            });
            var activeMonthlyCycle = _.find(allMonthlyCycles,{active:true});
            if($scope.showOneMonth)
                $scope.currentMonthCycle = [activeMonthlyCycle];
            $scope.allMonthlyCycles = allMonthlyCycles;
            const isSelectedAvailable = $scope.selectedCycle && _.find(allMonthlyCycles,{_id:$scope.selectedCycle._id});
            if(isSelectedAvailable){
                $scope.selectedMonthlyCycle = $scope.selectedCycle
            }else{
                $scope.selectedMonthlyCycle = activeMonthlyCycle;
            }
            $scope.selectedDailyCycle = null;
            //Allows to set selected cycle from outside
            if($scope.selectedCycle){
                if($scope.selectedCycle.type == 'weekly'){
                    $scope.cycleChange.weekly($scope.selectedCycle);
                }else if($scope.selectedCycle.type == 'daily'){
                    $scope.cycleChange.weekly(new Date($scope.selectedCycle.startDate));
                }
            }else{
                $scope.cycleChange.monthly($scope.selectedMonthlyCycle);
            }
        };

        this.monthlyCycleChange = function () {
            $scope.allWeeklyCycles = _.filter(_.cloneDeep(p0.allWeeklyCycles), function (weekCycle, index) {
                if(weekCycle.startDate && weekCycle.startDate >= $scope.selectedMonthlyCycle.startDate &&  weekCycle.startDate <= $scope.selectedMonthlyCycle.endDate){
                    return true;
                }else if(weekCycle.startDate && weekCycle.startDate <= $scope.selectedMonthlyCycle.startDate &&  weekCycle.endDate >= $scope.selectedMonthlyCycle.startDate){
                    return true;
                }
                else return false;
            });
            $scope.allWeeklyCycles.unshift({name:'ALL WEEKS', _id:'overall'});
            $scope.selectedWeeklyCycle = $scope.allWeeklyCycles[0];
            $scope.selectedDailyCycle = null;
        };

        this.weeklyCycleChange = function () {
            $scope.selectedDailyCycle = null;
            if($scope.selectedWeeklyCycle._id == 'overall'){
                $scope.selectedCycle = $scope.selectedMonthlyCycle;
            }else{
                $scope.selectedCycle = $scope.selectedWeeklyCycle;
            }
            $scope.selectedDailyCycle = null;
        };

        this.dailyCycleChange = function (date) {
            if(date){
                $scope.selectedMonthlyCycle = _.find(p0.allMonthlyCycles, function (cycle) {
                    return cycle.startDate <= date && cycle.endDate >= date;
                });
                self.monthlyCycleChange();
                $scope.selectedWeeklyCycle = _.find($scope.allWeeklyCycles, function (cycle) {
                    return cycle.startDate && cycle.startDate <= date && cycle.endDate >= date;
                });
                $scope.selectedCycle = _.find(p0.allCycles,{cycleType:'daily',startDate:date});
                if($scope.selectedCycle)
                $scope.selectedDailyCycle = new Date($scope.selectedCycle.startDate);
            }else{
                $scope.selectedDailyCycle = null;
            }
        };

        // $scope.$watch('selectedCycle',function(newValue, oldValue){
        //     if(newValue && newValue._id != oldValue._id && newValue.cycleType == 'monthly'){
        //         $scope.cycleChange.monthly(newValue);
        //     }
        // });
        $scope.$watch('showOneMonth',function(newValue, oldValue){
            if(newValue == true || newValue == false){
                self.setMonthlyDropdown();
            }
        });

        $scope.$watch('monthType',function(newValue, oldValue){
            if(newValue != oldValue && (newValue == 'regular' || newValue == 'midmonth')){
                $scope.allMonthlyCycles = _.filter(p0.allMonthlyCycles,function(cycle){
                    return cycle.subType == $scope.monthType;
                });
                $scope.selectedMonthlyCycle = _.find(p0.allMonthlyCycles,function(cycle){
                    return cycle.active && cycle.subType == $scope.monthType;
                });
                if($scope.selectedCycle._id != $scope.selectedMonthlyCycle._id){
                    $scope.cycleChange.monthly($scope.selectedMonthlyCycle);
                }
            }
        });

        $scope.$watchGroup(['selectedRole','selectedPlayType'], function(newVal, oldVal){
           if(newVal[0]){   //selectedRole value is mandatory to call the below functions ad selectedPlayType is optional
               //console.log($scope.selectedRole)
               self.setMonthlyDropdown();
               self.monthlyCycleChange();
           }
        });

        this.init();
    });
