angular.module('bitnudgeApp')
    .controller('addExcelHeaderCtrl', function ($http, $scope, Auth, Upload, $location, toaster, Sidebar, UtilService) {
        $scope.fileType = "excelHeaderFile"
        $scope.onFileSelectExcelHeader = function () {
            if ($scope.excelHeader == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.excelHeader[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx") {
                    Upload.upload({
                        url: '/api/file-header/import',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'ExcelHeaderFileUpload',invocationMethod:"manual"},
                        file: $scope.excelHeader[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "ExcelHeaderFile upload successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                }
            }
        }
    });
