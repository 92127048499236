"use strict";

angular
	.module("bitnudgeApp")
	.controller("LoginCtrl", function(
		$scope,
		Auth,
		$location,
		$state,
		$http,
		toaster,
		$mdDialog,
		Usage,
		p0,
		$rootScope
	) {
		$scope.user = {};
		$scope.errors = {};
		$scope.isDownloadApk = 0;
		$scope.forgotPasswordClicked = false;
		$scope.appVersion = $rootScope.appVersion;
		var securityQuestion;

		var initialize = $http
			.post("/api/config/getsecurityQuestion")
			.then(function(response) {
				response = response.data.securityQuestions;
				securityQuestion = response; // set false for sending email on forgot password
				$scope.sendPassword = function() {
					if (securityQuestion) {
						$state.go("user.securityQuestions", {
							set: false,
							email: $scope.email.toLowerCase()
						});
					} else {
						$http
							.post("/api/users/forgot", {
								email: $scope.email.toLowerCase()
							})
							.then(function(rs) {
								rs = rs.data;
								if (rs.msg === "Failed") {
									$location.path("/forgot");
									toaster.pop(
										"danger",
										"User with given Employee ID not found!"
									);
								} else {
									$location.path("/login");
									toaster.pop(
										"success",
										"Please check your email for instructions."
									);
								}
							});
					}
				};
			})
			.catch(function(err) {
				console.log(err);
			});

		$scope.downloadApk = function() {
			$scope.isDownloadApk = 1;
			$("#apkDownload").modal("hide");
			$scope.login($scope.loginForm);
		};

		$scope.cancelDownloadApk = function() {
			$("#apkDownload").modal("hide");
			$scope.login($scope.loginForm);
		};

		$scope.preLogin = function(form) {
			$scope.isDownloadApk = 0;
			if (navigator.userAgent.match(/Android/i)) {
				$scope.loginForm = form;
				$("#apkDownload").modal("show");
			} else {
				$scope.login(form);
			}
		};

		$scope.login = function(form) {
			$scope.submitted = true;

			if (form.$valid) {
				console.log('hereeee ')
				return Auth.login({
					email: $scope.user.email.toLowerCase(),
					password: $scope.user.password
				})
					.then(function(result) {
						console.log('result 123: ', result)
						if (result.status === false) {
							var errors = result.errors[0];
							var code = result.errors[0].code;
							if (code.toString() === "603") {
								$scope.timedout = $mdDialog.show({
									templateUrl:
										"../html/maxAttemptMailModal.html",
									controller: function($scope, $mdDialog) {
										$scope.onClose = function() {
											$mdDialog.cancel();
										};
										if (securityQuestion) {
											$scope.message =
												"Please reset your password using the 'I forgot my password' link.";
										} else {
											$scope.message =
												"Instructions to reset your password have been sent to your registered email.";
										}
									}
								});
							} else if (code.toString() == "607") {
								toaster.pop("error", result.errors[0].message);
							} else if (code.toString() == "608") {
								toaster.pop(
									"error",
									"Invalid Password",
									result.errors[0].message
								);
							} else {
								toaster.pop(
									"error",
									"Error in Login",
									result.errors[0].message
								);
								$scope.errors.other = result.errors[0].message;
							}
						} else {
							console.log('result: ', result)
							var user = result;
							if ($scope.isDownloadApk == 1) {
								//$window.location = "/apk/lms-release.apk";
							}
							console.log('user.roleType: ', user);
							if (user && user.roleType != "lmsAdmin") {
								Usage.setStartTime();
							}
							$state.go("userFlow.main");
						}
					})
					.catch(function(err) {
						console.log('here rrpr', err);
						toaster.pop("error", "Error in Login", err.message);
						$scope.errors.other = err.message;
					});
			} else {
				console.log("form not valid");
			}
		};

		/*$scope.loginOauth = function (provider) {
            $window.location.href = '/auth/' + provider;
        };*/

		$scope.forgotPassword = function() {
			$scope.forgotPasswordClicked = true;
		};

		function fetchLogoImage() {
			$http
				.post("/api/config/getLogoImage")
				.then(function(response) {
					$scope.logoImage = response.data.logoImage;
				})
				.catch(function(err) {
					console.log(err);
				});
		}
		fetchLogoImage();

		$scope.showMePassword = function(event) {
			$scope.showPassword = !$scope.showPassword;
		};

		return initialize;
	});
