angular.module('bitnudgeApp')
    .controller('queryUtilityCtrl', function ($rootScope, $scope, toaster, $timeout, p0, queryFields, queryUtilityService, fetchLeagues, $mdDialog, $http) {
        var kpiNameMap = {
            'K05': 'RBL',
            'K06': 'TWC',
            'K07': 'Liabilities'
        };

        $scope.selected = {};
        $scope.allCollections = [
            {
                _id: 'lead',
                name: 'Lead'
            },
            {
                _id: 'customer',
                name: 'Customers'
            }
        ];

        $scope.allStages = [
            "REGISTRATION",
            "CREATED",
            "DOCUMENTING",
            "EFORM",
            "ILLUSTRATION",
            "INTERACTION",
            "CLOSED",
            "SUBMISSION"
        ];
        $scope.allStatus = [
            "CREATED",
            "QUALIFIED",
            "VISITED",
            "CALLED",
            "SHARE_BROCHURE",
            "READ_BROCHURE",
            "LOAN_STRUCTURE",
            "SHARE_EFORM",
            "REGISTERED",
            "CLOSED",
            "FAILED",
            "SHARE_LEARNINGKIT",
            "OPEN_LEARNINGKIT",
            "SUBMIT_EFORM"
        ];
        $scope.allProducts = [];
        const productNameMap = {};
        p0.allProducts.forEach(product => {
            $scope.allProducts.push({_id: product.productCode, name: product.name});
            productNameMap[product.name] = product.productCode;
        });
        $scope.formulaRules = ['AND', 'OR'];
        const allowedMetrices = ['sum', 'average', 'count'];
        $scope.userGroups = ['self', 'reportees', 'team(self+reportees)'];
        $scope.compareLogicsList = ['greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo', 'equalTo', 'notEqualTo'];
        $scope.queryCriteriaList = ['equalTo', 'notEqualTo'];
        $scope.selected.queryCriteria = $scope.queryCriteriaList[0];
        $scope.customerNumericFields = [
            {
                _id: 'twcFacilityLimit',
                name: 'Twc Facility Limit'
            },
            {
                _id: 'twcFacilityUtilization',
                name: 'Twc Facility Utilization'
            },
            {
                _id: 'utilisationPercentage',
                name: 'Uilisation Percentage'
            },
            {
                _id: 'callReportsCount',
                name: 'Call Reports Count'
            },
            {
                _id: 'lastSixMonthsAvgBalance',
                name: 'Last Six Months Avg Balance'
            },
            {
                _id: 'lastSixMonthsTO',
                name: 'Last Six Months TO'
            },
            {
                _id: 'annualToAsPerLa',
                name: 'Annual To As Per La'
            }
        ];

        $scope.leadNumericFields = [
            {
                _id: 'amount',
                name: 'Amount'
            }
        ]
        $scope.query = {
            $and: [],
            $or: []
        }
        $scope.selected.metric = null;
        $scope.loading = false;
        $scope.expressions = [];
        $scope.exprToDisplay = [];
        $scope.expressionsList = [];
        $scope.formulas = [];
        $scope.formulaToDisplay = [];
        $scope.leadHeaders = [];
        $scope.leadsList = [];
        $scope.fetchOnlyUniqIdentifiers = false;
        $scope.displayQuery = '';
        $scope.finalQuery = null;
        $scope.selected.expression1 = null;
        $scope.selected.expression2 = null;
        $scope.metricsApplied = false;
        $scope.noRecordsFound = false;
        var activeStatus = ['active', 'inActive'];
        var booleanOptions = ['true', 'false'];
        var formulaRulesMap = { 'AND': '$and', 'OR': '$or' };
        var limitExpiredOptions = ['true', 'false'];
        var customerSourceOptions = ['Customer file upload', 'Other'];
        var customerProducts = ['RBL', 'TWC', 'Liabilities'];
        var portfolioTypes = ['assets', 'liabilities', 'limits'];
        var portfoliProjections = ['liabilitiesBalanceYesterday', 'sortliabilitiesBalanceDayBeforeYesterday', 'sortliabilitiesBalanceStartMonth', 'sortliabilitiesBalanceLastMonth', 'sortliabilitiesBalanceStartYear',
            'totalAssetBalanceYesterday', 'sorttotalAssetBalanceDayBeforeYesterday', 'sorttotalAssetBalanceStartMonth', 'sorttotalAssetBalanceDayLastMonth', 'sorttotalAssetBalanceStartYear'];
        var portfolioLevels = ['cif', 'account'];
        var targetTypes = ['Advances', 'Deposits'];
        var rankOrders = ['Top', 'Bottom'];
        var loginRejectStages = ['AU', 'COPS', 'CROPS'];
        var sortUpDown = [{ name: 'Top', _id: 'top' }, { name: 'Bottom', _id: 'bottom' }];
        const leaderBoardTypes = ['total' /*, 'business', 'activity', 'kpi', 'badge'*/];
        $scope.selected.collection = $scope.allCollections[0];
        $scope.leadFields = queryFields.queryFieldsList[$scope.selected.collection._id];
        var allKpis = _.cloneDeep(_.filter(p0.allKpis, { createLead: true }));
        var statusList = [];
        var newLeadStatusList = [];
        var leadStatusObj = {};
        // Object.keys(p0.statusToStage).forEach(function (key) {
        //     var temp = [];
        //     if (!_.isEmpty(p0.statusToStage[key])) {
        //         Object.keys(p0.statusToStage[key]).forEach(function (statusName) {
        //             var stageName = p0.statusToStage[key][statusName];
        //             temp.push(`${kpiNameMap[key]}-${stageName}-${statusName}`);
        //             !leadStatusObj[statusName] ? leadStatusObj[statusName] = [stageName] : leadStatusObj[statusName].push(stageName);
        //         })
        //         statusList = statusList.concat(temp);
        //     }
        // });
        // Object.keys(leadStatusObj).forEach(function (key) {
        //     var values = _.uniq(leadStatusObj[key]).toString();
        //     newLeadStatusList.push(`${key}(${values})`);
        // })

        var leadStageList = [];
        var newLeadStageList = [];
        var leadStageObj = {};
        // Object.keys(p0.stageToStatus).forEach(function (key) {
        //     var temp = [];
        //     if (!_.isEmpty(p0.stageToStatus[key])) {
        //         Object.keys(p0.stageToStatus[key]).forEach(function (stageName) {
        //             temp.push(`${kpiNameMap[key]}-${stageName}`);
        //             !leadStageObj[stageName] ? leadStageObj[stageName] = [kpiNameMap[key]] : leadStageObj[stageName].push(kpiNameMap[key]);
        //         })
        //         leadStageList = leadStageList.concat(temp);
        //     }
        // });
        // Object.keys(leadStageObj).forEach(function (key) {
        //     var values = leadStageObj[key].toString();
        //     newLeadStageList.push(`${key}(${values})`);
        // })

        var ticketSubTypes = [];
        // Object.keys(p0.ticketSubTypes).forEach(function (key) {
        //     p0.ticketSubTypes[key].forEach(function (value) {
        //         var tSubType = `${key}::${value}`;
        //         ticketSubTypes.push(tSubType);
        //     });
        // });
        $scope.allLeadStage = leadStageList;
        function setLeadSubTypesArray() {
            var leadSubTypeOptions = [];
            // Object.keys(p0.leadSubTypes).forEach(function (key) {
            //     p0.leadSubTypes[key].forEach(function (subType) {
            //         leadSubTypeOptions.push({ _id: subType, displayName: `${subType} (${key})` });
            //     })
            // });
            return leadSubTypeOptions;
        }

        function setSubProductsArray() {
            var subProductsOptions = [];
            // Object.keys(p0.leadsSubProducts).forEach(function (key) {
            //     p0.leadsSubProducts[key].forEach(function (subType) {
            //         var kpiIndex = _.findIndex(allKpis, { 'kpiCode': key });
            //         var productName = allKpis[kpiIndex].name;
            //         subProductsOptions.push({ _id: subType, displayName: `${subType} (${productName})` });
            //     })
            // });
            return subProductsOptions;
        }

        $scope.fieldChanged = function(field) {
            $scope.selected.isDynamic = false;
        }

        $scope.isDate = function(value) {
            var parsedDate = Date.parse(value);
            return isNaN(value) && !isNaN(parsedDate);
          }

        function init() {
            $scope.lbParams = {
                sortUpDown
            };
            $scope._lbParamsAdded = null;
            $scope.allowedMetrices = allowedMetrices;
            if ($scope.selected.collection._id === 'lead') {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'leadType') {
                            field.options = p0.leadTypes;
                        } else if (field._id === 'leadSubType') {
                            field.options = setLeadSubTypesArray();
                        } else if (field._id === 'product') {
                            field.options = _.map($scope.allProducts, 'name');
                        } else if (field._id === 'subProduct') {
                            field.options = setSubProductsArray();
                        } else if (field._id === 'active') {
                            field.options = activeStatus;
                        } else if (field._id === 'leadStatus') {
                            field.options = $scope.allStatus;
                        } else if (field._id === 'leadStage') {
                            field.options = $scope.allStages;
                        }
                        else {
                            field.options = booleanOptions;
                        }
                    }
                });
                $scope.numericFieldsList = $scope.leadNumericFields;
            } else if ($scope.selected.collection._id === 'ticket') {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'ticketStatus') {
                            field.options = p0.ticketsStatusList;
                        } else if (field._id === 'ticketType') {
                            field.options = p0.ticketTypes;
                        } else if (field._id === 'ticketSubType') {
                            field.options = ticketSubTypes;
                        } else if (field._id === 'loginRejectStage') {
                            field.options = loginRejectStages;
                        }  else if (field._id === 'product') {
                            field.options = _.map(allKpis, 'name');
                            field.options.push('Others')
                        } 
                        else {
                            field.options = booleanOptions;
                        }
                    }
                });
                $scope.numericFieldsList = $scope.ticketNumericFields;
            } else if ($scope.selected.collection._id === 'customer') {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'active') {
                            field.options = activeStatus;
                        } else if (field._id === 'utilisationPercentage') {
                            field.options = activeStatus;
                        } else if (field._id === 'limitExtendedExpiryDate') {
                            field.options = limitExpiredOptions;
                        } else if (field._id === 'customerSource') {
                            field.options = customerSourceOptions;
                        } else if (field._id === 'customerProduct') {
                            field.options = customerProducts;
                        }
                        else {
                            field.options = booleanOptions;
                        }
                    }
                });
                $scope.numericFieldsList = $scope.customerNumericFields;
            } else if ($scope.selected.collection._id === 'portfolio') {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'portfolioType') {
                            field.options = portfolioTypes;
                        } else if (field._id === 'portfolioLevel') {
                            field.options = portfolioLevels;
                        }
                    }
                });
                $scope.numericFieldsList = $scope.portfolioNumericFields;
                $scope.portfoliProjections = portfoliProjections;
            } else if ($scope.selected.collection._id === 'customerRanking') {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'rankType') {
                            field.options = portfolioTypes;
                        } else if (field._id === 'rankOrder') {
                            field.options = rankOrders;
                        }
                    }
                });
            } else if ($scope.selected.collection._id === 'leaderBoard') {
                $scope.allowedMetrices = ['count'];
            } else {
                $scope.leadFields.forEach(function (field) {
                    if (field.type === 'dropdown') {
                        if (field._id === 'targetType') {
                            field.options = targetTypes;
                        }
                    }
                });
            }
        }

        init();

        $scope.changeCollection = function (collection) {
            $scope.selected.collection = collection;
            $scope.leadFields = queryFields.queryFieldsList[$scope.selected.collection._id];
            if ($scope.selected.collection._id === 'customerRanking') {
                //Do not allow OR for calculating ranks
                $scope.formulaRules = ['AND'];
            } else {
                $scope.formulaRules = ['AND', 'OR'];
            }
            $scope.resetExpr();
            init();
        };

        $scope.resetExpr = function () {
            $scope.expressions = [];
            $scope.exprToDisplay = [];
            $scope.expressionsList = [];
            $scope.resetFormulas(); //reset formulas as well when expressions are reset
        }

        $scope.resetFormulas = function () {
            $scope.formulas = [];
            $scope.formulaToDisplay = [];
            $scope.displayQuery = '';
            $scope.finalQuery = null;
            $scope.selected.expression1 = null;
            $scope.selected.expression2 = null;
            $scope.leadHeaders = [];
            $scope.uniqueIdetifiersList = [];
            $scope.leadsList = [];
            $scope.expressionsList = [];
            $scope.exprToDisplay.forEach(function (expr) {
                $scope.expressionsList.push(expr.exprName);
            });
            $scope.removeMetrics();
            $scope.setUniqueIdentifier(false);
            if ($scope.selected.collection._id === 'leaderBoard') {
                $scope.lbParams.result = null;
            }
        }

        $scope.setlbEmail = function () {
            var email = $scope.lbParams.email;
            $scope.lbParams = { email, sortUpDown };
            if (!email) {
                toaster.pop('Failure', 'Please provide email');
                return;
            }

            queryUtilityService.getUserByEmail($scope.lbParams.email).then($scope.gatherlbData);
        }

        $scope.gatherlbData = function (user) {
            if (!user || _.isEmpty(user)) {
                toaster.pop('Failure', 'Invalid email.');
            }
            $scope.lbParams.user = user;
            var p0 = $scope.lbParams.userP0 = UserP0Factory.getInstance(user);
            p0.fetch($scope.lbInit);
        }

        $scope.lbInit = function () {
            var p0 = $scope.lbParams.userP0;
            var user = $scope.lbParams.user;
            $scope.lbParams.cycles = p0.allMonthlyCycles;
            $scope.lbParams.selectedCycle = p0.currentMonthlyCycle;
            $scope.lbParams.isOwnLeaderBoard = true;
            $scope.lbParams.roles = _.cloneDeep(p0.allJobRoles);
            $scope.lbParams.activities = p0.allActivities;
            $scope.lbParams.selectedPlayerType = user.roleType;
            $scope.setlbLeagues();
            $scope.setlbDivisions();
            $scope.setlbSortOrder();
            $scope.setlbLimit();
        }

        $scope.setlbDivisions = function () {
            var p0 = $scope.lbParams.userP0;
            $scope.lbParams.divisions = _.cloneDeep(p0.myDivisions);
            $scope.lbParams.selectedDivision = $scope.lbParams.divisions[0];
            $scope.setlbPlayTypes();
        };

        $scope.onlbDivisionChange = function (selectedDivision) {
            $scope.setlbPlayTypes();
        };

        $scope.setlbPlayTypes = function () {
            var allPlayTypes = _.cloneDeep($scope.lbParams.userP0.myPlayTypes);
            $scope.lbParams.playTypes = _.filter(allPlayTypes, function (playType) {
                return _.includes($scope.lbParams.selectedDivision.playTypes, playType._id);
            });

            $scope.lbParams.playTypes.unshift({ name: 'Overall', _id: 'overall' });
            $scope.lbParams.selectedPlayType = $scope.lbParams.playTypes[0];
            $scope.setlbRoles();
        };

        $scope.onlbPlayTypeChange = function (playType) {
            $scope.setlbRoles();
        };

        $scope.setlbRoles = function () {
            var playType = $scope.lbParams.selectedPlayType;

            var sm = { _id: 'seniorManager', name: $rootScope.strings.SENIORMANAGER.value };
            var manager = { _id: 'manager', name: $rootScope.strings.MANAGER.value };
            var frontline = { _id: 'frontline', name: $rootScope.strings.FRONTLINE.value };

            var user = $scope.lbParams.user;

            if (playType._id == 'overall' && user.roleType == 'seniorManager') {
                $scope.lbParams.roles = [sm, manager, frontline];
            } else if (playType._id == 'overall' && user.roleType == 'manager') {
                $scope.lbParams.roles = [manager, frontline];
            } else if (playType._id == 'overall' && user.roleType == 'frontline') {
                $scope.lbParams.roles = [frontline];
            } else if (user.roleType == 'manager' || user.roleType == 'seniorManager') {
                $scope.lbParams.roles = [manager, frontline];
            } else {
                $scope.lbParams.roles = [frontline, manager];
            }
            $scope.lbParams.selectedRole = $scope.lbParams.roles[0];

            $scope.setlbKPIs();
            $scope.setlbFlags();
            $scope.setlbActivities();
            $scope.setlbTypes();
            $scope.setlbLeagues();
        };

        $scope.onlbRoleChange = function () {
            //nothing here
        };

        $scope.setlbLeagues = function () {
            var allLeagues = fetchLeagues.getAllLeagues($scope.lbParams.user, $scope.lbParams.isOwnLeaderBoard, $scope.lbParams.selectedDivision);
            $scope.lbParams.leagues = _.filter(allLeagues, function(league) {
                return league._id !== 'Peer';
            });
            $scope.lbParams.selectedLeague = $scope.lbParams.leagues[0];
        };

        $scope.setlbFlags = function () {
            if ($scope.lbParams.selectedPlayerType == 'frontline') {
                if ($scope.lbParams.userP0.isFrontline) {
                    $scope.lbParams.isOwnLeaderBoard = true;
                } else if ($scope.lbParams.userP0.isMiddleManager) {
                    $scope.lbParams.isOwnLeaderBoard = false;
                } else {
                    $scope.lbParams.isOwnLeaderBoard = false;
                }
            } else {
                if ($scope.lbParams.userP0.isMiddleManager) {
                    $scope.lbParams.isOwnLeaderBoard = true;
                } else if ($scope.lbParams.userP0.isFrontline) {
                    $scope.lbParams.isOwnLeaderBoard = false;
                } else {
                    $scope.lbParams.isOwnLeaderBoard = false;
                }
            }
        };

        $scope.setlbKPIs = function () {
            $scope.lbParams.kpis = [];
            if ($scope.lbParams.selectedPlayType._id != 'overall') {
                $scope.lbParams.kpis = _.cloneDeep($scope.lbParams.selectedPlayType.kpis);
            }
            $scope.lbParams.kpis.unshift({ name: 'Overall', _id: 'overall' });
            $scope.lbParams.selectedKpi = $scope.lbParams.kpis[0];
        };

        $scope.onlbKpiChange = function () {
            //nothing here
        };

        $scope.setlbActivities = function () {
            $scope.lbParams.selectedActivity = { name: "Overall", _id: "overall" };
        };

        $scope.setlbTypes = function () {
            $scope.lbParams.types = [
                { name: "total", _id: "total" },
                { name: "business", _id: "business" },
                { name: "activity", _id: "activity" },
                { name: "kpi", _id: "kpi" }
            ]
            $scope.lbParams.selectedType = $scope.lbParams.types[0];
        };

        $scope.setlbSortOrder = function () {
            $scope.lbParams.selectedSortOrder = sortUpDown[0];
        };

        $scope.setlbLimit = function () {
            $scope.lbParams.limit = 10;
        };

        $scope.getSelectedJobRoleIdBasedOnPlayType = function () {
            if ($scope.lbParams.selectedPlayType._id == 'overall') {
                return $scope.lbParams.selectedRole._id;
            } else {
                let attributeName = ($scope.lbParams.selectedRole._id == 'frontline') ? "frontline" : "managers";
                let allJobRoles = $scope.lbParams.userP0.allJobRoles;
                let _id = $scope.lbParams.selectedPlayType[attributeName][0].toString();
                let role = _.find(allJobRoles, { _id });
                return role._id;
            }
        }

        $scope.setValue = function (field) {
            if (field._id === 'lbFields') {
                var lbParams = $scope.lbParams;
                $scope._lbParamsAdded = _.cloneDeep(lbParams);
                $scope.selected.field.value = JSON.stringify({
                    cycleId: lbParams.selectedCycle._id,
                    divisionId: lbParams.selectedDivision.id,
                    playerType: $scope.getSelectedJobRoleIdBasedOnPlayType(),
                    teamType: lbParams.selectedLeague.value,
                    teamId: lbParams.selectedLeague._id,
                    type: lbParams.selectedType._id,
                    kpiId: lbParams.selectedKpi._id,
                    activityId: lbParams.selectedActivity._id,
                    sort: lbParams.selectedSortOrder._id,
                    limit: lbParams.limit,
                    userEmail: lbParams.email
                });
            }
            if (_.isEmpty(field) || _.isUndefined(field.value) || _.isNull(field.value) || field.value === '') {
                toaster.pop('Failure', 'Invalid field value provided');
                return;
            }
            if (field.showCriteria && !$scope.selected.queryCriteria) {
                toaster.pop('Failure', 'Criteria cant be empty');
                return;
            }
            if (field._id === 'userEmail' && !$scope.selected.userGroup) {
                toaster.pop('Failure', 'UserGroup cannot be empty');
                return;
            }
            if (field._id === 'userEmail') {
                field.value = `${field.value}(${$scope.selected.userGroup})`;
            }

            if (field._id === 'utilisationPercentage' || field._id === 'twcFacilityLimit' ||
                field._id === 'compliance' || field._id === 'lienAmount' || field._id === 'lienRange' || field._id === 'ageing') {
                if (!$scope.selected.compareLogic) {
                    toaster.pop('Failure', 'compareLogic cannot be empty');
                    return;
                }
                field.value = `${field.value}(${$scope.selected.compareLogic})`;
            }

            if (field._id === 'leadAgeing') {
                if (!$scope.selected.compareLogic) {
                    toaster.pop('Failure', 'compareLogic cannot be empty');
                    return;
                } else if (!$scope.selected.leadStatusForAgeing) {
                    toaster.pop('Failure', 'leadStatus cannot be empty');
                    return;
                }
                field.value = `stage:${$scope.selected.leadStatusForAgeing}&days:${field.value}(${$scope.selected.compareLogic})`;
            }
            if (field._id === 'product') {
                field.value = productNameMap[field.value];
            }
            var exprName = `E${$scope.expressions.length + 1}`;
            var exprObj = {};
            var localExpr = {};
            localExpr[field._id] = _.isObject(field.value) ? field.value._id : field.value;
            localExpr['criteria'] = $scope.selected.queryCriteria ? $scope.selected.queryCriteria : null;
            localExpr['isDynamic'] = $scope.selected.isDynamic;
            exprObj[exprName] = localExpr;
            $scope.expressions.push(exprObj);
            $scope.exprToDisplay.push({
                exprName: exprName,
                field: field.label,
                value: _.isObject(field.value) ? field.value._id : field.value,
                criteria: $scope.selected.queryCriteria ? $scope.selected.queryCriteria : null,
                isDynamic: $scope.selected.isDynamic ? 'Dynamic' : 'Constant'
            })
            $scope.expressionsList.push(exprName);
            field = null;
            $scope.selected.field.value = null;
            $scope.selected.field = null;
            $scope.selected.userGroup = null;
            $scope.selected.compareLogic = null;
            $scope.selected.leadStatusForAgeing = null;
            $scope.selected.queryCriteria = $scope.queryCriteriaList[0];
            $scope.lbParams = { sortUpDown };
        }

        $scope.setFormulaValue = function (field) {
            var rule = $scope.selected.rule && formulaRulesMap[$scope.selected.rule];
            var ruleName = $scope.selected.rule;
            if (!rule || (!$scope.selected.expression1 && !$scope.selected.expression2)) {
                return;
            }
            var formulaName = `F${$scope.formulas.length + 1}`;
            var formulaObj = {};
            var localFormula = {};
            if (!_.isNull($scope.selected.expression1) && !_.isNull($scope.selected.expression2)) {
                localFormula[rule] = [$scope.selected.expression1, $scope.selected.expression2];
            } else if (_.isNull($scope.selected.expression1)) {
                localFormula[rule] = [$scope.selected.expression2];
            } else if (_.isNull($scope.selected.expression2)) {
                localFormula[rule] = [$scope.selected.expression1];
            }
            formulaObj[formulaName] = localFormula;
            $scope.formulas.push(formulaObj);
            $scope.formulaToDisplay.push({
                formulaName: formulaName,
                expr1: $scope.selected.expression1,
                expr2: $scope.selected.expression2,
                rule: ruleName
            })
            $scope.expressionsList.push(formulaName);
            $scope.selected.expression1 = null;
            $scope.selected.expression2 = null;
        }

        $scope.setUniqueIdentifier = function (fetchOnlyUniqIdentifiers) {
            $scope.fetchOnlyUniqIdentifiers = fetchOnlyUniqIdentifiers;
            if ($scope.fetchOnlyUniqIdentifiers) {
                $scope.finalQuery.fetchOnlyUniqIdentifiers = $scope.fetchOnlyUniqIdentifiers;
            } else {
                if ($scope.finalQuery) {
                    delete $scope.finalQuery.fetchOnlyUniqIdentifiers;
                }
                $scope.uniqueIdetifiersList = [];
            }
            if ($scope.finalQuery) {
                delete $scope.finalQuery['page'];
                delete $scope.finalQuery['limit'];
            }
            $scope.displayQuery = $scope.finalQuery ? JSON.stringify($scope.finalQuery) : '';
        }
        $scope.submit = function (formula) {
            var finalExpressionsObj = {};
            var finalFormulaObj = {};
            $scope.expressions.forEach(function (expr) {
                var key = _.first(Object.keys(expr));
                finalExpressionsObj[key] = expr[key];
            });
            $scope.formulas.forEach(function (formula) {
                var key = _.first(Object.keys(formula));
                finalFormulaObj[key] = formula[key];
            });
            if (!$scope.finalQuery) {
                $scope.finalQuery = {};
            }
            $scope.finalQuery.expressions = finalExpressionsObj;
            $scope.finalQuery.formula = finalFormulaObj;
            $scope.finalQuery.final = formula.formulaName;
            $scope.finalQuery.collection = $scope.selected.collection._id;
            var fQuery = prepareFinalDisplayQuery($scope.finalQuery.final, $scope.finalQuery);
            var allExpressionsKeys = Object.keys($scope.finalQuery.expressions);
            var allFormulaKeys = Object.keys($scope.finalQuery.formula);
            allExpressionsKeys.forEach(function (key) {
                if (!fQuery.expressions.includes(key)) {
                    delete $scope.finalQuery.expressions[key];
                }
            });
            allFormulaKeys.forEach(function (key) {
                if (!fQuery.formula.includes(key)) {
                    delete $scope.finalQuery.formula[key];
                }
            });
            $scope.displayQuery = JSON.stringify($scope.finalQuery);
        }


        function prepareFinalDisplayQuery(final, lookup, flag) {
            const operands = Object.values(lookup.formula[final])[0];
            let expressions = new Set();
            let formulas = new Set();
            formulas.add(final);
            operands.forEach((x) => {
                if (lookup.formula[x]) {
                    formulas.add(x);
                    let obj = prepareFinalDisplayQuery(x, lookup);
                    formulas = new Set([...formulas, ...obj.formula]);
                    expressions = new Set([...expressions, ...obj.expressions]);
                } else if (lookup.expressions[x]) {
                    expressions.add(x);
                } else {
                    console.log('Invalid operand')
                }
            });
            if (flag) {
                return {
                    expressions: Array.from(expressions).reduce((obj, x) => {
                        obj[x] = lookup.expressions[x];
                        return obj;
                    }, {}),
                    formula: Array.from(formulas).reduce((obj, x) => {
                        obj[x] = lookup.formula[x];
                        return obj;
                    }, {}),
                    final
                };
            } else {
                return { expressions: Array.from(expressions), formula: Array.from(formulas), final };
            }
        }

        $scope.removeMetrics = function () {
            if ($scope.finalQuery) {
                $scope.allowedMetrices.forEach(function (metric) {
                    delete $scope.finalQuery[metric];
                });
                delete $scope.finalQuery['page'];
                delete $scope.finalQuery['limit'];
                $scope.displayQuery = $scope.finalQuery ? JSON.stringify($scope.finalQuery) : '';
            }
            $scope.metricsApplied = false;
            $scope.metricsResult = null;
            $scope.selected.metric = null;
            $scope.selected.metricField = null;
            if ($scope.selected.collection._id === 'leaderBoard') {
                $scope.lbParams.metricsApplied = false;
            }
        }

        $scope.applyMetrics = function () {
            if (!$scope.finalQuery) {
                $scope.finalQuery = {};
            }
            $scope.finalQuery[$scope.selected.metric] = $scope.selected.metric === 'count' ? true : $scope.selected.metricField._id;
            delete $scope.finalQuery['page'];
            delete $scope.finalQuery['limit'];
            $scope.displayQuery = JSON.stringify($scope.finalQuery);
            $scope.metricsApplied = true;
            $scope.setUniqueIdentifier(false);
        }

        $scope.applyProjection = function(projectionField) {
            if (!$scope.finalQuery) {
                $scope.finalQuery = {};
            }
            $scope.finalQuery['projectionField'] = projectionField;
            $scope.displayQuery = JSON.stringify($scope.finalQuery);
        }


        $scope.removeProjection = function() {
            delete $scope.finalQuery['projectionField'];
            $scope.selected.projectionField = null;
            $scope.displayQuery = JSON.stringify($scope.finalQuery);
        }

        $scope.runQuery = function (flow) {
            var query = $scope.finalQuery;
            query.page = flow === 'prev' ? $scope.page - 1 : flow === 'next' ? $scope.page + 1 : 1;
            query.limit = 10;
            $scope.leadHeaders = [];
            $scope.leadsList = [];
            $scope.loading = true;
            queryUtilityService.getResults($scope.selected.collection._id, query).then(function (data) {
                if ($scope.selected.collection._id === 'leaderBoard') {
                    $scope.loading = false;
                    if (Array.isArray(data.leaderboard)) {
                        $scope.totalRecords = data.leaderboard.length;
                        $scope.lbParams.resultHeaders = ['userImage', 'userName', 'rank', 'value'];
                        $scope.lbParams.result = data;
                    } else if (data.hasOwnProperty('count')) {
                        $scope.lbParams.metricsApplied = true;
                        $scope.lbParams.resultHeaders = null;
                        $scope.lbParams.result = JSON.stringify(data);
                    }
                }
                if ($scope.selected.collection._id === 'target') {
                    $scope.metricsResult = JSON.stringify(data.data);
                    $scope.loading = false;
                    return;
                }
                if (!$scope.fetchOnlyUniqIdentifiers && !$scope.metricsApplied && data && data.data && data.data.docs) {
                    data.data.docs.forEach(function (lead) {
                        var leadHeaders = Object.keys(lead);
                        $scope.leadHeaders = _.uniq($scope.leadHeaders.concat(leadHeaders));
                        $scope.leadsList.push(lead);
                    });
                    $scope.page = data.data.page;
                    $scope.totalRecords = data.data.total;
                    $scope.totalPages = data.data.pages;
                    if (data.data.total === 0) {
                        $scope.noRecordsFound = true;
                    } else {
                        $scope.noRecordsFound = false;
                    }
                    $scope.loading = false;
                } else if ($scope.fetchOnlyUniqIdentifiers && data && data.data && _.isArray(data.data)) {
                    $scope.uniqueIdetifiersList = data.data;
                    if (data.data.length === 0) {
                        $scope.noRecordsFound = true;
                    } else {
                        $scope.noRecordsFound = false;
                    }
                    $scope.loading = false;
                } else if ($scope.metricsApplied && data) {
                    $scope.metricsResult = JSON.stringify(data.data);
                    $scope.loading = false;
                }
            },
                function (error) {
                    toaster.pop('error', error.err);
                    $scope.loading = false;
                    return error;
                });
        }
    });
