/* eslint-disable angular/controller-as */
/* eslint-disable angular/log */
/* eslint-disable angular/controller-name */
import xlsx from "ng-xslx";

("use strict");
angular
	.module("bitnudgeApp")
	.controller(
		"prospectUserDashboardCtrl",
		function ($rootScope, $scope, $http, p0, Auth, $q, Sidebar, prospectUserApiService, UtilService, toaster, DashboardService, userApiService) {
			let self = this;
			$scope.strings = p0.strings;
			const me = Auth.getCurrentUser();
			$scope.canViewADFilters = me.organizationalTag.jobRole && me.organizationalTag.jobRole.permissions && me.organizationalTag.jobRole.permissions.showFiltersOnAgentDashboard;
			$scope.selected = {
				trail: [{ _id: me._id, name: me.name, roleType: me.roleType }],
				userId: me._id,
				limit: 30,
				offset: 0,
				apiLoaded: true,
				disablePrevious: true,
				disableNext: true,
				role: me.organizationalTag.jobRole,
				startDate: p0.currentMonthlyCycle.startDate,
				endDate: p0.currentMonthlyCycle.endDate,
				isUserAggregation: false,
			};
			$scope.startDate = p0.currentMonthlyCycle.startDate;
			$scope.endDate = p0.currentMonthlyCycle.endDate;
			$scope.today = new Date();
			$scope.showExportOnAgentDashboard = $rootScope.config.showExportOnAgentDashboard;
			$scope.showEmailOnAgentDashboard = $rootScope.config.showEmailOnAgentDashboard;
			$scope.itemsInARow = 15; //default
			$scope.smPagination = {
				limit: 10,
				page: 1
			};
			$scope.searchSm = ''
			$scope.cycleChange = {
				startDate: function (startDate) {
					if (startDate) {
						$scope.selected.startDate = startDate;
					}
					init($scope.selected.userId);
				},
				endDate: function (endDate) {
					if (endDate) {
						$scope.selected.endDate = endDate;
					}
					init($scope.selected.userId);
				},
			};

			$scope.setSmListPagination = function (result) {
				$scope.smPagination.total = result.total;
				if(result.docs.length > 0)
					$scope.smPagination.page += 1;
			}

			$scope.isInteger = function (num) {
				return Number.isInteger(num);
			};

			self.fetchCounts = function (userId) {
				const deferred = $q.defer();
				const baseUrl = `/api/dashboard/prospectUser/counts`;
				const startTime = new Date(
					new Date($scope.selected.startDate).setHours(0, 0, 0, 0)
				);
				const endTime = new Date(
					new Date($scope.selected.endDate).setHours(23, 59, 59, 999)
				);
				const data = {
					userId,
					limit: $scope.selected.limit,
					offset: $scope.selected.offset,
					startDate: startTime.getTime(),
					endDate: endTime.getTime()
				};
				if($scope.selectedBranch._id !== 'all') {
					data.branchName = $scope.selectedBranch.name;
					data.branchCode = $scope.selectedBranch._id;
				}
				if($scope.selectedRegion._id !== 'all')  {
					data.region = $scope.selectedRegion.name;
					data.regionCode = $scope.selectedRegion._id;
				}
				if($scope.selectedArea._id !== 'all') {
					data.area = $scope.selectedArea.name;
					data.areaCode = $scope.selectedArea._id;
				}
				if($scope.selectedProduct && $scope.selectedProduct._id !== 'all') data.playTypes = prospectUserApiService.getPlayTypeIdFromProductId(p0.allPlayTypes, $scope.selectedProduct._id);

				$scope.selected.apiLoaded = false;
				$http.post(baseUrl, data).then(
					function (result) {
						deferred.resolve(result.data);
					},
					function (error) {
						deferred.reject(error);
					}
				);
				return deferred.promise;
			};

			$scope.searchSms = function(searchTerm){
				$scope.smPagination = {
					page: 1,
					limit:10
				};
				return $scope.getSeniorManagers(searchTerm);
			}

			$scope.getSeniorManagers = async function(searchTerm){
				const reqData = {
					roleTypes: ["seniorManager"],
					userId: me._id,
					limit: $scope.smPagination.limit,
					page: $scope.smPagination.page,
					searchTerm
				};
				let result = await userApiService.getAllUsersLeafOfMultipleJobRoles(reqData)
				$scope.setSmListPagination(result.data.data);
				$scope.dataLoaded = true;
				return result.data.data.docs;
			}

			self.generateDashboardData = async function(countsData, total, aggregationType){
				if (!countsData || !countsData.length) {
					$scope.selected.errorMessage = "No data found";
					toaster.pop('error', "No data found");
					return;
				}

				let userIdsFromCounts;
				let allReporteesFromCounts;
				let userIdVsUser;

				if(aggregationType === 'user'){
					userIdsFromCounts = countsData.map((count)=> count._id);
					allReporteesFromCounts = await prospectUserApiService.fetchUserDetails(userIdsFromCounts);
					userIdVsUser = _.keyBy(allReporteesFromCounts.data, "_id");
				}
				
				const playTypeProductMap = prospectUserApiService.mapPlayTypeAndProduct(p0.allPlayTypes, $scope.products)
				countsData.forEach((res) => {
					if (aggregationType === 'user') {
						let matchingUser = userIdVsUser[res._id];
						res.branch =
						!matchingUser.branchName && !matchingUser.branch
							? "other"
							: matchingUser.branchName || matchingUser.branch;
						res.officeLocationArea =
							!matchingUser.officeLocationArea
								? "other"
								: matchingUser.officeLocationArea;
						res.officeLocationRegional =
							!matchingUser.officeLocationRegional
								? "other"
								: matchingUser.officeLocationRegional;
						res.email = !matchingUser.email
							? "nil"
							: matchingUser.email;
						res.contactNumber = !matchingUser.contactNumber
							? "nil"
							: matchingUser.contactNumber;
						res.createdAt = !matchingUser.createdAt
							? "nil"
							: matchingUser.createdAt;
						
						const matchingProduct = [];
						playTypeProductMap.forEach((play) => {
							if (
								matchingUser.playTypes.includes(play.playTypeId)
							) {
								matchingProduct.push(play.productName);
							}
						});
						res.product = matchingProduct || "n/a";
					}
				});
				$scope.originalSelectedData = countsData;
				$scope.selected.data = _.cloneDeep(
					$scope.originalSelectedData
				);
				$scope.selected.total = total;
				$scope.selected.disableNext = false;
				$scope.selected.disablePrevious = false;
				if (
					$scope.selected.offset + $scope.selected.limit >=
					total
				) {
					$scope.selected.disableNext = true;
				}
				if ($scope.selected.offset == 0) {
					$scope.selected.disablePrevious = true;
				}

				const { counts } = countsData[0];
				$scope.headers = [];
				counts.forEach((column) => {
					$scope.headers.push({
						key: column.key,
						label: column.label,
					});
				});
				if(aggregationType === 'user') {
					$scope.itemsInARow = $scope.headers.length + 4; //here 4 is fixed columns
				} else {
					$scope.itemsInARow = $scope.headers.length + 2; //here 2 is fixed columns
				}
				
			}

			const baseRegion =[{ _id: "all", name: "All Regions" }]
			const baseBranch = [{ _id: "all", name: "All Branches" }];
			const baseArea = [{ _id: "all", name: "All Areas" }];
			const baseProduct = [{ _id: "all", name: "All Products" }];

			async function init(userId) {
				let seniorManagers = [
					{ _id: "all", name: "All Senior Managers" },
				];
				$scope.selectedSeniorManager = seniorManagers[0]
				const fetchedSeniorManagers = await $scope.getSeniorManagers();
				fetchedSeniorManagers.forEach(sm => {
						if (
							sm._id === me._id &&
							seniorManagers.length > 0
						) {
							return;
						}
						seniorManagers.push({
							_id: sm._id,
							name: sm.name,
						});
				});
				$scope.regions = baseRegion;
				$scope.areas = baseArea;
				$scope.branches = baseBranch;
				$scope.seniorManagers = seniorManagers;
				$scope.products = baseProduct;

				$scope.selectedBranch = baseBranch[0]
				$scope.selectedArea = baseArea[0]
				$scope.selectedRegion = baseRegion[0]
				$scope.selectedProduct = baseProduct[0]
				try {
					const { total, data: result, aggregationType } = await self.fetchCounts(
						userId
					);
					$scope.selected.isUserAggregation = aggregationType === 'user';

					let filteredProducts = [];
					if(p0.config.agentOnBoardingProduct){
						filteredProducts = p0.allProducts.filter(p =>{ return p.productCode !== p0.config.agentOnBoardingProduct && p.isMainProduct})
					}
					const fetchedRegions = await prospectUserApiService.fetchRegions()
					$scope.regions = [...baseRegion, ...fetchedRegions.data.data]
					$scope.selected.apiLoaded = true;
					const products = filteredProducts.map((product)=> {return {
						_id : product._id,
						name : product.name
					}})

					$scope.products = [
						...baseProduct,
						..._.compact(products),
					];
					const selected = _.remove(result, { _id: userId });
					if (selected && selected.length) {
						result.unshift(selected[0]);
					}
					await self.generateDashboardData(result, total, aggregationType)

				} catch (err) {
					console.error("err", err);
				}
			}

			$scope.onBranchChange = function (selectedBranch) {
				$scope.selectedBranch = selectedBranch;
				self.handleFilter();
			};

			$scope.onAreaChange = async function (selectedArea) {
				$scope.selectedArea = selectedArea;
				$scope.selectedBranch = baseBranch[0]
				let fetchedBranches = []
				if($scope.selectedRegion._id !== "all" && selectedArea._id !== "all"){
					fetchedBranches = await prospectUserApiService.fetchBranches($scope.selectedRegion._id, selectedArea._id)
				} else {
					fetchedBranches.data= {data : []}
				}
				$scope.branches = [baseBranch[0], ...fetchedBranches.data.data]
				self.handleFilter();
			};

			$scope.onRegionChange = async function (selectedRegion) {
				let fetchedAreas = []
				$scope.selectedRegion = selectedRegion
				$scope.selectedArea = baseArea[0]
				$scope.selectedBranch = baseBranch[0]
				if(selectedRegion._id !== "all") {
					fetchedAreas = await prospectUserApiService.fetchAreas(selectedRegion._id)
				} else {
					fetchedAreas.data = {data : []}
				}
				$scope.areas = [baseArea[0], ...fetchedAreas.data.data]
				$scope.branches = baseBranch
				self.handleFilter();
			};

			$scope.onSeniorManagerChange = function (selectedSeniorManager) {
				$scope.selectedSeniorManager = selectedSeniorManager;
				self.handleFilter();
			};

			$scope.onProductChange = function (selectedProduct) {
				$scope.selectedProduct = selectedProduct;
				self.handleFilter();
			};

			//handle filterind data

			self.handleFilter = async function () {
				const {total, data, aggregationType} = await self.fetchCounts($scope.selected.userId)
				await self.generateDashboardData(data, total, aggregationType);
				if ($scope.selectedSeniorManager._id === "all") {
					$scope.selected.data = $scope.selected.data.filter((a) => true);
				} else {
					const filteredData = $scope.selected.data.filter((data) => {
						return data._id === $scope.selectedSeniorManager._id;
					});
					$scope.selected.data = filteredData;
				}
			};

			$scope.onSmSelect = function (user) {
				$scope.selected.trail.push(user);
				$scope.selected.userId = user._id;
				$scope.selected.offset = 0;
				init(user._id);
			};

			$scope.onBreadCrumbClick = function (user) {
				const index = _.findIndex($scope.selected.trail, {
					_id: user._id,
				});
				$scope.selected.trail.length = index + 1;
				$scope.selected.offset = 0;
				$scope.selected.userId = user._id;
				init(user._id);
			};

			$scope.nextPage = function () {
				$scope.selected.offset += $scope.selected.limit;
				init($scope.selected.userId);
			};

			$scope.previousPage = function () {
				$scope.selected.offset -= $scope.selected.limit;
				init($scope.selected.userId);
			};

			$scope.exportExcel = function () {
				const appUrl = Sidebar.getAppUrl();
				const baseUrl = `/api/dashboard/prospectUser/counts/download`;
				const startDate = new Date(
					new Date($scope.selected.startDate).setHours(0, 0, 0, 0)
				);
				const endDate = new Date(
					new Date($scope.selected.endDate).setHours(23, 59, 59, 999)
				);
				const params = {
					userId : $scope.selected.userId,
					startDate,
					endDate,
				}
				return UtilService.downloadFile(appUrl + baseUrl, params);
			}
			$scope.emailExcel = async function () {
				const params = {
					userId : $scope.selected.userId,
				}
				try {
					await DashboardService.emailProspectExportExcel(params);
					toaster.pop('success', "Agent export triggered successfully!", "You will receive the counts email soon.");

				} catch(err) {
					console.log('err: ', err)
					toaster.pop('error', "Agent export", "Error occured while triggering agent export.");
				}
				

			}
			$scope.returnPercentageValue = function(value) {
				return value <= 100 ? value : 100;
			}
			init($scope.selected.userId);
		}
	);
