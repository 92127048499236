
// api calls related to users

"use strict";
angular.module("bitnudgeApp").service("userApiService", [
	"$http",
	"$q",
	function ($http, $q) {
		var self = this;

        self.getAllUsersLeafOfMultipleJobRoles = function(reqData) {

            const deferred = $q.defer();
            const url = '/api/users/getAllLeafJobRoles'
            $http.post(url, reqData)
            .then(
                function(result){
                    deferred.resolve(result);
                },
                function(err){
                    deferred.reject(err);
                } 
            );
            return deferred.promise;
        
        }

	}]);
