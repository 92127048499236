const autoImportNgTemplateLoaderTemplate1 = require('../html/managePeersModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('leaderboardsController', function($scope, $rootScope, $http, $location, $timeout, Auth, p0, fetchLeagues, $q, $stateParams, $state, $mdDialog, CurrentCycle, ngXlsx, toaster, social, firework, userAnalyticsService){

        var currentMonthlyCycle;

        this.setRoleTypeDropdown = (function (playerType) {
            var sm = {_id:'seniorManager', name: $rootScope.strings.SENIORMANAGER.value};
            var manager = {_id:'manager', name: $rootScope.strings.MANAGER.value};
            var frontline = {_id: 'frontline', name: $rootScope.strings.FRONTLINE.value};

            if(playerType._id == 'overall' && $scope.me.roleType == 'seniorManager'){
                let myRoleId = $scope.me.organizationalTag.jobRole._id;
                let myRole = _.find(p0.allJobRoles, {_id:myRoleId});
                if ($scope.config.showBasicScorecard) {
                    $scope.roleTypes = [manager, frontline];
                }
                else {
                    $scope.roleTypes = [manager, frontline];
                    if(myRole.level ===3){
                        if(myRole.parents && myRole.parents.length){
                            $scope.roleTypes.unshift(sm);
                        }
                    }else{
                        $scope.roleTypes.unshift(sm);
                    }
                }
            }else if(playerType._id == 'overall' && $scope.me.roleType == 'manager'){
                $scope.roleTypes = [manager, frontline];
            }else if(playerType._id == 'overall' && $scope.me.roleType == 'frontline'){
                $scope.roleTypes = [frontline];
            }else if($scope.me.roleType ==  'manager' || $scope.me.roleType == 'seniorManager'){
                $scope.roleTypes = [manager, frontline];
            }else{
                $scope.roleTypes = [frontline, manager];
            }
            $scope.roleType = $scope.roleTypes[0];
        }).bind(this);

        this.setDivisions = (function(){
            var allDivisions = _.cloneDeep(p0.myDivisions);
            $scope.allDivisions = allDivisions;
            /*if($rootScope.config.overallLeague) {
                $scope.allDivisions.unshift({name: 'Overall', _id: 'overall'});
                $scope.showLeagues = false;
            }*/
            $scope.selectedDivision = $scope.allDivisions[0];
            this.setPlayTypes($scope.selectedDivision);
            if($scope.selectedDivision.id == 'overall'){
                $scope.showCompare = false;
            }
        }).bind(this);
        $scope.onPlayTypeChange = (function(playType){
            $scope.selectedPlayType = playType;
            $scope.getRelevantKpis();
            this.setRoleTypeDropdown(playType);
            $scope.filterChange.roleType($scope.roleType)
            $scope.filterChange.playType($scope.selectedPlayType)
        }).bind(this);
        this.setPlayTypes = (function(division){
            var allPlayTypes = _.cloneDeep(p0.myPlayTypes);
            if(division.id == 'overall'){
                $scope.allPlayTypes = allPlayTypes;
            }else{
                $scope.allPlayTypes = _.filter(allPlayTypes, function(playType){
                    return _.includes(division.playTypes, playType._id);
                });
            }

            //OverallLeague among players in different divisions or playTypes
            /*if($rootScope.config.overallLeague) {
                $scope.allPlayTypes.unshift({name: 'Overall', _id: 'overall'});
                $scope.showLeagues = false;
            }*/
            $scope.allPlayTypes.unshift({name: 'Overall', _id: 'overall'});
            $scope.selectedPlayType = $scope.allPlayTypes[0];
            $scope.onPlayTypeChange($scope.selectedPlayType);
        }).bind(this);

        this.setKpis = (function(playType){
            $scope.kpisForDropdown = [];
            if(playType._id != 'overall'){
                $scope.kpisForDropdown = _.cloneDeep(playType.kpis);
            }

            if(p0.config.agentOnBoardingProductKpi) {
				$scope.kpisForDropdown = $scope.kpisForDropdown.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi && k.roleTypes.includes($scope.roleType._id))
            } else {
                $scope.kpisForDropdown = $scope.kpisForDropdown.filter(k => k.roleTypes.includes($scope.roleType._id))
            }

            $scope.kpisForDropdown = _.sortBy($scope.kpisForDropdown, ['sequence']);
            $scope.kpisForDropdown.unshift({name:'Overall', _id:'overall'});

            $scope.selectedKpi = $scope.kpisForDropdown[0];
        }).bind(this);

        $scope.downloadExcel = (function(){
            var cycle = $scope.selectedCycle,
                role =  $scope.roleType.name;
            var divisionName = $scope.trackData.name;
            var data = [];
            $scope.trackData.leaderboard.forEach(function(row){
                data.push({
                    employeeId: row.userId.email,
                    name: row.userName,
                    leagueRank: row.rank,
                    jobRole: row.userId.organizationalTag.jobRole.name,
                    teamName: row.userId.teams[0].name,
                    points:row.value.toFixed(0)
                });
            });
            var result = ngXlsx.writeXlsx([
                {
                    sheetName:  role+'s-'+ divisionName ,
                    columnDefs: [
                        {field: "employeeId", displayName: "Emp ID"},
                        {field: "name", displayName: "Name"},
                        {field: "leagueRank", displayName: "Rank"},
                        {field: "jobRole", displayName: "Job Role"},
                        {field: "teamName", displayName: "Team Name"},
                        {field: "points", displayName: "Points"}
                    ],
                    data: data
                }
            ]);
            // TODO: move excel download to server side
            saveAs(new Blob([this.s2ab(result)],{type:"application/octet-stream"}), "Leaderboard-"+role+"s-"+divisionName+"-["+cycle.name+"].xlsx");
        }).bind(this);

         this.s2ab=(function(s){
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }).bind(this)


        this.loadFlags = (function(){
            if($scope.playerType == 'frontline'){
                if($rootScope.isFrontline){
                    $scope.isOwnLeaderBoard = true;
                    $scope.showCompare = true;
                }else if($rootScope.isMiddleManager){
                    $scope.isOwnLeaderBoard = false;
                    $scope.showCompare = true;
                }else{
                    $scope.isOwnLeaderBoard = false;
                    $scope.showCompare = true;
                }
                $scope.managerEnabled = false;
            }else{
                if($rootScope.isMiddleManager){
                    $scope.isOwnLeaderBoard = true;
                    $scope.showCompare = true;
                }else if($rootScope.isFrontline){
                    $scope.isOwnLeaderBoard = false;
                    $scope.showCompare = false;
                }else{
                    $scope.isOwnLeaderBoard = false;
                    $scope.showCompare = true;
                }
                $scope.managerEnabled = true;
            }
            $scope.showTrackDetails = false;
        }).bind(this);




        this. getJobRoles=(function(){
            if($scope.selectedPlayType._id == 'overall'){
                $scope.selectedRole = {_id:$scope.roleType._id};
                $scope.jobRole = $scope.me.organizationalTag.jobRole;
            } else if($scope.roleType._id == 'frontline'){
                $scope.selectedRole = _.find(p0.allJobRoles, {_id:$scope.selectedPlayType.frontline[0].toString()});
                $scope.jobRole = _.cloneDeep($scope.selectedRole);
            }else{
                $scope.selectedRole = _.find(p0.allJobRoles, {_id:$scope.selectedPlayType.managers[0].toString()});
                $scope.jobRole = _.cloneDeep($scope.selectedRole);
            }
            $scope.isOwn = ($scope.me.organizationalTag.jobRole._id == $scope.selectedRole._id);
            this.setLeagues();
            $scope.getAllLeaderboards();
        }).bind(this);



        this.setLeagues = (function () {
            $scope.allLeagues = fetchLeagues.getAllLeagues($scope.me, $scope.isOwnLeaderBoard, $scope.selectedDivision);
            $scope.selectedLeague = $scope.allLeagues[0];
        }).bind(this);


        $scope.getAllLeaderboards = (function(){
            var cycle = $scope.selectedCycle,
                role = $scope.selectedRole,
                league = $scope.selectedLeague;
            if(!cycle || !role || !league) {
                console.error('cycle, role or league not found :' , cycle,role,league);
                return;
            }
            if($scope.selectedKpi._id == 'overall') {
                $scope.lbs = this.getLbs();
            }else {
                $scope.lbs = this.getLbsForKpi($scope.selectedKpi._id);
            }
            var lbArray;
            var arrCalls = [];
            lbArray = $scope.lbs;
            lbArray.forEach(function(lb, index){
                if(lb.kpiId)
                    arrCalls.push(this.getLb(lb.type, lb.kpiId, lb.activityId, cycle, role, league, lbArray, "#overallLB-loading", lb.lbOn));
            }.bind(this));
            $q.all(arrCalls).then(function(arrayOfResults) {
                arrayOfResults.forEach(function(lbx, index){
                    var lb = lbArray[index];
                    var leaderboard = arrayOfResults[index].data;
                    var numOfReporteesInTopFive, numOfReportees;
                    var userLeaders = leaderboard.leaderboard
                    if($scope.me.children!= undefined) {
                        numOfReportees= $scope.me.children.length
                        userLeaders = _.filter(userLeaders, function(user){
                            var userIds = _.map($scope.me.children, function(user){return user.childId.toString()});
                            return _.includes(userIds, user.userId._id.toString());
                        });
                        if(userLeaders)
                            numOfReporteesInTopFive = userLeaders.length
                    }
                    var index = _.findIndex(lbArray, {type:lb.type, kpiId:lb.kpiId, activityId:lb.activityId});
                    lbArray[index].leaderboard = leaderboard.leaderboard;
                    lbArray[index].userRank = leaderboard.userRank;
                    lbArray[index].leagueSize = leaderboard.leagueSize;
                    lbArray[index].managerRank = leaderboard.managerRank;
                    lbArray[index].numOfReportees = numOfReportees;
                    lbArray[index].movers = _.filter(leaderboard.movers,function(user){return user.deltaRank > 0;});
                    lbArray[index].shakers = _.filter(leaderboard.movers,function(user){return user.deltaRank < 0;});
                    lbArray[index].numOfReporteesInTopFive = numOfReporteesInTopFive;
                });

                $scope.trackData = $scope.lbs[$scope.currMetric];
                this.setVirtualLeagues();

            }.bind(this)).catch(function(err){
                console.error(err);
            });
        }).bind(this);

        $scope.fnShowTrackDetails = (function(trackDetails){
            $scope.trackDetails = trackDetails;
            $scope.showTrackDetails = true;
        }).bind(this);

        $scope.hideTrackDetails = (function(){
            $scope.showTrackDetails = false;
        }).bind(this);

        $scope.getAllLeaderboardsForKpis = (function(){
            var cycle = $scope.selectedCycle,
                role = $scope.selectedRole,
                league = $scope.selectedLeague;
            if(!cycle || !role || !league) {
                console.error('cycle, role or league not found :' , cycle,role,league);
                return;
            }
            if($scope.lbsForKpis){
                var lbArray;
                var arrCalls = []
                lbArray = $scope.lbsForKpis;
                lbArray.forEach(function(lb, index){
                    if(lb.kpiId)
                        arrCalls.push(this.getLb(lb.type, lb.kpiId, lb.activityId, cycle, role, league, lbArray, "#overallLB-loading", lb.lbOn));
                });
            }

            $q.all(arrCalls).then(function(arrayOfResults) {
                arrayOfResults.forEach(function(lbx, index){
                    var lb = lbArray[index];
                    var leaderboard = arrayOfResults[index].data;
                    var numOfReporteesInTopFive, numOfReportees;
                    var userLeaders = leaderboard.leaderboard
                    if($scope.me.children!= undefined) {
                        numOfReportees= $scope.me.children.length
                        userLeaders = _.filter(userLeaders, function(user){
                            var userIds = _.map($scope.me.children, function(user){return user.childId.toString()});
                            return _.includes(userIds, user.userId._id.toString());
                        });
                        if(userLeaders)
                            numOfReporteesInTopFive = userLeaders.length
                    }
                    var index = _.findIndex(lbArray, {type:lb.type, kpiId:lb.kpiId, activityId:lb.activityId,});
                    lbArray[index].leaderboard = leaderboard.leaderboard;
                    lbArray[index].userRank = leaderboard.userRank;
                    lbArray[index].leagueSize = leaderboard.leagueSize;
                    lbArray[index].managerRank = leaderboard.managerRank;
                    lbArray[index].numOfReportees = numOfReportees;
                    lbArray[index].movers = leaderboard.leaderboard.slice(0,2);
                    lbArray[index].shakers = leaderboard.leaderboard.slice(0,3);
                    lbArray[index].numOfReporteesInTopFive = numOfReporteesInTopFive;
                });
                $scope.relatedLeaderBoardLoaded = true;
                setTimeout(function() {
                    $scope.isSlickDataLoaded = true;
                }, 10);
            });

        }).bind(this);
        this.setKPIDropdown = (function(callback){
            $scope.kpisForDropdown= [];
            if($scope.selectedDivision.id == 'overall'){
                $scope.kpisForDropdown = [];
                setOverall();
            }else {
                userAnalyticsService.fetchUserAnalytics($scope.me._id, $scope.selectedCycle._id).then( function(userAna){
                    $scope.kpisForDropdown = _.cloneDeep(_.filter($scope.selectedDivision.kpis, function(goal){
                        if(userAna){
                            var gm =  _.find(userAna.kpiData,{kpiId: goal._id});
                        }else{
                            var gm = {};
                        }
                        if(gm && gm.target && gm.target.value){
                            return true;
                        }
                    }));
                    setOverall();
                });
            }
            function setOverall(){
                $scope.kpisForDropdown.unshift({name:'Overall', _id:'overall'});
                $scope.selectedKpi = $scope.kpisForDropdown[0];
                return callback(true);
            }
        }).bind(this);
        $scope.getRelevantKpis = (function(){
            $scope.activities = _.cloneDeep(p0.allActivities);
            $scope.activities = $scope.activities.filter(function (element, index, list) {
                return element.leaderboard;
            });
            $scope.activities = _.sortBy($scope.activities, 'activityCode');
            // Handling data requested for params cycle, role, league.
        }).bind(this);

        $scope.onChangeKpi = (function() {
            $scope.teamType='activity';
            $scope.getAllLeaderboards();
        }).bind(this);

        this.getLbsForKpi = (function (kpiId) {
            var lbsKpis = [];
            var jobroleId = $scope.selectedRole;
            if(jobroleId._id){
                jobroleId = jobroleId._id;
            }
            $scope.isSlickDataLoaded = false;
            var loadingText = "Loading...";
            var kpi = _.find($scope.kpisForDropdown, {_id: kpiId});
            var unit = $scope.config.showBasicScorecard ? ' (%)':'';
            lbsKpis.push({name:kpi.name+unit, type:"kpi", kpiId:kpi._id, activityId:"overall", leaderboard:null, loadingText:loadingText, comparable:true});
            if ($scope.config.showBasicScorecard) {
                lbsKpis.push({ name: kpi.name + " achievement (%)", type: "kpi", kpiId: kpi._id, activityId: "achievement", leaderboard: null, loadingText: loadingText, comparable: true });
            }
            var kpiSpecificActivities = _.filter($scope.activities, function(activity){
                return activity.kpiSpecific && _.find(activity.kpiData,{kpiId:kpiId});
            });
            kpiSpecificActivities.forEach(function(activity){
                var pointsMultiplier = _.find(activity.pointsMultipliers,{role:jobroleId});
                var lbOn =  pointsMultiplier.value ? 'points' :'value';
                lbsKpis.push({name:activity.names[kpiId], type:"activity", kpiId:kpiId, activityId:activity._id, leaderboard:null, loadingText:loadingText, comparable:true, lbOn:lbOn, unit: activity.unit});
            });
            if(p0.config.showBasicScorecard && kpi.kpiCode == 'K01'){
                var actFieldNames =  ["CSATCount","Quality"];
                var basicActs = _.filter($scope.activities, function(activity){
                    return _.includes(actFieldNames, activity.fieldName);
                });
                basicActs.forEach(function(activity){
                    var pointsMultiplier = _.find(activity.pointsMultipliers,{role:jobroleId});
                    var lbOn =  pointsMultiplier.value ? 'points' :'value';
                    var multiplier = activity.activityType == 'percentage' ? 100 :1 ;
                    lbsKpis.push({name:activity.name, type:"activity", kpiId:"overall", activityId:activity._id, leaderboard:null, loadingText:loadingText, comparable:true, lbOn:lbOn, unit: activity.unit, multiplier:multiplier});
                });
            }

            /*if($rootScope.config.lms) {
                lbsKpis.push({name: 'Conversion ratio', type: "kpi", kpiId: kpi._id, activityId: "conversionRatio", leaderboard: null, loadingText: loadingText});
            }*/

            return lbsKpis;
        }).bind(this);

        $scope.jobRoleChanged = (function(){
            this.setKPIDropdown(function(result){
                $scope.getRelevantKpis();
            });
        }).bind(this);


        this.getLbs=(function() {
            var lbs = [];
            var loadingText = "Loading...";
            var unit = $scope.config.showBasicScorecard ? ' (%)':'';
            let comparable = true;
            if($scope.selectedPlayType._id === "overall"){
                comparable = false;
            }
            // some legacy codes are commented here from beginning keeping this for now
            // will remove when entire QA scope of lb is complete
            lbs.push({name:"Overall "+$scope.strings.POINTS_O.value+unit,                type:"total",           kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText, comparable:comparable});
            if($scope.selectedPlayType._id != 'overall'){
                if (!$scope.config.showBasicScorecard) {
                    lbs.push({ name: $scope.strings.IMPACT.value + ' ' + $scope.strings.POINTS.value, type: "business", kpiId: "overall", activityId: "overall", leaderboard: null, loadingText: loadingText, comparable: comparable });
                    lbs.push({ name: $scope.strings.ACTIVITY.value + ' ' + $scope.strings.POINTS.value, type: "activity", kpiId: "overall", activityId: "overall", leaderboard: null, loadingText: loadingText, comparable: comparable });
                }
                //lbs.push({name:"Badges",                        type:"badge",           kpiId:"overall",        activityId:"overall",               leaderboard:null,           loadingText:loadingText});

                var lbOn = 'points';
                var jobroleId = $scope.selectedRole;
                if(jobroleId._id){
                    jobroleId = jobroleId._id;
                }
                if(!p0.config.showBasicScorecard){
                    $scope.activities.forEach(function (activity) {
                        var pointsMultiplier = _.find(activity.pointsMultipliers,{role:jobroleId});
                        lbOn =  pointsMultiplier.value ? 'points' :'value';
                        lbs.push({
                            name: activity.name,
                            type: "activity",
                            kpiId: "overall",
                            activityId: activity._id,
                            leaderboard: null,
                            loadingText: loadingText,
                            comparable:comparable,
                            lbOn: lbOn,
                            unit: activity.unit
                        });
                    });
                }

                /*if($scope.selectedKpi._id != 'overall') {
                    $scope.kpisForDropdown.forEach(function (kpi) {
                        if (kpi._id != 'overall') {
                            lbs.push({
                                name: kpi.name,
                                type: "kpi",
                                kpiId: kpi._id,
                                activityId: "overall",
                                leaderboard: null,
                                loadingText: loadingText
                            });
                        }
                    });
                    $scope.activities.forEach(function (activity) {
                        lbs.push({
                            name: activity.name,
                            type: "activity",
                            kpiId: "overall",
                            activityId: activity._id,
                            leaderboard: null,
                            loadingText: loadingText
                        });
                    });
                }else{
                    $scope.activities.forEach(function (activity) {
                        lbs.push({
                            name: activity.name,
                            type: "activity",
                            kpiId: "overall",
                            activityId: activity._id,
                            leaderboard: null,
                            loadingText: loadingText
                        });
                    });
                }*/
            }
            return lbs;
        }).bind(this)


        /** lbOn will be either points or value**/
        this.getLbRoute=(function(type, kpiId, activityId, cycle, jobRole, league, lbOn) {
            const url = '/api/analytics/leaderboards/cycles';
            const postData = {
                cycleId: cycle._id,
                kpiId,
                activityId,
                count:5,
                type,
                playerType: $scope.roleType._id,
                teamType: league.value,
                teamId: league._id,
                lbOn
            }
            const playType = _.get($scope.selectedPlayType, '_id', null);
            if (playType) {
                postData['playType'] = playType;
            }
            return {url,postData}
        }).bind(this)

        this.getLb=(function(type, kpiId, activityId, cycle, jobRole, league, lbArray, congratsElement, lbOn) {
            //$(congratsElement).text("Loading...");
            var cache = league.value != "peer";
            const urlDetails = this.getLbRoute(type, kpiId, activityId, cycle, jobRole, league, lbOn);
            return $http.post(urlDetails.url, urlDetails.postData);
        }).bind(this)

        $scope.incrementCounter = (function(){
            var index = $scope.currMetric;
            if($scope.currMetric == $scope.lbs.length-1)
                $scope.currMetric = 0;
            else
                $scope.currMetric += 1;
            $scope.slickConfigCard.method.slickGoTo($scope.currMetric);
            $scope.trackData = $scope.lbs[$scope.currMetric];
            if($scope.isRelatedLeaderBoards){
                $scope.getRelatedLeaderBoards('inline');
            }
            _.forEach($scope.lbs[index].leaderboard, function (selectedUser) {
                if(selectedUser){
                    var foundUser = _.find($scope.lbs[$scope.currMetric].leaderboard, function(user){
                        return user.userId._id == selectedUser.userId._id;
                    });
                    if(foundUser) foundUser.compareSelection = selectedUser.compareSelection;
                }
            });
        }).bind(this);

        $scope.decrementCounter = (function(){
            var index = $scope.currMetric;
            if($scope.currMetric == 0)
                $scope.currMetric = $scope.lbs.length-1;
            else
                $scope.currMetric -= 1;
            $scope.slickConfigCard.method.slickGoTo($scope.currMetric);
            $scope.trackData = $scope.lbs[$scope.currMetric];
            if($scope.isRelatedLeaderBoards){
                $scope.getRelatedLeaderBoards('inline');
            }
            _.forEach($scope.lbs[index].leaderboard, function (selectedUser) {
                if(selectedUser){
                    var foundUser = _.find($scope.lbs[$scope.currMetric].leaderboard, function(user){
                        return user.userId._id == selectedUser.userId._id;
                    });
                    if(foundUser) foundUser.compareSelection = selectedUser.compareSelection;
                }
            });
        }).bind(this);

        $scope.setCurrIndex = (function(ind){
            $scope.currMetric = ind;
            $scope.trackData = $scope.lbs[$scope.currMetric];
            if($scope.isRelatedLeaderBoards){
                $scope.getRelatedLeaderBoards('inline');
            }
        }).bind(this);

        $scope.filterChange = {
            kpi : function(selectedKpi){
                if($scope.selectedKpi == selectedKpi) return;
                $scope.selectedKpi = selectedKpi;
                $scope.isSlickDataLoaded = false;
                $scope.compareList = [];
                $scope.onChangeKpi();
            },
            cycle : function(selectedCycle){
                $scope.selectedCycle = selectedCycle;
                if($scope.selectedCycle.cycleType == 'monthly'){
                    currentMonthlyCycle = selectedCycle;
                }
                $scope.currMetric = 0;
                $scope.getAllLeaderboards();
            },
            league : function(selectedLeague){
                $scope.selectedLeague = selectedLeague;
                $scope.isSlickDataLoaded = false;
                $scope.compareList = [];
                $scope.getAllLeaderboards();
            },
            playType: function(selectedPlayType){
                $scope.selectedPlayType = selectedPlayType;
                $scope.isSlickDataLoaded = false;
                $scope.compareList = [];
                $scope.getAllLeaderboards();
            },
            roleType: (function(roleType){
                // if(roleType && $scope.playerType != roleType._id){
                    $scope.roleType = roleType;
                    $scope.playerType = roleType._id;
                    $scope.compareList = [];
                    $scope.dataLoaded = false;
                    $scope.isSlickDataLoaded = false;
                    this.loadFlags();
                    this.setKpis($scope.selectedPlayType);
                    this.getJobRoles();
                // }
            }).bind(this),
            virtualLeague:(function(virtualLeague){
                $scope.selectedVirtualLeague = virtualLeague;
                this.setVirtualLB(virtualLeague);
            }).bind(this)
        }

        $scope.getStyle = (function(rank){
            var width = "";
            var marginTop = "";
            switch(rank){
                case 1:
                    width = "90%";
                    marginTop = "10px";
                    break;
                case 2:
                    width = "80%";
                    marginTop = "15px";
                    break;
                case 3:
                    width = "70%";
                    marginTop = "20px";
                    break;
                default:
                    width = "50%";
                    marginTop = "25px";
                    break;
            }
            return {width:width,margin:'auto','border-radius':'100%','bottom':'0px',"margin-top":marginTop};
        }).bind(this);

        $scope.closeMoverAndShakers = (function(){
            $scope.isMoversAndShakers = false;
        }).bind(this);

        $scope.closeRelatedLeaderboard = (function(){
            $scope.isRelatedLeaderBoards = false;
        }).bind(this);

        $scope.showMoverAndShaker = (function(){
            if($scope.selectedDivision.id != 'overall' && $scope.selectedVirtualLeague.name == 'All') {
                $scope.isRelatedLeaderBoards = false;
                $scope.isMoversAndShakers = !$scope.isMoversAndShakers;
            }
        }).bind(this);

        $scope.getRelatedLeaderBoards = (function(state){
            $scope.isMoversAndShakers = false;
            if(!state){
                $scope.isRelatedLeaderBoards = !$scope.isRelatedLeaderBoards;
            }

            $scope.relatedLeaderBoardLoaded = false;
            $scope.relatedLeaderBoardData = [];
            if($scope.lbs[$scope.currMetric].type == "kpi"){
                $scope.lbsForKpis = this.getLbsForKpi($scope.lbs[$scope.currMetric].kpiId);
                $scope.getAllLeaderboardsForKpis();
            }else{
                $timeout(function () {
                    $scope.relatedLeaderBoardLoaded = true;
                }, 10);
                $scope.lbsForKpis = JSON.parse(JSON.stringify($scope.lbs));
            }
        }).bind(this);

        $scope.setMiddleSectionHeight = (function(){
            var windowHeight = $(document).height();
            var middleSectionOffset = $("#middleSection").offset();
            var footerHeight = $(".dashboard-footer").height();
            if($scope.divisionHeight != (windowHeight - middleSectionOffset.top - footerHeight))
                $scope.divisionHeight = windowHeight - middleSectionOffset.top - footerHeight;

            return {height:$scope.divisionHeight,"position":"relativie","display":"flex"};
        }).bind(this);

        $scope.setTrackSectionStyles = (function(){
            var trackSectionHeight = $('.track-section').height();
            var trackSectionWidth = $('.track-section').width();

            return {width:trackSectionWidth,height:trackSectionHeight};
        }).bind(this);

        $scope.getRelatedLeaderBoardsWidth = (function(){
            var trackSectionWidth = $('.track-section').width() -50;
            return {width:trackSectionWidth};
        }).bind(this);

        $scope.setMoversShakersHeight = (function(){
            var trackSectionHeight = $('.track-section').height() - 35;
            return {height:trackSectionHeight};
        }).bind(this);

        $scope.onDivisionChange = (function(selectedDivision){
            $scope.selectedDivision = selectedDivision;
            $scope.isSlickDataLoaded = false;
            this.setPlayTypes($scope.selectedDivision);
        }).bind(this);

        $scope.openPeersModal = (function () {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'managePeersModalController',
                size: 'md',
                resolve: {
                    refreshDataCB:  function(){return   $scope.getAllLeaderboards;}
                }
            });
        }).bind(this);

        $scope.comparePlayer = (function() {
            $rootScope.jobRoleFromLB = $scope.me.organizationalTag.jobRole;
            $rootScope.myComparison = true;
            $rootScope.playersFromLB = [$scope.trackDetails.userId.id];
            $rootScope.comparePlayersFromLB = true;
            $state.go('userFlow.analytics', {tabName: 'playerComparison', myComparison: true, roleType: $scope.playerType,cycleId:$scope.selectedCycle._id});
        }).bind(this);

        $scope.gotoSimulate = (function(){
            $state.go('userFlow.gameplanSimulation',{userId:$scope.trackDetails.userId.id});
        }).bind(this);

        $scope.gotoProfile = (function(){
            $state.go('userFlow.profile',{selectedUserId: $scope.trackDetails.userId.id});
        }).bind(this);

        $scope.comparePlayers = (function(name, type, kpiId, activityId, league){
            if($scope.isOwnLeaderBoard && $scope.compareList.length < 1)
                return toaster.pop('error', 'Comparison Selection', "Please select at least 1 player for Comparison");
            else if(!$scope.isOwnLeaderBoard && $scope.compareList.length < 2)
                return toaster.pop('error', 'Comparison Selection', "Please select at least 2 players for Comparison");

            var selectedPlayerIds=[];
            $scope.compareList.forEach(function(user){
                selectedPlayerIds.push(user.userId._id);
            });
            $rootScope.playersFromLB = selectedPlayerIds;
            $rootScope.comparePlayersFromLB = true;
            $rootScope.jobRoleFromLB = $scope.selectedRole;
            $rootScope.nameOfLb = name;
            $rootScope.typeOfLb = type;
            $rootScope.kpiIdOfLb= kpiId;
            $rootScope.activityIdOfLb= activityId;

            if(league === 'peer') $rootScope.leagueOfLb = 'bank';
            else $rootScope.leagueOfLb = league;

            var roleType ='';   // manager, frontline
            if($scope.managerEnabled) roleType = 'manager';
            else roleType = 'frontline';
            $state.go('userFlow.analytics', {tabName:'playerComparison', myComparison: $scope.isOwnLeaderBoard, roleType: roleType,cycleId:$scope.selectedCycle._id});

        }).bind(this);

        this.setVirtualLeagues = (function(){
            var virtualLeagues = _.map($scope.lbs[$scope.currMetric].leaderboard,'userId.virtualLeague');
            virtualLeagues = _.without(virtualLeagues,undefined);
            virtualLeagues = _.without(virtualLeagues,null);
            $scope.allVirtualLeagues = _.uniqBy(virtualLeagues,function(league){
                return league.toString();
            });
            $scope.allVirtualLeagues.unshift('All');
            $scope.allVirtualLeagues = _.map($scope.allVirtualLeagues,function(league){
                return {name:league};
            });
            $scope.selectedVirtualLeague = $scope.allVirtualLeagues[0];
            this.setVirtualLB($scope.selectedVirtualLeague);
        }).bind(this)

        this.setVirtualLB=(function(virtualLeague){
            $scope.virtualLbs = _.cloneDeep($scope.lbs);
            if(virtualLeague.name != 'All'){
                $scope.virtualLbs.forEach(function(leaderboard,key){
                    $scope.virtualLbs[key].leaderboard = _.filter($scope.virtualLbs[key].leaderboard,function(user){
                        return user.userId.virtualLeague == virtualLeague.name;
                    });
                    var index = 0;
                    var prevVal = 0;
                    $scope.virtualLbs[key].leaderboard.forEach(function(user){
                        if(user.rank != prevVal){
                            user.rank = ++index;
                        }else{
                            user.rank = index;
                        }
                        user.deltaRank = 0;
                    });
                });
            }
            setTimeout(function() {
                $scope.currMetric = 0;
                $scope.dataLoaded = true;
                $scope.isSlickDataLoaded = true;
            }, 10);
        }).bind(this)

        this.init = (function () {
            $scope.strings = $rootScope.strings;
            $scope.me = Auth.getCurrentUser();
            $scope.playerType = $stateParams.player;
            $scope.trackData = [];
            $scope.relatedLeaderBoardLoaded = false;
            $scope.relatedLeaderBoardData = [];
            $scope.dataLoaded = false;
            $scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
            $scope.strings = $rootScope.strings;
            $scope.config = p0.config;
            $scope.isSeniorManager = $rootScope.isSeniorManager;
            //Will Hold the Current Metric Index
            $scope.currMetric = 0;
            $scope.currKpiMetric = 0;
            $scope.currCycleMetric = 0;
            $scope.currRoleMetric = 0;
            $scope.currLeagueMetric = 0;
            $scope.isMoversAndShakers = false;
            $scope.isRelatedLeaderBoards = false;
            $scope.slickConfigCard = {
                method: {},
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true,
                draggable: true
            };

            $scope.slickConfigBoard = {
                method: {},
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
                draggable: true
            };
            $scope.showLeagues = true;
            $scope.allCycles = p0.allMonthlyCycles;
            $scope.selectedCycle = p0.currentMonthlyCycle;
            $scope.isOwnLeaderBoard = true;
            $scope.roleTypes = [];
            $scope.divisionHeight = 300;

            $rootScope.config.overallLeague = false;
            $scope.socialAction = social.socialAction;
            $scope.hideCompare = false;
            if ($scope.me.systemRole == 'frontline') {
                $scope.hideCompare = !$scope.isOwn;
            }
            $scope.compareList = [];
            this.setDivisions();
            // firework.triggerFireWorks(true);
        }).bind(this)

        this.init();

    });
