const autoImportNgTemplateLoaderTemplate1 = require('../../html/tickets/uploadFile.html');
const autoImportNgTemplateLoaderTemplate2 = require('../../html/tickets/reassignTicket.html');
const autoImportNgTemplateLoaderTemplate3 = require('../../html/tickets/ticketTimeline.html');
const autoImportNgTemplateLoaderTemplate4 = require('../../html/tickets/ticketsAllFilters.html');
const autoImportNgTemplateLoaderTemplate5 = require('../../html/tickets/ticketsExport.html');

'use strict';
angular.module('bitnudgeApp')
	.controller('ticketTableContainerController', function ($scope, $state, $stateParams, $rootScope, Auth, p0, Tickets) {

		var loggedInUser = Auth.getCurrentUser();
		var currentUser = {};
		var filterObj = {};
		$scope.filterObj = filterObj;
		$scope.isFrontline = (loggedInUser.roleType === 'frontline');
		$scope.tabs = [];
		$scope.selected = {
			cycle: {}
		};
		$scope.itemsList = [];
		$scope.apiLoaded = false;

		$scope.query = {
			order: '-creationDate',
		};

		$scope.ticketFilters = {
			showAll: true,
			filtered: false
		};

		$scope.sortingObj = {
			sortKey: 'requiredClosureDate',
			sortOrder: 'asc'
		};

		$scope.pagination = Tickets.getPaginationObj();

		this.$onChanges = (changes) => {
			if (changes.customer.currentValue) {
				$scope.customer = changes.customer.currentValue;

				//Initialization method - for the frontline
				//For other roles - init method will be called inside $scope.onReporteeChange() method
				//if ($scope.isFrontline) {
					currentUser = loggedInUser;
					var extraParams = {};
					extraParams.cui = $scope.customer.cui;
					extraParams.userId = currentUser._id;
					Tickets.getTicketsPillsCount(extraParams).then(function (result) {
						$scope.ticketStats = result.data;
					}, function (err) {
						console.log(err)
					})
					init(currentUser);
				//}
			}
		};

		function disableAllFilters() {
			Object.keys($scope.ticketFilters).forEach(function (key) {
				$scope.ticketFilters[key] = false;
			})
		}

		$scope.searchResult = false;

		$scope.searchTickets = function () {
			$scope.searchResult = true;
			$scope.fetchTickets(currentUser);
		}

		$scope.resetSearchTickets = function () {
			$scope.filterObj.searchParam = ""
			$scope.searchResult = false;
			$scope.fetchTickets(currentUser);
		}

		var setTabs = function (tabs) {
			$scope.tabs = [];
			//set the total count
			tabs.forEach(function (kpi) {
				$scope.tabs.push(kpi);
			});
		}
		
		var setPaginationData = function (data) {
			$scope.pagination.total = data.total;
			if (data.tickets.docs.length === 0) {
				$scope.filteredCount = 0;
			} else {
				if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
					$scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
				} else {
					$scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
				}
			}

			if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
				$scope.pagination.disableNext = true;
			} else {
				$scope.pagination.disableNext = false;
			}
			if ($scope.pagination.offset === 0) {
				$scope.pagination.disablePrevious = true;
			} else {
				$scope.pagination.disablePrevious = false;
			}
		}

		var setStateParams = function (filterObj) {
			var stateParams = {
				tab: filterObj.tab,
				userId: filterObj.userId,
			};

			var highlightFilterButton = false;

			//Set filters tab as-well
			if (filterObj.state) {
				highlightFilterButton = true;
				stateParams.state = filterObj.state;
			} else {
				stateParams.state = null;
			}

			if (filterObj.type) {
				highlightFilterButton = true;
				stateParams.type = filterObj.type;
			} else {
				stateParams.type = null;
			}

			disableAllFilters();

			if (highlightFilterButton) {
				$scope.ticketFilters.filtered = true;
			} else {
				$scope.ticketFilters.showAll = true;
			}

			// $state.go('lms.tickets', stateParams, {
			// 	notify: false
			// });
		};

		var onApplyFilterCall = function (updatedFilterObj) {
			//set the filters on to the state params
			filterObj = updatedFilterObj;
			$scope.fetchTickets(currentUser);
		}

		$scope.fetchTickets = function (user) {
			setStateParams(filterObj);
			$scope.itemsList = [];
			$scope.apiLoaded = false;
			if (!user) {
				user = currentUser;
			}
			filterObj.withReporting = (user._id === loggedInUser._id);
			$scope.filterObj.tab = null;
			Tickets.fetchTickets(user._id, filterObj, $scope.pagination, $scope.customer.cui,true, $scope.sortingObj).then(function (data) {
				//set extra information on tickets
				const resultData = data.data.data
				Tickets.setExtraInfoOnTicketsList(resultData.tickets.docs);
				setPaginationData(resultData);
				//set the list of tickets to the scope variable
				$scope.itemsList = resultData.tickets.docs;
				_.each($scope.itemsList,function(ticket){
					ticket.isUnassigned = ticket.userId && !_.isEmpty(ticket.userId) ? false : true;
				});
				//set data loaded to true
				$scope.apiLoaded = true;
			});
		};

		var setInitialFilters = function (user) {
			filterObj = {
				tab: p0.allKpis[0]._id,
				state: null,
				type: null,
				searchParam: null,
				userId: user._id
			};
		}

		var checkAndRestoreStateParams = function (user) {
			//restore filters if present in the state params
			if ($stateParams && Object.keys($stateParams).length !== 0) {
				Object.keys($stateParams).forEach(function (key) {
					filterObj[key] = $stateParams[key];
				});
				if (!filterObj.userId) {
					filterObj.userId = user._id.toString();
				}
			} else {
				setInitialFilters(user);
			}
			$scope.selectedTab = filterObj.tab;
		}

		var fetchTabsAndTickets = function (user) {
			var withReportee = (user._id === loggedInUser._id);;
			Tickets.getSubTabs(user._id, withReportee).then(function (result) {
				var tabs = result.data.counts;
				//Set tabs
				tabs = _.orderBy(tabs, 'total', 'desc');
				setTabs(tabs);
				//Set the default tab if tab was not set
				if (!filterObj.tab) {
					filterObj.tab = tabs[0]._id;
				}
				filterObj.tab = filterObj.tab;
				$scope.selectedTab = filterObj.tab;
				//Fetch tickets
				$scope.fetchTickets(user)
			});
		}

		var init = function (user) {
			$scope.selected.jobRole = user.organizationalTag.jobRole;
			$scope.apiLoaded = false;

			//Set filterObject based on state params
			checkAndRestoreStateParams(user)

			//Get tabs info of the page
			fetchTabsAndTickets(user)

		}

		/****************************************************/
		/*  All Dropdown changes are handled here ***********/
		/****************************************************/

		$scope.kpiChanged = function (kpi) {
			$scope.selectedTab = kpi._id;
			filterObj.tab = kpi._id;
			$scope.fetchTickets(currentUser);
		}

		//if not frontline these reportee filter event handlers will set jobRole
		$scope.onReporteeChange = function (reportee) {
			$scope.selected.user = reportee;
			$scope.selected.jobRole = reportee.organizationalTag.jobRole;
			currentUser = reportee;
			init(currentUser);
		};

		$scope.onPlayTypeChange = function (playType) {
			$scope.selected.playType = playType;
		};

		$scope.onDivisionChange = function (division) {
			$scope.selected.division = division;
		};

		$scope.onRoleTypeChange = function (roleType) {
			$scope.selected.roleType = roleType;
		};

		$scope.nextPage = function () {
			$scope.pagination.offset += $scope.pagination.limit;
			//What is this selected user check?
			$scope.fetchTickets(currentUser);
		}

		$scope.previousPage = function () {
			$scope.pagination.offset -= $scope.pagination.limit;
			//What is this selected user check?
			$scope.fetchTickets(currentUser);
		}

		$scope.showall = function () {
			disableAllFilters();

			//At least set default based on my Id
			filterObj = {
				tab: filterObj.tab,
				userId: filterObj.userId,
			};
			$scope.fetchTickets(currentUser)
		};


		/****************************************************/
		/*  All Show Modals starts here *********************/
		/****************************************************/

		// //Create Ticket
		// $scope.createTicket = function (ev) {
		// 	return $state.go('userFlow.addTicket', {
		// 		tab: $scope.selectedTab
		// 	});

		// };

		//Bulk ticket upload
		$scope.uploadFiles = function (name, url, ev, id) {
			ev.stopPropagation();
			$mdDialog.show({
					templateUrl: autoImportNgTemplateLoaderTemplate1,
					controller: 'ticketUploadCtrl',
					parent: angular.element(document.body),
					targetEvent: ev,
					clickOutsideToClose: true,
					locals: {
						employee: "userName"
					},
					fullscreen: true, // Only for -xs, -sm breakpoints.
					resolve: {
						parameters: function () {
							return {
								modalName: name,
								url: url
							};
						}
					}
				})
				.then(function (answer) {
					//Fetch tickets again
					//Re fetch tickets again.
				}, function () {});
		};

		//Archive Ticket
		$scope.archiveTicket = function (ticket) {
			$http.post('/api/tickets/archiveTicket',{ticketId: ticket._id}).then(function (response) {
				response = response.data;
				if (response.err) {
					//toaster.failed
					toaster.pop('failed', "Ticket Archive", "Ticket could not be archived.");
				} else {
					//toaster.then
					//ticketsListBackup.delete(ticket)
					toaster.pop('success', "Ticket Archive", "Ticket successfully archived.");
					//TODO: Update local list
				}
			});
		};

		//Reassign Ticket
		$scope.reassignTicket = function (ticket, userId) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate2,
				controller: 'reassignTicketCtrl',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				fullscreen: true, // Only for -xs, -sm breakpoints.
				resolve: {
					ticket: function () {
						return ticket;
					}
				}
			});
		}

		//Show Time-line of state change
		$scope.showTimeline = function (ticket) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate3,
				controller: 'timelineController',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				fullscreen: true, // Only for -xs, -sm breakpoints.
				resolve: {
					ticket: function () {
						return ticket;
					}
				}
			});
		};

		$scope.showFilters = function () {
			disableAllFilters();
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate4,
				controller: 'allTicketFiltersController',
				clickOutsideToClose: true,
				fullscreen: false,
				resolve: {
					filters: function () {
						return filterObj;
					},
					callback: function () {
						return onApplyFilterCall;
					}
				}
			});
		}

		$scope.exportTickets = function (ev) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate5,
				controller: 'ticketExportCtrl',
				parent: angular.element(document.body),
				targetEvent: ev,
				clickOutsideToClose: true,
				fullscreen: true, // Only for -xs, -sm breakpoints.
				resolve: {
					parameters: function () {
						return {
							totalTickets: $scope.pagination.total,
							filterObj: $scope.filterObj,
							currentUser: currentUser
						};
					}
				}
			})
		};

		$scope.filterOnState = function (state) {
			if ((state.id === "archived" && $scope.filterObj.archived) || (state.id === $scope.filterObj.state)) {
				$scope.filterObj.archived = false;
				$scope.filterObj.state = null;
			} else if (state.id == $scope.filterObj.state && state.id != "archived") {
				$scope.filterObj.state = null
				$scope.filterObj.archived = false;
			} else if (state.id != $scope.filterObj.state && state.id != "archived"){
				$scope.filterObj.archived = false
				$scope.filterObj.state = state.id;
			}else{
				$scope.filterObj.archived = true;
				$scope.filterObj.state = null;
			}
			
			$scope.fetchTickets(currentUser);
		}

		$scope.search_enter = function (keyEvent) {
			if (keyEvent.which === 13 && $scope.filterObj.searchParam && $scope.filterObj.searchParam.length) {
				$scope.searchTickets()
			}
		}



	});