'use strict';
/* eslint-disable */

angular.module('bitnudgeApp').controller('editProspectInfoCtrl', function (Auth, $scope, prospectService, prospect, $mdDialog, reloadProspectInfoPage, toaster, p0, $rootScope) {
	$scope.prospect = prospect;
	$scope.myIntlTelInputCtrl;
	const {contactNumber} = prospect;
	$scope.shouldNameSplit = $rootScope.config.shouldNameSplit;
	$scope.selected = {
		firstName: prospect.firstName,
		middleName: prospect.middleName,
		lastName: prospect.lastName,
		email: prospect.email,
		number: `${contactNumber.countryCode}${contactNumber.number}`,
		name: prospect.name
	};
	$scope.strings = p0.strings;
	
	if(contactNumber.countryCode){
		const allCounties = window.intlTelInputGlobals.getCountryData();
		const code = contactNumber.countryCode.replace('+','');
		$scope.country = allCounties.find(c => c.dialCode === code);
	}

	$scope.onClose = function () {
		$mdDialog.hide();
	};
	
	$scope.onSubmit = () => {
		const data = {
			firstName: $scope.shouldNameSplit ? $scope.selected.firstName : "",
			middleName: $scope.shouldNameSplit ? $scope.selected.middleName : "",
			lastName: $scope.shouldNameSplit ? $scope.selected.lastName : "",
			email: $scope.selected.email,
			name: !$scope.shouldNameSplit ? $scope.selected.name : ""
		};
		prospectService.updateProspectInfo(prospect._id, data).then((result) => {
			const { data } = result;
			const header = 'Update Prospect info';
			if(data.status){
				toaster.pop('success', header, `${header} is successful.`);
				reloadProspectInfoPage();
				$scope.onClose();
			}else if(data.errors && data.errors.length){
				data.errors.forEach(e => {
					toaster.pop('error', header, e.message);
				})
			}
		}).catch(function (err) {
			toaster.pop('Error', err.message)
		});
	}
})
