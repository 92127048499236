'use strict';
angular.module('bitnudgeApp')
    .service('UtilService', function($rootScope, p0) {
        let self = this;
        this.downloadFile = function(url, params) {
            if(!params){
                params = {}
            }
            params.apiToken = $rootScope.apiToken;
            this.openWindowWithPost(url, null, 'downloadform', params);
        }

        this.paramsToObject = function (entries) {
            let result = {}
            for(let entry of entries) {
                const [key, value] = entry;
                result[key] = value;
            }
            return result;
        }

        this.openWindowWithPost = function(url, windowoption, name, params) {
            let form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", url);
            form.setAttribute("target", name);
            for (let i in params) {
                if (params.hasOwnProperty(i)) {
                    let input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            window.open("post.html", name, windowoption);
            form.submit();
            document.body.removeChild(form);
         }
        this.getTimeRange = function(monthType, context) {
            const daily = { _id: 'daily', name: 'Daily' },
                monthly = { _id: 'monthly', name: 'Monthly' },
                weekly = { _id: 'weekly', name: 'Weekly' };

            let today = new Date();
            let dailyWeekly = [daily];
            let weeklyMonthly = [monthly];
            if (p0.config.weeklyCycle) {
                dailyWeekly.push(weekly);
                weeklyMonthly.unshift(weekly);
            }
            let currentCycle = _.find(p0.allAdminCycles, { cycleType: 'monthly', active: true, subType: monthType });
            if (context === 'ticket' || context === 'lead' || context === 'prospect') {
                currentCycle = p0.currentMonthlyCycle;
            }
            let timeRange = [
                { name: 'Last 7 Days', _id: 'last7Days', startDate: new Date(), endDate: new Date(), cycleTypes: [daily] },
                { name: 'This month', _id: 'thisMonth', startDate: new Date(), endDate: new Date(), cycleTypes: dailyWeekly },
                { name: 'Custom', _id: 'custom', startDate: new Date(currentCycle.startDate), endDate: new Date(currentCycle.endDate) }
            ];
            if (currentCycle.previousCycleId) {
                timeRange.push({ name: 'Last month', _id: 'lastMonth', startDate: new Date(), endDate: new Date(), cycleTypes: dailyWeekly });
                timeRange.push({ name: 'Last 3 months', _id: 'last3Months', startDate: new Date(), endDate: new Date(), cycleTypes: weeklyMonthly });
                timeRange.push({ name: 'Last 6 months', _id: 'last6Months', startDate: new Date(), endDate: new Date(), cycleTypes: weeklyMonthly });
            }

            let lastSevenStart = new Date(moment(today).subtract(6, 'd'));
            let lastSeven = _.find(timeRange, { _id: 'last7Days' });
            if (lastSeven) {
                lastSeven.startDate = lastSevenStart.setHours(0, 0, 0, 0);
                lastSeven.endDate = new Date().setHours(23, 59, 59, 999);
            }
            if (p0.config.lastNMonthShouldInclideCurrentMonthDays) {
                let thisMonth = _.find(timeRange, { _id: 'thisMonth' });
                const endDate = new Date().setHours(23, 59, 59, 999);
                thisMonth.startDate = new Date(today.getFullYear(), today.getMonth(), 1).setHours(0, 0, 0); //firstDateOfCurrentMonth
                thisMonth.endDate = endDate;

                let lastMonth = _.find(timeRange, { _id: 'lastMonth' });
                if (lastMonth) {
                    lastMonth.startDate = new Date(moment(today).subtract(1, 'months')).setHours(0, 0, 0, 0);
                    lastMonth.endDate = endDate;
                }


                let last3M = _.find(timeRange, { _id: 'last3Months' });
                if (last3M) {
                    last3M.startDate = new Date(moment(today).subtract(3, 'months')).setHours(0, 0, 0, 0);
                    last3M.endDate = endDate;
                }

                let last6M = _.find(timeRange, { _id: 'last6Months' });
                if (last6M) {
                    last6M.startDate = new Date(moment(today).subtract(6, 'months')).setHours(0, 0, 0, 0);
                    last6M.endDate = endDate;
                }
            } else {
                let thisMonth = _.find(timeRange, { _id: 'thisMonth' });
                if (thisMonth) {
                    //thisMonth.startDate = new Date(today).setHours(0, 0, 0, 0);
                    thisMonth.startDate = new Date(today.getFullYear(), today.getMonth(), 1).setHours(0, 0, 0); //firstDateOfCurrentMonth
                    thisMonth.endDate = new Date().setHours(23, 59, 59, 999);
                    let timeDiff = Math.abs(new Date(thisMonth.endDate).getTime() - new Date(thisMonth.startDate).getTime());
                    let days = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    let cycleTypes = [daily];
                    if (days >= 7 && p0.config.weeklyCycle) {
                        cycleTypes.push(weekly);
                    }
                    thisMonth.cycleTypes = cycleTypes;
                }
                if (currentCycle.previousCycleId) {
                    let previousCycle = currentCycle.previousCycleId;
                    let previous = _.find(timeRange, { _id: 'lastMonth' });
                    if (previous && previousCycle) {
                        previous.startDate = new Date(previousCycle.startDate).setHours(0, 0, 0, 0);
                        previous.endDate = new Date(previousCycle.endDate).setHours(23, 59, 59, 999);
                    }
    
                    let y = today.getFullYear(),
                        m = today.getMonth(),
                        endDate, startDate;
                    if (monthType == 'regular') {
                        endDate = new Date(y, m, 0);
                        startDate = new Date(y, m - 3, 1);
                    } else {
                        endDate = new Date(y, m - 1, 14);
                        startDate = new Date(y, m - 4, 16);
                    }
                    endDate = endDate.setHours(23, 59, 59, 999);
                    let last3M = _.find(timeRange, { _id: 'last3Months' });
                    if (last3M) {
                        last3M.startDate = new Date(startDate).setHours(0, 0, 0, 0);
                        last3M.endDate = endDate;
                    }
                    if (monthType == 'regular') {
                        startDate = new Date(y, m - 6, 1);
                    } else {
                        startDate = new Date(y, m - 7, 16);
                    }
                    let last6M = _.find(timeRange, { _id: 'last6Months' });
                    if (last6M) {
                        last6M.startDate = new Date(startDate).setHours(0, 0, 0, 0);
                        last6M.endDate = endDate;
                    }
                }
            }
            return timeRange;
        };
    });