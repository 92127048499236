'use strict';

angular.module('bitnudgeApp').controller('addProspectCtrl', function ($scope, $rootScope, $state, Auth,
    prospectService, toaster, p0, countryList, $window, $http) {
    const me = Auth.getCurrentUser();
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.strings = p0.strings;
    $scope.isNotDropDown = false;
    $scope.data = {};
    $scope.currentUser = me;
    $scope.searchUser='';
    $scope.myTeam = [];
    $scope.pagination = {
        page: 1,
        limit: 10,
        total: 0
    };
    $scope.selected = {};
    $scope.number;
    $scope.initPageLoad = true;
    $scope.isEditable = false;
    $scope.prospectData = {}
    $scope.allRoles = [];
    $scope.config = p0.config;
    if (me.roleType === 'manager') {
        $scope.allRoles = [$scope.strings.MANAGER.value.toUpperCase(), $scope.strings.FRONTLINE.value.toUpperCase()];
    }else if (me.roleType === 'seniorManager'){
        $scope.allRoles = [$scope.strings.SENIORMANAGER.value.toUpperCase(), $scope.strings.MANAGER.value.toUpperCase(), $scope.strings.FRONTLINE.value.toUpperCase() ];
    } else {
        $scope.allRoles = [$scope.strings.FRONTLINE.value.toUpperCase()]
    }
    $scope.selectedRole = $scope.allRoles[0];
    $scope.shouldNameSplit = $rootScope.config.shouldNameSplit

    $scope.loadMoreUsersList = function(query){
        return fetchUsersListForSeniorManager(null, query);
    }

    const lazyFetchUsersListForSeniorManager = _.debounce( function (roleType, query){
        if(query!==$scope.oldQuery)
        {
            $scope.myTeam = [];
            $scope.pagination = {
                page: 1,
                limit:10
            };
        }
        return fetchUsersListForSeniorManager(roleType, query);
    }
    , 500);

    function fetchUsersListForSeniorManager(roleType, query) {
        $scope.userToggleSwitch = true;
        return $http.post('/api/users/getAllLeafJobRoles', {
            roleTypes: ['manager','seniorManager'],
            userId: me._id,
            searchTerm: query,
            page:$scope.pagination.page,
            limit:$scope.pagination.limit,
        }).then(function (result) {
            $scope.setPaginationData(result.data.data);
            result=result.data.data.docs;
            let playtypeRoles = []
            p0.myPlayTypes.forEach(playType => 
                playtypeRoles = [...playtypeRoles, ...playType.frontline, ...playType.managers, ...playType.seniorManagers]
            );
            playtypeRoles = _.uniq(playtypeRoles);
            result = result.filter( user => 
                _.includes(playtypeRoles, user.organizationalTag.jobRole._id)
            );
            $scope.dataLoaded = true;
            if(query===''){
                if($scope.myTeam.length == 0)
                {
                    $scope.myTeam = [...result, me];
                }
                else {
                    $scope.myTeam = [...$scope.myTeam, ...result];
                }
            }
            else{
                if(query === $scope.oldQuery){
                    $scope.myTeam = [...$scope.myTeam,...result];
                }else {
                    $scope.myTeam = [...result];
                }  
                $scope.oldQuery = query;
            }
            $scope.data.user = $scope.myTeam[0]
            $scope.selectedUser = $scope.data.user
            $scope.selected.myTeam = [];
            $scope.selected.myTeam = _.cloneDeep($scope.myTeam);

            if(query || $scope.pagination.page > 2){
                angular.element('input[type=search]').focus();
            }
        })
    }

    $scope.setPaginationData = function (result) {
        $scope.pagination.total = result.total;
        if(result.docs.length > 0)
            $scope.pagination.page += 1;
    }

    $scope.changeHandler = function (selectedUser) {
        $scope.selectedUser = selectedUser;
    }
    //on user toggle change update the userlist
    $scope.changeUsers = function (roleType) {
        roleType = roleType.toUpperCase();
        if (me.roleType === 'frontline') {
            return;
        } else if (me.roleType === 'manager') {
            $scope.myTeam = [me];
            $scope.data.user = $scope.myTeam[0]
            $scope.selectedUser = $scope.myTeam[0];
            $scope.selected.myTeam = [];
            $scope.selected.myTeam = _.cloneDeep($scope.myTeam);
            return;
        } else {
            fetchUsersListForSeniorManager(roleType)
        }
    }

    var createProspect = function (prospectData) {

        let postPayload = prospectData;
        if ($rootScope.isFrontline) {
            postPayload.userId = me._id;
        } else {
            postPayload.userId = $scope.selectedUser._id;
        }

        prospectService.createProspect(postPayload).then(function (response) {
            let responseData = response.data;
            if (responseData.status) {
                $scope.errors = [];
                toaster.pop('success', 'Prospect creation successful.');
                const params = {}
                params.prospectId = responseData.data.prospectUser._id;
                $state.go('userFlow.editProspect', params)
            } else {
                $scope.errors = responseData.errors;
                toaster.pop('error', 'Prospect creation failed.');
            }
        }).catch(function (err) {
            console.error('CreateProspect API failed ', err);
            toaster.pop('error', 'Prospect creation failed.');
        });
    }
    var init = function () {
        $scope.prospect = {};
        $scope.nationList = countryList.countries;
        fetchProspectSourcesList();

        if ($rootScope.isMiddleManager) {
            prospectService.getActiveFrontlines().then(function (result) {
                $scope.frontLineList = result;
                if(!result.length){
                    $scope.allRoles = [$scope.strings.MANAGER.value.toUpperCase()];
                }
            })
        }

        if (me.roleType == "frontline") {
            $scope.myTeam = [me];
            $scope.data.user = $scope.myTeam[0]
            $scope.onSelectUser()
        } else {
            $scope.changeUsers($scope.selectedRole);
        }
    };

    $scope.onSelectUser = function () {
        $scope.selectedUser = $scope.data.user
    }

    $scope.onSubmit = function (form) {
        if (form.$valid) {
            let formData = $scope.prospect
            const { firstName, middleName, lastName, companyName, nationality } = $scope.prospectData
            if(firstName){
                $scope.prospectData.name = firstName;
                formData.firstName = firstName;
            }
            if(middleName){
                $scope.prospectData.name += ' '+middleName;
                formData.middleName = middleName;
            }
            if(lastName){
                $scope.prospectData.name += ' '+lastName;
                formData.lastName = lastName;
            }
            formData.name = $scope.prospectData.name;
            formData.contactNumber = $scope.phoneNumber;
            const { email } = $scope.prospectData;
            if(email){
                formData.email = email;
            }
            formData.prospectUserSource = $scope.prospectData.source;
            formData.nationality = nationality;
            formData.companyName = companyName;
            createProspect(formData)
        }
    }
    $scope.onCancel = function () {
        $window.history.back()
    }
    function searchComponent(query,key, key2) {
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if(key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if(ismatched)
                return true;
            else
                return false;
        };
      }

    //---angular material -----
    $scope.searchList = function(query,list,key, key2) {
        var results = query ? list.filter( searchComponent(query, key, key2) ) : list;
        return results;
    }

    $scope.searchUsers = function(query){
        $scope.myTeam = [];
        $scope.pagination = {
            page: 1,
            limit:10
        };
        return fetchUsersListForSeniorManager(null, query);
    }

    $scope.searchAutoList = function(query) {
        return $scope.selected.myTeam;
    }


    function fetchProspectSourcesList() {
        prospectService.fetchProspectSourceList().then(function (response) {
            const allProspectSources = response.data && response.data.data;
            const defaultProspectSource = allProspectSources.find(source => {
                return source.defaultConfig && source.defaultConfig.toLowerCase().replace(/\s/g,'') === $scope.currentUser.roleType.toLowerCase()
            });
            const referralProspectSource = allProspectSources.find(source => source.internalId === 'Referral');
            const selfGeneratedSource = allProspectSources.find(source => source.internalId === 'SelfGenerated');
            if (me.roleType !== 'frontline') {
                $scope.prospectSourceList = [defaultProspectSource, selfGeneratedSource, referralProspectSource];
            } else {
                $scope.prospectSourceList = [defaultProspectSource, referralProspectSource];
            }
            $scope.prospectData.source = defaultProspectSource && defaultProspectSource.internalId;
        }, function (err) {
            console.log(err)
        })
    }

    $scope.myIntlTelInputCtrl;


    init();
});
