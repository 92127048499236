"use strict";

//POLYFILLS
import "@babel/polyfill";

// THIRD PARTY
import "jquery";
import "jquery-ui/ui/widgets/slider.js";
import "bootstrap";

import "angular/angular.js";
import "angular-animate/angular-animate.js";
import "angular-ui-bootstrap";
import "angular-resource/angular-resource.js";
import "angular-cookies/angular-cookies.js";
import "angular-sanitize/angular-sanitize.js";
import "angular-socket-io/socket.js";
import "lodash/lodash.js";
import "angularjs-toaster/toaster.js";
import "d3/d3.js";
import "chosen-js/chosen.jquery.js";
import "datatables/media/js/jquery.dataTables.js";
import "angular-datatables/dist/angular-datatables.js";
import "angular-datatables/dist/plugins/colreorder/angular-datatables.colreorder.js";
import "angular-datatables/dist/plugins/columnfilter/angular-datatables.columnfilter.js";
import "angular-datatables/dist/plugins/light-columnfilter/angular-datatables.light-columnfilter.js";
import "angular-datatables/dist/plugins/colvis/angular-datatables.colvis.js";
import "angular-datatables/dist/plugins/fixedcolumns/angular-datatables.fixedcolumns.js";
import "angular-datatables/dist/plugins/fixedheader/angular-datatables.fixedheader.js";
import "angular-datatables/dist/plugins/scroller/angular-datatables.scroller.js";
import "angular-datatables/dist/plugins/tabletools/angular-datatables.tabletools.js";
import "angular-datatables/dist/plugins/buttons/angular-datatables.buttons.js";
import "angular-datatables/dist/plugins/select/angular-datatables.select.js";
import "ng-file-upload/index.js";
import "angularjs-scroll-glue/src/scrollglue.js";
import "inflection/lib/inflection.js";
import "nginflection/index.js";
import "angular-truncate-2/dist/angular-truncate-2.js";
import "angular-fcsa-number/src/fcsaNumber.js";
import "ng-idle/angular-idle.js";
import "jquery.elastic/jquery.elastic.source.js";
import "angular-timer/dist/angular-timer.js";
import "humanize-duration/humanize-duration.js";
import "moment/moment.js";
import "angular-moment/angular-moment.js";
import "angular-chosen-localytics/dist/angular-chosen.js";
import "slick-carousel/slick/slick.js";
import "angular-slick-carousel/dist/angular-slick.js";
import "angular-aria/angular-aria.js";
import "angular-messages/angular-messages.js";
import "angular-material/angular-material.js";
import "angular-loading-bar/build/loading-bar.js";
import "angular-material-data-table/dist/md-data-table.js";
import "ngmap/build/scripts/ng-map.js";
import "chartist/dist/chartist.js";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.js";
import "angular-ui-router/release/angular-ui-router.js";
import "smdatetimerangepicker/src/picker.js";
import "file-saver/FileSaver.js";
import "ng-xslx/angular-xslx.js";
import "noisy-js-ui/jquery/jquery.noisy.js";
import "angular-ui-tree/dist/angular-ui-tree.js";
import "angular-scroll-animate/dist/angular-scroll-animate.js";
import "angular-timeline/dist/angular-timeline.js";
import "angular-moment-picker/dist/angular-moment-picker.js";
import "hashmap/hashmap.js";
import "angular-camelcase-to-human/camelcase-browser.js";
import "socket.io-client/dist/socket.io.js";
import "angular-chartist.js/dist/angular-chartist.js";
import "popper.js/dist/popper";
import "highcharts/highstock.js";
import "highcharts/highcharts.js";
import "angular-file-saver/dist/angular-file-saver.bundle.js";
import "intl-tel-input/build/js/intlTelInput-jquery.js";
import "intl-tel-input/build/js/intlTelInput.js";
import "intl-tel-input/build/js/utils.js";
import "ng-intl-tel-input/dist//ng-intl-tel-input.min.js"
//game2048
import "./libraries/game2048/js/animframe_polyfill.js";
import "./libraries/game2048/js/bind_polyfill.js";
import "./libraries/game2048/js/classlist_polyfill.js";
import "./libraries/game2048/js/game_manager.js";
import "./libraries/game2048/js/grid.js";
import "./libraries/game2048/js/html_actuator.js";
import "./libraries/game2048/js/keyboard_input_manager.js";
import "./libraries/game2048/js/local_storage_manager.js";
import "./libraries/game2048/js/tile.js";

//LOCAL
import "./app.js";
import "./controllers/abilityController.js";
import "./controllers/advancedFilters.js";
import "./controllers/activityExtraDataModalController.js";
import "./controllers/activityIndicatorCardController.js";
import "./controllers/basicActivityIndicatorCardController.js";
import "./controllers/activityLBController.js";
import "./controllers/activityTrendModalController.js";
import "./controllers/addActivityController.js";
import "./controllers/addBulkActivityController.js";
import "./controllers/addBulkActivityPointMultipliersController.js";
import "./controllers/addBulkActivityWeightagesController.js";
import "./controllers/addBulkContestController.js";
import "./controllers/addBulkDivisionsController.js";
import "./controllers/addBulkEmployeeController.js";
import "./controllers/addBulkErrorWorkItemsController.js";
import "./controllers/addBulkIncentivesController.js";
import "./controllers/addBulkIndividualIncentiveController.js";
import "./controllers/addBulkIndividualTargetController.js";
import "./controllers/addBulkLeadController.js";
import "./controllers/addBulkLeadsSubmissionStatusController.js";
import "./controllers/addBulkMetrics.js";
import "./controllers/addBulkMTDActivityController.js";
import "./controllers/addBulkPortfolio.js";
import "./controllers/addBulkProductController.js";
import "./controllers/addBulkRewardsController.js";
import "./controllers/addBulkSecurityQuestionsController.js";
import "./controllers/addBulkStringController.js";
import "./controllers/addBulkticketController.js";
import "./controllers/addContestRewardController.js";
import "./controllers/addEmployeeController.js";
import "./controllers/addLeadPosController.js";
import "./controllers/addLevelController.js";
import "./controllers/addLostLeadsController.js";
import "./controllers/addRemoveSeriesController.js";
import "./controllers/addTicketController.js";
import "./controllers/addGlossaryController.js";
import "./controllers/addACL.js";
import "./controllers/editTicketController.js";
import "./controllers/adminCampaignsController.js";
import "./controllers/adminConfigController.js";
import "./controllers/adminController.js";
import "./controllers/adminDashboardController.js";
import "./controllers/adminExportLeadsController.js";
import "./controllers/adminLogsController.js";
import "./controllers/adminLogsModalController.js";
import "./controllers/allFiltersController.js";
import "./controllers/analyticsController.js";
import "./controllers/analyticsSwotController.js";
import "./controllers/autoUploadLogsController.js";
import "./controllers/autoUploadSchedulerController.js";
import "./controllers/badgeAchieversModalController.js";
import "./controllers/badgeCarouselController.js";
import "./controllers/badgeController.js";
import "./controllers/badgeCornerController.js";
import "./controllers/badgePageController.js";
import "./controllers/badgeRecommendationModalController.js";
import "./controllers/barGraphController.js";
import "./controllers/benchmarkWidgetController.js";
import "./controllers/branchController.js";
import "./controllers/broadcastMessageModalController.js";
import "./controllers/bulkCommentModalController.js";
import "./controllers/bulkConfigController.js";
import "./controllers/bulkExcelUploadController.js";
import "./controllers/burnStatementController.js";
import "./controllers/contestsController.js";
import "./controllers/campaignController.js";
import "./controllers/CampaignLeaderboardController.js";
import "./controllers/campaignLeagueTableController.js";
import "./controllers/campaignsController.js";
import "./controllers/campaignsNewController.js";
import "./controllers/cardHeaderController.js";
import "./controllers/carImageChangeModalController.js";
import "./controllers/carouselSelectController.js";
import "./controllers/challengeController.js";
import "./controllers/chartCardController.js";
import "./controllers/chartLegendController.js";
import "./controllers/chatPageController.js";
import "./controllers/chatPlayersListController.js";
import "./controllers/cneCardController.js";
import "./controllers/cneKpiNetworkTabController.js";
import "./controllers/cneKpiTabController.js";
import "./controllers/cneNetworkCardController.js";
import "./controllers/cneTabController.js";
import "./controllers/cneTableCardTableController.js";
import "./controllers/commandLineController.js";
import "./controllers/compareMetricController.js";
import "./controllers/compareMetricGroupController.js";
import "./controllers/competitiveController.js";
import "./controllers/configureLeadsSubProductController.js";
import "./controllers/configureLeadSubTypeController.js";
import "./controllers/configureTicketSubTypeController.js";
import "./controllers/configureTicketTypeAndPermissionController.js";
import "./controllers/confirmWithCheckModalController.js";
import "./controllers/contactsController.js";
import "./controllers/correlationsCardController.js";
import "./controllers/createPlayerGroupModalController.js";
import "./controllers/customCorrelationsCardController.js";
import "./controllers/customerTimelineController.js";
import "./controllers/customReportsFormController.js";
import "./controllers/exportCustomers.js";
import "./controllers/cycleController.js";
import "./controllers/cyclesController.js";
import "./controllers/dailyDigestController.js";
import "./controllers/dailyFeedModalController.js";
import "./controllers/dailyGameplanDetailController.js";
import "./controllers/dashboardController.js";
import "./controllers/mgrDashboardController.js";
import "./controllers/leadMgrDashboardController.js";
import "./controllers/customerMgrDashboardCtrl.js";
import "./controllers/mgrDashSidePanelCtrl.js";
import "./controllers/reporteesHierarchyCtrl.js";
import "./controllers/queryUtilityController.js";
import "./controllers/addManagerDashboardConfigController.js";
import "./controllers/redirectConfirmationCtrl.js";
import "./controllers/dataDictionaryModalController.js";
import "./controllers/dateFiltersController.js";
import "./controllers/dayWiseEarnStatementController.js";
import "./controllers/deepDiveController.js";
import "./controllers/deleteOrLeaveGroupModalController.js";
import "./controllers/deltaIconController.js";
import "./controllers/detailChatController.js";
import "./controllers/docUploadModalController.js";
import "./controllers/downloadLatestFileController.js";
import "./controllers/earnStatementController.js";
import "./controllers/editConfigController.js";
import "./controllers/editEmployeeController.js";
import "./controllers/employeeController.js";
import "./controllers/filterController.js";
import "./controllers/filterInfoModalController.js";
import "./controllers/frontlineSwotController.js";
import "./controllers/fullCardController.js";
import "./controllers/funTabController.js";
import "./controllers/game2048Controller.js";
import "./controllers/gameAnalyticsController.js";
import "./controllers/gamePlanTicksController.js";
import "./controllers/goalSummaryController.js";
import "./controllers/gpAnalyzeController.js";
import "./controllers/gpCommentBoxController.js";
import "./controllers/gpGraphController.js";
import "./controllers/gpModalController.js";
import "./controllers/gpNotMadeModalController.js";
import "./controllers/gpPlanController.js";
import "./controllers/gpSimulationController.js";
import "./controllers/graphCardController.js";
import "./controllers/graphSectionController.js";
import "./controllers/hallOfFameController.js";
import "./controllers/healthMetricsController.js";
import "./controllers/heatmapController.js";
import "./controllers/heatmapMirrorChartController.js";
import "./controllers/heatmapRadialChartController.js";
import "./controllers/huddleBoardController.js";
import "./controllers/huddleBoardSummaryController.js";
import "./controllers/inboxController.js";
import "./controllers/instructionsController.js";
import "./controllers/jobRoleController.js";
import "./controllers/kpiController.js";
import "./controllers/kpiMixController.js";
import "./controllers/kpiRadialGraphController.js";
import "./controllers/labController.js";
import "./controllers/labModalController.js";
import "./controllers/lbCarouselController.js";
import "./controllers/lbCompleteListController.js";
import "./controllers/leaderboardsController.js";
import "./controllers/leadNotesController.js";
import "./controllers/leadQuickFilterModalController.js";
import "./controllers/leadUploadController.js";
import "./controllers/leagueTable.controller.js";
import "./controllers/leaveMgtModalController.js";
import "./controllers/legendInfoModalController.js";
import "./controllers/levelController.js";
import "./controllers/listActivitySchemaController.js";
import "./controllers/listConfigController.js";
import "./controllers/listCustomersController.js";
import "./controllers/listCycleController.js";
import "./controllers/listEmployeeController.js";
import "./controllers/listIndividualTargetController.js";
import "./controllers/listLeadController.js";
import "./controllers/listProspectController.js";
import "./controllers/listLevelController.js";
import "./controllers/listRedemptionsController.js";
import "./controllers/liveBadgeUpdateController.js";
import "./controllers/lmsBranchReportController.js";
import "./controllers/lmsComplianceReport.controller.js";
import "./controllers/lmsController.js";
import "./controllers/workflowLayoutRendererController.js";
import "./controllers/inputRendererController.js";
import "./controllers/loginController.js";
import "./controllers/loginPrivilegesController.js";
import "./controllers/mainController.js";
import "./controllers/managePeersModalController.js";
import "./controllers/mapsController.js";
import "./controllers/metricSelectionModalController.js";
import "./controllers/moreDataCardController.js";
import "./controllers/moreDataModalController.js";
import "./controllers/moreDataValuesController.js";
import "./controllers/moversAndShakersCardsController.js";
import "./controllers/moversAndShakersController.js";
import "./controllers/myFormController.js";
import "./controllers/mySwotController.js";
import "./controllers/navbarController.js";
import "./controllers/newsAndUpdatesController.js";
import "./controllers/nonAdminExportLeadsController.js";
import "./controllers/notificationsController.js";
import "./controllers/nudgeWallController.js";
import "./controllers/oneClickReportController.js";
import "./controllers/organizationController.js";
import "./controllers/organizationTreeController.js";
import "./controllers/otherAnalyticsController.js";
import "./controllers/otherKeyPartialController.js";
import "./controllers/performanceBarometerController.js";
import "./controllers/basicPerformanceBarometerController.js";
import "./controllers/performanceIndicatorsController.js";
import "./controllers/pipelineController.js";
import "./controllers/pipelineAnalyticsController.js";
import "./controllers/pipelineModalController.js";
import "./controllers/playbookCarouselController.js";
import "./controllers/playbookContoller.js";
import "./controllers/playerCommentModalController.js";
import "./controllers/playerComparisonCardController.js";
import "./controllers/playerComparisonController.js";
import "./controllers/playerComparisonMirrorChartController.js";
import "./controllers/playerComparisonModalController.js";
import "./controllers/playerListController.js";
import "./controllers/playerListModalController.js";
import "./controllers/pointsBoardController.js";
import "./controllers/pointsInfoController.js";
import "./controllers/portfolioModalController.js";
import "./controllers/profileController.js";
import "./controllers/progressBarController.js";
import "./controllers/quoteController.js";
import "./controllers/reassignLeadController.js";
import "./controllers/reassignLeadModalController.js";
import "./controllers/reassignReferralLeadController.js";
import "./controllers/reassignTicketController.js";
import "./controllers/recommendationCardController.js";
import "./controllers/recommendationModalController.js";
import "./controllers/redeemModalController.js";
import "./controllers/redemptionOptionsController.js";
import "./controllers/regionController.js";
import "./controllers/reporteeFiltersController.js";
import "./controllers/reporteewiseTrendModalController.js";
import "./controllers/reportModalController.js";
import "./controllers/rhsAnalyticsL1Controller.js";
import "./controllers/rhsAnalyticsL2AchievementController.js";
import "./controllers/rhsAnalyticsL2ActivityController.js";
import "./controllers/rhsAnalyticsL2IncentiveController.js";
import "./controllers/rhsAnalyticsL2SocialController.js";
import "./controllers/rhsAnalyticsTabController.js";
import "./controllers/rhsCardBarController.js";
import "./controllers/rhsCardController.js";
import "./controllers/rhsCardTableController.js";
import "./controllers/rhsSidebarController.js";
import "./controllers/scoreCardController.js";
import "./controllers/basicScoreCardController.js";
import "./controllers/scoreCardManagerModalController.js";
import "./controllers/incentivesModalController";
import "./controllers/scoreCardModalController.js";
import "./controllers/scoreCardPipelineModalController.js";
import "./controllers/securityQuestionsController.js";
import "./controllers/settingsController.js";
import "./controllers/notificationController.js";
import "./controllers/showGroupUsersController.js";
import "./controllers/showKPIActivitiesController.js";
import "./controllers/sidebarController.js";
import "./controllers/signupController.js";
import "./controllers/srManagerFormController.js";
import "./controllers/step1Controller.js";
import "./controllers/step2Controller.js";
import "./controllers/swotCardController.js";
import "./controllers/gradesCardController.js";
import "./controllers/swotChartController.js";
import "./controllers/swotController.js";
import "./controllers/gradesController.js";
import "./controllers/swotIconController.js";
import "./controllers/gradesIconController.js";
import "./controllers/swotModalController.js";
import "./controllers/swotWithValueController.js";
import "./controllers/taggingInputController.js";
import "./controllers/targetRatesModalController.js";
import "./controllers/teamController.js";
import "./controllers/ticketFiltersController.js";
import "./controllers/ticketsAllFiltersController.js";
import "./controllers/ticketsController.js";
import "./controllers/ticketsTimelineController.js";
import "./controllers/ticketUploadController.js";
import "./controllers/timelineController.js";
import "./controllers/toggleSwitchController.js";
import "./controllers/trackController.js";
import "./controllers/trendModalController.js";
import "./controllers/triggerCronsController.js";
import "./controllers/userCompareCardController.js";
import "./controllers/userFlowController.js";
import "./controllers/userProfilePopoverController.js";
import "./controllers/userSummaryModalController.js";
import "./controllers/welcomeBoardController.js";
import "./controllers/customers/customerController.js";
import "./controllers/customers/addCustomerController.js";
import "./controllers/customers/editCustomerController.js";
import "./controllers/customers/filterCustomerController.js";
import "./controllers/customers/addBulkCustomerController";
import "./controllers/leadTableController.js";
import "./controllers/prospectTableController.js";
import "./controllers/lms/updateLeadStatusController.js";
import "./controllers/lms/editLeadInfoController.js";
import "./controllers/lms/addFollowUpController.js";
import "./controllers/lms/editLeadController_v1.js";
import "./controllers/lms/editLeadController_v2.js";
import "./controllers/lms/addLeadController_v1.js";
import "./controllers/lms/addLeadController_v2.js";
import "./controllers/lms/addLeadNotesController.js";
import "./controllers/ticketTableController.js";
import "./controllers/addBulkDocumentTypesController.js";
import "./controllers/userActivityController.js";
import "./controllers/alertsController.js";
import "./controllers/setTriggerController.js";
import "./controllers/logTriggerController.js";
import "./controllers/alertsFilterController.js";
import "./controllers/ticketsExportController.js";
import "./controllers/uploadFlowCronController.js";
import "./controllers/configureCronController.js";
import "./controllers/uploadFlowCronLogController.js";
import "./controllers/timelineHistoryController.js";
import "./controllers/customers/interactionHistoryController.js";
import "./controllers/customers/leadTableContainerController.js";
import "./controllers/customers/ticketTableContainerController.js";
import "./controllers/addBulkProductMetadata.js";
import "./controllers/addBulkProductColourConfig.js";
import "./controllers/lbJobroleConfigController.js";
import "./controllers/lbJobroleConfigModalController.js";
import "./controllers/auditLogsController.js";
import "./controllers/productConfigurationController.js";
import "./controllers/addDeepDiveConfig.js";
import "./controllers/userPortfolioPieController.js";
import "./controllers/transaction/transactionController.js";
import "./controllers/transaction/transactionTableController.js";
import "./controllers/userPortfolioTrendController.js";
import "./controllers/addIPController.js";
import "./controllers/addModuleController.js";
import "./controllers/uploadDocumentController.js";
import "./controllers/documentModalController.js";
import "./controllers/rejectDocController.js";
import "./controllers/archiveDocumentController.js";
import "./controllers/addPrerequisiteController.js";
import "./controllers/alertAdvancedFiltersController.js";
import "./controllers/customers/reassignCustomerModalController.js";
import "./controllers/adminFiltersController";
import "./controllers/hotKeysController.js";
import "./controllers/hotKeyModalController.js";
import "./controllers/pushNotificationsController";
import "./controllers/addExcelHeaderFileController.js"
import "./controllers/addL3ProductsController.js";
import "./controllers/allProspectsFiltersControllers.js";
import "./controllers/lms/editProspectController.js";
import "./controllers/lms/addProspectFollowUpController.js";
import "./controllers/lms/updateProspectStatusController.js";
import "./controllers/lms/addProspectNotesController.js";
import "./controllers/lms/editProspectInfoController.js";
import "./controllers/reassignProspectController.js";
import "./controllers/lms/addProspectController.js";
import "./controllers/prospectTimelineController.js";
import "./controllers/prospectNotesController.js";
import "./controllers/adminExportProspectsController.js";
import "./controllers/addBulkProspectUserController.js";
import "./controllers/prospectUserDashboardController";
import "./controllers/prospectUserReportController";
import "./controllers/brochureUsageStatsController";
import "./controllers/genericUploads/genericUploadController";


/** component related controllers */
import "./controllers/components/documentComponentController.js";
import "./controllers/components/carouselComponentController.js";
import "./controllers/components/editableComponentController.js";
import "./controllers/components/historyCardComponentController.js";
import "./controllers/components/historyComponentController.js";
import "./controllers/components/infoComponentController.js";
import "./controllers/components/previewComponentController.js";
import "./controllers/components/thumbnailComponentController.js";
import "./controllers/components/attachmentComponentController.js";
import "./controllers/components/attachmentPreviewController.js";

/** end document controllers */

import "./directives/ability.js";
import "./directives/alertsTable.js";
import "./directives/prospectUserActivityTable.js";
import "./directives/multi-selector";
import "./directives/account.js";
import "./directives/activityIndicatorCard.js";
import "./directives/basicActivityIndicatorCard.js";
import "./directives/addRemoveSeries.js";
import "./directives/admin.js";
import "./directives/adminLogs.js";
import "./directives/analyticsSwot.js";
import "./directives/badge.js";
import "./directives/badgeAchieversModal.js";
import "./directives/badgeCarousel.js";
import "./directives/badgeCorner.js";
import "./directives/barGraph.js";
import "./directives/benchmarkWidget.js";
import "./directives/branch.js";
import "./directives/burnStatement.js";
import "./directives/campaign.js";
import "./directives/campaignLeagueTable.js";
import "./directives/cardHeader.js";
import "./directives/carouselSelect.js";
import "./directives/chartCard.js";
import "./directives/chartLegend.js";
import "./directives/chatList.js";
import "./directives/chatPage.js";
import "./directives/cneCard.js";
import "./directives/cneKpiNetworkTab.js";
import "./directives/cneKpiTab.js";
import "./directives/cneNetworkCard.js";
import "./directives/cneTab.js";
import "./directives/cneTableCard.js";
import "./directives/compareMetric.js";
import "./directives/compareMetricGroup.js";
import "./directives/compile.js";
import "./directives/contenteditableDiv.js";
import "./directives/correlationsCard.js";
import "./directives/customCorrelationsCard.js";
import "./directives/customReportsForm.js";
import "./directives/dailyDigest.js";
import "./directives/dateFilter.js";
import "./directives/dayWiseEarnStatement.js";
import "./directives/deltaIcon.js";
import "./directives/detailChat.js";
import "./directives/disableAnimation.js";
import "./directives/division.js";
import "./directives/downloadLatestFile.js";
import "./directives/earnStatement.js";
import "./directives/employee.js";
import "./directives/frontlineSwot.js";
import "./directives/fullCard.js";
import "./directives/funTab.js";
import "./directives/game2048.js";
import "./directives/gameAnalytics.js";
import "./directives/gameplanTicks.js";
import "./directives/goalSummary.js";
import "./directives/gpAnalyze.js";
import "./directives/gpCommentBox.js";
import "./directives/gpGraph.js";
import "./directives/gpPlan.js";
import "./directives/gpSimulation.js";
import "./directives/gpSlider.js";
import "./directives/graphCard.js";
import "./directives/graphSection.js";
import "./directives/hallOfFame.js";
import "./directives/heatmapMirrorChart.js";
import "./directives/heatmapRadialChart.js";
import "./directives/huddleBoardSummary.js";
import "./directives/inputPattern.js";
import "./directives/jobRole.js";
import "./directives/kpiRadialGraph.js";
import "./directives/lab.js";
import "./directives/landingPage.js";
import "./directives/lbCarousel.js";
import "./directives/lbCompleteList.js";
import "./directives/leadDirective.js";
import "./directives/leagueTable.js";
import "./directives/level.js";
import "./directives/liveBadgeUpdate.js";
import "./directives/lms.js";
import "./directives/map.js";
import "./directives/mongoose-error.directive.js";
import "./directives/moreDataValues.js";
import "./directives/moversAndShakers.js";
import "./directives/moversAndShakersCards.js";
import "./directives/myForm.js";
import "./directives/mySwot.js";
import "./directives/ngEnter.js";
import "./directives/ngToggle.js";
import "./directives/nudgeWall.js";
import "./directives/organization.js";
import "./directives/organizationTree.js";
import "./directives/otherAnalytics.js";
import "./directives/performanceBarometer.js";
import "./directives/basicPerformanceBarometer.js";
import "./directives/performanceIndicators.js";
import "./directives/playbookCarousel.js";
import "./directives/playerComparison.js";
import "./directives/playerComparisonCard.js";
import "./directives/playerComparisonMirrorChart.js";
import "./directives/playerListModal.js";
import "./directives/pointsBoard.js";
import "./directives/progressBar.js";
import "./directives/radialGraph.js";
import "./directives/recommendationCard.js";
import "./directives/redemptionOptions.js";
import "./directives/region.js";
import "./directives/reporteeFilters.js";
import "./directives/rhsAnalyticsL1.js";
import "./directives/rhsAnalyticsL2Achievement.js";
import "./directives/rhsAnalyticsL2Activity.js";
import "./directives/rhsAnalyticsL2Incentive.js";
import "./directives/rhsAnalyticsL2Social.js";
import "./directives/rhsAnalyticsTab.js";
import "./directives/rhsCard.js";
import "./directives/rhsCardBar.js";
import "./directives/rhsCardTable.js";
import "./directives/rhsSidebar.js";
import "./directives/scoreCard.js";
import "./directives/basicScoreCard.js";
import "./directives/splitMe.js";
import "./directives/srManagerForm.js";
import "./directives/swot.js";
import "./directives/grades.js";
import "./directives/swotCard.js";
import "./directives/gradesCard.js";
import "./directives/swotChart.js";
import "./directives/swotIcon.js";
import "./directives/gradesIcon.js";
import "./directives/swotWithValue.js";
import "./directives/taggingInput.js";
import "./directives/team.js";
import "./directives/toggleSwitch.js";
import "./directives/track.js";
import "./directives/user.js";
import "./directives/userCompareCard.js";
import "./directives/userFlow.js";
import "./directives/campaignFlow.js";
import "./directives/userProfilePopover.js";
import "./directives/welcomeboard.js";
import "./directives/httpSrc.js";
import "./directives/workflowLayoutRenderer.js";
import "./directives/inputRenderer.js";
import "./directives/leadTable.js";
import "./directives/ticketTable.js";
import "./directives/leadRight.js";
import "./directives/documentsView.js";
import "./directives/interactionsTable.js";
import "./directives/customerProducts.js";
import "./directives/timelineHistory.js";
import "./directives/leadtableContainer.js";
import "./directives/ticketTableContainer.js";
import "./directives/transactionTable.js";
import "./directives/kaiSwot.js";
import "./directives/hotKeys.js";
import "./directives/leadMgrDashboard.js";
import "./directives/customerMgrDashboard.js";
import "./directives/mgrDashSidePanel.js";
import "./directives/customerVerificationLogs.js";
import "./directives/prospectTable.js";
import "./directives/prospectRight.js";
import "./directives/endscrolldetect.js";
import "./directives/ticketRight.js";

/** component related js */
import "./components/documentComponent.js";
import "./components/carouselComponent.js";
import "./components/editableComponent.js";
import "./components/historyCardComponent.js";
import "./components/historyComponent.js";
import "./components/infoComponent.js";
import "./components/previewComponent.js";
import "./components/thumbnailComponent.js"
import "./components/attachmentComponent.js";
import "./components/attachmentPreviewComponent.js";
/** end component js */

import "./chartDirectives/pieChart.js";
import "./chartDirectives/lineChart.js";
import "./chartDirectives/funnelChart.js";
import "./chartDirectives/gauzeChart.js";
import "./services/accuracyService.js";
import "./services/activityUpdatesService.js";
import "./services/analyticsService.js";
import "./services/authService.js";
import "./services/badgeCornerService.js";
import "./services/benchmarkService.js";
import "./services/burnStatementService.js";
import "./services/cneActivityIndicatorService.js";
import "./services/correlationsService.js";
import "./services/countryListService.js";
import "./services/currentCycleService.js";
import "./services/customerService.js";
import "./services/dailyFeedService.js";
import "./services/deepDiveService.js";
import "./services/earnStatementService.js";
import "./services/ewiService.js";
import "./services/exitStatusService.js";
import "./services/fireworkService.js";
import "./services/forecastMultiplierService.js";
import "./services/genderService.js";
import "./services/getDeltaImageService.js";
import "./services/getGPService.js";
import "./services/getLeagueService.js";
import "./services/gpSimulationService.js";
import "./services/heatmapMirrorChartService.js";
import "./services/heatmapService.js";
import "./services/huddleBoardService.js";
import "./services/IdleUtilService.js";
import "./services/jobroleService.js";
import "./services/kpiService.js";
import "./services/leadsService.js";
import "./services/leadsSummaryFilterService.js";
import "./services/leagueAnalyticService.js";
import "./services/LeaveMgtService.js";
import "./services/modalService.js";
import "./services/newsAndUpdatesService.js";
import "./services/oneClickReportService.js";
import "./services/p0Service.js";
import "./services/p1Service.js";
import "./services/performanceBarometerService.js";
import "./services/performanceIndicatorService.js";
import "./services/pipelineService.js";
import "./services/pointsMultiplierService.js";
import "./services/portfolioService.js";
import "./services/profileService.js";
import "./services/productService.js";
import "./services/recommendationService.js";
import "./services/redemptionOptionsService.js";
import "./services/redirectRouterService.js";
import "./services/reporteesService.js";
import "./services/rhsAnalyticsL1Service.js";
import "./services/rhsAnalyticsL2AchievementService.js";
import "./services/rhsAnalyticsL2ActivityService.js";
import "./services/rhsAnalyticsL2SocialService.js";
import "./services/serviceManagerService.js";
import "./services/sidebarService.js";
import "./services/sidebarVariablesService.js";
import "./services/socialService.js";
import "./services/socketFactory.js";
import "./services/socketService.js";
import "./services/speedometer.js";
import "./services/step2Service.js";
import "./services/swotService.js";
import "./services/gradesService.js";
import "./services/thresholdReportService.js";
import "./services/ticketsService.js";
import "./services/trendGraphService.js";
import "./services/usageService.js";
import "./services/userActionsService.js";
import "./services/userAnalyticsService.js";
import "./services/userService.js";
import "./services/UtilService.js";
import "./services/workFlowService.js";
import "./services/notificationService.js";
import "./services/cacheService.js";
import "./services/userActivitiesService.js";
import "./services/triggerService.js";
import "./services/uploadFlowCronService.js";
import "./services/alertsService";
import "./services/badgeService.js";
import "./services/activityService.js";
import "./services/documentService.js";
import "./services/fileService.js";
import "./services/transactionService.js";
import "./services/adminDashboardService";
import "./services/dashboardService";
import "./services/hotKeysService";
import "./services/queryUtilityService.js";
import "./services/mgrDashboardConfigService.js";
import "./services/mgrDashboardMetricService.js";
import "./services/mgrDashboardService.js";
import "./services/prospectService.js";
import "./services/prospectUserApiService";
import "./services/userApiService";

//JS
import "./js/EasePack.min.js";
import "./js/gauge.js";
import "./js/rAF.js";
import "./js/rectangleEmitter.js";
import "./js/snow.js";
import "./js/TweenLite.min.js";
import "./js/filters.js";
import "./js/popover-trigger.js";
import "./js/queryFields.js";
import "./js/angular-ui-tree-filter.min.js";

//CSS
import "./scss/index.scss";
import "../node_modules/angularjs-toaster/toaster.css";
import "../node_modules/datatables/media/css/jquery.dataTables.css";
import "../node_modules/chosen-js/chosen.css";
import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";
// import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/components-font-awesome/css/font-awesome.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/angular-material/angular-material.css";
import "../node_modules/angular-loading-bar/build/loading-bar.css";
import "../node_modules/angular-material-data-table/dist/md-data-table.css";
import "../node_modules/chartist/dist/chartist.min.css";
import "../node_modules/chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";
import "../node_modules/angular-ui-tree/dist/angular-ui-tree.css";
import "../node_modules/angular-timeline/dist/angular-timeline.css";
import "../node_modules/angular-moment-picker/dist/angular-moment-picker.min.css";
import "../node_modules/angular-moment-picker/dist/themes/material-ui.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/intl-tel-input/build/css/intlTelInput.css";
import "../node_modules/highcharts/css/highcharts.css"

//INSTALLS
