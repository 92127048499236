'use strict';

angular.module('bitnudgeApp')
	.service('HotKeyService', function ($http, $q) {
		this.hotKeysEnum = {
			brochure:'brochure',
			eform:'eform',
			docusign:'docusign',
			pubnub:'pubnub',
			jitsi:'jitsi'
		}
		this.hotKeys = [{
			key: 'brochure',
			name: 'Brochure',
			icon: 'brochure',
			artifactType: 'document'
		},
		{
			key: 'eform',
			name: 'E-form',
			icon: 'eform',
			artifactType:'eform'
		},
		{
			key: 'docusign',
			name: 'Document',
			icon: 'docusign',
			artifactType:'docusigntemplate'
		},
		{
			key: 'pubnub',
			name: 'Chat',
			icon: 'chat',
			artifactType:'pubnub-chat'
		},
		{
			key: 'jitsi',
			name: 'Audio Video',
			icon: 'jitsi',
			artifactType:'jit-si'
		},
	]
		this.getHotKeyListings = (hotKey, options) => {
			const deferred = $q.defer();
			let url = `/api/hotKeys/list`
			$http.post(url, {
				hotKey:hotKey.key,
				...options
			}).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.shareLink = (data)=>{
            const deferred = $q.defer();
			let url = `/api/hotKeys/shareLink`
			$http.post(url, data).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
        }
        
        this.sendSMS = (data) => {
            const deferred = $q.defer();
			let url = `/api/hotKeys/sendSms`
			$http.post(url,data).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
		
		this.getReviewToken = (linkCode)=>{
			const deferred = $q.defer();
			let url = `/api/hotKeys/reviewToken`
			$http.post(url,{linkCode}).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
	})