'use strict';
angular.module('bitnudgeApp')
    .controller('performanceIndicatorsCtrl', function ($scope, $rootScope, $http, Auth, p0, performanceIndicatorService, $timeout, ForecastMultiplier, Kpi, PortfolioService, PipelineService, _) {
        let self=this;
        $scope.me = Auth.getCurrentUser();
        var userId = $scope.me._id;
        var jobRole = $scope.me.organizationalTag.jobRole._id;
        $scope.toggle = {};
        $scope.myKpis = p0.myKpis;
        $scope.products = p0.myProducts;
        $scope.forecastMultiplier = 1;
        $scope.card = {isActivity:false};
        $scope.achievementDataFetched = false;
        $scope.activityDataFetched = false;
        $scope.selected = {};
        $scope.config = p0.config;
        $scope.strings = p0.strings;
        let __constPerformanceGoal = []
        let __constActivityGoals = []
        var allJobroles=p0.allJobRoles;
        var kpis=[],isLowestSeniorManagerLevel;

        self.init=function()
        {
            var userLevel = Auth.getCurrentUser().organizationalTag.jobRole.level;
            //don't allow reportee view  if the user level is more than the lowest senior manager
            isLowestSeniorManagerLevel = self.checkLowestSeniorManager(p0.allJobRoles, userLevel)
            window.addEventListener('resize', self.findSlidesToDisplay);
            $scope.slick = {};
            $scope.compareValue = {};
        }

        self.checkLowestSeniorManager=function(allJobroles, userLevel) {
            var lowestLeverSenioMangerOrBelow = false;
            var seniorManagerJobRoles = allJobroles.filter(function (jobRole) {
                if (jobRole.roleType == "seniorManager") {
                    return true;
                }
                else {
                    return false;
                }
            })
            seniorManagerJobRoles = _.orderBy(seniorManagerJobRoles, ['level'], ['asc']);
            var lowestLevel = seniorManagerJobRoles[0].level;
            if (userLevel <= lowestLevel) {
                return true;
            }
            else {
                return false;
            }
        }

        $scope.setBenchmarks = function () {
            return performanceIndicatorService.fetchLeagues($scope.selectedUser._id, $scope.selectedCycle._id).then(function (leagues) {
                if($scope.selectedUser.roleType != 'seniorManager'){
                    _.forEach(leagues, function (league) {
                        $scope.benchmarks.push({name:league.teamTypeName + ' Avg.', _id:league.leagueId, show:true});
                    });
                }
                if(leagues.length == 0 && $scope.selectedCycle.active && $scope.config.showForecast){
                    if(!_.find($scope.benchmarks, {_id:'forecast'})) $scope.benchmarks.push({name:'Forecast', _id:'forecast', show:true});
                }
                if($scope.selectedCycle.previousCycleId) $scope.benchmarks.push({name:'Previous Period', _id:'prevCycle', show:$scope.config.showForecast?false:true});
                if(!_.find($scope.benchmarks, {_id:'ytd'})) $scope.benchmarks.push({name:'YTD Best', _id:'ytd', show:$scope.config.showForecast?false:true});
                $scope.peers = [];
                //Disabling for now because of release
                // return performanceIndicatorService.fetchJobroleId($scope.selectedUser._id, $scope.selectedCycle._id).then( function (jobroleId) {
                //     return $http.post('/api/users/getUsersOfJobRole',{jobRoleIds: [jobroleId], fetchTarget:true, cycleId:$scope.selectedCycle._id}).then(function (users) {
                //         users=users.data;
                //         _.remove(users, {_id:$scope.selectedUser._id});
                //         $scope.peers = users;
                //         return;
                //     });
                // });
            });
        };

        $scope.init = function () {
            self.findSlidesToDisplay();
            $scope.benchmarks = [];
            return $scope.setBenchmarks()
                .then(function () {
                    $scope.selected.benchmark = $scope.benchmarks[0];
                    $scope.achievementDataFetched = false;

                    return $scope.fetchAchievedGoals()
                }).then(function(){
                   return $scope.pipelineDataFunc();

                })
                // .then(function(){
                //    return $scope.userportFolio();
                // })
                .then(function () {
                    return $scope.fetchKpiBenchmark()
                })
                .then(function () {
                    $timeout(function () {
                        $scope.achievementDataFetched = true;
                    }, 0);
                    return;
                })
                .then(function () {
                $scope.aggregatePipelinePortfoio();
                if($scope.config.showBasicScorecard)
                {
                    $scope.activityDataFetched = false;
                    return $scope.fetchBasicActiveGoals().then(function () {
                        return $scope.fetchKaiBenchmark().then(function () {
                            $timeout(function () {
                                $scope.activityDataFetched = true;
                            }, 0);
                            return;
                        });
                    });
                }
                else if(p0.config.lms) {
                    $scope.activityDataFetched = false;
                    return $scope.fetchActiveGoals().then(function () {
                        return $scope.fetchKaiBenchmark().then(function () {
                            $timeout(function () {
                                $scope.activityDataFetched = true;
                            }, 0);
                            return;
                        });
                    });
                }else{
                    $scope.activityDataFetched = false;
                        return $scope.fetchNonLmsActivityData().then(function () {
                            return $scope.fetchKaiBenchmark().then(function () {
                                $scope.activityDataFetched = true;
                                return;
                            });
                        });
                }

            });
        };
        $scope.userportFolio = function(){
            let cycleId = p0.currentMonthlyCycle._id;
            const userId = $scope.selectedUser && $scope.selectedUser._id;
            return PortfolioService.getPortfolio(cycleId, userId).then(function (result) {
                result = result.data;
                if(result.status){
                    if (result.data.length > 0) {
                        $scope.userportfolioData = _.find(result.data, {_id: $scope.selectedUser._id});
                    }
                }
                return
            })
        }
        $scope.pipelineDataFunc = function(){
            return PipelineService.getPipelineScorecardData(userId, $scope.selectedCycle._id)
                .then(function (pipelineScorecardData) {
                    pipelineScorecardData = pipelineScorecardData.data
                    pipelineScorecardData = self.mapProductNames(pipelineScorecardData);
                    $scope.pipelineData = pipelineScorecardData;
                    return;
            })
        }
        $scope.fetchAchievedGoals = function () {
            var allJobRoles = p0.allJobRoles;
            var level = $scope.me.organizationalTag.jobRole.level;
                return performanceIndicatorService.fetchAchieved(userId, $scope.selectedCycle._id,kpis,level,p0.myPlayTypes,allJobRoles).then(function (goals) {
                    // show overall kpi card only if configured to show
                    if(!$scope.config.showOverallKpiCard){
                        goals = goals.filter(g=> g.kpiCode !== 'K00')
                    }
                    if(p0.config.agentOnBoardingProductKpi){
                        goals = goals.filter(g => g.kpiCode !== p0.config.agentOnBoardingProductKpi)
                    }

                    // set kpiSequence for K00 to -1 so it comes first in the list
                    goals.forEach(function (goal) {
                      if (goal.kpiCode === 'K00')
                        goal.kpiSequence = -1;
                    });
                    goals = _.sortBy(goals, ['kpiSequence']);
                    $scope.performanceGoals = goals;
                    //sort the kpis by their kpi ids
                    // try {
                    //     $scope.performanceGoals.sort(function (a, b) {
                    //         if (a.kpiCode < b.kpiCode) return -1;
                    //         if (a.kpiCode > b.kpiCode) return 1;
                    //         return 0;
                    //     })
                    // }
                    // catch (err) {
                    //     console.log("scorecard sorting failed with error" + err)
                    // }
                    if ($scope.config.lms && $scope.config.showPipeline) {
                        $scope.performanceGoals.push({
                            isPipelineCard: true
                        })
                    }
                    if ($scope.config.showPortfolio && self.showPortfolioCard()) {
                        $scope.performanceGoals.push({
                            isPortfolio: true
                        })
                    }
                    if (!$scope.config.showBasicScorecard) {
                        if (!isLowestSeniorManagerLevel) {
                            if (goals.length > 0 && goals[0].kpiCode == "K00") {
                                var overallScorecard = goals.shift();
                                goals.push(overallScorecard);
                            }
                        }
                    }
                    else {
                        if ($scope.selectedUser.roleType == "seniorManager") {
                            $scope.performanceGoals = $scope.performanceGoals.filter(function (goal) {
                                var kpi=_.find(p0.allKpis,function(kpi){
                                    return kpi.kpiCode==goal.kpiCode;
                                })
                                return kpi.roleTypes.includes($scope.selectedUser.roleType)
                            })
                        }
                        else {
                            $scope.performanceGoals = $scope.performanceGoals.filter(function (goal) {
                                if ((goal.target && goal.target.value > 0) && goal.kpiCode != "K00") {
                                    return true;
                                }
                                return false;
                            })
                        }
                    }

                    $scope.performanceGoals.forEach( function(pergoal){
                        let index = _.findIndex(p0.allKpis,{_id:pergoal.kpiId})
                        if(index > -1) {
                            pergoal.productId = p0.allKpis[index].products[0]
                        }

                    })

                    const groupedPerformanceGoals = _.groupBy($scope.performanceGoals,'productId');

                    $scope.performanceGoals.forEach ( perGoal => {
                        if(perGoal.parentProductId ){
                            perGoal.hasParent = true;
                            perGoal.showMe = false;
                            let childIds = groupedPerformanceGoals[perGoal.parentProductId]['childIds'];
                            if(groupedPerformanceGoals[perGoal.parentProductId]['hasChild']){
                                groupedPerformanceGoals[perGoal.parentProductId]['childIds'] = [...childIds, perGoal.productId]
                            }else if(perGoal.parentProductId && !groupedPerformanceGoals[perGoal.parentProductId]['hasChild']){
                                groupedPerformanceGoals[perGoal.parentProductId]['hasChild'] = true;
                                groupedPerformanceGoals[perGoal.parentProductId]['childIds'] = [perGoal.productId]
                            }
                            childIds = groupedPerformanceGoals[perGoal.parentProductId]['childIds'].length;
                            perGoal['marginLeft'] = -76 * childIds;
                        }
                    })

                    $scope.performanceGoals.forEach((perGoal) => {
                        const formattedGoal = groupedPerformanceGoals[perGoal.productId]
                        if(formattedGoal){
                            perGoal['hasChild'] = formattedGoal['hasChild'];
                            perGoal['childIds'] = formattedGoal['childIds'];
                            perGoal['showChildren'] = false;
                        }
                    })

                    __constPerformanceGoal = Object.assign([],$scope.performanceGoals);

                    __constPerformanceGoal.forEach( goal => {
                        $scope.toggleMyChild(goal, false);
                    })
                    // self.findSlidesToDisplay();
                    //#endregion

                    return;
                });
        };
        $scope.fetchActiveGoals = function () {
            var userKpis = _.cloneDeep(kpis);
            var user = $scope.selectedUser;
            if(user && user.organizationalTag && user.organizationalTag.jobRole){
                var jobRole = user.organizationalTag.jobRole;
                var selectedRole = _.find(p0.allJobRoles,{_id:jobRole._id});
                /** Do not show scorecard in activity indicators for level 4 and above SM's**/
                if(selectedRole && selectedRole.level > 3){
                    userKpis = _.remove(userKpis, function(kpi){
                        return kpi.uiLevel == 1;
                    });
                }
            }
            return performanceIndicatorService.fetchActivity(userId, $scope.selectedCycle,userKpis).then(function (goals) {
                //TODO: will be removed once activity indicator task is done.
                // const goalsDummy = [
                //     {
                //       "name": "Investments",
                //       "kpiCode": "K01",
                //       "kpiId": "5b4b681f7772b363571a1cba",
                //       "productId":"5b4b681f7772b363571a1cba",
                //       "points": 0,
                //       "percentageCompleted": 0,
                //       "call": {
                //         "value": 17,
                //         "last": "2019-03-18T08:50:56.773Z"
                //       },
                //       "submission": {
                //         "value": 2,
                //         "last": "2019-03-18T08:50:56.707Z"
                //       },
                //       "meeting": {
                //         "value": 0,
                //         "last": "2019-03-18T08:50:56.779Z"
                //       },
                //       "activeLeadCount": {
                //         "value": 1,
                //         "last": "26 m ago"
                //       },
                //       "lostLeadCount": {
                //         "value": 0,
                //         "last": "42 m ago"
                //       },
                //       "newLeadsCount": {
                //         "value": 1,
                //         "last": "15 m ago"
                //       },
                //       "followUpToday": 0,
                //       "followUpLost": 2,
                //       "leadsWon": 0
                //     },
                //     {
                //       "name": "Investments-child",
                //       "kpiCode": "K01",
                //       "kpiId": "5b4b681f7772b363571a1cba",
                //       "parentProductId":"5b4b681f7772b363571a1cba",
                //       "productId":"5b4b681f7772b363571a1cb12",
                //       "points": 0,
                //       "percentageCompleted": 0,
                //       "call": {
                //         "value": 17,
                //         "last": "2019-03-18T08:50:56.773Z"
                //       },
                //       "submission": {
                //         "value": 2,
                //         "last": "2019-03-18T08:50:56.707Z"
                //       },
                //       "meeting": {
                //         "value": 0,
                //         "last": "2019-03-18T08:50:56.779Z"
                //       },
                //       "activeLeadCount": {
                //         "value": 1,
                //         "last": "26 m ago"
                //       },
                //       "lostLeadCount": {
                //         "value": 0,
                //         "last": "42 m ago"
                //       },
                //       "newLeadsCount": {
                //         "value": 1,
                //         "last": "15 m ago"
                //       },
                //       "followUpToday": 0,
                //       "followUpLost": 2,
                //       "leadsWon": 0
                //     },
                //     {
                //       "name": "Insurance",
                //       "kpiCode": "K02",
                //       "kpiId": "5b4b681f7772b363571a1cb9",
                //       "productId":"5b4b681f7772b363571a1cb9",
                //       "points": 0,
                //       "percentageCompleted": 0,
                //       "call": {
                //         "value": 7,
                //         "last": "2019-03-18T08:50:56.774Z"
                //       },
                //       "submission": {
                //         "value": 2,
                //         "last": "2019-03-18T08:50:56.713Z"
                //       },
                //       "meeting": {
                //         "value": 0,
                //         "last": "2019-03-18T08:50:56.780Z"
                //       },
                //       "activeLeadCount": {
                //         "value": 3,
                //         "last": "22 m ago"
                //       },
                //       "lostLeadCount": {
                //         "value": 0,
                //         "last": "20 m ago"
                //       },
                //       "newLeadsCount": {
                //         "value": 2,
                //         "last": "30 m ago"
                //       },
                //       "followUpToday": 0,
                //       "followUpLost": 0,
                //       "leadsWon": 0
                //     },
                //     {
                //       "name": "Insurance-child",
                //       "kpiCode": "K02",
                //       "kpiId": "5b4b681f7772b363571a1cb9",
                //       "parentProductId":"5b4b681f7772b363571a1cb9",
                //       "productId":"5b4b681f7772b363571a1cb3",
                //       "points": 0,
                //       "percentageCompleted": 0,
                //       "call": {
                //         "value": 7,
                //         "last": "2019-03-18T08:50:56.774Z"
                //       },
                //       "submission": {
                //         "value": 2,
                //         "last": "2019-03-18T08:50:56.713Z"
                //       },
                //       "meeting": {
                //         "value": 0,
                //         "last": "2019-03-18T08:50:56.780Z"
                //       },
                //       "activeLeadCount": {
                //         "value": 3,
                //         "last": "22 m ago"
                //       },
                //       "lostLeadCount": {
                //         "value": 0,
                //         "last": "20 m ago"
                //       },
                //       "newLeadsCount": {
                //         "value": 2,
                //         "last": "30 m ago"
                //       },
                //       "followUpToday": 0,
                //       "followUpLost": 0,
                //       "leadsWon": 0
                //     }
                //   ]
                if(p0.config.agentOnBoardingProductKpi){
                    goals = goals.filter(g => g.kpiCode !== p0.config.agentOnBoardingProductKpi)
                }
                  $scope.activityGoals = goals;
                //   $scope.activityGoals = goalsDummy;
                const groupedActivityGoals = _.groupBy($scope.activityGoals,'productId');

                $scope.activityGoals.forEach ( activityGoal => {
                    if(activityGoal.parentProductId ){
                        activityGoal.hasParent = true;
                        activityGoal.showMe = false;
                        let childIds = groupedActivityGoals[activityGoal.parentProductId]['childIds'];
                        if(groupedActivityGoals[activityGoal.parentProductId]['hasChild']){
                            groupedActivityGoals[activityGoal.parentProductId]['childIds'] = [...childIds, activityGoal.productId]
                        }else if(activityGoal.parentProductId && !groupedActivityGoals[activityGoal.parentProductId]['hasChild']){
                            groupedActivityGoals[activityGoal.parentProductId]['hasChild'] = true;
                            groupedActivityGoals[activityGoal.parentProductId]['childIds'] = [activityGoal.productId]
                        }
                        childIds = groupedActivityGoals[activityGoal.parentProductId]['childIds'].length;
                        activityGoal['marginLeft'] = -76 * childIds;
                    }
                })

                $scope.activityGoals.forEach((activityGoal) => {
                    const formattedGoal = groupedActivityGoals[activityGoal.productId]
                    if(formattedGoal){
                        activityGoal['hasChild'] = formattedGoal['hasChild'];
                        activityGoal['childIds'] = formattedGoal['childIds'];
                        activityGoal['showChildren'] = false;
                    }
                })

                __constActivityGoals = Object.assign([],$scope.activityGoals);

                __constActivityGoals.forEach( goal => {
                    $scope.toggleMyChild(goal, false);
                })
                $scope.showNoDataMessageActivityIndicator = goals.length ? false : true;
                return goals;
            });
        };
        $scope.fetchBasicActiveGoals = function (callback) {
            var userKpis = _.cloneDeep(kpis);
            var activities=["CSATCount","Quality"]
            performanceIndicatorService.fetchBasicActivity(userId, $scope.selectedCycle,activities).then(function (goals) {
                $scope.activityGoals = goals;
                try {
                    $scope.activityGoals.sort(function (a, b) {
                        if (a.kpiCode < b.kpiCode) return -1;
                        if (a.kpiCode > b.kpiCode) return 1;
                        return 0;
                    })
                }
                catch (err) {
                    console.log("activit indicator sorting failed with error" + err)
                }
                $scope.activityGoals=$scope.activityGoals.filter(function (goal) {
                    if ((goal.target && goal.target.value > 0)&&goal.kpiCode!="K00") {
                        return true;
                    }
                    return false;
                })
                if(callback) callback();
            });
        };
        $scope.fetchNonLmsActivityData = function(callback){
            return performanceIndicatorService.fetchNonLmsActivity(userId,$scope.selectedCycle, kpis).then(function(data){
                $scope.nonLmsActivityData = data;
                return;
            });
        };

        $scope.fetchKpiBenchmark = function () {
            return performanceIndicatorService.fetchKpiBenchmark(userId, $scope.selectedCycle, $scope.selected.benchmark).then( function (benchmarksValues) {
                $scope.kpiBenchmarksValues = benchmarksValues;
                return;
            });
        };
        $scope.fetchKaiBenchmark = function () {
            return performanceIndicatorService.fetchKaiBenchmark(userId, $scope.selectedCycle, $scope.selected.benchmark).then(function (benchmarksValues) {
                $scope.kaiBenchmarksValues = benchmarksValues;
                return;
            });
        };
        $scope.benchmarkChange = function (benchmark) {
            $scope.selected.benchmark = benchmark;
            if(kpis.length == 1){
                $scope.fetchKpiBenchmark().then(function(){
                    $scope.fetchKaiBenchmark();

                });
            }else if(!$scope.card.isActivity){
                $scope.fetchKpiBenchmark();
            }else if($scope.card.isActivity){
                $scope.fetchKaiBenchmark();
            }
            if(benchmark._id == 'ytd' || benchmark._id == 'prevCycle' || benchmark._id == 'forecast' || $scope.compareValue.value == 'actual'){
                $scope.applyForecast = false;
            }else{
                $scope.applyForecast = true;
            }
            $scope.forecastMultiplier = $scope.config.showForecast ? ForecastMultiplier.ofCycle($scope.selectedCycle._id) : 1;

        };
        $scope.customBenchmarkChange = function () {
            var peer = _.find($scope.peers,{_id:$scope.selected.peerId});
            $scope.benchmarkChange({_id:'custom', userId:$scope.selected.peerId, name:peer.name});
        };
        $scope.compareValueChange = function (compareValue) {
            $scope.compareValue.value = compareValue;
            var benchmark;
            if($scope.compareValue.value == 'actual' && $scope.config.showForecast){
                if($scope.selectedUser.isSeniorManager && !_.find($scope.benchmarks, {_id:'forecast'})) $scope.benchmarks.unshift({name:'Forecast', _id:'forecast', show:true});
            }else{
                _.remove($scope.benchmarks, {_id:'forecast'});
            }
            benchmark = $scope.benchmarks[0];
            $scope.forecastMultiplier = ForecastMultiplier.ofCycle($scope.selectedCycle._id);;
            if(benchmark) $scope.benchmarkChange(benchmark);
        };
        $scope.isActive = function (benchmark) {
            if($scope.selected.benchmark)
                return $scope.selected.benchmark._id == benchmark._id;
            else return false;
        };
        $scope.$watchGroup(['selectedCycle', 'selectedUser', 'selectedPlayType'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2]){
                $scope.card.isActivity = false;
                userId = $scope.selectedUser._id;
                jobRole = $scope.selectedUser.organizationalTag.jobRole._id;
                if($scope.selectedUser.roleType == 'seniorManager'){
                    $scope.selectedUser.isSeniorManager = true;
                    _.forEach($scope.benchmarks, function (benchmark) {
                        benchmark.show = true;
                    });
                }else{
                    $scope.selectedUser.isSeniorManager = false;
                }
                var allPlayTypes = p0.allPlayTypes;
                if($scope.selectedDivision.id != 'all'){
                    var ptIds = _.filter($scope.selectedDivision.playTypes, function(pt){
                        return _.includes($scope.selectedUser.playTypes, pt);
                    });
                    allPlayTypes = _.filter(p0.allPlayTypes, function(playType){
                        return _.includes(ptIds, playType._id);
                    });
                }
                if($scope.selectedPlayType._id != 'all'){
                    allPlayTypes = [$scope.selectedPlayType];
                }
                allPlayTypes = _.filter(allPlayTypes, function(playType){
                    return _.includes($scope.selectedUser.playTypes,playType._id);
                });
                var gms=[];
                _.forEach(allPlayTypes, function (playType) {
                    gms = gms.concat(playType.uiKpis);
                });
                kpis = _.cloneDeep(_.uniqBy(gms,'_id'));
                $scope.showActual = true;
                $scope.compareValue.value = 'actual';
                $scope.init();
                $scope.isActiveCycle = $scope.selectedCycle.active;
                if(!$scope.selectedCycle.active)
                    $scope.compareValue.value = 'actual';
                $scope.compareValueChange($scope.compareValue.value);
            }
        });


         self.findSlidesToDisplay=function(){
            var totalWidth = window.innerWidth;
            var slides = Math.floor((totalWidth * 0.85)/400);
            var slidesToShow = slides > 5 ? 5 : slides;
            $scope.slick.slickConfig= {
                enabled: true,
                autoplay: false,
                infinite: false,
                draggable: true,
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
                method: {},
                event:{
                    init:function(event,slick){
                        slick.setPosition(0);
                    }
                }
            };

            $scope.achievementDataFetched = false;
            $scope.activityDataFetched = false;
            $timeout(function () {
                $scope.achievementDataFetched = true;
                $scope.activityDataFetched = true;
            }, 0);
        }

        $scope.$watch('selectedKpi',function(newVal,oldVal){
            if($scope.selectedKpi && $scope.selectedKpi._id != 'overall' && $scope.performanceGoals){
                $scope.toggle.displaySubProducts = true;
                var index = _.findIndex($scope.performanceGoals, {kpiId:$scope.selectedKpi._id});

                if(!index) {
                    index= 0;
                }
                $scope.slick.slickConfig.method.slickGoTo(index);
                if($scope.selected.benchmark && $scope.selected.benchmark._id == 'custom'){
                    var peer = _.find($scope.peers,{_id:$scope.selected.peerId});
                    if(!peer.targets[$scope.selectedKpi._id]){
                        var user = _.find($scope.peers, function (peer) {
                            return peer.targets[$scope.selectedKpi._id];
                        });
                        if(user){
                            $scope.selected.peerId = user._id;
                            $scope.customBenchmarkChange();
                        }else{
                            $scope.benchmarkChange($scope.benchmarks[0]);
                        }
                    }

                }
                let selectedGoal = null
                $scope.performanceGoals = __constPerformanceGoal.filter( goal => {
                    if(goal.kpiId === $scope.selectedKpi._id || goal.kpiCode === 'K00'){
                        selectedGoal = goal;
                        return true;
                    }
                })
                if(selectedGoal){
                    $scope.toggleMyChild(selectedGoal, true);
                }
            }else{
                $scope.performanceGoals = __constPerformanceGoal;
                $timeout(function(){
                    $scope.toggle.displaySubProducts = false;
                    $scope.toggleDisplaySubProducts($scope.toggle.displaySubProducts)
                });
            }

            if($scope.selectedKpi && $scope.selectedKpi._id != 'overall'){
                $scope.performanceGoals = __constPerformanceGoal.filter( goal => {
                    if(goal.kpiId === $scope.selectedKpi._id || goal.kpiCode === 'K00'){
                        return true;
                    }
                })
                $scope.activityGoals = __constActivityGoals.filter( goal => {
                    if(goal.kpiId === $scope.selectedKpi._id || goal.kpiCode === 'K00'){
                        return true;
                    }
                })
            }
            self.findSlidesToDisplay();
        });

        self.showPortfolioCard=function() {
            var userPlaytypes = p0.myPlayTypes;
            var showPortfolio = false;
            userPlaytypes.forEach(function (userPlaytype) {
                if (userPlaytype.showPortfolio) {
                    showPortfolio = true;
                }

            })
            return showPortfolio;
        }

        self.mapProductNames = function (pipelineScorecardData) {
            let data = {};
            /* remove name, email, _id from the pipeline object keep only product id keys*/
            p0.myProducts.forEach((prd) => {
                data[prd._id] = pipelineScorecardData[prd._id] || {}
                data[prd._id].shortName = prd.shortName
            });
            return data;
        }

        $scope.aggregatePipelinePortfoio = function(){
            var index = _.findIndex($scope.performanceGoals,{kpiCode: "K00"});
            if(index > -1){
                var totalPortfolio = 0;
                Object.keys($scope.userportfolioData.portfolio).forEach( function(key){
                    totalPortfolio += $scope.userportfolioData.portfolio[key].value
                })
                $scope.performanceGoals[index].totalPortfolioValue= totalPortfolio

                var totalPipelineValue = 0;
                Object.keys($scope.pipelineData).forEach( function(key){
                    totalPipelineValue += $scope.pipelineData[key].totalPipelineValue
                })
                $scope.performanceGoals[index].totalPipelineValue= totalPipelineValue

            }

        }
        //#endregion

        //function to toggle state of the children
        const toggleChildrenPerformanceGoalState = (productId, showOnUi) => {
            __constPerformanceGoal.forEach ( goal => {
                if(goal.parentProductId === productId){
                    goal.showMe = showOnUi
                }
            })

            $scope.performanceGoals = __constPerformanceGoal.filter( goal => {
                if(goal.hasOwnProperty('hasParent')){
                    if(goal.showMe){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return true;
                }
            });
        }

        //function to toggle state of the activity goals of children
        const toggleActivityChildrenState = (productId, showOnUi) => {
            __constActivityGoals.forEach ( goal => {
                if(goal.parentProductId === productId){
                    goal.showMe = showOnUi
                }
            })

            $scope.activityGoals = __constActivityGoals.filter( goal => {
                if(goal.hasOwnProperty('hasParent')){
                    if(goal.showMe){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return true;
                }
            });
        }

        $scope.toggleMyChild = (kpi, showChildren, isScoreCardEvent) => {
            let selectedProductId = null;
            if(__constPerformanceGoal.length){
                __constPerformanceGoal.forEach ( (goal) => {
                    if(goal.productId === kpi.productId){
                        selectedProductId = goal.productId;
                        if(showChildren){
                            //make child hidden i.e. toggle the previous state
                            goal.showChildren = showChildren;
                        }else{
                            goal.showChildren = showChildren;
                            //make child visible i.e. toggle the previous state
                        }
                    }
                })
                //#endregion
                toggleChildrenPerformanceGoalState(selectedProductId, showChildren);
            }

            if(!showChildren){
                $scope.toggle.displaySubProducts = false
            }

            if(__constActivityGoals.length){
                //toggle activity goals
                __constActivityGoals.forEach ( (goal) => {
                    if(goal.productId === kpi.productId){
                        selectedProductId = goal.productId;
                        if(showChildren){
                            //make child hidden i.e. toggle the previous state
                            goal.showChildren = showChildren;
                        }else{
                            goal.showChildren = showChildren;
                            //make child visible i.e. toggle the previous state
                        }
                    }
                })
                //#endregion
                toggleActivityChildrenState(selectedProductId, showChildren);
            }

            //called only when it is toggled from scorecard
            if(isScoreCardEvent){
                self.findSlidesToDisplay();
            }
        }
        //#endregion

        $scope.toggleDisplaySubProducts = (displayState) => {
            $scope.toggle.displaySubProducts = displayState;
            //toggle performance goal state
            if($scope.selectedKpi && $scope.selectedKpi._id !== 'overall'){
                let selectedGoal = null
                $scope.performanceGoals = __constPerformanceGoal.filter( goal => {
                    if((goal.kpiId === $scope.selectedKpi._id) || goal.kpiCode === 'K00'){
                        selectedGoal = goal;
                        return true;
                    }
                })
                if(selectedGoal){
                    $scope.toggleMyChild(selectedGoal, displayState);
                }
            }else{
                if(Object.keys(__constPerformanceGoal).length){

                    __constPerformanceGoal.forEach ( goal => {
                        $scope.toggleMyChild(goal, displayState);
                    })
                }

                //toggle activity goal state
                if(Object.keys(__constActivityGoals).length){
                    __constActivityGoals.forEach ( goal => {
                        $scope.toggleMyChild(goal, displayState);
                    })
                }
            }
            if($scope.selectedKpi && $scope.selectedKpi._id != 'overall'){
                $scope.performanceGoals = __constPerformanceGoal.filter( goal => {
                    if(goal.kpiId === $scope.selectedKpi._id || goal.kpiCode === 'K00'){
                        if(!displayState && goal.hasParent && goal.showMe){
                            return true;
                        }else if(!displayState && !goal.hasParent){
                            return true;
                        }else if(displayState){
                            return true
                        }
                        return false
                    }
                })
                $scope.activityGoals = __constActivityGoals.filter( goal => {
                    if(goal.kpiId === $scope.selectedKpi._id || goal.kpiCode === 'K00'){
                        return true;
                    }
                })
            }

            self.findSlidesToDisplay();
        }

        self.init()
    });
