module.exports = {
	USER_PERMISSIONS: {
		viewTaggedCustomer: "viewTaggedCustomer",
		exportCustomers: "exportCustomers",
		searchNonTaggedCustomer: "searchNonTaggedCustomer",
		retagCustomer: "retagCustomer",
		createCustomer: "createCustomer",
		createLead: "createLead",
		createProspectUser: "createProspectUser",
		reassignLead: "reassignLead",
		reassignProspectUser: "reassignProspectUser",
		exportLeads: "exportLeads",
		uploadCampaigns: "uploadCampaigns",
		createReferralLead: "createReferralLead",
		assignReferralLead: "assignReferralLead",
		viewLeadList: "viewLeadList",
		uploadLeads: "uploadLeads",
		createServiceRequest: "createServiceRequest",
		assignServiceRequest: "assignServiceRequest",
		viewServiceRequest: "viewServiceRequest",
		exportServiceRequest: "exportServiceRequest",
		uploadServiceRequest: "uploadServiceRequest",
		viewServiceRequestList: "viewServiceRequestList",
		changeCustomerSegment: "changeCustomerSegment",
		editLead: 'editLead',
		editProspectUser: 'editProspectUser',
		followLead: 'followLead', 
		followProspectUser: 'followProspectUser', 
		shareDocuments: 'shareDocuments', 
		callInteraction: 'callInteraction', 
		chatInteraction: 'chatInteraction',
		meetingInteraction: 'meetingInteraction',
		editLeadInfo : 'editLeadInfo',
		editProspectUserInfo : 'editProspectUserInfo',
		addNotes: 'addNotes'
	},
};
