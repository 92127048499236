"use strict";

angular
    .module("bitnudgeApp", [
        "ngMaterial",
        "ngIdle",
        "ngResource",
        "ngSanitize",
        "btford.socket-io",
        "ui.router",
        "toaster",
        "angularMoment",
        "datatables.directive",
        "datatables.columnfilter",
        "luegg.directives",
        "ngInflection",
        "truncate",
        "ngAnimate",
        "slickCarousel",
        "fcsa-number",
        "timer",
        //'ngScrollbars',
        "localytics.directives", // angular-chosen-localytics
        "angular-loading-bar",
        "md.data.table",
        "ngMap",
        "angular-chartist",
        "smDateTimeRangePicker",
        "ngXlsx",
        "ui.tree",
        "angular-scroll-animate",
        "angular-timeline",
        "moment-picker",
        "camelCaseToHuman",
        "ngFileUpload",
        "ngMessages",
        "ngFileSaver",
        "ui.bootstrap",
        "pdfjsViewer",
        'ngIntlTelInput',
    ])
    // changing the value globally in the provider itself, dependencies have been injected gloally in the project that is
    // why I had to put another config

    // replace the format LLL with "DD.MM.YYYY HH:mm" if you want to see the format as 24 hours in the input box as well
    .config(['momentPickerProvider','ngIntlTelInputProvider', function (momentPickerProvider,ngIntlTelInputProvider) {
        momentPickerProvider.options({
            hoursFormat: 'LT',
            format:"MMMM Do YYYY, hh:mm A"
        });
        ngIntlTelInputProvider.set({ initialCountry: 'id', nationalMode: false });
    }])
    .config(function(
        $urlRouterProvider,
        $locationProvider,
        $httpProvider,
        $compileProvider,
        $mdAriaProvider,
        $mdThemingProvider,
        $mdIconProvider,
        $mdDateLocaleProvider,
        cfpLoadingBarProvider,
        momentPickerProvider
    ) {
        // configuring only additional required values
        momentPickerProvider.options({         
            minutesFormat: 'HH:mm',
        });
        $locationProvider.html5Mode(true);
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
        $urlRouterProvider.otherwise(function($injector) {
            $injector.get("$state").go("userFlow.main");
        });

        $httpProvider.interceptors.push("relativeUrlInterceptor");
        $httpProvider.interceptors.push("authInterceptor");
        $httpProvider.interceptors.push("errorInterceptor");
        // $httpProvider.interceptors.push('cacheInterceptor');
        $httpProvider.defaults.withCredentials = true;
        delete $httpProvider.defaults.headers.common["X-Requested-With"];

        moment.updateLocale("en", {
            calendar : {
                lastDay : '[Yesterday]',
                sameDay : '[Today]',
                nextDay : '[Tomorrow]'
            },
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "sec",
                m: "1 min",
                mm: "%d mins",
                h: "1 hr",
                hh: "%d hrs",
                d: "a day",
                dd: "%d days",
                M: "a month",
                MM: "%d months",
                y: "a year",
                yy: "%d years"
            }
        });
        moment.relativeTimeThreshold('d', 29);

        // Set true to hit remote server for BE calls
        var remoteServer = true;
        if (remoteServer) {
            var remoteUrl = window.remoteUrl;

            // remoteUrl = "http://localhost:9000";

            $httpProvider.interceptors.push(function($q, $rootScope) {
                /**Set remoteUrl to $rootScope variable which is used in httpSrc directive**/
                $rootScope.remoteUrl = remoteUrl;
                $rootScope.openDMS = false;
                return {
                    request: function(config) {
                        if (config.url && config.url.search(".html") === -1 && config.url.indexOf('http') === -1) {
                            if (config.url[0] !== "/") {
                                config.url = "/" + config.url;
                            }
                            config.url = remoteUrl + config.url;
                        }
                        return config;
                    }
                };
            });
        }

        $mdDateLocaleProvider.formatDate = function(date) {
            if (date == null) return "Select a date";
            else return moment(date).format("DD-MMM-YY");
        };

        $mdAriaProvider.disableWarnings();
        $mdThemingProvider.definePalette("bitnudgePallet", {
            "50": "ffebee",
            "100": "ffcdd2",
            "200": "ef9a9a",
            "300": "e57373",
            "400": "ef5350",
            "500": "3777af",
            "600": "e53935",
            "700": "d32f2f",
            "800": "c62828",
            "900": "b71c1c",
            A100: "ff8a80",
            A200: "ff5252",
            A400: "ff1744",
            A700: "d50000",
            contrastDefaultColor: "light", // whether, by default, text (contrast) on this palette should be dark or light
            contrastDarkColors: [
                "50",
                "100", //hues which contrast should be 'dark' by default
                "200",
                "300",
                "400",
                "A100"
            ],
            contrastLightColors: undefined // could also specify this if default was 'dark'
        });
        $mdThemingProvider.definePalette("accentPallet", {
            "50": "3777af",
            "100": "3777af",
            "200": "3777af",
            "300": "3777af",
            "400": "3777af",
            "500": "3777af",
            "600": "3777af",
            "700": "3777af",
            "800": "3777af",
            "900": "3777af",
            A100: "3777af",
            A200: "3777af",
            A400: "ff1744",
            A700: "3777af"
        });

        $mdThemingProvider
            .theme("default")
            .primaryPalette("bitnudgePallet")
            .accentPalette("accentPallet");

        $mdIconProvider.icon("speedometer", require("./images/icons/speedometer.svg")).defaultFontSet("fa");

        cfpLoadingBarProvider.includeSpinner = false;
        cfpLoadingBarProvider.latencyThreshold = 173;
    })

// Use relative URLs even if absolute url is used in $http calls or location hrefs
.factory("relativeUrlInterceptor", function() {
    return {
        request: function(config) {
            if (config.url[0] === "/") config.url = config.url.substring(1);
            return config;
        }
    };
})

.factory("authInterceptor", function($q, $location, $rootScope) {
    $rootScope.apiToken = "*&@@*#SKD@*@W*@#E%*&B!(*#*#";
    return {
        // Add authorization token to headers
        request: function(config) {
            if(config.url.indexOf('http') === -1){
                config.headers = config.headers || {};
                config.headers.apiToken = $rootScope.apiToken;
            }else{
                config.headers = null;
                config.withCredentials = false;
            }
            return config;
        },

        // Intercept 401s and redirect you to login
        responseError: function(response) {
            if (response.status === 401) {
                //$location.path('/login');
                $location.path("login");
                // remove any stale tokens
                return $q.reject(response);
            } else {
                return $q.reject(response);
            }
        }
    };
})

.factory("errorInterceptor", function($q, $location, $rootScope) {
    return {
        responseError: function(response) {
            if (response.status === 400) {
                response.status = 200;
                response.data = {status: false, errors: [response.data]}
                return $q.resolve(response);
            }
            return $q.reject(response);
        }
    };
})

.factory("cacheInterceptor", function($q, $location, $rootScope, BNCache, $cacheFactory) {
    var http_ttl_cache = {};
    return {
        request: function(config) {
            if (!config.cacheAble) {
                return config;
            }

            var urlHash = $rootScope.remoteUrl + "/" + config.url;

            // we don't have the last time stamp
            // can't cache the data
            return BNCache.updateLastSyncTime().then(function(lastSyncTime) {
                if (localStorage.getItem("LAST_SYNC_TIME")) {
                    var localDate = new Date(localStorage.getItem("LAST_SYNC_TIME"));
                    // fetch data from remote server and invalidate cache
                    if (localDate.getTime() !== lastSyncTime.getTime()) {
                        // clear cache and set the new timestamp
                        localStorage.clear();
                        localStorage.setItem("LAST_SYNC_TIME", lastSyncTime);
                        return config;
                    }

                    // item is not present get it from remote server
                    if (!localStorage.getItem(urlHash)) {
                        return config;
                    }

                    // Data is cached and usable, we can use it.
                    // We will reject the request from here and then catch it in the
                    // response error block. This is done because the response block
                    // expects us to return a config only compared to a reponse or
                    // response error block where we can return a response.
                    var data = JSON.parse(localStorage.getItem(urlHash));
                    return $q.reject({
                        config: config,
                        response: data,
                        cached: true
                    });
                } else {
                    localStorage.setItem("LAST_SYNC_TIME", lastSyncTime);
                    return config;
                }
            });
        },

        response: function(response) {
            if (response.config.method === "GET" && response.config.cacheAble == true) {
                var urlHash = response.config.url;

                localStorage.setItem(urlHash, JSON.stringify(response));
            }
            return response;
        },

        responseError: function(rejection) {
            if (rejection.cached) {
                return $q.resolve(rejection.response);
            }

            return $q.reject(rejection);
        }
    };
})

.run(function(trackingService, $rootScope, $state, $stateParams, Auth, p0, $document, IdleUtil) {
        $rootScope._ = window._; // for injecting lodash
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.appVersion = "1.5.1";

        // Redirect to login if route requires auth and you're not logged in
        if ("Notification" in window) {
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            }
        }

        $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
            // routes which don't require login.
            $rootScope.openDMS = false;
            if (toState.url === "/login" || toState.url === "/forgot" || toState.url.indexOf("/reset") !== -1) {
                if (toState.url === "/login")
                    angular
                    .element($document[0].body)
                    .removeClass("no-skin")
                    .addClass("login-layout");

                p0.getBatchStrings(function(fetchedStrings) {
                    if (fetchedStrings) {
                        $rootScope.strings = p0.getString();

                        $state
                            .go(toState.name, toParams, {
                                notify: false
                            })
                            .then(function() {
                                $rootScope.$broadcast("$stateChangeSuccess", toState, toParams, fromState, fromParams);
                            })
                            .catch(function(err) {
                                console.log("p0.getBatchStrings failed");
                            });
                    }
                });

                return;
            }

            angular
                .element($document[0].body)
                .removeClass("login-layout")
                .addClass("no-skin");
            // routes which require login. if not logged in, redirect to login
            // event.preventDefault() is the hacky way. another way is to resolve 'user' before each controller is INITed using:-
            //     'resolve:{user: function(Auth){return Auth.checkUser();}' in the $stateProvider.state({}) object
            event.preventDefault();
            Auth.isLoggedInAsync().then(function(args) {
                var loggedIn = args[0];

                Auth.setSecurityQuestions().then(function(setSecurityQuestions) {
                    Auth.isApplicationSetupMode().then(function(appSetupMode) {
                        var states = {
                            NOT_LOGGED_IN: 1,
                            SHOW_TUTORIALS: 2,
                            SET_AVATAR: 3,
                            SET_SECURITY_QUESTIONS: 4,
                            LOGGED_IN: 5,
                            SKIP_STATE_CHECK: 100
                        };

                        var gotoState = function(state, params) {
                            $state
                                .go(toState.name, params, {
                                    notify: false
                                })
                                .then(function() {
                                    $rootScope.$broadcast("$stateChangeSuccess", toState, params, fromState, fromParams);
                                });
                        };

                        var showLogin = function() {
                            if (toState.name !== "user.securityQuestions") {
                                toState = $state.get("login");
                            }
                            return gotoState(toState, toParams);
                        };

                        var showTutorials = function() {
                            toState = $state.get("instructions");
                            return gotoState(toState, toParams);
                        };

                        var showSetAvatar = function() {
                            toState = $state.get("user.step1");
                            return gotoState(toState, toParams);
                        };

                        var showSetSecurityQuestions = function() {
                            toState = $state.get("user.securityQuestions");
                            return gotoState(toState, {
                                set: true
                            });
                        };

                        var showAfterLogin = function(appSetupMode) {
                            var currentUser = Auth.getCurrentUser();
                            var playerValidLMSStates = ["lms.listLead", "lms.tickets", "lms.huddleBoard", "lms.heatmap", "lms.customers", "lms.listProspect"];

                            //Check AdminUsers and Players valid states
                            p0.getAdminMenuItems(currentUser).then(function(menuItems) {
                                if (!appSetupMode &&
                                    currentUser.systemRole === "lmsAdmin" &&
                                    menuItems.states &&
                                    menuItems.states.length &&
                                    menuItems.states.indexOf(toState.name) === -1
                                ) {
                                    toState = $state.get("lms.adminDashboard");
                                }
                                // string.startsWith not supported by IE 10
                                //  else if(currentUser.systemRole !== "lmsAdmin" && toState.name.startsWith('lms') && playerValidLMSStates.indexOf(toState.name) === -1){
                                else if (
                                    currentUser.systemRole !== "lmsAdmin" &&
                                    toState.name.indexOf("lms") === 0 &&
                                    playerValidLMSStates.indexOf(toState.name) === -1
                                ) {
                                    toState = $state.get("userFlow.dashboard");
                                }

                                //if user is new or expired redirect to settings page and prohibit him from accessing anything
                                return p0.fetch().then(function(completed) {
                                    if (completed) {
                                        IdleUtil.init();
                                        $rootScope.strings = p0.getString();
                                        
                                        var result = userNewOrExpired();
                                        if ($rootScope.config.bypassAuthentication) result = false;

                                        if (result) toState = $state.get("settings");
                                        // var locationUrl = $state.href(toState.name, toParams);
                                        // $location.path(locationUrl);
                                        return gotoState(toState, toParams);
                                    }
                                });
                            });
                        };

                        var state = states.NOT_LOGGED_IN;

                        if (!loggedIn) {
                            state = states.NOT_LOGGED_IN;
                        } else if (Auth.showTutorials()) {
                            state = states.SHOW_TUTORIALS;
                        } else if (Auth.showAvatar()) {
                            state = states.SET_AVATAR;
                        } else if (setSecurityQuestions) {
                            state = states.SET_SECURITY_QUESTIONS;
                        } else {
                            state = states.LOGGED_IN;
                        }

                        //On first time application setup we skpi states until we upload all config files
                        if (appSetupMode) {
                            state = states.SKIP_STATE_CHECK;
                        }
                        switch (state) {
                            case states.NOT_LOGGED_IN:
                                return showLogin();
                            case states.SHOW_TUTORIALS:
                                return showTutorials();
                            case states.SET_AVATAR:
                                return showSetAvatar();
                            case states.SET_SECURITY_QUESTIONS:
                                return showSetSecurityQuestions();
                            case states.LOGGED_IN:
                                return showAfterLogin(appSetupMode);
                            case states.SKIP_STATE_CHECK:
                                return showAfterLogin(appSetupMode);
                            default:
                                return showLogin();
                        }
                    });
                });
            });
        });

        $rootScope.$on("$locationChangeStart", function(newUrl, oldUrl) {
            var path = "";

            if (newUrl != oldUrl) {
                trackingService.stateChanged(oldUrl, newUrl);
                var splitedURL = oldUrl.split(/\/+/g);

                splitedURL.forEach(function(split, index) {
                    if (index > 1) path += "/" + split;
                });
            }

            // $rootScope.hashLocation = newUrl.split('#')[1];
            $rootScope.previousPage = path;
        });

        var userNewOrExpired = function() {
            var currentUser;
            var user = Auth.getCurrentUser();

            if (user.cloneUser) currentUser = user.cloneUser;
            else currentUser = user;

            if(!currentUser.useBitNudgeLoginFlow){
                return false;
            }

            var currentDate = new Date();
            var expiryDate = new Date(currentUser.passwordExpires);

            if (expiryDate.getTime() < currentDate.getTime() || expiryDate == "Invalid Date") return true;
            return false;
        };
        $rootScope.currentState = {};
    })
    .constant("_", window._)
    .config(function(pdfjsViewerConfigProvider) {
        pdfjsViewerConfigProvider.setWorkerSrc("./pdf.js-viewer/pdf.worker.js");
        pdfjsViewerConfigProvider.setCmapDir("./pdf.js-viewer/cmaps");
        pdfjsViewerConfigProvider.setImageDir("./pdf.js-viewer/images");

        pdfjsViewerConfigProvider.disableWorker();
        pdfjsViewerConfigProvider.setVerbosity("infos"); // "errors", "warnings" or "infos"
    }).filter('formatdate', function($filter) {
        return function(timestamp) {
          var currentDate = new Date()
          var toFormat = new Date(timestamp)
          if(toFormat.getDate() == currentDate.getDate() && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear() ) {
            return 'Today ' + $filter('date')(toFormat.getTime(), 'hh:mm a')
          }
          if(toFormat.getDate() == (currentDate.getDate() - 1) && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
            return 'Yesterday ' + $filter('date')(toFormat.getTime(), 'hh:mm a')
          }
      
            return $filter('date')(toFormat.getTime(), 'd-MMM-y, hh:mm a')
        }
      })
      .filter('formatdateWithoutTime', function($filter) {
        return function(timestamp) {
          var currentDate = new Date()
          var toFormat = new Date(timestamp)
          if(toFormat.getDate() == currentDate.getDate() && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear() ) {
            return 'Today'
          }
          if(toFormat.getDate() == (currentDate.getDate() - 1) && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
            return 'Yesterday'
          }
          if(toFormat.getDate() == (currentDate.getDate() + 1) && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
            return 'Tomorrow'
          }
      
            return $filter('date')(toFormat.getTime(), 'd-MMM-y')
        }
      })
