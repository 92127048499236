const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/ticketRight.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/tickets/ticketTimeline.html');

'use strict';
angular.module('bitnudgeApp')
    .component('ticketRight', {
        bindings: {
            ticket: '<',
            closeSelectedTicket: '&',
            editTicket: '&',
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function ($scope, $rootScope,Auth, $mdDialog, leadsService, p0, toaster) {
            let ctrl = this;
            $scope.strings = $rootScope.strings;
            const allProducts = p0.allProducts
            $scope.contactKeys = {
                'ticketNumber': 'SR Number',
                'cui': 'NRIC',
                'customerName': 'Name',
                'contactNumber': 'Contact Number',
                'productName': 'Product',
                'subProductName': 'Sub Product',
                'type': 'Type',
                'subType': 'Sub Type',
                'type': 'SR Type',
                'ticketStatus': 'Status',
                'createdAt': 'Created On',
                'updatedAt': 'Update On',
            };

            $scope.me = Auth.getCurrentUser();
            $scope.isAgentOnBoardingVerificationUser = $rootScope.isAgentOnBoardingVerificationUser;

            $scope.isSeniorManager = $rootScope.isSeniorManager;
            $scope.isFrontline = $rootScope.isFrontline;
            this.$onChanges = (changes) => {
                console.log('changes: ', changes);
                if (changes.ticket) {
                    $scope.selectedTicket = changes.ticket.currentValue;
                    const srProduct = allProducts.filter(prd => prd._id === $scope.selectedTicket.productId)
                    let srSubProduct = [];
                    if(srProduct && srProduct.length && srProduct[0].subProducts)
                        srSubProduct = srProduct[0].subProducts.filter(prd => prd.internalId === $scope.selectedTicket.subProduct)
                    $scope.selectedTicket.productName = srProduct[0] ? srProduct[0].name : null;
                    $scope.selectedTicket.subProductName = srSubProduct[0] ? srSubProduct[0].name : null;
                    console.log('$scope.selectedTicket: ', $scope.selectedTicket);
                }
            };

            this.showTimeline = function(ticket){
                $mdDialog.show({
                    templateUrl: autoImportNgTemplateLoaderTemplate2,
                    controller: 'timelineController',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true, // Only for -xs, -sm breakpoints.
                    resolve:{
                        ticket: function(){
                            return ticket;
                        }
                    }
                });
            };
        }

    });
