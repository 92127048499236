'use strict';
angular.module('bitnudgeApp')
    .controller('loginPrivilegesCtrl', function($http, $scope,  Auth, $location, $q, toaster,$mdDialog, ngXlsx, $filter) {
        $scope.asc = true;
		$scope.sortField = 'name'
		$scope.sortOrder = 1;
        $scope.selected = {
		    searchKey: null
        }

        $scope.fetchUsers = function(flow) {
            $scope.loading = true;
            const reqBody = {
                limit: 10,
                page: flow === 'prev' ? $scope.page - 1 : flow === 'next' ? $scope.page + 1 : 1,
                sortKey: $scope.sortField,
                sortOrder: $scope.sortOrder
            }
			if ($scope.selected.searchKey && $scope.selected.searchKey !== '') {
				reqBody.searchKey = $scope.selected.searchKey;
			}
            $http.post('/api/users/privileges',reqBody).then(function(result) {
                const data = result.data;
                $scope.page = data.page;
                $scope.totalRecords = data.total;
                $scope.totalPages = data.pages;
                $scope.userlist = data.docs;
                $scope.loading = false;
            });
        }
        $scope.fetchUsers();

        $scope.sortTable = function(fieldName) {
			$scope.asc = !$scope.asc;
			$scope.sortField = fieldName;
			$scope.sortOrder = $scope.asc ? 1 : -1;
			$scope.fetchUsers();
		}

        $scope.cancelSearch = function() {
            $scope.selected.searchKey = null;
            $scope.fetchUsers();
        }

        $scope.toggleUserPriviliges = function(user){
            user.isAltered = true;
        }

        $scope.saveLoginPriviliges = function(){
            var alteredUsers = _.filter($scope.userlist, function(user){
                return user.isAltered;
            });
            alteredUsers = _.map(alteredUsers, function(user){
                return {
                    _id : user._id,
                    mobileLogin : user.mobileLogin,
                    webLogin: user.webLogin,
                    resetDevices : user.resetDevices
                }
            })
            return $http.post('/api/users/alterPrivileges', {
                users : alteredUsers
            }).then(function(result){
                result=result.data;
                toaster.pop('success', "Privileges updated");
            }).catch(function(err){
                toaster.pop('error', "Failed to update privileges");
            })
        }
    });
