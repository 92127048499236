const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/jobRole/jobRole.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/jobRole/showjobRole.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/jobRole/addBulkRole.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/jobRole/addClonedBulkRole.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/jobRole/addJobRole.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
        .state('lms.jobRole', {
            url: '/jobRole',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'JobRoleCtrl'
        })
        .state('lms.showjobRole', {
            url: '/showjobRole/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: function($stateParams, $scope, $http, $location,  toaster, Upload, Auth, p0) {
                $scope.jobrole = p0.getJobRole($stateParams.id);
                //socket.syncUpdates('jobrole', $scope.jobrole);
            }
        })
 
        .state('lms.addBulkRole', {
                url: '/addBulkRole',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: function($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "jobRoles";
                    console.log('I am here JRB');
                    $scope.onFileSelectLead = function() {
                        if ($scope.bulkLead == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");

                        } else {
                            var fileName = $scope.bulkLead[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/jobroles/import/excel',
                                    data: {
                                        userId: Auth.getCurrentUser()._id,
                                        cronTag:'uploadJobRoles',
                                        invocationMethod:"manual"
                                    },
                                    file: $scope.bulkLead[0],
                                    progress: function(e) {}
                                }).then(function(data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Job Role creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }

                        }
                    }

                }
            })
            .state('lms.addClonedBulkRole', {
                url: '/addClonedBulkRole',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: function($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "cloneJobRoles";
                    $scope.onFileSelectLead = function() {
                        if ($scope.bulkLead == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");

                        } else {
                            var fileName = $scope.bulkLead[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/upload',
                                    data: {
                                        userId: Auth.getCurrentUser()._id,
                                        cronTag:'uploadClonedJobRoles',
                                        invocationMethod:"manual"
                                    },
                                    file: $scope.bulkLead[0],
                                    progress: function(e) {}
                                }).then(function(data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Cloned Job Role creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }

                        }
                    }

                }
            })
            .state('lms.addJobRole', {
                url: "/addJobRole/:id",
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: function($scope, $http, $stateParams, toaster, $location) {
                    $scope.editForm = false;
                    $scope.family = ["SO","DSA","BM","TL","Deputy_AM","RM","AM","SM"];
                    $scope.roleType = ["frontline","manager","seniorManager"];
                    $scope.flRoles = ["SO","DSA"];
                    $scope.mgrRoles = ["Branch Manager","Team Leader"];
                    $scope.jobRole = {};
                    if($stateParams.id){
                        $scope.editForm = true;
                        $http.post('/api/jobroles/show', {id:$stateParams.id}).then(function (jRole) {
                            jRole=jRole.data;
                            $scope.jobRole = jRole;
                        });
                    }
                    $scope.addJobRole = function() {
                        $http.post('/api/jobroles/jobroleForm', $scope.jobRole).then(function(jobRole) {
                            jobRole=jobRole.data;
                            if (jobRole) {
                                $location.path('/jobRole');
                                toaster.pop('success', "Job Role Creation", "New Job Role Added.");
                            }
                        })
                    }
                }
            })
    });
