const autoImportNgTemplateLoaderTemplate1 = require('../html/mobileOTP.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('SidebarCtrl', function ($rootScope, $scope, Auth, p0, serviceManager, $state, Sidebar, $window, $mdDialog, toaster, Upload, $timeout, $cacheFactory,$http) {
        let self = this;
        $scope.currentUser = Auth.getCurrentUser();
        $scope.userName = $scope.currentUser.cloneUser ? $scope.currentUser.cloneUser.name : $scope.currentUser.name;

        if($scope.currentUser.cloneUser){
            $scope.showChangePassword = $scope.currentUser.cloneUser.useBitNudgeLoginFlow;
        }else if($scope.currentUser){
            $scope.showChangePassword = $scope.currentUser.useBitNudgeLoginFlow;
        }

        if ($rootScope.isSeniorManager) {
            $scope.roleText = p0.strings.SENIORMANAGER.value;
        }
        if ($rootScope.isMiddleManager) {
            $scope.roleText = p0.strings.MANAGER.value;
        }
        if ($rootScope.isFrontline) {
            $scope.roleText = p0.strings.FRONTLINE.value;
        }

		$scope.p0MenuItems = getMenuItems();
        $scope.menuItems = $scope.p0MenuItems;
        $scope.config = p0.config;

        $scope.userAdminMenuItems = p0.menuItems;

        $scope.onMenuClick = function (menuItem) {
            console.log('###menuItem: ', menuItem)
            $state.go(menuItem.state, menuItem.params);
        };

        $scope.onAdminMenuClick = function (menuItem) {
            //externalApi stores API path for import
            $state.go(menuItem.state, { excel: menuItem.params, externalApi: menuItem.externalApi });
        };

        // $scope.getHref = function (menuItem) {
        //     return menuItem.href || $state.go(menuItem.state, menuItem.params);
        // };

        $scope.isActive = function (menuItem) {
            return $state.includes(menuItem.state);
        };

        $scope.onProfileClick = function () {
            $state.go('userFlow.profile', { selectedUserId: $scope.currentUser._id });
        };

        $scope.onFileSelect = function ($files) {
            //$files: an array of files selected, each file has name, size, and type.
            for (var i = 0; i < $files.length; i++) {
                var $file = $files[i];
                var fileName = $files[i].name.split(".");
                var fileType = fileName[fileName.length - 1].toLowerCase();
                if (fileType == "jpg" || fileType == "jpeg" || fileType == "png") {
                    return Upload.upload({
                        url: '/api/users/picture/addImage',
                        data: { userId: $scope.currentUser._id },
                        file: $file,
                    }).then(function (response) {
                        var data = response.data;
                        $scope.currentUser.image = data.image;
                    }).catch(function () {
                        //console.log('error status: ' + status);
                    })
                } else {
                    toaster.pop('error', "Submission.", "Upload '.png', '.jpg' Types Only.");
                }
            }
        };

        function getMenuItems() {
            return Sidebar.menuItems();
        }
        if ($scope.currentUser.systemRole == "lmsAdmin") {
            var typedWord = "";
            $scope.advancedAdminOptions = false;
            $scope.allAdminOption = false;
            document.onkeypress = function (e) {
                // if(document.activeElement.id == 'BNLogo' && (!$scope.advancedAdminOptions || ! $scope.allAdminOption)){
                //     e = e || window.event;
                //     var charCode = (typeof e.which == "number") ? e.which : e.keyCode;
                //     if(charCode == 46){//press . to compare and clear the string
                //         if(typedWord == 'superadmin'){
                //             $rootScope.$apply(function(){
                //                 $scope.advancedAdminOptions = true;
                //             });
                //         }
                //         else if(typedWord == 'superhacker'){
                //             $rootScope.$apply(function(){
                //                 $scope.allAdminOption = true;
                //             });
                //         }
                //         typedWord = "";
                //     }
                //     else typedWord += String.fromCharCode(charCode);
                // } else typedWord = "";
            };
        }

        self.showOTPController = function ($scope, $http, $mdDialog) {
            var timer;
            $scope.getOtp = function () {
                $scope.enableButton = false;
                return $http.post('/api/users/getOtp').then(function (data) {
                    data = data.data;
                    $scope.otp = data.otp;
                    $scope.expires = new Date(data.otpExpires);
                    var expiresIn = $scope.expires.getTime() - new Date().getTime();
                    timer = setTimeout(function () { $scope.enableButton = true; clearTimeout(timer); }, expiresIn);
                    return;
                });
            };
            $scope.onClose = function () {
                $mdDialog.hide();
            }
            return $scope.getOtp();
        }

        $scope.showOTP = function () {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                escapeToClose: true,
                controller: self.showOTPController
            });
        }

        $scope.onLogoutClick = function () {
            return Auth.logout().then(function () {
                serviceManager.logout();
                $window.localStorage.clear()
                $timeout(function () {
                    $cacheFactory.get('$http').removeAll();
                }, 500);
                $state.go('login');
                return;
            })
                .catch(function (error) {
                    console.log(error);
                    return toaster.pop('error', 'Logout', 'Can\'t connect to server. Please try again in a moment.');
                })
        };
        $scope.changePassword = function () {
            $state.go('settings');
        }
        // function changeHandler(e) {
        //     getIPbasedModules();
        // }

        // $window.navigator.connection.onchange = changeHandler;


    });
