'use strict';

angular.module('bitnudgeApp').controller('allProspectFilterCtrl', function($scope, $mdDialog, CurrentCycle, p0, prospectService, $timeout, filters, isReferralsView, onApplyFilterCall, workflowColorCodes, workflowStagesCodes, UtilService, callback) {
    $scope.filterObj = filters;
    $scope.selected = {};
    $scope.applyNOT = $scope.filterObj.applyNOT;
    $scope.isReferralsView = isReferralsView;
    $scope.onApplyFilterCall = onApplyFilterCall;
    $scope.workflowColorCodes = workflowColorCodes;
    $scope.workflowStagesCodes = workflowStagesCodes;
    $scope.colorCode = $scope.filterObj.colorCode ? $scope.filterObj.colorCode.color : null
    $scope.stageCode = $scope.filterObj.stageCode ? $scope.filterObj.stageCode.name : null
    $scope.thirdListFilter = []
    $scope.filterObj.selectedCampaign = $scope.filterObj.selectedCampaign;

    var setTimeRange = function(monthType) {
        $scope.timeRange = UtilService.getTimeRange(monthType, 'prospect');
        if ($scope.filterObj.startDate) {
            $scope.selected.cycle = _.find($scope.timeRange, function(range) {
                return moment(range.startDate).valueOf() == moment($scope.filterObj.startDate).valueOf() &&
                    moment(range.endDate).valueOf() == moment($scope.filterObj.endDate).valueOf();
            });
            if (!$scope.selected.cycle) {
                $scope.selected.cycle = _.find($scope.timeRange, {
                    _id: 'custom'
                });
                $scope.selected.cycle.startDate = new Date($scope.filterObj.startDate);
                $scope.selected.cycle.endDate = new Date($scope.filterObj.endDate);
            }
        }
        if (!$scope.selected.cycle) {
            $scope.selected.cycle = $scope.timeRange[0];
        }
    };
    setTimeRange('regular');


    $scope.filterProspectsOnStatus = function(key) {
        if ($scope.filterObj.selectedStatuses && $scope.filterObj.selectedStatuses == key) {
            $scope.filterObj.selectedStatuses = "";
            $scope.selectedStatuses = null;
        } else {
            $scope.filterObj.selectedStatuses = key;
        }

        if ($scope.filterObj.selectedStatuses == 'new') {
            $scope.filterObj.prospectDateKey = 'created';
            $scope.selected.cycle = CurrentCycle.cycle;
            setDates($scope.selected.cycle);
        }
        $scope.filterObj.actNowFilterKey = null;
    };

    $scope.filterProspectsOnLevel = function(key) {
        if ($scope.filterObj.selectedLevels && $scope.filterObj.selectedLevels == key) {
            $scope.filterObj.selectedLevels = "";
            $scope.selectedLevels = null;
        } else {
            $scope.filterObj.selectedLevels = key;
        }
    };
    $scope.$watch('selected.cycle', function(newVal, oldVal) {
        if (newVal) {
            setDates(newVal);
        }
    });

    $scope.applyFilter = function() {
        $scope.filterObj.includeArchived = false;
        callback($scope.filterObj);
        $mdDialog.hide();
    }

    function setDates(cycle) {
        $scope.startDate = new Date(cycle.startDate);
        $scope.endDate = new Date(cycle.endDate);
        $scope.filterObj.startDate = $scope.startDate;
        $scope.filterObj.endDate = $scope.endDate;
    }
    $scope.removeFilter = function(filter) {
        $scope.filterObj.actNowFilterKey = null;
        switch (filter) {
            case 'prospectStatus':
                $scope.selectedStatuses = "";
                $scope.filterObj.selectedStatuses = "";
                break;
            case 'prospectDate':
                $scope.prospectDateFilterApplied = false;
                $scope.filterObj.prospectDateFilterApplied = false;
                $scope.filterObj.applyNOT = false;
                $scope.applyNOT = false;
                $scope.selected.cycle = $scope.timeRange[0];
                $timeout(function() {
                    $scope.startDate = null;
                    $scope.endDate = null;
                });

                break;
            case 'prospectLevel':
                $scope.selectedLevels = "";
                $scope.filterObj.selectedLevels = "";
                break;
            default:
                break;
        }
    };
    $scope.cycleChange = {
        cycle: function(cycle) {
            if ($scope.selected.cycle && cycle._id != $scope.selected.cycle._id) {
                $scope.selected.cycle = cycle;
            };
            $scope.filterObj.startDate = $scope.selected.cycle.startDate;
            $scope.filterObj.endDate = $scope.selected.cycle.endDate;
            $scope.filterObj.prospectDateFilterApplied = true;
        },
        startDate: function(startDate) {
            $scope.startDate = new Date(startDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.startDate = $scope.startDate;
            }
            $scope.filterObj.startDate = $scope.startDate;
        },
        endDate: function(endDate) {
            $scope.endDate = new Date(endDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.endDate = $scope.endDate;
            }
            $scope.filterObj.endDate = $scope.endDate;
        }
    };

    $scope.allFilters = [];
    prospectService.getFilters().then(function (response) {
        const filters = response.data && response.data;
        const filtersByKey = _.groupBy(filters, 'key');
        $scope.statusListFilter = filtersByKey['prospectStatus'];
        $scope.secondListFilter = filtersByKey['agentLevel'];
    }, function (err) {
        console.log(err)
    })

    $scope.selectedStatuses = $scope.filterObj.selectedStatuses;
    $scope.selectedLevels = $scope.filterObj.selectedLevels;
    $scope.prospectDateKey = $scope.filterObj.prospectDateFilterApplied;

    $scope.onClose = function() {
        $mdDialog.hide();
    };
});