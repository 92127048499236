const autoImportNgTemplateLoaderTemplate1 = require('../html/adminLogsModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/confirmWithCheckModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/adminLogsModal.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/dataDictionaryModal.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/adminFilters.html');

angular.module('bitnudgeApp')
    .controller('adminDashboardCtrl', function($scope, $rootScope, $http, moment, $mdDialog, DTOptionsBuilder, SocketService, toaster, Sidebar, UtilService, AdminDashboardService) {

        $scope.crons = [
            { name: 'KPI Cron', _id: 'kpi', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null },
            { name: 'KAI Cron', _id: 'kai', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null },
        ];
        if($rootScope.config.showBadges){
            $scope.crons.push({ name: 'Rolling Badges Cron', _id: 'rollingBadge', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null });
            $scope.crons.push({ name: 'Cyclic Badges Cron', _id: 'cyclicBadge', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null });
        }
        $scope.crons.push({ name: 'Daily Analytics Cron', _id: 'dailyAnalytics', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null })

        if($rootScope.config.dailyFeed){
            $scope.crons.push({ name: 'Daily Feed Cron', _id: 'dailyFeed', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null })
        }
        if ($rootScope.config.lms) {
            $scope.crons.unshift({ name: 'Pipeline Cron', _id: 'pipeline', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null });
        }
        if ($rootScope.config.campaigns) {
            $scope.crons.push({ name: 'Contests Cron', _id: 'campaignCron', statusText: 'Ready to start', disable: true, toRun: true, startedAt: null })
        }

        $rootScope.isCronRunning = true;
        $scope.DateObj = Date;
        $scope.loadingValidationLogs = false;

        $scope.pipeline = { runOnLastSync: false };
        $scope.getStatus = function(cron) {
            if (cron.statusText == 'Ready to start') {
                return 'blue-bg';
            } else if (cron.statusText == 'Running') {
                return 'swot-amber-bg';
            } else if (cron.statusText == 'Error') {
                return 'red-bg';
            } else if (cron.statusText == 'Stopped') {
                return 'swot-blue-bg';
            } else {
                return 'green-bg';
            }
        };

        let appUrl = Sidebar.getAppUrl();

        $scope.cronLogsFetched = false;
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };
        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        var trackDate;

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withPaginationType('simple')
            .withDisplayLength(4)
            .withOption('lengthChange', false)
            .withOption('paging', true)
            .withOption('searching', true)
            .withOption('ordering', false);

        $scope.fetchValidationErrors = function() {
            $scope.loadingValidationLogs = true;
            $http.post('/api/cron/validate',{collection:'users'}).then(function(result) {
                result = result.data;
                $scope.userValidationErrors = result;
                $scope.loadingValidationLogs = false;
            });
        }

        const PER_PAGE = 50;
        let PAGE_NUMBER = 0;
        $scope.adminActions = [];
        $scope.showLoad = false;

        let fetchLogs = function() {
            $scope.showLoad = false;
            $scope.logsLoading = true;
            const params = getQueryParams();
            const url =`api/adminActions/index`;
            AdminDashboardService.fetchAdminAction(url, params).then(function(result) {
                $scope.logsLoading = false;
                PAGE_NUMBER++;
                addLogs(result.data);
            });
        }

        let addLogs = function(result) {
            let logs = result.docs;
            $scope.showLoad = (result.total > result.offset + result.limit);
            $scope.page = {
                loaded:true,
                total: result.total,
                to: $scope.showLoad ? result.offset+result.limit : result.total,
            }

            logs = _.sortBy(logs, function(action) { var dt = new Date(action.timeStamp); return -dt; });
            logs.forEach(function(action) {
                if (!action.userId) {
                    action.userId = {
                        _id: null,
                        image: null,
                        name: action.userName
                    }
                }
                action.content = ' has ' + action.actionType + ' ';
                if (action.actionType === 'run the cron') action.content += ' of ' + action.dataType;
                else if (!action.file) action.content += 'form';

                action.content2 = "";
                if (!action.actionType === 'run the cron') action.content2 += ' on ' + action.dataType + ' page';
                action.content2 += action.cycleId ? ' for cycle ' + action.cycleId.name : '';
                action.content2 += action.date ? ' for date ' + moment(action.date).format('MM/DD/YYYY') : '';
                action.content2 += ' on ' + moment(action.timeStamp).format('MM/DD/YYYY , hh:mm:ss');
            });
            $scope.adminActions = $scope.adminActions.concat(logs);
        };

        $scope.loadMore = () => {
            fetchLogs();
        }

        $scope.saveFile = function(actionId, filename) {
            return UtilService.downloadFile(appUrl + '/api/adminActions/fileData', { actionId });
        };

        $scope.openLogsModal = function(index) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'adminLogsModalCtrl',
                size: 'md',
                resolve: {
                    logs: function() {
                        return $scope.adminActions[index].logs;
                    },
                    header: function() {
                        var adminAction = $scope.adminActions[index];
                        var log = adminAction.dataType + ' ' + (adminAction.actionType == 'run the cron' ? 'cron' : adminAction.actionType) + ' ';
                        if (adminAction.date)
                            log += '[ ' + moment(adminAction.date).format('MM/DD/YYYY') + ' ] ';
                        if (adminAction.cycleId)
                            log += '[ ' + adminAction.cycleId.name + ' ] ';
                        return log + '- by ' + adminAction.userName;
                    },
                    isCronCompleted: function() { return false }
                }
            });
        };

        $http.post('/api/lmsTrackers/index').then(function(crons) {
            crons = crons.data;
            $scope.lastSync = _.find(crons, { name: 'LAST_SYNC_DATE' });
            $scope.dataUploadDate = _.find(crons, { name: 'DATA_UPLOAD_DATE' });
            $scope.lastSync.lastUpdatedAt = new Date($scope.lastSync.lastUpdatedAt);
            $scope.lastSyncDate = new Date($scope.lastSync.lastUpdatedAt);
            $scope.dataUploadDate.lastUpdatedAt = new Date($scope.dataUploadDate.lastUpdatedAt);
            $scope.dataUploadLatestDate = new Date($scope.dataUploadDate.lastUpdatedAt);
            var cronLogs = _.filter(crons, function(cron) {
                if (cron.status == 'SUCCESS' && (cron.name == 'PIPELINE_CRON' || cron.name == 'ROLLING_BADGE_CRON' || cron.name == 'CYCLIC_BADGE_CRON' || cron.name == 'ANALYTICS_CRON' || cron.name == 'DAILY_FEED_CRON')) {
                    cron.lastUpdatedAt = new Date(cron.lastUpdatedAt);
                    return true;
                }
                return false;
            });
            cronLogs = _.sortBy(cronLogs, 'lastUpdatedAt');
            $scope.cronLogs = _.reverse(cronLogs);
            $scope.cronLogsFetched = true;
        });

        $scope.saveTrackDate = function($event, tracker) {
            if (!tracker.lastUpdatedAt) {
                toaster.pop('error', "Update", 'Date is empty');
                return;
            }
            trackDate = tracker;
            showDoubleConfirmModal($event, updateTrackerDates, 'Would you like to update ' + tracker.name + '?');
        };

        var showDoubleConfirmModal = function($event, onConfirm, subHeader) {
            $mdDialog.show({
                controller: 'confirmWithCheckModalCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                escapeToClose: true,
                resolve: {
                    onConfirm: function() {
                        return onConfirm;
                    },
                    subHeader: function() {
                        return subHeader;
                    }
                }
            });
        };

        $scope.showDoubleConfirm = function($event) {
            if (!$scope.pm2AppName) {
                toaster.pop('error', "Restart", 'Enter pm2 app name!');
                return;
            }
            showDoubleConfirmModal($event, restartCron, 'Would you like to restart CRON?');
        };

        var restartCron = function(status) {
            if (!status) {
                toaster.pop('error', "Update", 'Select checkbox and click yes to update!');
                return;
            }
            $http.post('api/lmsTrackers/resetCronStatus').then(function(result) {
                result = result.data;
                $http.post('/api/commandLine/execute', { appName: $scope.pm2AppName, option: 'restartApp' }).then(function(result) {}).catch(function(err) {
                    const message = err && err.message ? err.message : 'Error occured while Restart.'
                    toaster.pop('error', "Restart", message);
                });
            }).catch(function(err) {
                $scope.result = err;
            });
        };

        var updateTrackerDates = function(status) {
            if (!status) {
                toaster.pop('error', "Update", 'Select checkbox and click yes to update!');
                if (trackDate.name == 'LAST_SYNC_DATE') {
                    trackDate.lastUpdatedAt = $scope.lastSyncDate;
                } else {
                    trackDate.lastUpdatedAt = $scope.dataUploadLatestDate;
                }
                return;
            }
            $http.post('/api/lmsTrackers/update',{trackDateId:trackDate._id, trackDate}).then(function(response) {
                response = response.data;
                if (response.error) {
                    toaster.pop('error', "Update", response.error);
                } else {
                    if (trackDate.name == 'LAST_SYNC_DATE') {
                        $scope.lastSyncDate = trackDate.lastUpdatedAt;
                    } else {
                        $scope.dataUploadLatestDate = trackDate.lastUpdatedAt;
                    }
                    toaster.pop('success', "Update", trackDate.name);
                }
            }).catch(function(err) {
                const message = err && err.message ? err.message : 'Error occured while Update.'
                toaster.pop('error', "Update", message);
            });
        };

        $scope.showConfirm = function(ev) {
            var cronMessage = {
                pipeline: false,
                kai: _.find($scope.crons, { _id: 'kai' }).toRun,
                kpi: _.find($scope.crons, { _id: 'kpi' }).toRun,                
                dailyAnalytics: _.find($scope.crons, { _id: 'dailyAnalytics' }).toRun,
                isCampaign: false
            };
            if($rootScope.config.showBadges){
                cronMessage.rollingBadge = _.find($scope.crons, { _id: 'rollingBadge' }).toRun;
                cronMessage.cyclicBadge = _.find($scope.crons, { _id: 'cyclicBadge' }).toRun;
            }
            if($rootScope.config.dailyFeed){
                cronMessage.dailyFeed = _.find($scope.crons, { _id: 'dailyFeed' }).toRun
            }
           
            if ($rootScope.config.lms) {
                cronMessage.pipeline = _.find($scope.crons, { _id: 'pipeline' }).toRun;
                cronMessage.runPipeLineOnLastSync = $scope.pipeline.runOnLastSync;
            }
            if ($rootScope.config.campaigns) {
                cronMessage.isCampaign = _.find($scope.crons, { _id: 'campaignCron' }).toRun;
            }
            var cronNames = '';
            $scope.crons.forEach(function(cron) {
                if (cron.toRun) cronNames += cron.name + ', ';
            });
            if (!cronNames) {
                $mdDialog.show(
                    $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('Message')
                    .textContent('Please select at-least one cron.')
                    .ok('Close')
                    .targetEvent(ev)
                );
                return;
            }
            cronNames = cronNames.substring(0, cronNames.length - 2);
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.confirm()
                .title('CRON - Continue')
                .textContent('you are running ' + cronNames)
                .ariaLabel('CRON')
                .targetEvent(ev)
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(confirm).then(function() {
                cronAction(cronMessage);
                $mdDialog.hide();
            }, function() {
                $scope.status = 'You decided to keep your debt.';
                $mdDialog.hide();
            });
        };

        var cronAction = function(cronMessage) {
            $rootScope.isCronRunning = true;
            $scope.crons.forEach(function(cron) {
                cron.disable = true;
            });
            $http.post('/api/upload', { cronMessage: cronMessage, cronTag: 'DashboardCron', invocationMethod: "manual" }).then(function(response) {});
        };
        var mtdCrons = ['kpi', 'kai'];
        var Socket;
        var runningCron = null;

        function initSocket() {
            SocketService.getSocket().then(function(socket) {
                Socket = socket;
                $scope.cronLiveLogs = {};
                socket.removeAllListeners('cronMessage'); //Remove all listeners on the same before listen
                socket.on('cronMessage', function(data) {
                    if (data._id != 'cron') {
                        var cron = _.find($scope.crons, { _id: data._id });
                        if (cron) {
                            if (data.status == 'started') {
                                cron.statusText = 'Running';
                                cron.startedAt = data.startedAt;
                                cron.showAnimation = true;
                                openAlertBox(cron.name, 'Cron is in Progress!');
                                toaster.pop('info', cron.name, "Started Successfully!");
                            } else if (data.status == 'completed') {
                                cron.statusText = 'Completed';
                                toaster.pop('success', cron.name, "Completed Successfully!");
                                openAlertBox(cron.name, 'Cron is completed!');
                                cron.startedAt = null;
                            } else {
                                cron.statusText = 'Error';
                                cron.startedAt = null;
                                toaster.pop('error', cron.name, data.info);
                            }
                        }
                        if (data._id == "dbBackup" && data.status == "completed") {
                            toaster.pop('success', "DB Backup", "DB Backup completed Successfully!");
                            $scope.dbBackButtonText = 'Take DB Backup';
                            $rootScope.isCronRunning = false;
                            $scope.takingDBBackup = false;
                            var options = {
                                body: 'DB Backup completed Successfully!',
                                noscreen: true
                            };
                            var errorNotification = new Notification('Success', options);
                            errorNotification.close.bind(errorNotification);
                        }
                        if (data._id == 'TARGET_FILE_UPLOAD' || data._id == 'SCORECARD_FILE_UPLOAD') {
                            if (data.status == 'started') {
                                $rootScope.isCronRunning = true;
                                disableStart = true;
                                var id = status._id == 'TARGET_FILE_UPLOAD' ? 'Daily targets' : 'Daily scorecard metrics';
                                openAlertBox(id, 'Upload is in progress!');
                            } else if (data.status == 'completed') {
                                $rootScope.isCronRunning = false;
                                disableStart = false;
                                var id = data._id == 'TARGET_FILE_UPLOAD' ? 'Daily targets' : 'Daily scorecard metrics';
                                openAlertBox(id, 'Upload completed Successfully!');
                            }
                        }
                    } else {
                        if (data.status == 'completed') {
                            resetCrons(data.info);
                        } else {
                            resetCrons(data.info);
                        }
                    }
                });
                //browser notifications.
                socket.removeAllListeners('logNotif');
                socket.on('logNotif', function(dataObj) {
                    // openAlertBox(dataObj.type,dataObj.body);
                    var options = {
                        body: dataObj.body,
                        noscreen: true
                    };
                    var notification = new Notification(dataObj.type, options);
                    notification.close.bind(notification);
                });
                socket.removeAllListeners('cronLog');
                socket.on('cronLog', function(logData) {
                    if ((logData._id == runningCron || _.includes(mtdCrons, logData._id)) && logData.status == 'completed') {} else if (logData._id == 'dbBackup') {
                        // console.log(data);
                    } else if (logData._id && logData._id != 'cron' && logData.status == 'started') {
                        if (_.includes(mtdCrons, logData._id))
                            runningCron = 'analytics';
                        else runningCron = logData._id;
                        if (!$scope.cronLiveLogs[runningCron]) {
                            $scope.cronLiveLogs[runningCron] = [];
                        }
                    } else {
                        if (!runningCron) {
                            var rc = _.find($scope.crons, { statusText: 'Running' });
                            if (rc) {
                                if (_.includes(mtdCrons, rc._id))
                                    runningCron = 'analytics';
                                else runningCron = rc._id;
                            }
                        }
                        if (!$scope.cronLiveLogs[runningCron]) $scope.cronLiveLogs[runningCron] = [];
                        $scope.cronLiveLogs[runningCron].push(logData);
                    }
                });
            });
        }
        var nonActiveStatus = ['Ready to start', 'Error'];
        $scope.isActiveStatus = function(cronStatus) {
            if (_.includes(nonActiveStatus, cronStatus)) {
                return false;
            } else return true;
        };
        $scope.showActiveLogs = function(cronId) {
            if (_.includes(mtdCrons, cronId)) cronId = 'analytics';
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'adminLogsModalCtrl',
                resolve: {
                    logs: function() { return $scope.cronLiveLogs[cronId]; },
                    header: function() { return cronId + ' cron'; },
                    isCronCompleted: function() { return runningCron != cronId; }
                }
            });
        };

        $scope.showInfoModal = function() {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'dataDictionaryModalCtrl',
            });
        };

        var resetCrons = function(error) {
            $scope.crons.forEach(function(cron) {
                cron.toRun = false;
                cron.disable = false;
                cron.showAnimation = false;
            });
            $rootScope.isCronRunning = false;
            if (error) {
                toaster.pop('error', "Cron", error);
            } else {
                toaster.pop('success', "Cron", "Completed Successfully!");
            }
        };
        $http.post('/api/cron/status').then(function(response) {
            response = response.data;
            var disableStart = false;
            response.forEach(function(status) {
                var cron = _.find($scope.crons, { _id: status._id });
                if (cron) {
                    if (status.status == 'running') {
                        cron.statusText = 'Running';
                        cron.startedAt = new Date(status.since);
                        disableStart = true;
                        cron.showAnimation = true;
                        openAlertBox(cron.name, 'Cron is in Progress!');
                    } else if (status.status == 'error') {
                        cron.statusText = 'Error';
                        cron.startedAt = new Date(status.since);
                        cron.showAnimation = true;
                    } else if (status.status == 'stopped') {
                        cron.statusText = 'Stopped';
                        cron.startedAt = new Date(status.since);
                        cron.showAnimation = true;
                    }
                } else if (status._id == 'dbBackup' && status.status == 'running') {
                    $scope.dbBackButtonText = 'Taking DB Backup';
                    $rootScope.isCronRunning = true;
                    $scope.takingDBBackup = true;
                    disableStart = true;
                } else if (status._id == 'TARGET_FILE_UPLOAD' || status._id == 'SCORECARD_FILE_UPLOAD') {
                    if (status.status == 'running') {
                        $rootScope.isCronRunning = true;
                        disableStart = true;
                        var id = status._id == 'TARGET_FILE_UPLOAD' ? 'Daily targets' : 'Daily scorecard metrics';
                        openAlertBox(id, 'Upload is in progress!');
                    }
                }
            });
            $rootScope.isCronRunning = disableStart;
            $scope.crons.forEach(function(cron) {
                if ($rootScope.isCronRunning) {
                    cron.disable = true;
                    cron.toRun = true;
                } else {
                    cron.disable = false;
                    cron.toRun = false;
                }
            });
            initSocket();
        });
        var alert;

        function openAlertBox(id, data, progress) {
            $mdDialog.hide();
            alert = null;
            alert = $mdDialog.alert({
                title: id,
                textContent: data,
                multiple: false,
                ok: 'Close'
            });
            $mdDialog.show(alert).then(function() {});
        }
        $scope.dbBackButtonText = 'Take DB Backup';
        $scope.takingDBBackup = false;
        $scope.takeDbBackup = function() {
            $http.post('api/adminActions/action', {action:'dbbackup'}).then(function(result) {
                result = result.data;
                $scope.dbBackButtonText = 'Taking DB Backup';
                $rootScope.isCronRunning = true;
                $scope.takingDBBackup = true;
            });
        }
        $scope.fetchFilters = () => {
            AdminDashboardService.fetchFilters().then(result=>{
                const {data} = result;
                if(data.status){
                    $mdDialog.show({
                        templateUrl: autoImportNgTemplateLoaderTemplate5,
                        controller: 'adminFiltersController',
                        clickOutsideToClose: true,
                        fullscreen: false,
                        resolve: {
                            filters: () => {
                                return data.filters;
                            },
                            appliedFilters: () => {
                                return $scope.filters;
                            },
                            onApplyFilterCall: () => {
                                return onApplyFilterCall;
                            }
                        }
                    });
                }else{
                    toaster.pop('error', "Fetching filters", data.message);
                }
            },error=>{
                toaster.pop('error', 'Fetching Filters', 'Error in fetching filters');
            })
        }

        $scope.filters = {};
        const resetLogsDetails =  () =>{
            $scope.filters = {};
            PAGE_NUMBER = 0;
            $scope.adminActions = [];
        }

        $scope.clearFilters = () =>{
            resetLogsDetails();
            fetchLogs();
        }

        const onApplyFilterCall = (appliedFilters) => {
            resetLogsDetails();
            $scope.filters = appliedFilters;
            fetchLogs();
        }

        const getQueryParams = () => {
            const {dates, user, dataType, searchParam }  = $scope.filters;
            const query = {};
            if(searchParam){
                query.searchParam = searchParam;
            }
            if(dates && dates.start){
                query.startDate = new Date(new Date(dates.start).setHours(0,0,0,0)).getTime();
            }
            if(dates && dates.end){
                query.endDate = new Date(new Date(dates.end).setHours(23,59,59,999)).getTime();
            }
            if(user){
                query.userId = user._id;
            }
            if(dataType){
                query.dataType = dataType._id
            }
            query.skip = PAGE_NUMBER * PER_PAGE;
            query.limit = PER_PAGE;
            return query;
        }

        fetchLogs();
    });