const autoImportNgTemplateLoaderTemplate1 = require('../html/alerts/alertsFilter.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/alerts/alertAdvancedFilters.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('alertsCtrl', function($scope, $redirectRouter, $state, $mdDialog, $rootScope, Auth, toaster, p0, AlertsService) {
    	let filtersData = [];
    	let advancedFilters = [];
        let filtersCountData = [];
        $scope.showSmListOnActivity = $rootScope.config.showSmListOnActivity;
        $scope.prospectUser = 'prospectUser';
        $scope.hideAlertsStateSubTab = $rootScope.config.hideAlertsStateSubTab
        $scope.hideAlertsEscalationsSubTab = $rootScope.config.hideAlertsEscalationsSubTab
    	let level3Filters = [
			{_id:'all', name:'All'},
			{_id:'READ', name:'Read'},
			{_id:'UNREAD', name:'Unread'},
			//{_id: 'RESOLVED', name:'Resolved'}
        ];
        $scope.hidel3Filters = $rootScope.config.hidel3Filters

        //initial pagination data
        let paginationData = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: false,
            disablePrevious: false,
            page: 1,
            totalPages: 1,
            isPagination: true
        };
        $scope.me = Auth.getCurrentUser();
        $scope.isFrontline = $rootScope.isFrontline;
		$scope.filters = {
			//Tab id
			level1: null,
			//Type
			level2: null,
			//Read, unread
			level3: null,
			//productId
			level4: null,
			//date
			level5: null,
			searchParam: ""
		};

        $scope.showOtherTab = false;
		$scope.level3Filters = level3Filters;
		$scope.filters.level3 = level3Filters[0]._id;

        $scope.overallStats = [{
                name: 'Active SR',
                count: 6
            },
            {
                name: 'Blocker',
                count: 22,

            },
            {
                name: 'Critical',
                count: 4
            },
            {
                name: 'Major',
                count: 2,
            },
            {
                name: 'Open',
                count: 4,
            },
            {
                name: 'In Progress',
                count: 14
            },
            {
                name: 'Reopened',
                count: 25
            },
            {
                name: 'Others',
                count: 3
            }
        ];

        $scope.pagination = Object.assign({},paginationData);

        var messages = {
            read: "You have taken the action on this alert on "
        };

        $scope.sortingObj = {
            sortKey: 'scheduledAt',
            sortOrder: 'desc'
        };
        $scope.selectedTab = 0;
        $scope.isSearchResult = false;
        $scope.selected = { cycle: p0.currentMonthlyCycle };
        $scope.selected.user = $scope.me;
        $scope.selected.role = $scope.me.organizationalTag.jobRole;
        $scope.isAggregatedView = false;

        var setPaginationData = function(data) {

            if(data.docs){
                $scope.alertsLogs = data.docs
            } else {
                AlertsService.generateDescription(data.data);
                $scope.alertsLogs = data.data
            }
            $scope.pagination.total = data.count || data.total
            $scope.pagination.totalPages = $scope.pagination.total ? Math.abs(Math.ceil($scope.pagination.total/$scope.pagination.limit)):0 

            if (data.data && data.data.length == 0) {
                $scope.filteredCount = 0;
            } else {
                if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
                } else {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
                }
            }
            if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;
            }
            if ($scope.pagination.offset == 0) {
                $scope.pagination.disablePrevious = true;

            } else {
                $scope.pagination.disablePrevious = false;
            }
        }

        $scope.nextPage = function() {
            $scope.pagination.offset += $scope.pagination.limit;
            $scope.pagination.page += 1;
            if($scope.filters.level1 === $scope.prospectUser && $scope.filters.level2._id === $scope.activityIndex){
                $scope.fetchProspectUserActivities()
            }else{
                fetchData($scope.selected.user)
            }
        };

        $scope.previousPage = function() {
            $scope.pagination.offset -= $scope.pagination.limit;
            $scope.pagination.page -= 1;
            if($scope.filters.level1 === $scope.prospectUser && $scope.filters.level2._id === $scope.activityIndex){
                $scope.fetchProspectUserActivities()
            }else{
                fetchData($scope.selected.user)
            }
        };

        $scope.readAlert = function(alert) {
            if (alert.status === "UNREAD") {
                AlertsService.setAlertActionRead(alert).then(function(result) {
                    toaster.pop('success', "Alert.", "Alert set read");
                    fetchData();
                }, function(err) {
                    toaster.pop('error', "Alert.", "Alert unable to set read");
                })
            }
        };

        $scope.timelineAlert = function(alert) {

            var createDateString = new Date(alert.createdAt);
            createDateString = createDateString.toString().slice(4, 25)

            let timeline = ["Alert was created on " + createDateString]

            if (alert.read == true) {
                var updateDateString = new Date(alert.updatedAt);
                updateDateString = updateDateString.toString().slice(4, 25)
                timeline.push(messages.read + updateDateString)
            } else {
                var timeRemain = daysBetween(new Date(), new Date(alert.extraParams.dueDate));

                timeline.push(alert.content + ", You have " + timeRemain + " remain to take the action");
            }
            $scope.selectedTab = 1;
            $scope.timeline = timeline;
            console.log(timeline)
        };

        var daysBetween = function(date1, date2) {
            //Get 1 day in milliseconds
            var one_day = 1000 * 60 * 60 * 24;

            // Convert both dates to milliseconds
            var date1_ms = date1.getTime();
            var date2_ms = date2.getTime();

            // Calculate the difference in milliseconds
            var difference_ms = date2_ms - date1_ms;
            //take out milliseconds
            difference_ms = difference_ms / 1000;
            var seconds = Math.floor(difference_ms % 60);
            difference_ms = difference_ms / 60;
            var minutes = Math.floor(difference_ms % 60);
            difference_ms = difference_ms / 60;
            var hours = Math.floor(difference_ms % 24);
            var days = Math.floor(difference_ms / 24);
            var dateString = "";
            if (days) {
                dateString += days + ' days '
            }
            if (hours) {
                dateString += hours + ' hours '
            }
            if (minutes) {
                dateString += minutes + ' minutes'
            }
            return dateString;
        };

        $scope.fetchAlertsData = function() {
            $scope.pagination = Object.assign({},paginationData);
            getTabsCountsSummary($scope.selected.user);
            fetchData($scope.selected.user);
        };

        function fetchData(user) {
            let userId = null;
            if ($scope.isAggregatedView && user) {
                userId = user._id;
            }
            AlertsService.getUserAlerts($scope.filters, $scope.pagination, true, $scope.sortingObj, $scope.isAggregatedView, userId).then(function(result) {
                if(result.data.alerts){
                    setPaginationData(result.data.alerts);
                } else{
                    setPaginationData(result);
                }
                $scope.selectedTab = 0;
                let alertIds = []
                if(result.data.alerts){
                    result.data.alerts.forEach( alert => {
                        if(alert.status && (alert.status != 'READ' && alert.status != 'RESOLVED') && !alert.actionable){
                            alertIds.push({_id: alert._id})
                        }
                        if($scope.isAggregatedView){
                            //this is a temporary string manipulation for a particular view.
                            
                            if(alert.content && alert.content.startsWith("Your")){
                                alert.content = alert.content.replace('Your', `${alert.extraParams.handlerName}'s`);
                            } else if(alert.content && alert.content.startsWith("You")){
                                alert.content = alert.content.replace('You', alert.extraParams.handlerName);
                            }
                        }
                    })
                }            
                $scope.alertsApiLoaded = true;

            }, function(error) {
                console.log(error)
            })
        }

        $scope.clickAlert = function(alertObj) {
            AlertsService.setAlertActionRead(alertObj).then(function(response) {
                if (response) {
                    var extraParams = {
                        customerId: alertObj.customerCui,
                        name: alertObj.name
                    };
                    $redirectRouter.redirectTo(alertObj.linkTo, extraParams);
                }
            }, function(err) {
                console.log(err)
            });
        };

        $scope.getFilteredAlerts = function() {
            fetchData($scope.selected.user);
        };

        $scope.getSearchFilteredAlerts = function() {
            $scope.pagination = Object.assign({},paginationData);
            fetchData($scope.selected.user);
            $scope.isSearchResult = true;
        };

        $scope.resetSearch = function() {
            $scope.filters.searchParam = "";
			$scope.pagination = Object.assign({},paginationData);
            $scope.isSearchResult = false;
            fetchData($scope.selected.user);
        };


        // TriggerService.getAlarmLogs().then(function(results){
        //     console.log(results,'-----results----')
        // },function(error){
        //     console.log(error,'---error---')
        // })

        $scope.alertsApiLoaded = false;

		function setFiltersLevel4(){
			const level1 = filtersData.find(list => list._id === $scope.filters.level1);
			if($scope.filters.level2 && level1){
				$scope.level4Filters = $scope.filters.level2.products;
				if($scope.level4Filters && $scope.level4Filters.length){
					$scope.filters.level4 = $scope.level4Filters[0]._id;
                }
                if($scope.filters.level2){
                    advancedFilters = $scope.filters.level2.date;
                }
			}
		}

		function setReadUnreadCounts(){
			const filterCounts = filtersCountData.list.find(readUnreadCounts => {
				if($scope.filters.level2){
					return readUnreadCounts[0] && readUnreadCounts[0].tabId === $scope.filters.level2._id;
				}
				return false;
			});
			if(filterCounts){
				$scope.level3Filters.forEach((filter) => {
					const countObj = filterCounts.find(count => count.status === filter._id);
					if(countObj){
						filter.count = countObj.count;
					}
				});
			}
		}

		function getTabsCountsSummary(user){
            const tabId = $scope.filters.level1;
            let userId = null;
            if ($scope.isAggregatedView && user) {
                userId = user._id;
            }
			AlertsService.getTabsCountsSummary(tabId, $scope.isAggregatedView, userId).then(function(data){
				filtersCountData = data;
				data.list.forEach(tab => {
                    const filterMeta = $scope.level2Filters.find(filter => filter._id === tab[0].tabId);
                    if(!(tabId === $scope.prospectUser && filterMeta.tag === 'activity')){
                        filterMeta.count = tab[0].count;
                    }
				});
				setReadUnreadCounts();
			}).catch((err) => {
				console.error('Failed to get tabs count ', err);
			});
		}

        function setFiltersLevel2(){
            const level1 = filtersData.find(list => list._id === $scope.filters.level1);
			if(level1){
                $scope.level2Filters = level1.list;
                if($scope.level2Filters) {
                    if($scope.filters.level1 === "prospectUser"){
                        if($scope.hideAlertsStateSubTab){
                            $scope.level2Filters = $scope.level2Filters.filter((l1)=> l1.type !== "state")
                        }
                        if($scope.hideAlertsEscalationsSubTab){
                            $scope.level2Filters = $scope.level2Filters.filter((l1)=> l1.type !== "escalation")
                        }
                    }
                }
                $scope.filters.level2 = $scope.level2Filters[0];
				setFiltersLevel4();
			}
		}

        function init() {
			AlertsService.getFiltersList().then(function(data){
                filtersData = data;
				let firstFilters = _.cloneDeep(filtersData);
				let lastFilters = firstFilters.splice(-1, 1);
                // TODO: for sending correct tabs from backend only and do required filtering in backend only.
                
                if (!$rootScope.config.showAlertSRTab) {
                    _.remove(firstFilters, function(f) {
                        return f._id === 'ticket';
                    });
                }
                if (!$rootScope.config.showAlertAgentTab) {
                    _.remove(firstFilters, function(f) {
                        return f._id === 'prospectUser';
                    });
                }
                if (!$rootScope.config.showAlertCustomerTab) {
                    _.remove(firstFilters, function(f) {
                        return f._id === 'customer';
                    });
                }
                if ($rootScope.config.showAlertOtherTab) {
                    $scope.showOtherTab = true;
                }

                const prospectUserMenu = filtersData.find(f => f._id === 'prospectUser')
                if(prospectUserMenu){
                    const activityMenu = prospectUserMenu.list.find(f => f.tag === 'activity')
                    const otherUserInfoMenu = prospectUserMenu.list.find(f => f.type === 'otherInfo' && f.tag === 'prospectUser')
                    if(activityMenu){
                        $scope.activityIndex = activityMenu._id;
                    }
                    if(otherUserInfoMenu){
                        $scope.otherInfoIndex = otherUserInfoMenu._id
                    }
                }
				$scope.primaryTabs =  firstFilters;
				$scope.lastTabs =  lastFilters;
				$scope.filters.level1 = filtersData[0]._id;
				setFiltersLevel2();
				getTabsCountsSummary();
				fetchData();
			}).catch( (err) => {
				toaster.pop('error', 'Alert', 'Failed to open alerts page. Please try again later.');
				console.error(err)
			});

        }
        init();

        $scope.showMoreFilters = function(filters, callback) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'alertsFilterController',
                clickOutsideToClose: true,
                fullscreen: false,
                resolve: {
                    filters: function() {
                        return filters;
                    },
                    callback: function() {
                        return callback;
                    }
                }
            });
        };

        $scope.redirectAlert = function(alert) {
            $scope.readAlert(alert);
            if (alert.entityType === 'lead' && alert.entityId && $scope.isFrontline) {
                $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1' : 'userFlow.editLead_v2', {
                    leadId: alert.entityId
                });
            } else if (alert.entityType === 'ticket' && alert.entityId && $scope.isFrontline) {
                $state.go('userFlow.editTicket', {
                    ticketId: alert.entityId,
                    statusUpdate: alert.extraParams.internalTab === 'updateStatus' ? true : false
                })
            } else if (alert.entityType === 'customer' && alert.entityId) {
                $state.go('userFlow.customer', {
                    customerId: alert.entityId,
                    activeTab: alert.extraParams ? alert.extraParams.internalTab : 'documents'
                })
            }
            if(alert.extraParams.isRedirectable){
                // alert('called');
                if(alert.tag === 'lead'){
                    const {startDate, endDate, productId} = alert.extraParams
                    let params = {
                        productId,
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endDate).getTime(),
                        dateField: 'followUpIn'
                    }
                    $state.go('lms.listLead', params)
                }else if(alert.tag==='ticket'){
                    const {startDate, endDate, productId} = alert.extraParams
                    let params = {
                        tab: productId,
                        state: 'followUpIn',
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endDate).getTime(),
                    }
                    $state.go('lms.tickets', params)
                }

                if((alert.extraParams.workflowId ||alert.extraParams.workflowIds) && alert.extraParams.productId){
                    if(alert.tag === 'lead'){
                        let params = {
                            productId:alert.extraParams.productId,
                            workflowIds: alert.extraParams.workflowIds && alert.extraParams.workflowIds.length ? alert.extraParams.workflowIds : [alert.extraParams.workflowId]
                        }
                        if(alert.alertCondition && ["workflowReassignFromViaAlerts","workflowReassignFrom"].includes(alert.alertCondition.options.name)){
                            params.tabName="lostLeads"
                        }
                        $state.go('lms.listLead', params)
                    } else if(alert.tag === 'ticket'){
                        let params = {
                            tab: alert.extraParams.productId,
                            workflowIds: alert.extraParams.workflowIds && alert.extraParams.workflowIds.length ? alert.extraParams.workflowIds : [alert.extraParams.workflowId]
                        }
                        $state.go('lms.tickets', params)
                    }
                }
            }
        };

        function setNonActionableAlertsReadFunc(ids) {
            AlertsService.setNonActionableAlertsRead(ids).then(function(result) {
                console.log('Alerts read status for non actionable alerts')
            }, function(err) {
                toaster.pop('error', "Alert.", "Alert unable to set read");
            })
        }

        $scope.fetchProspectUserActivities = function(onlyCounts){

            const cycle = $scope.selected.cycle;
            if(!cycle){
                return;
            }

            let selectedIds;
            if($scope.showSmListOnActivity){
                selectedIds = ($scope.selected.smUsers || []).map(u => u._id);
                const { level } = $scope.selected.role;
                if(level > 3 && !selectedIds.length){
                return;
            }
            }else {
                selectedIds = [$scope.selected.user._id];
            }
            const pagination = angular.copy($scope.pagination);
            if(onlyCounts){
                pagination.limit = 1;
            }
            AlertsService.fetchProspectUserActivities(cycle, selectedIds, pagination, $scope.sortingObj).then(function(result) {
                const { total, data } = result;
                $scope.selected.activities = data;
                if(!onlyCounts){
                    setPaginationData({ total })
                }
                const filterMeta = $scope.level2Filters.find(filter => filter.tag === 'activity');
                if(filterMeta){
                    filterMeta.count = total;
                }
                const storedMainFilter = filtersData.find(f => f._id === $scope.prospectUser)
                if(storedMainFilter){
                    const activityFilter = storedMainFilter.list.find(f => f.tag === 'activity')
                    if(activityFilter){
                        activityFilter.count = total;
                    }
                }
            }, function(err) {
                console.error(err)
                toaster.pop('error', "Activities.", err.message);
            })
        }

        $scope.onSmSelect = function(data){
            $scope.selected.smUsers = data;
            if(!data.length){
                return toaster.pop('error','Activities', "Please select at least 1 user")
            }
            $scope.fetchProspectUserActivities()
        }

        $scope.filterAlerts = function(tag, level){

			switch (level) {
				case 1:
					$scope.filters.level1 = tag;
					$scope.filters.level2 = null;
					$scope.level3Filters = level3Filters;
					$scope.filters.level3 = $scope.level3Filters[0]._id;
					$scope.filters.level4 = null;
					$scope.filters.level5 = null;
					$scope.filters.searchParam = null;
                    setFiltersLevel2(tag);
                    if(tag === $scope.prospectUser){
                        $scope.fetchProspectUserActivities(true)
                        getTabsCountsSummary();
                    }else{
                        getTabsCountsSummary();
                    }
					break;
				case 2:
					const level1 = filtersData.find(list => list._id === $scope.filters.level1);
					if(level1){
						$scope.filters.level2 = level1.list.find(filter => filter._id === tag);
						$scope.level3Filters = level3Filters;
						$scope.filters.level3 = $scope.level3Filters[0]._id;
						$scope.filters.level4 = null;
						$scope.filters.level5 = null;
						// setReadUnreadCounts();
						setFiltersLevel4();
                    }
                    if($scope.filters.level1 === $scope.prospectUser && $scope.filters.level2._id === $scope.activityIndex){
                        $scope.fetchProspectUserActivities()
                    }
					break;
				case 3:
					$scope.filters.level3 = tag;
					break;
				case 4:
					$scope.filters.level4 = tag;
					break;
				default:
					break;
            }
            // if(tag !== $scope.prospectUser){
            //     getTabsCountsSummary($scope.selected.user);
            // }
            $scope.pagination.offset = 0;
            $scope.pagination.page = 1;
			fetchData($scope.selected.user);
		};

        const applyAdvancedFilters = function(filter){
        	let newValue;
			if(filter && filter._id === 'all'){
				newValue = null;
			}else{
				newValue = filter;
			}
			//No change in the filter value
			if(newValue && $scope.filters.level5 && newValue._id !== 'custom' && newValue._id === $scope.filters.level5._id){
				return;
			}
            $scope.filters.level5 = newValue;
            getTabsCountsSummary($scope.selected.user);
			fetchData($scope.selected.user);
		};

		$scope.showAdvancedFilters = function(){
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate2,
				controller: 'alertAdvancedFiltersController',
				clickOutsideToClose: true,
				fullscreen: false,
				resolve: {
					advancedFilters: function(){
						return advancedFilters;
					},
					selectedFilter: function(){
						return $scope.filters.level5 ? $scope.filters.level5 : null;
					},
					callback: function(){
						return applyAdvancedFilters;
					}
				}
			});
        }
        
        $scope.onReporteeChange = function (reportee) {
            //if the same reportee is selected then do nothing
            if ($scope.selected.user && reportee._id == $scope.selected.user._id) {
                return;
            }
            $scope.pagination = Object.assign({},paginationData);
            $scope.selected.user = reportee;
            $scope.selected.role = reportee.organizationalTag.jobRole;
            getTabsCountsSummary(reportee);
            if($scope.filters.level2 && $scope.filters.level2.tag !== 'activity'){
                fetchData(reportee);
            } else {
                $scope.fetchProspectUserActivities();
            }
        };

        $scope.viewToggleChanged = function(val) {
            $scope.isAggregatedView = val;
            $scope.pagination = Object.assign({},paginationData);
            getTabsCountsSummary();
            fetchData();
        }

        $scope.search_enter = function(keyEvent){
            if (keyEvent.which === 13) {
                $scope.pagination = Object.assign({},paginationData);
                fetchData($scope.selected.user);
                $scope.isSearchResult = true;
            }
        }

        $scope.onCycleChange = function(cycle) {
            $scope.selected.cycle = cycle;
            if($scope.filters.level1 === $scope.prospectUser){
                $scope.fetchProspectUserActivities()
            }
		};
        fetchSeniorManagersList = function(){
            AlertsService.fetchSeniorManagersList().then(function(result) {
                $scope.selected.smList = result;
                $scope.selected.showL3smList = true;
                $scope.selected.smUsers = [$scope.selected.smList[0]]
                $scope.fetchProspectUserActivities();
            }, function(err) {
                console.error(err)
                toaster.pop('error', "Alert.", "Error in fetching the senior manager's list");
            })
        }

        const { level } = $scope.selected.role;
        if(level > 3){
            fetchSeniorManagersList()
        }
    });
