angular.module('bitnudgeApp')
    .controller('addBulkDivisionsCtrl', function ($scope,Auth, Upload, toaster) {
        $scope.fileType = "divisions";
        $scope.onFileSelectDivision = function () {
            if ($scope.bulkDivision == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.bulkDivision[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                    Upload.upload({
                        url: '/api/divisions/import',
                        data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadDivisions',invocationMethod:"manual"},
                        file: $scope.bulkDivision[0],
                        progress: function (e) {
                        }
                    }).then(function (data, status, headers, config) {
                        toaster.pop('success', "Excel uploaded", "Division creation successfully finished.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    }); 