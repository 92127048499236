'use strict';
angular.module('bitnudgeApp')
    .controller('nudgeWallCtrl', function($scope,  $http,Auth, $rootScope){
        $scope.cloned = $rootScope.cloned;
        $scope.userTagIds=[];
        $scope.currentUser = _.cloneDeep(Auth.getCurrentUser());
        $scope.replyOpenArrObj = {};
        if($scope.currentUser.systemRole == 'frontline' || $scope.currentUser.systemRole == 'manager'){
            $scope.currentUser.systemRole = 'player';
        }
        $scope.nudges = [];
        $scope.replyOnMessage= function (statusMessage) {
            if($scope.replyOpenArrObj.hasOwnProperty(statusMessage._id))
                delete $scope.replyOpenArrObj[statusMessage._id];
            else
                $scope.replyOpenArrObj[statusMessage._id] = 1;
        };
        $scope.isNudgeOpenForReply = function(statusMessage) {
            if($scope.replyOpenArrObj.hasOwnProperty(statusMessage._id))
                return true;
            else
                return false;
        };
        // commenting this for performance issues, and currently this feature is not used
        // $http.post('/api/statusMessages').then(function(nudges){
        //     nudges=nudges.data;
        //     $scope.nudges = _.sortBy(nudges, 'createdAt').reverse();
        //     $scope.nudges = _.filter($scope.nudges,function(nudge){
        //         if(!nudge.userId){
        //             return false;
        //         }
        //        if($scope.playerRole == 'player'){
        //            return nudge.userId.systemRole == 'frontline' || nudge.userId.systemRole == 'manager';
        //        }else{
        //            return nudge.userId.systemRole == $scope.playerRole;
        //        }
        //     });
        // });
        $scope.deleteNudge= function (nudge) {
            $http.post('/api/statusMessages/archive',{id:nudge._id}).then(function () {
                _.remove($scope.nudges, function (currentNudge) {
                    return currentNudge._id == nudge._id;
                });
            });
        };
        $scope.addNudge = function(inputText, userTags,kpiTags){
            //var messageText = $('.activeTaggingInput').html();
            var message = {content:inputText, userId: $scope.currentUser._id, createdAt: new Date(), userTags: userTags,kpiTags:kpiTags};
            message.postToProfile = true;
            $http.post('/api/statusMessages/postToProfile', message).then(function(response) {
                response=response.data;
                message.userId = Auth.getCurrentUser();
                message._id = response._id;
                $scope.nudges.unshift(message);
            }).catch(function() {});
            $('.activeTaggingInput').html('');
            $scope.userTagIds = [];
        };
        $scope.showPlayersForMessage= false;
        $scope.setShowPlayersForMessage = function(val){
            $scope.showPlayersForMessage = val;
        };

        $scope.addReply = function(replyText, message){
            if($scope.message === '') {
                return;
            }
            var replyPayloadForBE ={content: replyText, createdAt: new Date(), userId: $scope.currentUser._id };
            var replyObj = Object.assign({}, replyPayloadForBE);
            replyPayloadForBE.id = id;
            $http.post('/api/statusMessages/reply', replyPayloadForBE).then(function() {
            });
            replyObj.userId =$scope.currentUser;
            message.replies?message.replies.push(replyObj):message.replies = [replyObj];
            $scope.myreply='';
            delete $scope.replyOpenArrObj[message._id];
        }
    });
