angular.module('bitnudgeApp')
    .controller('mgrDashboardController', function ($scope, $mdDialog, Auth, p0, mgrDashboardService) {
        console.log('This is mgr controller');
        $scope.currentTpl7 = 'leadTemp';
    //////******************* */
    // $scope.roleTypeNames = {
    //     'seniorManager': { name: 'Senior Manager', _id: 'seniorManager', level: 2 },
    //     'manager': { name: $rootScope.strings.MANAGER.value, _id: 'manager', level: 1 },
    //     'frontline': { name: $rootScope.strings.FRONTLINE.value, _id: 'frontline', level: 0 }
    // };
    $scope.selected = {};
    $scope.me = Auth.getCurrentUser();
    $scope.selectedReportee = $scope.me;
    var myLeadKpis = [];
    var allProducts = [];
    $scope.selectedCycle=p0.currentMonthlyCycle;
    $scope.allCycles=[p0.currentMonthlyCycle];
    $scope.cLeadName = 'Customer Lead'
    $scope.aLeadName = 'Agent Lead'
    console.log('$scope.selectedCycle: ', $scope.selectedCycle);
    console.log('$scope.allCycles: ', $scope.allCycles);
    // p0.myPlayTypes.forEach(function(playType){
    //     myLeadKpis = myLeadKpis.concat(playType.leadKpis);
    // });
    // myLeadKpis = _.uniqBy(myLeadKpis, function(object){
    //     return object._id.toString();
    // });
    // myLeadKpis.forEach(function(kpi){
    //     allProducts.push({
    //         _id: kpi._id,
    //         name: kpi.name
    //     });
    // });
    var firstProduct = 'RBL';
    $scope.dynamicParams = {'userEmail': `${$scope.me.email}(team(self+reportees))`, 'product': firstProduct, 'customerProduct': firstProduct, 'rankType': 'assets', 'dataType': 'Count'};
    $scope.divisions = [];
    $scope.divisions = _.cloneDeep(p0.myDivisions);
    if ($scope.divisions.length > 1) {
        $scope.divisions.unshift({ _id: 'all', name: 'All ' + $rootScope.strings.DIVISION.value + 's' });
    }
    var loggedInUserRoleType = $scope.me.systemRole;
    $scope.selectedDivision = $scope.divisions[0];
    $scope.dynamicParams['divisionId'] = $scope.selectedDivision.id;

    // var setCycleDropdown = function() {
    //     var jobRoleId = $scope.selectedReportee.organizationalTag && $scope.selectedReportee.organizationalTag.jobRole &&
    //         $scope.selectedReportee.organizationalTag.jobRole._id;
    //     if (jobRoleId) {
    //         var jobDetails = JobRole.getDetails(jobRoleId, null);
    //         var allMonthlyCycles = _.filter(p0.allCycles, function(cycle){
    //             return jobDetails.monthlyCycleType == cycle.subType && cycle.cycleType == 'monthly';
    //         });
    //         var currentActiveCycle = _.find(allMonthlyCycles,{subType:jobDetails.monthlyCycleType, active:true})
    //         $scope.allCycles = allMonthlyCycles;
    //         $scope.selectedCycle = currentActiveCycle || allMonthlyCycles[0];
    //     } else {
    //         $scope.allCycles = p0.allMonthlyCycles;
    //         $scope.selectedCycle = p0.currentMonthlyCycle;
    //     }
    //     $scope.dynamicParams['cycleId'] = $scope.selectedCycle._id;

    // }
    // setCycleDropdown();
    // var getSelectedJobRoleIdBasedOnPlayType = function () {
    //     if ($scope.selectedPlayType._id == 'overall' || $scope.selectedPlayType._id == 'all') {
    //         return $scope.selectedRoleType._id;
    //     } else {
    //         let attributeName = ($scope.selectedRoleType._id == 'frontline') ? "frontline" : "managers";
    //         let allJobRoles = p0.allJobRoles;
    //         let _id = $scope.selectedPlayType[attributeName][0].toString();
    //         let role = _.find(allJobRoles, { _id });
    //         return role._id;
    //     }
    // }

    const initWidgets = function() {
        var userId = null;
        if ($scope.selectedReportee._id !== 'me' && $scope.selectedReportee._id !== 'me(own)') {
            userId = $scope.selectedReportee._id
        }
        console.log('userId here: ', userId);
        mgrDashboardService.init({
            cycleId: $scope.selectedCycle._id,
            roleType: loggedInUserRoleType,
            userId: userId
        }).then(function(){
            console.log('here init done');
            mgrDashboardService.register({
                name: 'W_LEAD_PARAMS',
                metrics: ['W_LEAD_PARAMS'],
                dynamicParams: $scope.dynamicParams,
                render: function (config, data) {
                    $scope.leadOverviewData = data;
                    $scope.leadOverviewConfig = {
                        mgrConfig: config,
                        currentUser: $scope.selectedReportee,
                        roleType: $scope.selectedReportee.systemRole,
                        currentCycle: $scope.selectedCycle
                    }
                    $scope.currentTpl7 = 'leadTemp';
                }
            });
            mgrDashboardService.register({
                name: 'W_LEAD_PARAMS_1',
                metrics: ['W_LEAD_PARAMS_1'],
                dynamicParams: $scope.dynamicParams,
                render: function (config, data) {
                    $scope.leadOverviewData2 = data;
                    $scope.leadOverviewConfig2 = {
                        mgrConfig: config,
                        currentUser: $scope.selectedReportee,
                        roleType: $scope.selectedReportee.systemRole,
                        currentCycle: $scope.selectedCycle
                    }
                    $scope.currentTpl7 = 'leadTemp';
                }
            });
            mgrDashboardService.register({
                name: 'W_CUSTOMER_PARAMS',
                metrics: ['W_CUSTOMER_PARAMS'],
                dynamicParams: $scope.dynamicParams,
                render: function (config, data) {
                    $scope.customerOverviewData = data;
                    $scope.customerOverviewConfig = {
                        mgrConfig: config,
                        currentUser: $scope.selectedReportee,
                        roleType: $scope.selectedReportee.systemRole,
                        currentCycle: $scope.selectedCycle
                    }
                    $scope.currentTpl5 = 'customerTemp';
                }
            });
            // mgrDashboardService.register({
            //     name: 'W_TAT_BREACH_PARAMS',
            //     metrics: ['W_TAT_BREACH_PARAMS'],
            //     dynamicParams: $scope.dynamicParams,
            //     render: function (config, data) {
            //         $scope.tatOverviewData = data;
            //         $scope.tatOverviewConfig = {
            //             mgrConfig: config,
            //             currentUser: $scope.selectedReportee,
            //             roleType: $scope.selectedReportee.systemRole,
            //             currentCycle: $scope.selectedCycle
            //         }
            //         $scope.currentTpl8 = 'tatBreachTemp';
            //     }
            // });
            mgrDashboardService.renderWidgets(['W_LEAD_PARAMS', 'W_LEAD_PARAMS_1', 'W_CUSTOMER_PARAMS'],$scope.dynamicParams);
        });
    }

    var setDefaultReportee = function () {
        if (_.find($scope.allReportees, { _id: 'all' })) {
            var all = _.remove($scope.allReportees, { _id: 'all' });
            $scope.allReportees.unshift(all[0]);
        } else if (_.find($scope.allReportees, { _id: $scope.me._id })) {
            var me = _.remove($scope.allReportees, { _id: $scope.me._id });
            $scope.allReportees.unshift(me[0]);
        }
        $scope.selectedReportee = $scope.allReportees[0];
    };

    $scope.changeDivision = function (division) {
        $scope.selectedDivision = division;
        $scope.division = division;
        if ($scope.division.id != 'all') {
            $scope.allPlayTypes = _.filter(p0.myPlayTypes, function (playType) {
                return _.includes(division.playTypes, playType._id);
            });
        } else {
            $scope.allPlayTypes = _.cloneDeep(p0.myPlayTypes);
        }
        if ($scope.allPlayTypes.length > 1) {
            $scope.allPlayTypes.unshift({ _id: 'all', name: 'All ' + $rootScope.strings.PLAYTYPE.value + 's' });
        }
        $scope.selectedPlayType = $scope.allPlayTypes[0];
        $scope.dynamicParams['divisionId'] = $scope.selectedDivision.id;
        $scope.changePlayType($scope.selectedPlayType);
    };

    $scope.changePlayType = function (playType) {
        $scope.selectedPlayType = playType;
        $scope.playType = playType;
        $scope.allRoleTypes = [];
        $scope.allReportees = [];
        var allJobRoles = [];
        if ($scope.playType._id != 'all') {
            var jobRoles = $scope.playType.frontline;
            jobRoles = jobRoles.concat($scope.playType.managers);
            allJobRoles = jobRoles.concat($scope.playType.seniorManagers);
        } else {
            if ($scope.selectedDivision.id == 'all') {
                _.each(p0.myPlayTypes, function (playType) {
                    allJobRoles = allJobRoles.concat(playType.frontline);
                    allJobRoles = allJobRoles.concat(playType.managers);
                    allJobRoles = allJobRoles.concat(playType.seniorManagers);
                });
            } else {
                var div = $scope.selectedDivision;
                allJobRoles = allJobRoles.concat(div.frontline);
                allJobRoles = allJobRoles.concat(div.managers);
                allJobRoles = allJobRoles.concat(div.seniorManagers);
            }
        }
        fetchReporteesHelper(allJobRoles);
    };

    $scope.changeRoleType = function (roleType) {
        $scope.selectedRoleType = roleType;
        $scope.dynamicParams['playerType'] = getSelectedJobRoleIdBasedOnPlayType();
        $scope.allReportees = [];
        var allReportees = _.cloneDeep($scope.reporteesGroupBy[$scope.selectedRoleType._id]);
        $scope.allReportees = _.sortBy(allReportees, 'name');
        setDefaultReportee();
        $scope.changeReportees($scope.selectedReportee);
    };

    $scope.onReporteeChange = function (reportee) {
        console.log('selected.user 123: ', $scope.selected.user)
        if (!reportee) return;
        $scope.selectedReportee = reportee;
        console.log('$scope.selectedReportee: ', $scope.selectedReportee);
        if ($scope.selectedReportee._id === 'me(own)') {
            $scope.dynamicParams['userEmail'] = `${$scope.me.email}(self)`;
        } else {
            $scope.dynamicParams['userEmail'] =`${$scope.selectedReportee.email}(team(self+reportees))`;
        }
        //setCycleDropdown();
        initWidgets();
    }

    $scope.changeCycle = function(selectedCycle) {
        $scope.selectedCycle = selectedCycle;
        $scope.dynamicParams['cycleId'] = $scope.selectedCycle._id;
        initWidgets();
    }

    $scope.leadChangeProduct = function(product) {
        if (product !== 'All') {
            $scope.dynamicParams['product'] = product;
        } else {
            delete $scope.dynamicParams.product
        }
        mgrDashboardService.renderWidgets(['W_LEAD_PARAMS'], $scope.dynamicParams);
    }

    $scope.ticketChangeProduct = function(product) {
        if (product !== 'All') {
            $scope.dynamicParams['product'] = product;
        } else {
            delete $scope.dynamicParams.product
        }
        mgrDashboardService.renderWidgets(['W_TICKET_PARAMS'], $scope.dynamicParams);
    }

    $scope.customerChangeProduct = function(product) {
        if (product !== 'All') {
            $scope.dynamicParams['customerProduct'] = product;
        } else {
            delete $scope.dynamicParams.customerProduct
        }
        mgrDashboardService.renderWidgets(['W_CUSTOMER_PARAMS'], $scope.dynamicParams);
    }

    $scope.customerChangeDataType = function(dataType) {
        $scope.dynamicParams['dataType'] = dataType;
        mgrDashboardService.renderWidgets(['W_CUSTOMER_PARAMS'], $scope.dynamicParams);
    }

    $scope.changePlayerType = function(playerType) {
        $scope.dynamicParams['playerType'] = playerType;
        mgrDashboardService.renderWidgets(['W_GC_PARAMS'], $scope.dynamicParams);
    }


    $scope.customerChangeType = function(customerType) {
        if (customerType !== 'All') {
            $scope.dynamicParams['active'] = customerType;
            delete $scope.dynamicParams.customerSource;
        } else {
            delete $scope.dynamicParams.active;
            delete $scope.dynamicParams.customerSource;
        }
        mgrDashboardService.renderWidgets(['W_CUSTOMER_PARAMS'], $scope.dynamicParams);
    }

    $scope.customerChangeSource = function(customerSource) {
        if (customerSource !== 'All') {
            $scope.dynamicParams['customerSource'] = customerSource;
            delete $scope.dynamicParams.active;
        } else {
            delete $scope.dynamicParams.customerSource;
            delete $scope.dynamicParams.active;
        }
        mgrDashboardService.renderWidgets(['W_CUSTOMER_PARAMS'], $scope.dynamicParams);
    }

    $scope.leadChangeType = function(leadType) {
        if (leadType !== 'All') {
            $scope.dynamicParams['leadType'] = leadType;
        } else {
            delete $scope.dynamicParams.leadType
        }
        mgrDashboardService.renderWidgets(['W_LEAD_PARAMS'], $scope.dynamicParams);
    }

    $scope.tatChangeProduct = function(product) {
        if (product !== 'All') {
            $scope.dynamicParams['product'] = product;
        } else {
            delete $scope.dynamicParams.product
        }
        mgrDashboardService.renderWidgets(['W_TAT_BREACH_PARAMS'], $scope.dynamicParams);
    }

    // var fetchReporteesHelper = function (jobRoleIds) {
    //     jobRoleIds = _.uniqBy(jobRoleIds, function (id) { return id });
    //     var url = '/api/users/playersOfJobRoles/' + $scope.me._id + '?jobRoles=' + JSON.stringify(jobRoleIds);

    //     $http.get(url).success(function (reportees) {
    //         if (reportees.length == 0) {
    //             $scope.allReportees = [$scope.me];
    //             setDefaultReportee();
    //             $scope.changeReportees($scope.selectedReportee);
    //             return;
    //         }
    //         $scope.allRoleTypes = [];
    //         $scope.reporteesGroupBy = _.groupBy(reportees, 'systemRole');
    //         _.each($scope.reporteesGroupBy, function (reportees, key) {
    //             $scope.allRoleTypes.push({ _id: key, name: $scope.roleTypeNames[key].name, level: $scope.roleTypeNames[key].level });
    //         });
    //         $scope.allRoleTypes = _.sortBy($scope.allRoleTypes, 'level');
    //         if ($scope.user) {
    //             $scope.selectedRoleType = _.find($scope.allRoleTypes, { _id: $scope.user.systemRole });
    //         } else {
    //             $scope.selectedRoleType = $scope.allRoleTypes[$scope.allRoleTypes.length - 1];
    //         }
    //         $scope.changeRoleType($scope.selectedRoleType);
    //         if ($scope.me.systemRole == 'seniorManager') {
    //             var allReportees = _.cloneDeep($scope.reporteesGroupBy[$scope.selectedRoleType._id]);
    //             $scope.allReportees = _.sortBy(allReportees, 'name');
    //             if ($scope.reporteeList) {
    //                 $scope.reporteeList = $scope.allReportees;
    //             }
    //         } else {
    //             $scope.allReportees = _.sortBy(reportees, 'name');
    //             if ($scope.reporteeList) {
    //                 $scope.reporteeList = $scope.allReportees;
    //             }
    //             _.find($scope.allReportees, { _id: $scope.me._id }).name = 'Me';
    //         }
    //         if ($scope.me.systemRole != 'frontline') {
    //             $scope.allReportees.unshift({_id: 'me(own)', name: 'Me (Own)'});
    //         }
    //         setDefaultReportee();
    //         $scope.changeReportees($scope.selectedReportee);
    //     });
    // };

    function init() {
        //$scope.changeDivision($scope.selectedDivision);
        initWidgets();
    }
    init();


    //***************************** */
    });