"use strict";

angular.module("bitnudgeApp").controller("attachmentComponentCtrl", function($scope, Auth, $rootScope) {
    $scope.getCurrentUser = Auth.getCurrentUser;

    this.$onChanges = changes => {
        if(changes.displayMode){
            $scope.displayMode = changes.displayMode.currentValue;
        }
        if(changes.attachments){
            $scope.attachments = changes.attachments.currentValue;
            // adding domain to dms Url
            if($scope.attachments && $scope.attachments.length){
                $scope.attachments.forEach(attachment => {
                    if(attachment.dmsUrl && !attachment.dmsUrl.startsWith("https")){
                        attachment.dmsUrl = $rootScope.config.DMS_EXTERNAL_URL + attachment.dmsUrl + `?token=${$rootScope.dmsToken}`
                    }
                });
                const attachment = $scope.attachments[0];
                $scope.selectedAttachment = {
                    name: attachment.name,
                    isPresent: attachment.dmsUrl ? true : false,
                    fileType: attachment.mimeType,
                    dmsId: attachment.dmsId,
                    dmsUrl: attachment.dmsUrl,
                    interactionTime: attachment.interactionTime,
                    uploadedBy: attachment.user,
                    interactionType: attachment.interactionType
                    }
            }
        }
        if (changes.context) {
            $scope.context = changes.context.currentValue;
        }
        if (changes.selectedAttachmentId) {
            $scope.selectedAttachmentId = changes.selectedAttachmentId.currentValue;
        }
        initAttachmentView()
    }

    const initAttachmentView = () => {
        if($scope.displayMode) {
            getCarouselThumbContent({ isDisplayMode: true , context: $scope.context})
        }
    }

    const getCarouselThumbContent = ({ isDisplayMode }) => {
        if(isDisplayMode){
            $scope.attachmentsThumb = $scope.attachments.map((attachment)=> {
                return {
                    thumbnail: {
                        dmsUrl: attachment.dmsUrl
                    },
                    name: attachment.name,
                    isPresent: attachment.dmsUrl ? true : false,
                    fileType: attachment.mimeType,
                    dmsId: attachment.dmsId,
                    dmsUrl: attachment.dmsUrl,
                    interactionTime: attachment.interactionTime,
                    uploadedBy: attachment.user,
                    interactionType: attachment.interactionType
                }
            })
            return
        }
    };

    $scope.$on("attachment:carousel:click", (event, {thumbnailselected}) => {
        $scope.selectedAttachment = thumbnailselected;
        console.log(thumbnailselected, "===========")
    })

});
