angular.module('bitnudgeApp')
    .service('SocketService', function($http, $rootScope, Auth, $q){
        var ioSocket;
        this.getSocket = function() {
            if(ioSocket){
                return $q.resolve(ioSocket);
            } else{
                var subfolder = $rootScope.config.subfolder?$rootScope.config.subfolder:"";
                var domain = $rootScope.config.domain;
                ioSocket = io.connect(domain, {path: subfolder + "/socket.io", transports: ['websocket']});
                ioSocket.on('connected', function(){
                    console.log('socket connected');
                    var user = Auth.getCurrentUser();
                    ioSocket.emit('register', {userId: user._id, socketId:ioSocket.id});
                });
                return $q.resolve(ioSocket);
            }
        };
        this.destroy = function(){
            ioSocket = null;
        };
    });

