const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/prospectTable.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('prospectTable', function () {
        return {
            name: 'prospectTable',
            scope: {
                prospectList:     '=',
                fetchProspects: '=',
                selectedProspect:  '=',
                pagination: '=',
                apiLoaded: '=',
                previousPage: '=',
                nextPage: '=',
                archiveProspect: '=',
                activateProspect: '=',
                isReferralsView: '=',
                unassignedProspects: '=',
                selectedProductFilter: '=',
                filterObj: '=',
                showSelectedProspect: '=',
                isCustomerView: '=',
                showLostProspects: '=',
                sortingObj: '='
            },
            controller: 'prospectTableCtrl',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
