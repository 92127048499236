/* eslint-disable angular/controller-as */
/* eslint-disable angular/log */
/* eslint-disable angular/controller-name */

"use strict";
import _ from "lodash";
angular
	.module("bitnudgeApp")
	.controller(
		"prospectUserReportCtrl",
		function ($scope, $http, p0, $q, UtilService, Sidebar, prospectUserApiService) {
			let self = this;
			$scope.strings = p0.strings;
			$scope.pagination = {
				size: 50,
				from: 0,
				disableNext: true,
				disablePrevious: true,
			};
			$scope.selected = {
				sortOrder: "asc",
				searchParam: "",
				searchParamApplied: "",
				searchImplemented: false,
				apiLoaded: false,
				startDate: p0.currentMonthlyCycle.startDate,
				endDate: p0.currentMonthlyCycle.endDate
			};
			$scope.today = new Date()

			$scope.cycleChange = {
				startDate: function (startDate) {
					if (startDate) {
						$scope.selected.startDate = startDate;
					}
					init($scope.selected.userId);
				},
				endDate: function (endDate) {
					if (endDate) {
						$scope.selected.endDate = endDate;
					}
					init($scope.selected.userId);
				},
			};

			self.fetchData = function () {
				const deferred = $q.defer();
				const baseUrl = `/api/prospectUser/handler/performance`;
				const data = {
					from: $scope.pagination.from,
					size: $scope.pagination.size,
					searchParam: $scope.selected.searchParamApplied,
					sortKey: "name",
					sortOrder: $scope.selected.sortOrder,
					startDate : new Date($scope.selected.startDate),
					endDate : new Date($scope.selected.endDate)
				};
				if($scope.selectedBranch._id !== 'all') data.branchName = $scope.selectedBranch.name;
				if($scope.selectedRegion._id !== 'all') data.region = $scope.selectedRegion.name;
				if($scope.selectedArea._id !== 'all') data.area = $scope.selectedArea.name;
				if($scope.selectedProduct._id !== 'all') data.playTypes = prospectUserApiService.getPlayTypeIdFromProductId(p0.allPlayTypes, $scope.selectedProduct._id);

				$http.post(baseUrl, data).then(
					function (result) {
						deferred.resolve(result.data.data);
					},
					function (error) {
						deferred.reject(error);
					}
				);
				return deferred.promise;
			};

			self.generateReportData = async function(reportsData, total){
				if (!total || !reportsData || !reportsData.length) {
					$scope.selected.errorMessage = "No data found";
				}
				const userIds = reportsData.map((d) => d._id);

				const userDetails = await prospectUserApiService.fetchUserDetails(userIds);
				$scope.selected.apiLoaded = true;

				const playTypeProductMap = prospectUserApiService.mapPlayTypeAndProduct(p0.allPlayTypes, $scope.products)

				reportsData.forEach(async (d) => {
					const matchingUser = _.find(userDetails.data, {
						_id: d._id,
					});
					const matchingJobRole = _.find(p0.allJobRoles, {
						_id: matchingUser.organizationalTag.jobRole,
					});
					const matchingProduct = [];
					playTypeProductMap.forEach((play) => {
						if (
							matchingUser.playTypes.includes(play.playTypeId)
						) {
							matchingProduct.push(play.productName);
						}
					});
					d.branch =
						matchingUser.branchName || matchingUser.branch
							? matchingUser.branchName || matchingUser.branch
							: "nil";
					d.jobRole = matchingJobRole.clientDisplayName
						? matchingJobRole.clientDisplayName
						: "nil";
					d.region = matchingUser.officeLocationRegional || "n/a";
					d.area = matchingUser.officeLocationArea || "n/a";
					d.product = matchingProduct || "n/a";
				});
				$scope.originalSelectedData = reportsData;
					$scope.selected.data = _.cloneDeep(
						$scope.originalSelectedData
					);
					$scope.pagination.total = total;
					$scope.pagination.disableNext = false;
					$scope.pagination.disablePrevious = false;
					if (
						$scope.pagination.from + $scope.pagination.size >=
						total
					) {
						$scope.pagination.disableNext = true;
					}
					if ($scope.pagination.from == 0) {
						$scope.pagination.disablePrevious = true;
					}
			}


			const baseRegion =[{ _id: "all", name: "All Regions" }]
			const baseBranch = [{ _id: "all", name: "All Branches" }];
			const baseArea = [{ _id: "all", name: "All Areas" }];	
			const baseProduct = [{ _id: "all", name: "All Products" }];

			async function init() {

				$scope.branches = baseBranch;
				$scope.regions = baseRegion;
				$scope.areas = baseArea;
				$scope.products = baseProduct
				$scope.selectedBranch = baseBranch[0];
				$scope.selectedRegion = baseRegion[0];
				$scope.selectedArea = baseArea[0];
				$scope.selectedProduct = baseProduct[0];

				try {
					$scope.selected.apiLoaded = false;
					const { data, total } = await self.fetchData();
					if (!total || !data || !data.length) {
						$scope.selected.errorMessage = "No data found";
					}
					let filteredProducts = [];
					if(p0.config.agentOnBoardingProduct){
						filteredProducts = p0.allProducts.filter(p => {return p.productCode !== p0.config.agentOnBoardingProduct && p.isMainProduct})
					}
					
					const products = filteredProducts.map((product)=> {return {
						_id : product._id,
						name : product.name
					}})
					const fetchedRegions = await prospectUserApiService.fetchRegions()
					$scope.regions = [...baseRegion, ...fetchedRegions.data.data]
					$scope.products = [
						...baseProduct,
						...products
					];
					
					await self.generateReportData(data, total)
				} catch (err) {
					console.error("err", err);
				}
			}

			$scope.onBranchChange = function (selectedBranch) {
				$scope.selectedBranch = selectedBranch;
				self.handleFilter();
			};

			$scope.onAreaChange = async function (selectedArea) {
				$scope.selectedArea = selectedArea;
				$scope.selectedBranch = baseBranch[0]
				let fetchedBranches = []
				if($scope.selectedRegion._id !== "all" && selectedArea._id !== "all"){
					fetchedBranches = await prospectUserApiService.fetchBranches($scope.selectedRegion._id, selectedArea._id)
				} else {
					fetchedBranches.data = {data : []}
				}
				$scope.branches = [baseBranch[0], ...fetchedBranches.data.data]

				self.handleFilter();
			};

			$scope.onRegionChange = async function (selectedRegion) {
				$scope.selectedRegion = selectedRegion;
				let fetchedAreas = []
				$scope.selectedRegion = selectedRegion
				$scope.selectedArea = baseArea[0]
				$scope.selectedBranch = baseBranch[0]
				if(selectedRegion._id !== "all") {
					fetchedAreas = await prospectUserApiService.fetchAreas(selectedRegion._id)
				} else {
					fetchedAreas.data = {data : []}
				}
				$scope.areas = [baseArea[0], ...fetchedAreas.data.data]
				$scope.branches = baseBranch
				self.handleFilter();
			};

			$scope.onProductChange = function (selectedProduct) {
				$scope.selectedProduct = selectedProduct;
				self.handleFilter();
			};

			self.handleFilter = async function () {
				const {data, total} = await self.fetchData()
				await self.generateReportData(data, total)
			};

			$scope.nextPage = function () {
				$scope.pagination.from += $scope.pagination.size;
				init();
			};

			$scope.previousPage = function () {
				$scope.pagination.from -= $scope.pagination.size;
				init();
			};

			$scope.removeSearch = function () {
				$scope.selected.searchParamApplied = "";
				$scope.selected.searchImplemented = false;
				$scope.selected.searchParam = null;
				$scope.pagination.from = 0;
				init();
			};

			$scope.search = function () {
				$scope.selected.searchImplemented = true;
				$scope.selected.searchParamApplied =
					$scope.selected.searchParam;
				$scope.pagination.from = 0;
				init();
			};
			$scope.search_enter = function (keyEvent) {
				if (keyEvent.which === 13) {
					if ($scope.selected.searchParam.length) {
						$scope.search();
					} else {
						$scope.removeSearch();
					}
				}
			};
			$scope.download = function () {
				const appUrl = Sidebar.getAppUrl();
				const baseUrl = `/api/prospectUser/handler/performance/download`;
				const params = {
					searchParam: $scope.selected.searchParamApplied,
					startDate : new Date($scope.selected.startDate),
					endDate : new Date($scope.selected.endDate)
				};
				return UtilService.downloadFile(appUrl + baseUrl, params);
			};
			init();
		}
	);
