const autoImportNgTemplateLoaderTemplate1 = require('../html/pointsInfo.html');

"use strict";
angular
	.module("bitnudgeApp")
	.controller("dashboardController", function($scope, Auth, p0, $rootScope, sidebarVariables, CurrentCycle, $timeout, $mdDialog, Kpi, DashboardService, $state) {
		let self = this;
		this.selectedDivision, this.selectedPlayType;
			//upcoming followups will always be shown for today as per the requirement
			//other parameters are needed by the existing actionboard api
			const timeRange = "upcoming";
			let startToday = new Date();
			startToday = startToday.setHours(0,0,0,0)
			let endToday = new Date();
			endToday = endToday.setHours(23,59,59,999)
			$scope.loadingUpcomingLeads = true
			$scope.loadingRatedLeads = true
		this.init = function() {
			$scope.slickBannerConfig = {
				enabled: true,
				autoplay: true,
				draggable: false,
				autoplaySpeed: 3000,
				slidesToShow: 1,
				infinite: true
			};
			$scope.config = p0.config;
			if(!$scope.config.ratedLeadCount){
				$scope.config.ratedLeadCount = 20; //default number is config variable not set
			}
			$scope.strings = $rootScope.strings;
			$scope.me = Auth.getCurrentUser();
			$scope.lastSyncDate = $rootScope.lastUpdatedAtDate;
			$scope.switch = { isReportee: false, showSwot:false };
			$scope.reporteeFrontline = true;
			// $scope.timeout = false;
			// $timeout(function() {
			// 	$scope.timeout = true;
			// }, 10000);

			$scope.labCollapsed = true;
			$scope.isFrontline = false;
			$scope.selected = {};
			$scope.swotType = "kpi";
			$scope.gradeType = "personal";
			if ($scope.me.systemRole == "frontline") {
				$scope.isFrontline = true;
				$scope.selected.user = $scope.me;
				$scope.selected.jobRole = $scope.me.organizationalTag.jobRole;
				$scope.selected.division = p0.myDivisions[0];
				$scope.selected.playType = p0.myPlayTypes[0];
				self.setKpiDropdown(p0.myKpis);
				sidebarVariables.setUser($scope.selected.user);
				getUpcomingFollowupActions({userId: $scope.selected.user._id, timeRange, startDate:startToday, endDate : endToday, only: 'leads' });
				
				getRatedLeadsDashboard({userId: $scope.selected.user._id,limit:$scope.config.ratedLeadCount});
			}


			$scope.reporteeFrontline = $rootScope.isFrontline;
			if (!$rootScope.isFrontline) {
				$scope.label1 = $rootScope.isMiddleManager ? "Me" : $scope.strings.FRONTLINE.value;
				$scope.label2 = $rootScope.isMiddleManager ? $scope.strings.FRONTLINE.value : $scope.strings.MANAGER.value;
			}
			$scope.showLastSync = true;
			
		};

		const getUpcomingFollowupActions = ({userId, timeRange, startDate, endDate, only}) =>{
			$scope.loadingUpcomingLeads = true;
			const upcomingInteractions = DashboardService.getTodaysFollowupActions({userId, timeRange, startDate, endDate, only});
			upcomingInteractions.then((data) => {
				$scope.upcomingLeads = data.data.leads
				$scope.loadingUpcomingLeads = false;
			}).catch(error => {
				console.error(error)
				$scope.loadingUpcomingLeads = false;
			})
		}

		const getRatedLeadsDashboard = ({userId, limit}) => {
			$scope.loadingRatedLeads = true;
			const ratedLeadsPromise = DashboardService.getRatedLeads({userId, limit});
			ratedLeadsPromise.then((data) => {
				$scope.ratedLeads = data.data
				$scope.loadingRatedLeads = false;
			}).catch(error => {
				console.error(error)
				$scope.loadingRatedLeads = false;
			})
		}
		this.getUserKpis = function(playTypeIds) {
			var kpis = [];
			_.forEach(playTypeIds, function(playTypeId) {
				var playType = _.find(p0.allPlayTypes, { _id: playTypeId });
				if (playType) kpis = kpis.concat(playType.kpis);
			});
			kpis = _.uniqBy(kpis, "_id");
			kpis = _.sortBy(kpis, ['sequence']);
			return kpis;
		};

		this.setKpiDropdown = function(kpis) {
			if(p0.config.agentOnBoardingProductKpi){
				kpis = kpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)
			}
			if(p0.config.scoreBlockProductKpi){
				kpis = kpis.filter(k => k.kpiCode !== p0.config.scoreBlockProductKpi)
			}
			kpis = _.sortBy(kpis, ['sequence']);
			$scope.kpis = _.cloneDeep(kpis);
			//as per the current requirement
			if ($scope.kpis.length > 1) {
				$scope.kpis.unshift({ _id: "overall", name: "Overall" });
			}
			$scope.selectedKpi = $scope.kpis[0];
			sidebarVariables.setKpi($scope.selectedKpi);
		};

		$scope.onReporteeChange = function(reportee) {
			$scope.selected.user = reportee;
			$scope.selected.jobRole = $scope.selected.user.organizationalTag.jobRole;
			$scope.isSeniorManager = reportee.roleType == "seniorManager";
			var userKpis = self.getUserKpis($scope.selected.user.playTypes);
			if (self.selectedDivision.id == "all" && self.selectedPlayType._id == "all") {
				self.setKpiDropdown(userKpis);
			} else if (self.selectedDivision.id != "all" && self.selectedPlayType._id == "all") {
				var kpis = p0.getUIKpisOfPlayTypes(self.selectedDivision.playTypes);
				self.setKpiDropdown(kpis);
			} else {
				self.setKpiDropdown(self.selectedPlayType.kpis);
			}
			sidebarVariables.setUser($scope.selected.user);
			if ($scope.selected.user.systemRole == "manager") {
				$scope.label1 = "Me";
				if ($scope.me._id != $scope.selected.user._id) $scope.label1 = $scope.selected.user.name;
				$scope.label2 = $scope.strings.FRONTLINE.value;
				if ($scope.me._id != $scope.selected.user._id) $scope.label2 = $scope.selected.user.name + "'s " + $scope.strings.FRONTLINE.value;
				$scope.reporteeFrontline = false;
			} else if ($scope.selected.user.systemRole == "seniorManager") {
				$scope.label1 = $scope.strings.FRONTLINE.value;
				if ($scope.me._id != $scope.selected.user._id) $scope.label1 = $scope.selected.user.name + "'s " + $scope.strings.FRONTLINE.value;
				$scope.label2 = $scope.strings.MANAGER.value;
				if ($scope.me._id != $scope.selected.user._id) $scope.label2 = $scope.selected.user.name + "'s " + $scope.strings.MANAGER.value;
				$scope.reporteeFrontline = false;
			} else {
				$scope.reporteeFrontline = true;
			}
			getUpcomingFollowupActions({userId: $scope.selected.user._id, timeRange, startDate:startToday, endDate : endToday, only: 'leads' })
			getRatedLeadsDashboard({userId: $scope.selected.user._id, limit:$scope.config.ratedLeadCount});
		};

		$scope.onDivisionChange = function(division) {
			self.selectedDivision = division;
		};
		$scope.onPlayTypeChange = function(playType) {
			self.selectedPlayType = playType;
		};

		$scope.resizeLab = function() {
			if ($scope.labCollapsed) {
				$scope.labCollapsed = false;
			} else {
				$scope.labCollapsed = true;
			}
		};

		$scope.changeKpi = function(kpi) {
			$scope.selectedKpi = kpi;
			sidebarVariables.setKpi($scope.selectedKpi);
		};
		$scope.onCycleChange = function(cycle) {
			$scope.selectedCycle = cycle;
			sidebarVariables.setCycle(cycle);
			CurrentCycle.setCycle(cycle);
			if (cycle._id == p0.currentMonthlyCycle._id) $scope.showLastSync = true;
			else $scope.showLastSync = false;
		};
		$scope.showPointsInfo = function() {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: "pointsInfoCtrl"
			});
		};



		$scope.redirectToLeads = (lead) => {
			let params = {
				productId: lead.product ? lead.product._id : lead.productId,
				workflowIds: lead.workflowId
			}
			$state.go('lms.listLead', params)
		}

		this.init();
	});
