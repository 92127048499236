/* eslint-disable angular/controller-as */
/* eslint-disable angular/log */
/* eslint-disable angular/controller-as-vm */
/* eslint-disable angular/di */
"use strict";
angular.module("bitnudgeApp").service("prospectUserApiService", [
	"$http",
	"$q",
	function ($http, $q) {
		var self = this;

		//get list of all products
		self.getUserProducts = function () {
			const deferred = $q.defer();
			const baseUrl = "/api/products/allproducts";
			const data = {
				fields: ["name", "_id", "productCode"],
				includeSubProducts: false,
			};
			$http.post(baseUrl, data).then(
				function (result) {
					deferred.resolve(result);
				},
				function (err) {
					deferred.reject(err);
				}
			);
			return deferred.promise;
		};

        //get list of all playtypes
		self.getPlaytypes = function () {
			const deferred = $q.defer();
			const baseUrl = "/api/playtypes/list";
			$http.post(baseUrl).then(
				function (result) {
					deferred.resolve(result);
				},
				function (err) {
					deferred.reject(err);
				}
			);
			return deferred.promise;
		};

        //get users details of an array of users
        self.fetchUserDetails = function (userIdArr) {
            const deferred = $q.defer();
            $http
                .post("/api/users/getUsersByIds", { userIds: userIdArr })
                .then(
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );
            return deferred.promise;
        };

		self.mapPlayTypeAndProduct = function (playTypes, userProduct) {
			const playTypeProductMap = []
			const prodObject = {};
			userProduct.forEach((prod)=>{
				if(!(prod._id in prodObject)){
					prodObject[prod._id] = {
						name : prod.name
					}
				}
			})
			playTypes.forEach((playType) => {
				playType.products.forEach((prod)=>{
					if(prod in prodObject){
						playTypeProductMap.push({
							productName: prodObject[prod].name,
							playTypeId: playType._id
						})
					}
				})
			})
			return playTypeProductMap
		};

		/**
		 * 
		 * @returns { _id : "regionaid"
		 * name : "regionname"}
		 */
		self.fetchRegions = function(){
			const deferred = $q.defer()
			$http
                .post("/api/field-options/regions")
                .then(
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );
			return deferred.promise;
		}

		/**
		 * 
		 * @param {*} regionCode 
		 * @returns {
		 * _id : "areaId",
		 * name : "area name"}
		 */
		self.fetchAreas = function(regionCode){
			const deferred = $q.defer()
			$http
                .post(`/api/field-options/areas`, {regionCode})
                .then(
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );
			return deferred.promise;
		}

		/**
		 * 
		 * @param {*} regionCode 
		 * @param {*} areaCode 
		 * @returns {
		 * _id : "branchId",
		 * name : "branchname"}
		 */
		self.fetchBranches = function(regionCode, areaCode){
			const deferred = $q.defer()
			$http
                .post(`/api/field-options/branches`, {regionCode, areaCode})
                .then(
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );
			return deferred.promise;
		}

		/**
		 * gets playtype from productid
		 * 
		 */
		self.getPlayTypeIdFromProductId = function(playTypes, productId){
			const playType = playTypes.filter((play)=> play.products.includes(productId))
			return playType.map((play)=> play._id)
		}
	}]);
