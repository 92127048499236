'use strict';
angular.module('bitnudgeApp')
    .service('JobRole', function (p0) {
        var jobRoles = _.cloneDeep(p0.allJobRoles);


        function getChildJobRoles(role, jobRoles, children, playTypeId){
            var childRoles = _.filter(jobRoles, function(jobRole){
                if(playTypeId){
                    return jobRole.parents && jobRole.parents.indexOf(role._id) > -1 && _.includes(jobRole.playTypes, playTypeId);
                }else{
                    return jobRole.parents && jobRole.parents.indexOf(role._id) > -1
                }
            });
            if(childRoles.length){
                childRoles.forEach(function (jobrole) {
                    children.push(jobrole);
                });
                childRoles.forEach(function (jobrole) {
                    getChildJobRoles(jobrole, jobRoles, children, playTypeId);
                });
            }
            return children;
        }

        function getParentJobRoles(role, jobRoles, parents) {
            var parentRoles = role.parents;
            while(parentRoles && parentRoles.length){
                var newParentRoles = [];
                parents.forEach(function (parent) {
                    var parent = _.find(jobRoles,{_id:parent});
                    parents.push(parent);
                    newParentRoles.push(parent._id);
                });
                parentRoles = newParentRoles;
            }
            return parents;
        }

        this.getDetails = function (roleId, playTypeId) {
            console.log('jobRoles: ', jobRoles)
            console.log('roleId: ', roleId)
            console.log('playTypeId: ', playTypeId)
            var role = _.cloneDeep(_.find(jobRoles, {id:roleId}));
            if(!role){
                return null;
            }
            var children = [];
            children = getChildJobRoles(role, jobRoles, children, playTypeId);
            var parents = [];
            /** Do not find parent jobRoles at all. just find my child jobroles to show relevant cycles**/
            // parents = getParentJobRoles(role, jobRoles, parents);
            var relevantJObRoles = children.concat(parents);
            relevantJObRoles.push(role);
            relevantJObRoles = _.uniqBy(relevantJObRoles, '_id');
            role.frontline = _.map(_.filter(relevantJObRoles, {roleType:'frontline'}),'_id');
            role.managers = _.map(_.filter(relevantJObRoles, {roleType: 'manager'}),'_id');
            role.seniorManagers = _.map(_.filter(relevantJObRoles, {roleType:'seniorManager'}),'_id');
            var relevantCycleTypeIds = _.map(relevantJObRoles, 'monthlyCycleTypeId');
            relevantCycleTypeIds.push(role.monthlyCycleTypeId);
            role.monthlyCycleType = _.find(p0.monthlyCycleTypes, {_id:role.monthlyCycleTypeId}).cycleTypeCode;
            var relevantCycleTypes = _.filter(p0.monthlyCycleTypes, function(type){
                return _.includes(relevantCycleTypeIds, type._id);
            });
            relevantCycleTypes = _.map(relevantCycleTypes, 'cycleTypeCode');
            role.cycleTypes = relevantCycleTypes;
            return role;

        };
    });
