'use strict';
angular.module('bitnudgeApp')
    .directive('httpSrc', ['$rootScope', 'p0', function ($rootScope, p0) {
        var directive = {
            link: link,
            restrict: 'A',
            scope: {model: '=httpSrc'},
        };
        return directive;

        function getUrl(rawUrl) {
            if (rawUrl.startsWith('http')) {
                if ($rootScope.dmsToken && rawUrl.startsWith(p0.config.DMS_EXTERNAL_URL) && !rawUrl.includes('token')) {
                    return `${rawUrl}?token=${$rootScope.dmsToken}`
                }
                return rawUrl;
            }

            let url = $rootScope.remoteUrl;
            if (url[url.length - 1] != '/') {
                url = url + '/';
            }
            return url + rawUrl;
        }

        function link($scope, elem, attrs) {
            if ($scope.model) {
                console.log('url is:', getUrl($scope.model))
                attrs.$set('src', getUrl($scope.model));
            }

            $scope.$watch('model', function (newVal, oldVal) {
                if (newVal && newVal != oldVal) {
                    console.log('url is updated to:', getUrl(newVal))
                    attrs.$set('src', getUrl(newVal));
                }
            })
        }
    }
    /**TODO need to fix the http request issue**/
            // $scope.$watch('objectURL', function (objectURL) {
            //     elem.attr('src', objectURL);
            // });

            // $scope.$on('$destroy', function () {
            //     revokeObjectURL();
            // });
            // attrs.$observe('httpSrc', function (url) {
            //     revokeObjectURL();

            //     if(url && url.indexOf('data:') === 0) {
            //         $scope.objectURL = url;
            //     } else if(url) {
            //         $http.get(url, {
            //                 responseType: 'arraybuffer',
            //                 headers: {
            //                     'accept': 'image/webp,image/*,*/*;q=0.8'
            //                 }
            //             })
            //             .then(function (response) {
            //                 var blob = new Blob(
            //                     [ response.data ], 
            //                     { type: response.headers('Content-Type') }
            //                 );
            //                 $scope.objectURL = URL.createObjectURL(blob);
            //             });
            //     }
            // });
]);