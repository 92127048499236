'use strict';
angular.module('bitnudgeApp').controller('addProspectNotesCtrl', function ($scope, $mdDialog, prospectService, prospect,reloadProspectInfoPage, toaster) {
    $scope.prospect = prospect;
    $scope.currentNote = ""
    $scope.cancel = function () {
        $mdDialog.hide();
    }

    $scope.addNote = () => {
        prospectService.addNote($scope.prospect._id, $scope.currentNote).then((data)=>{
            if(data.status){
                $scope.currentNote = "";
                toaster.pop('success', 'Prospects Notes', `Note is successfully added.`);
                reloadProspectInfoPage();
                $mdDialog.hide();
            }else{
                toaster.pop('error','Prospects Notes', 'Error occured while adding notes')
            }
        }).catch(err => {
            const message = err && err.message ? err.message : 'Error occured while adding notes.'
            toaster.pop('error','Prospects Notes', message)
        })
    }
    

});
