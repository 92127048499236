'use strict';
angular.module('bitnudgeApp')
    .controller('gameAnalyticsCtrl', function ($scope, Auth, $http, p0, analytics, $q, $timeout) {

        $scope.slickConfig= {
            enabled: true,
            infinite: true,
            draggable: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
        };
        $scope.metric = 'kpi';
        $scope.user = Auth.getCurrentUser();
        $scope.showOneMonth = true;
        $scope.selected = {};
        // console.log($scope.user)
        $scope.selected.jobRole = $scope.user.organizationalTag.jobRole;
        $scope.changeCycle = function(cycle){
            $scope.selectedCycle = cycle;
            $scope.endDate =  new Date(p0.lastUpdatedAt) || new Date();
            $scope.startDate =  new Date($scope.endDate.getFullYear(), $scope.endDate.getMonth()-6, 1, 0, 0, 0);
            fetchKpiDataFromServer();
        }
        $scope.cycleTypes = [
            {
                id: "monthly",
                name : 'Monthly'
            },{
                id: "weekly",
                name : 'Weekly'
            }
        ];
        let myKpis = _.cloneDeep(p0.myKpis);
        myKpis = myKpis.filter(k => k.kpiCode !== p0.config.agentOnBoardingProductKpi)

        $scope.kpiIndicator = myKpis[0];
        $scope.updatedCombinedActivities = false;
        $scope.activityConversionRatiosData = {};
        $scope.activityConversionRatiosBenchmarkData = {};
        let cycleIds = {}, leagueIds = [];
        let kpiSpecificOption = {
            id : 'indicator',
            isActive:true,
            checkBoxname: 'All KPIs',
            placeholder: 'Select KPIs',
            allSelected: false,
            selectedOptions: [],
            options: _.map(myKpis, function(kpi){
                return {
                    _id : kpi._id,
                    name : kpi.name,
                    selected: false
                }
            })
        };
        let activitySpecificOption = {
            id : 'indicator',
            isActive:true,
            checkBoxname: 'All Activities',
            placeholder: 'Select Activities',
            allSelected: false,
            selectedOptions: [],
            options: _.map( p0.myActivities, function(activity){
                return {
                    _id : activity._id,
                    name : activity.name,
                    selected: false
                }
            })
        };

        $scope.activityFieldNames = ['submission', 'call', 'meeting', 'newLead', 'lostLeads', 'activeLeads'];
        $scope.allActivities = _.map($scope.activityFieldNames, function(activityFieldName){
           return _.find(p0.myActivities, {fieldName : activityFieldName});
        });
        $scope.activities = _.filter($scope.allActivities, function(activity){
            return _.includes($scope.kpiIndicator.activities, activity._id);
        });

        $scope.combinedActivities = [
            {
                _id : 'interactions',
                name : 'Interactions',
                includedActivities : ['submission', 'call', 'meeting']
            },{
                _id : 'leads',
                name : 'Leads',
                includedActivities : ['newLead', 'lostLeads', 'activeLeads']
            },
            // {
            //     _id : 'conversionRatios',
            //     name : 'Conversion Ratios',
            //     includedActivities : ['submission', 'call', 'meeting']
            // }
        ];
        _.each($scope.combinedActivities, function(combinedActivity){
            combinedActivity.activities = _.map(combinedActivity.includedActivities, function(activityFieldName){
                return _.find($scope.activities, {fieldName : activityFieldName});
            })
        })
        $scope.combinedActivity = $scope.combinedActivities[0];
        $scope.slickActivities = $scope.combinedActivity.activities;
        let peerSpecificOption = {
            id : 'peer',
            isActive:true,
            checkBoxname: 'Peer',
            placeholder: 'Select Peer',
            allSelected: false,
            selectedOptions: [],
            options: _.map( p0.myPeers, function(peer){
                return {
                    _id : peer._id,
                    name : peer.name,
                    selected: false
                }
            })
        };
        $scope.benchmarkComparisonOptions = [
            {
                left: {name: 'Bank Avg.', selected: false, id: 'avg' },
                right: {name: 'Bank Top 10%', selected: false, id: 'topDecile' },
                isCustom: false
            },
            // {
            //     label: 'Custom',
            //     placeholder: 'Select Peer',
            //     isCustom: true,
            //     selected: false,
            //     selectedOptions: [],
            //     options: []
            // }
        ];

        $scope.units = {
            "kpi" : [
                {
                    "id" : "percentageCompleted",
                    "name" :"Percentage Completed (%)"
                },{
                    "id" : "achievedValue",
                    "name" : "Achieved Value (#)"
                }
            ],
            "activity" : [
                {
                    "id" : "value",
                    "name" : "Volume (#)"
                },{
                    "id" : "perFTE",
                    "name" : "Per FTE (#)"
                },{
                    "id" : "perDayPerFTE",
                    "name" : "Per day per FTE (#)"
                }
            ]
        };
        $scope.userSpecificAddRemoveOptions = {
            'seniorManager' : {
                'kpi' : [kpiSpecificOption],
                'activity':  [activitySpecificOption]
            },
            'frontline' : {
                'kpi' : [kpiSpecificOption],
                'activity':  [activitySpecificOption]
            },
            'manager' : {
                'kpi' : [kpiSpecificOption],
                'activity':  [activitySpecificOption]
            }
        };
        $scope.addRemoveOptions  = $scope.userSpecificAddRemoveOptions[$scope.user.systemRole];
        $scope.myKpis = myKpis;
        $scope.activityPeerAnalyticsData = {};
        $scope.kpiPeerAnalyticsData = {};

        // fetchKpiDataFromServer();

        $scope.onKpiChange = function (kpi) {
        	$scope.kpiIndicator = kpi;
        	$scope.activities = _.filter($scope.allActivities, function (activity) {
        		return _.includes($scope.kpiIndicator.activities, activity._id);
            });
            if(_.isEmpty(cycleIds) || !cycleIds.monthly){
                return;
            }
        	return fetchActivityAnalyticsDataAndBenchmarks().then(() => {
        		if ($scope.kpiGraphAnalyticsData) {
        			mapConversionRatiosToActivityData($scope.kpiGraphAnalyticsData);
        		}
        		if ($scope.benchmarkData) {
        			mapActivityConversionRatioBenchmarkData($scope.benchmarkData);
                }
        		$scope.onCombinedActivityChange($scope.combinedActivity);
        	})
        }

        $scope.getPeerAnalyticsData = function(peerIds, metric){
            let peerAnalyticsData = [];
            if(metric === 'kpi'){
                _.each(peerIds, function(peerId){
                    peerAnalyticsData.push( analytics.generateGameAnalyticsKpiGraphs(peerId, $scope.startDate.getTime(), $scope.endDate.getTime()));
                })
                $q.all(peerAnalyticsData).then(function(kpiDataOfAllPeers){
                    _.each(peerIds, function(peerId, index){
                        $scope.kpiPeerAnalyticsData[peerId] = kpiDataOfAllPeers[index];
                    });
                }, function(error){
                    console.log(error);
                });
            }else{
                _.each(peerIds, function(peerId){
                    peerAnalyticsData.push( analytics.getKpiSpecificActivitiesAnalyticsData(peerId, $scope.kpiIndicator._id, cycleIds, leagueIds));
                })
                $q.all(peerAnalyticsData).then(function(activityDataOfPeers){
                    _.each(peerIds, function(peerId, index){
                        $scope.activityPeerAnalyticsData[peerId] = activityDataOfPeers[index];
                    });
                }, function(error){
                    console.log(error);
                });
            }
        }

        function fetchKpiDataFromServer(){
            let userId = $scope.user._id;
            let startEpochTime = $scope.startDate.getTime();
            let endEpochTime = $scope.endDate.getTime();
            let kpiId = myKpis[0]._id;
            let jobRoleId = [$scope.user.organizationalTag.jobRole._id];
            analytics.generateGameAnalyticsKpiGraphs(userId, startEpochTime, endEpochTime)
                .then(function(kpiGameAnalyticsData){
                    $scope.kpiGraphAnalyticsData = kpiGameAnalyticsData;
                    _.each($scope.cycleTypes, function(cycleType){
                        cycleIds[cycleType.id] = _.map(kpiGameAnalyticsData[cycleType.id][kpiId], 'cycleId');
                    });
                }).then( () => {
                    return fetchActivityAnalyticsDataAndBenchmarks()
                }).then(()=>{
                    mapConversionRatiosToActivityData($scope.kpiGraphAnalyticsData);
                })
                .then(function(){
                    return analytics.getBenchmarkData(userId, startEpochTime, endEpochTime);
                })
                .then(function(benchmarkData){
                    $scope.benchmarkData = benchmarkData;
                    _.each($scope.cycleTypes, function(cycleType){
                        let kpiId = myKpis[0]._id;
                        let leagues = _.map(benchmarkData[cycleType.id][kpiId], 'leagueId');
                        let filteredLeagues = [];
                        if(leagueIds.length === 0){
                            leagueIds = _.uniq(leagues);
                        }

                        else{
                             filteredLeagues = _.filter(leagues, function(leagueId){
                                return ! _.includes(leagueIds, leagueId);
                            })
                        }
                        if(filteredLeagues.length > 0){
                            leagueIds = leagueIds.concat(filteredLeagues);
                        }
                    });
                    mapActivityConversionRatioBenchmarkData(benchmarkData);
                    $scope.onCombinedActivityChange($scope.combinedActivity);
                })
                .then(function(){
                    $scope.peers = [];
                    //disabling for urgent deployment
                    //return $http.post('/api/users/getUsersOfJobRole',{jobRoleIds:jobRoleId});
                })
                // .then(function (users) {
                //     users = users.data;
                //     _.remove( users, { _id : userId });
                //     $scope.peers = users;
                //     let benchmarkPeerOption = _.find($scope.benchmarkComparisonOptions, {label : 'Custom'});
                //     benchmarkPeerOption.options = _.map($scope.peers, function(peer){
                //         return {
                //             name : peer.name,
                //             _id : peer._id
                //         }
                //     });
                // })
                .catch(function(error){
                    console.log(error);
                })
        }

        function mapActivityConversionRatioBenchmarkData(benchmarkData) {
            _.each($scope.cycleTypes, function (cycleType) {
                $scope.activityConversionRatiosBenchmarkData[cycleType.id] = {};
                let conversionRatioActivities = _.find($scope.combinedActivities, { _id: 'conversionRatios' });
                if (conversionRatioActivities) {
                    _.each(conversionRatioActivities.activities, function (activity) {
                        $scope.activityConversionRatiosBenchmarkData[cycleType.id][activity._id] = _.map(benchmarkData[cycleType.id][$scope.kpiIndicator._id], function (kpiData) {
                            return {
                                cycleId: kpiData.cycleId,
                                value: {
                                    avg: kpiData.activityConversionRatios[activity.fieldName].avg,
                                    topDecile: kpiData.activityConversionRatios[activity.fieldName].topDecile
                                }
                            }
                        });
                    })
                }
            });
        }

        function mapConversionRatiosToActivityData(kpiGameAnalyticsData){

            _.each($scope.cycleTypes, function(cycleType){
                $scope.activityConversionRatiosData[cycleType.id] = {};
                let conversionRatioActivities = _.find($scope.combinedActivities, {_id: 'conversionRatios'});

                if(conversionRatioActivities){     
                    _.each(conversionRatioActivities.activities, function(activity){

                        $scope.activityConversionRatiosData[cycleType.id][activity._id] = _.map(kpiGameAnalyticsData[cycleType.id][$scope.kpiIndicator._id], function(kpiData){
                            return {
                                cycleId : kpiData.cycleId,
                                value : {
                                    value : kpiData.activityConversionRatios && kpiData.activityConversionRatios[activity.fieldName] && kpiData.activityConversionRatios[activity.fieldName].value || 0
                                }
                            }
                        });
                        
                    })
                }
            });

        }

        function fetchActivityAnalyticsDataAndBenchmarks(){
            let userId = $scope.user._id;
            let kpiId = $scope.kpiIndicator._id;

            return analytics.getKpiSpecificActivitiesAnalyticsData(userId, kpiId, cycleIds, leagueIds)
                .then(function(activityGameAnalyticsData){
                    $scope.activityGameAnalyticsData = activityGameAnalyticsData;
                })
                .then(function(){
                    return analytics.activityBenchmarkData(userId, kpiId, cycleIds, leagueIds);
                })
                .then(function(activityBenchmarksData){
                    $scope.activityBenchmarksData = activityBenchmarksData;
                })
                .catch(function(error){
                    console.log(error);
                })
        }

        $scope.onDateChange = function(changedDate){

        };

        $scope.onCombinedActivityChange = function(indicator){
            $scope.updatedCombinedActivities = false;
            $scope.combinedActivity = indicator;
            $scope.slickActivities = $scope.combinedActivity.activities;
            $timeout(function () {
                $scope.updatedCombinedActivities = true;
            }, 100);
        }

        $scope.$watchCollection('slickActivities',function (newCollection) {

        })


    });
