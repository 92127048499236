'use strict';

angular.module('bitnudgeApp')
    .factory('Auth', function Auth($http, User, $q, $timeout, $cacheFactory) {
        let currentUser = User.get();
        let setSecurityQuestions = null;
        let appSetupMode = null;

        let shouldSetSecurityQuestions = function (setSecurityQuestions) {
            var shouldSet = false;
            var questionsSet;
            let useBitNudgeLoginFlow = true;
            if(currentUser.cloneUser){
                questionsSet = currentUser.cloneUser.questionsSet;
                useBitNudgeLoginFlow = currentUser.cloneUser.useBitNudgeLoginFlow;
            }else{
                questionsSet = currentUser.questionsSet;
                useBitNudgeLoginFlow = currentUser.useBitNudgeLoginFlow;
            }
            if(setSecurityQuestions && !questionsSet){
                shouldSet = true;
            }
            // if user is not using BitNudge login flow then security questions are not required.
            if(!useBitNudgeLoginFlow){
                shouldSet = false;
            }
            return shouldSet;
        };

        return {
            login: function(user) {
                return $http.post('/api/auth/local', {
                    email: user.email,
                    password: user.password
                }).
                then(function (response) {

                    var defer = $q.defer();
                    console.log('response.data: ', response.data)
                    if(response.data.status){
                        var data = response.data.data;
                        console.log('data: ', data)
                        if(data.token){
                            console.log('here ii1: ')
                            currentUser = User.get({}, function() {
                                defer.resolve(currentUser);
                            });
                        }else{
                            console.log('here ii2: ')
                            return defer.resolve(data);
                        }
                    }else{
                        console.log('here ii3: ')
                        defer.resolve(response.data);
                    }
                    console.log('here ii4: ')
                    return defer.promise;
                }).
                catch(function(err) {
                    console.log('here ii5: ', err)
					this.logout();
                    return $q.reject(err);
                }.bind(this));
            },

            logout: function() {
                return $http.post('/api/users/logout').then(function() {
                    $timeout(function () {
                        $cacheFactory.get('$http').removeAll();
                    }, 500);
                    currentUser = {};
                });
            },

            createUser: function(user) {
                return User.save(user).then(function(data) {
                        currentUser = data;
                        return $q.resolve(user);
                    }).catch(function(err) {
                        this.logout();
                        return $q.reject(err);
                    }.bind(this));
            },

            changePassword: function(oldPassword, newPassword) {
                return $http.post('/api/users/password',{oldPassword, newPassword}).then(function(res) {
                    const response = res.data;
                    if (response.status) {
                        let affectedUser = currentUser.cloneUser ? currentUser.cloneUser : currentUser;
                        affectedUser.passwordExpires =  new Date().getTime()+(90*24*60*60*1000);
                        return {status: true};
                    } else {
                        return response;
                    }
                }).catch(function(err) {
                        return $q.reject(err);
                    });
            },

            getCurrentUser: function() {
                return currentUser;
            },
            updateCurrentUser: function(field, newValue) {
                // if(currentUser.hasOwnProperty(field)){
                    currentUser[field] = newValue;
                // }
            },

            isLoggedIn: function() {
                return currentUser.hasOwnProperty('systemRole');
            },

            isLoggedInAsync: function() {
                if(currentUser.hasOwnProperty('$promise')) {
                    return currentUser.$promise.then(function() {
                        return $q.resolve([true, currentUser]);
                    }).catch(function() {
                        return $q.resolve([false]);
                    });
                }
                else return $q.resolve([false]);
            },

            isAdmin: function() {
                return currentUser.systemRole === 'lmsAdmin';
            },

            setField: function(field,val){
                currentUser[field] = val;
            },

            showTutorials: function(){
                var instructionsRead;
                if(currentUser.cloneUser){
                    instructionsRead = currentUser.cloneUser.instructionsRead;
                }else{
                    instructionsRead = currentUser.instructionsRead;
                }
                return !instructionsRead && currentUser.systemRole !== 'lmsAdmin';
            },
            showAvatar: function(){
                var avatarSet;
                if(currentUser.cloneUser){
                    avatarSet = currentUser.cloneUser.avatarSet;
                }else{
                    avatarSet = currentUser.avatarSet;
                }
                return (currentUser.systemRole === 'frontline'
                    || currentUser.systemRole === 'manager' )
                    && !avatarSet;
            },
            setSecurityQuestions: function(){
                var defer = $q.defer();
                if(setSecurityQuestions != null){
                    defer.resolve(shouldSetSecurityQuestions(setSecurityQuestions));
                } else {
                    $http.post('/api/config/getsecurityQuestion').then(function(result){
                        setSecurityQuestions = result.data;
                        defer.resolve(shouldSetSecurityQuestions(setSecurityQuestions));
                    });
                }
                return defer.promise;
            },
            isApplicationSetupMode: function(){
                var defer = $q.defer();
                if(appSetupMode != null){
                    defer.resolve(appSetupMode);
                } else {
                    $http.post('/api/config', {configKey: 'isAppSetupMode'}).then(function(result){
                        appSetupMode = result.data;
                        defer.resolve(appSetupMode);
                    });
                }
                return defer.promise;
            }
        };
    });
