const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/lms.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/adminDashboard.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/addBulkIndividualTarget.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/queryUtility.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/addManagerDashboardConfig.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/addBulkIndividualMTD.html');
const autoImportNgTemplateLoaderTemplate7 = require('../html/admin/addBulkIndividualIncentive.html');
const autoImportNgTemplateLoaderTemplate8 = require('../html/admin/addBulkLead.html');
const autoImportNgTemplateLoaderTemplate9 = require('../html/admin/addBulkProspectUser.html');
const autoImportNgTemplateLoaderTemplate10 = require('../html/admin/genericUpload.html');
const autoImportNgTemplateLoaderTemplate11 = require('../html/admin/addBulkCustomer.html');
const autoImportNgTemplateLoaderTemplate12 = require('../html/admin/configureLeadsSubProduct.html');
const autoImportNgTemplateLoaderTemplate13 = require('../html/admin/configureLeadSubType.html');
const autoImportNgTemplateLoaderTemplate14 = require('../html/admin/addBulkLeadsSubmissionStatus.html');
const autoImportNgTemplateLoaderTemplate15 = require('../html/admin/addLostLeads.html');
const autoImportNgTemplateLoaderTemplate16 = require('../html/admin/exportLeads.html');
const autoImportNgTemplateLoaderTemplate17 = require('../html/admin/exportProspects.html');
const autoImportNgTemplateLoaderTemplate18 = require('../html/admin/listIndividualTarget.html');
const autoImportNgTemplateLoaderTemplate19 = require('../html/lms/listLead.html');
const autoImportNgTemplateLoaderTemplate20 = require('../html/lms/listProspect.html');
const autoImportNgTemplateLoaderTemplate21 = require('../html/heatmap.html');
const autoImportNgTemplateLoaderTemplate22 = require('../html/lms/listCustomers.html');
const autoImportNgTemplateLoaderTemplate23 = require('../html/huddleBoard.html');
const autoImportNgTemplateLoaderTemplate24 = require('../html/admin/lmsBranchReport.html');
const autoImportNgTemplateLoaderTemplate25 = require('../html/admin/contacts.html');
const autoImportNgTemplateLoaderTemplate26 = require('../html/admin/addBulkDivisions.html');
const autoImportNgTemplateLoaderTemplate27 = require('../html/admin/addBulkRewards.html');
const autoImportNgTemplateLoaderTemplate28 = require('../html/admin/addBulkString.html');
const autoImportNgTemplateLoaderTemplate29 = require('../html/admin/listRedemptions.html');
const autoImportNgTemplateLoaderTemplate30 = require('../html/admin/autoUploadScheduler.html');
const autoImportNgTemplateLoaderTemplate31 = require('../html/admin/autoUploadLogs.html');
const autoImportNgTemplateLoaderTemplate32 = require('../html/admin/badge/listBadge.html');
const autoImportNgTemplateLoaderTemplate33 = require('../html/admin/badge/addBadge.html');
const autoImportNgTemplateLoaderTemplate34 = require('../html/admin/badge/addBulkBadge.html');
const autoImportNgTemplateLoaderTemplate35 = require('../html/admin/cycle/cycle.html');
const autoImportNgTemplateLoaderTemplate36 = require('../html/admin/cycle/listCycle.html');
const autoImportNgTemplateLoaderTemplate37 = require('../html/admin/cycle/addCycle.html');
const autoImportNgTemplateLoaderTemplate38 = require('../html/admin/cycle/addBulkCycle.html');
const autoImportNgTemplateLoaderTemplate39 = require('../html/admin/kpi/kpi.html');
const autoImportNgTemplateLoaderTemplate40 = require('../html/admin/kpi/listKpi.html');
const autoImportNgTemplateLoaderTemplate41 = require('../html/admin/kpi/editKpi.html');
const autoImportNgTemplateLoaderTemplate42 = require('../html/admin/kpi/showKpi.html');
const autoImportNgTemplateLoaderTemplate43 = require('../html/admin/kpi/addBulkWeightage.html');
const autoImportNgTemplateLoaderTemplate44 = require('../html/admin/kpi/addBulkKpiPointMultipliers.html');
const autoImportNgTemplateLoaderTemplate45 = require('../html/admin/addBulkProduct.html');
const autoImportNgTemplateLoaderTemplate46 = require('../html/admin/bulkExcelUpload.html');
const autoImportNgTemplateLoaderTemplate47 = require('../html/admin/activitySchema/listActivitySchema.html');
const autoImportNgTemplateLoaderTemplate48 = require('../html/admin/activitySchema/addActivity.html');
const autoImportNgTemplateLoaderTemplate49 = require('../html/admin/activitySchema/addBulkActivity.html');
const autoImportNgTemplateLoaderTemplate50 = require('../html/admin/glossary/addGlossary.html');
const autoImportNgTemplateLoaderTemplate51 = require('../html/admin/excelHeader/addExcelHeaderFile.html');
const autoImportNgTemplateLoaderTemplate52 = require('../html/admin/l3Products/l3Products.html');
const autoImportNgTemplateLoaderTemplate53 = require('../html/admin/acl/addACL.html');
const autoImportNgTemplateLoaderTemplate54 = require('../html/admin/activitySchema/addBulkMTDActivity.html');
const autoImportNgTemplateLoaderTemplate55 = require('../html/admin/activitySchema/addBulkActivityPointMultipliers.html');
const autoImportNgTemplateLoaderTemplate56 = require('../html/admin/activitySchema/addBulkActivityWeightages.html');
const autoImportNgTemplateLoaderTemplate57 = require('../html/admin/kpi/kpiMix.html');
const autoImportNgTemplateLoaderTemplate58 = require('../html/admin/quote.html');
const autoImportNgTemplateLoaderTemplate59 = require('../html/admin/addBulkSecurityQuestions.html');
const autoImportNgTemplateLoaderTemplate60 = require('../html/admin/addBulkIncentives.html');
const autoImportNgTemplateLoaderTemplate61 = require('../html/admin/commandLine.html');
const autoImportNgTemplateLoaderTemplate62 = require('../html/admin/listConfig.html');
const autoImportNgTemplateLoaderTemplate63 = require('../html/admin/editConfig.html');
const autoImportNgTemplateLoaderTemplate64 = require('../html/admin/bulkConfig.html');
const autoImportNgTemplateLoaderTemplate65 = require('../html/admin/adminReports.html');
const autoImportNgTemplateLoaderTemplate66 = require('../html/admin/addBulkContest.html');
const autoImportNgTemplateLoaderTemplate67 = require('../html/admin/addContestReward.html');
const autoImportNgTemplateLoaderTemplate68 = require('../html/admin/triggerCrons.html');
const autoImportNgTemplateLoaderTemplate69 = require('../html/admin/addBulkErrorWorkItems.html');
const autoImportNgTemplateLoaderTemplate70 = require('../html/tickets/tickets.html');
const autoImportNgTemplateLoaderTemplate71 = require('../html/lms/configureTicketSubType.html');
const autoImportNgTemplateLoaderTemplate72 = require('../html/lms/configureTicketTypeAndPermission.html');
const autoImportNgTemplateLoaderTemplate73 = require('../html/admin/addBulkTicket.html');
const autoImportNgTemplateLoaderTemplate74 = require('../html/admin/addBulkPortfolio.html');
const autoImportNgTemplateLoaderTemplate75 = require('../html/adminConfigs.html');
const autoImportNgTemplateLoaderTemplate76 = require('../html/admin/addBulkDocumentTypes.html');
const autoImportNgTemplateLoaderTemplate77 = require('../html/admin/triggers/setTriggers.html');
const autoImportNgTemplateLoaderTemplate78 = require('../html/admin/triggers/logTriggers.html');
const autoImportNgTemplateLoaderTemplate79 = require('../html/admin/uploadFlowCron.html');
const autoImportNgTemplateLoaderTemplate80 = require('../html/admin/configureCron.html');
const autoImportNgTemplateLoaderTemplate81 = require('../html/admin/addBulkProductMetadata.html');
const autoImportNgTemplateLoaderTemplate82 = require('../html/admin/lbJobroleConfig.html');
const autoImportNgTemplateLoaderTemplate83 = require('../html/admin/auditLogs.html');
const autoImportNgTemplateLoaderTemplate84 = require('../html/admin/productConfiguration.html');
const autoImportNgTemplateLoaderTemplate85 = require('../html/admin/addDeepDiveConfig.html');
const autoImportNgTemplateLoaderTemplate86 = require('../html/admin/addBulkProductColourConfig.html');
const autoImportNgTemplateLoaderTemplate87 = require('../html/admin/notification.html');
const autoImportNgTemplateLoaderTemplate88 = require('../html/admin/addIP.html');
const autoImportNgTemplateLoaderTemplate89 = require('../html/admin/addModules.html');
const autoImportNgTemplateLoaderTemplate90 = require('../html/admin/pushNotifications.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('lms', {
                url: '/lms',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'LmsCtrl'
            })
            .state('lms.adminDashboard', {
                url: '/adminDashboard',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'adminDashboardCtrl'
            })
            .state('lms.addBulkIndividualTarget', {
                url: '/addBulkIndividualTarget',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: 'addBulkMetrics'
            })
            .state('lms.queryUtility', {
                url: '/queryUtility',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: 'queryUtilityCtrl'
            })
            .state('lms.addManagerDashboardConfig', {
                url: '/addManagerDashboardConfig',
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller: 'addManagerDashboardConfigCtrl'
            })
            // todo: investigate
            // .state('lms.addBulkIndividualSubmission', {
            //     url: '/addBulkIndividualSubmission',
            //     templateUrl: '../html/admin/addBulkIndividualSubmission.html',
            //     controller:'addBulkIndividualSubmissionController'
            // })
            .state('lms.addBulkIndividualMTD', {
                url: '/addBulkIndividualMTD',
                templateUrl: autoImportNgTemplateLoaderTemplate6,
                controller: 'addBulkMetrics'
            })
            .state('lms.addBulkIndividualIncentive', {
                url: '/addBulkIndividualIncentive',
                templateUrl: autoImportNgTemplateLoaderTemplate7,
                controller: 'addBulkIndividualIncentiveController'
            })
            .state('lms.addBulkLead', {
                url: '/addBulkLead',
                templateUrl: autoImportNgTemplateLoaderTemplate8,
                controller: 'addBulkLeadController'
            })
            .state('lms.addBulkProspectUser', {
                url: '/addBulkProspectUser',
                templateUrl: autoImportNgTemplateLoaderTemplate9,
                controller: 'addBulkProspectUserController'
            })
            .state('lms.genericUpload', {
                url: '/genericUpload:externalApi',
                templateUrl: autoImportNgTemplateLoaderTemplate10,
                controller: 'genericUploadController',
                params: {externalApi : $stateProvider.externalApi}
            })
            .state('lms.addBulkCustomer', {
                url: '/addBulkCustomer',
                templateUrl: autoImportNgTemplateLoaderTemplate11,
                controller: 'addBulkCustomerController'
            })
            .state('lms.configureLeadsSubProduct', {
                url: '/configureLeadsSubProduct',
                templateUrl: autoImportNgTemplateLoaderTemplate12,
                controller: 'configureLeadsSubProduct'
            })
            .state('lms.configureLeadSubType', {
                url: '/configureLeadSubType',
                templateUrl: autoImportNgTemplateLoaderTemplate13,
                controller: 'configureLeadSubType'
            })
            .state('lms.addLeadsSubmissionStatus', {
                url: '/addBulkLeadsSubmissionStatus',
                templateUrl: autoImportNgTemplateLoaderTemplate14,
                controller: 'addBulkLeadsSubmissionStatusController'
            })
            .state('lms.addLostLeads', {
                url: '/addLostLeads',
                templateUrl: autoImportNgTemplateLoaderTemplate15,
                controller: 'addLostLeadsController'
            })
            .state('lms.exportLeads', {
                url: '/exportLeads',
                templateUrl: autoImportNgTemplateLoaderTemplate16,
                controller: 'exportLeadsController'
            })
            .state('lms.exportProspectUsers', {
                url: '/exportProspects',
                templateUrl: autoImportNgTemplateLoaderTemplate17,
                controller: 'adminExportProspectsController'
            })
            .state('lms.listIndividualTarget', {
                url: '/listIndividualTarget',
                templateUrl: autoImportNgTemplateLoaderTemplate18,
                controller: 'listIndividualTargetController'
            })
            .state('lms.listLead', {
                url: '/listLead/:status/:dateField/:source/:productId/:userId/:leadId/:startDate/:endDate/:kpiId/:lostReassign/:systemSource/:fromHB/:quickMenu/:tabName',
                templateUrl: autoImportNgTemplateLoaderTemplate19,
                controller: "listLeadCtrl",
                params : {
                    user : null,
                    customerIds: null,
                    stageCode : null,
                    pipelineParam : null,
                    campaignCode:null,
                    workflowIds:null,
                    kpiId: null,
                    productId: null,
                    externalParams: {}
                },
                resolve:{
                    p1Data: function(p1){
                        return p1.fetch();
                    }
                }
            })
            .state('lms.listProspect', {
                url: '/listProspect',
                templateUrl: autoImportNgTemplateLoaderTemplate20,
                controller: "listProspectCtrl",
                resolve:{
                    p1Data: function(p1){
                        return p1.fetch();
                    }
                }
            })
            // .state('lms.quickView', {
            //     url: "/quickView",
            //     templateUrl: "../html/lms/lmsQuickView.html",
            //     controller: "quickViewCtrl"
            // })

            .state('lms.heatmap', {
                url: '/heatmap/:userId/:selectedAction/:kpiId/:cycleId',
                templateUrl: autoImportNgTemplateLoaderTemplate21,
                controller: 'heatmapCtrl',
                resolve:{
                    p1Data: function(p1){
                        return p1.fetch();
                    }
                }
            })
            .state('lms.customers', {
                url: '/customers/:userId',
                templateUrl: autoImportNgTemplateLoaderTemplate22,
                controller: 'listCustomersCtrl',
                params : {
                    externalParams: {}
                }
            })
            .state('lms.huddleBoard', {
                url: '/huddleBoard/:kpiId',
                templateUrl: autoImportNgTemplateLoaderTemplate23,
                controller: 'huddleBoardController',
                resolve:{
                    p1Data: function(p1){
                        return p1.fetch();
                    }
                }
            })
            .state('lms.lmsBranchReport', {
                url: '/lmsBranchReport',
                templateUrl: autoImportNgTemplateLoaderTemplate24,
                controller: 'lmsBranchReportController'
            })
            .state('lms.contacts',{
                url:'/contacts/contacts',
                templateUrl:autoImportNgTemplateLoaderTemplate25,
                controller: 'contactsCtrl'
            })
            .state('lms.addBulkDivisions',{
                url:'/addBulkDivisions',
                templateUrl: autoImportNgTemplateLoaderTemplate26,
                controller: 'addBulkDivisionsCtrl'
            })
            .state('lms.addBulkRewards',{
                url:'/addBulkRewards',
                templateUrl: autoImportNgTemplateLoaderTemplate27,
                controller: 'addBulkRewardsCtrl'
            })
            .state('lms.addBulkStrings',{
                url:'/uploadStrings',
                templateUrl: autoImportNgTemplateLoaderTemplate28,
                controller: 'addBulkStringsCtrl'
            })
            .state('lms.listRedemptions',{
                url:'/listRedemptions',
                templateUrl: autoImportNgTemplateLoaderTemplate29,
                controller: 'listRedemptionsCtrl'
            })
            .state('lms.autoUploadScheduler',{
                url:'/autoUploadScheduler',
                templateUrl: autoImportNgTemplateLoaderTemplate30,
                controller: 'autoUploadSchedulerCtrl'
            })
            .state('lms.autoUploadLogs',{
                url:'/autoUploadLogs',
                templateUrl: autoImportNgTemplateLoaderTemplate31,
                controller: 'autoUploadLogsCtrl'
            })
            // .state('lms.badge', {
            //     url: '/badge',
            //     templateUrl: '../html/admin/badge/badge.html',
            //     controller: function(){}
            // })
            .state('lms.listBadge',{
                url: '/listBadge',
                templateUrl: autoImportNgTemplateLoaderTemplate32,
                controller: function($scope,$http){
                    $http.post('/api/badges/index').then(function(badges){
                        badges=badges.data;
                        $scope.badges = badges;
                        //socket.syncUpdates('badge', $scope.badges);
                    });
                }
            })
            .state('lms.addBadge',{
                url: '/addBadge/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate33,
                controller: function($scope,$http,Auth,$location, $stateParams, toaster, p0){
                    $scope.getCurrentUser = Auth.getCurrentUser;
                    $scope.badgeCategories = ["overall","business","activity","level","conversion","productivity"];
                    $scope.badgePeriods = ["cyclic","rolling"];
                    $scope.badgeMetrics = ["top1","topDecile","topQuartile"];
                    $scope.badgeTypes = ["level","overall","fastest-level","productivity","activity","kpi","overallBusiness","overallActivity","total","level","overall","productivity"];
                    $scope.gMasters = p0.allKpis;
                    if($stateParams.id) {
                        $http.post('/api/badges/show',{id:$stateParams.id}).then(function (badge) {
                            badge=badge.data;
                            $scope.badge = badge;
                        });
                    }
                    $scope.addBadge = function() {
                        if($scope.team === '') {
                            return;
                        }
                        $http.post('/api/badges', { name: $scope.newBadge}).then(function() {
                            // this callback will be called asynchronously
                            // when the response is available
                            $location.path('/badge/listBadge');
                            toaster.pop('success', "Badge Creation", "Badge created successfully.");
                        }).catch(function() {
                            // called asynchronously if an error occurs
                            // or server returns response with an error status.
                        });
                        $scope.newTeam = '';
                    };
                }
            })
            .state('lms.addBulkBadge',{
                url: '/addBulkBadge',
                templateUrl: autoImportNgTemplateLoaderTemplate34,
                controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "badges";
                    $scope.onFileSelectBadge = function () {
                        if ($scope.bulkBadge == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");
                        } else {
                            var fileName = $scope.bulkBadge[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/upload',
                                    data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadBadges',invocationMethod:"manual"},
                                    file: $scope.bulkBadge[0],
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Badge creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }
                        }
                    }
                }
            })
            .state('lms.cycle', {
                url: '/cycle',
                templateUrl: autoImportNgTemplateLoaderTemplate35,
                controller: 'CycleCtrl'
            })
            .state('lms.listCycle', {
                url: '/listCycle',
                templateUrl: autoImportNgTemplateLoaderTemplate36,
                controller: 'listCycleCtrl'
            })
            .state('lms.addCycle', {
                url: '/addCycle/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate37,
                controller: function ($http, $scope,  Auth, $location, $q, $stateParams, toaster) {
                    $scope.editForm = false;
                    $scope.activeList = [true, false];
                    $scope.currentCycle = {};
                    if($stateParams.id){
                        $scope.editForm = true;
                        $http.post('/api/cycles/show',{id:$stateParams.id}).then(function (cycle) {
                            cycle=cycle.data;
                            $scope.currentCycle = cycle;
                            $scope.populateCycles(cycle.cycleType);
                        });
                    }
                    $scope.cycleList = ["monthly","weekly","campaign"];
                    $scope.populateCycles = function (type) {
                        $http.post('/api/cycles/cycleType',{cycleType: type}).then(function (cycles) {
                            cycles=cycles.data;
                            $scope.prevCyclesList = cycles;
                        });
                    }
                    $scope.addCycle = function () {
                        var name = $("#cycle_name").val();
                        var startDate = $("#cycle-start-date-js").val();
                        var endDate = $("#cycle-end-date-js").val();
                        var cycleType = $("#cycle_type").val();

                        var startDateYear = startDate.split("-")[2];
                        var startDateMonth = startDate.split("-")[1];
                        var startDateDate = startDate.split("-")[0];

                        var endDateYear = endDate.split("-")[2];
                        var endDateMonth = endDate.split("-")[1];
                        var endDateDate = endDate.split("-")[0];

                        var formattedStartDate = new Date(startDateYear, (startDateMonth-1), startDateDate);
                        var formattedEndDate = new Date(endDateYear, (endDateMonth-1), endDateDate);


                        $http.post('/api/cycles', {
                            name: name,
                            startDate: formattedStartDate,
                            endDate: formattedEndDate,
                            cycleType: cycleType
                        }).then(function (responce) {
                            $location.path('/cycle/listCycle');
                            toaster.pop('success', "Cycle Creation", "New Cycle Added.");
                        }).catch(function (err) {

                        });
                    }
                }
            })
            .state('lms.addBulkCycle',{
                url: '/addBulkCycle',
                templateUrl: autoImportNgTemplateLoaderTemplate38,
                controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "cycles";
                    $scope.onFileSelectCycle = function () {
                        if ($scope.bulkCycle == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");

                        } else {
                            var fileName = $scope.bulkCycle[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/upload',
                                    data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadCycles',invocationMethod:"manual"},
                                    file: $scope.bulkCycle[0],
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    //$location.path('/cycle');
                                    toaster.pop('success', "Excel uploaded", "Cycle creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }

                        }
                    }

                }
            })
            .state('lms.kpi', {
                url: '/kpi',
                templateUrl: autoImportNgTemplateLoaderTemplate39,
                controller: 'KpiCtrl'
            })
            .state('lms.listKpi', {
                url: '/listKpi',
                templateUrl: autoImportNgTemplateLoaderTemplate40,
                controller: function($scope, $http, Auth, p0) {
                    $scope.currentUser = Auth.getCurrentUser();
                    $scope.products = p0.allKpis;
                }
            })
            .state('lms.editKpi', {
                url: '/editKpi/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate41,
                controller: function($scope, $http, Auth, $stateParams, $location, toaster, p0) {
                    $scope.currentUser = Auth.getCurrentUser();
                    $scope.editForm = false;
                    $scope.kpiTypePercentage = false;
                    $scope.blackPointOptions = [true, false];
                    $scope.kpiTypes = ['value', 'percentage'];
                    $scope.units = [{
                        name: "AED"
                    }, {
                        name: "#"
                    }]
                    $scope.product = {};
                    $scope.product.stages = [];
                    $scope.product.kpiWeightage = [];
                    $scope.product.pointsMultipliers = [];
                    $scope.add = function(array) {
                        array.push({});
                    }
                    $scope.remove = function(index, array) {
                        array.splice(index, 1);
                    };
                    $scope.fn = function () {
                        $scope.product.kpiType = $scope.kpiTypePercentage ? 'percentage' : 'value';
                        console.log($scope.product);
                    }
                    if($stateParams.id){
                        $scope.editForm = true;
                        $scope.product = p0.allKpis;
                        $scope.kpiTypePercentage = product.kpiType == 'percentage';
                    }
                    $http.post('/api/jobRoles').then(function (jobroles) {
                        jobroles=jobroles.data;
                        $scope.jobRoles = jobroles;
                    });
                    $scope.updateProduct = function() {
                        $scope.product.kpiWeightage.forEach(function (kpiWeight, index) {
                            $scope.product.kpiWeightage[index].role = kpiWeight.role._id
                        });
                        $scope.product.pointsMultipliers.forEach(function (ptsMultiplier, index) {
                            $scope.product.pointsMultipliers[index].role = ptsMultiplier.role._id
                        });
                        $http.post('/api/products/productForm', $scope.product).then(function(product) {
                            product=product.data;
                            $location.path('/listProduct');
                            toaster.pop('success', "Product Update", "Product Details Updated.");
                        });
                    }
                }
            })
            .state('lms.showKpi', {
                url: '/showKpi/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate42,
                controller: function($scope, $http, Auth, $stateParams) {
                    $scope.currentUser = Auth.getCurrentUser();

                    $http.post('/api/kpis/show',{id: $stateParams.id} ).then(function(product) {
                        product=product.data;
                        $scope.product = product;
                    });
                }
            })
            .state('lms.addBulkWeightage',{
                url: '/addBulkWeightage',
                templateUrl: autoImportNgTemplateLoaderTemplate43,
                controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "kpiWeightage";
                    $scope.onFileSelectWeightage = function () {
                        console.log('is click')
                        if ($scope.bulkWeightage == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");
                        } else {
                            var fileName = $scope.bulkWeightage[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/upload',
                                    data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadKpiWeightages',
                                    invocationMethod:"manual"},
                                    file: $scope.bulkWeightage[0],
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Weightage creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }
                        }
                    }
                }
            })
            .state('lms.addBulkKpiPointMultipliers',{
                url: '/addBulkKpiPointMultipliers',
                templateUrl: autoImportNgTemplateLoaderTemplate44,

                controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                    $scope.fileType = "kpiPointsMultiplier";
                    $scope.onFileSelectPointMultiplier = function () {
                        console.log('is click')
                        if ($scope.bulkPoints == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");
                        } else {
                            var fileName = $scope.bulkPoints[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/upload',
                                    data: {userId: Auth.getCurrentUser()._id,cronTag:'uploadKpiPoints',
                                    invocationMethod:"manual"},
                                    file: $scope.bulkPoints[0],
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Weightage creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }
                        }
                    }
                }
            })
            .state('lms.addProduct', {
                url: '/product',
                templateUrl: autoImportNgTemplateLoaderTemplate45,
                controller: 'addBulkProductCtrl'
            })
            .state('lms.bulkExcelUpload', {
                url: '/bulkExcelUpload/:excel',
                templateUrl: autoImportNgTemplateLoaderTemplate46,
                controller: 'bulkExcelUploadCtrl'
            })
            .state('lms.listActivitySchema', {
                url: '/listActivitySchema',
                templateUrl: autoImportNgTemplateLoaderTemplate47,
                controller: 'listActivitySchemaCtrl'
            })
            .state('lms.addActivity', {
                url: '/addActivity/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate48,
                controller: 'addActivityCtrl'
            })
            .state('lms.addBulkActivity',{
                url: '/addBulkActivity',
                templateUrl: autoImportNgTemplateLoaderTemplate49,
                controller:'addBulkActivityCtrl'
            })
            .state('lms.addGlossaryFile',{
                url: '/addGlossaryFile',
                templateUrl: autoImportNgTemplateLoaderTemplate50,
                controller:'addGlossaryCtrl'
            })
            .state('lms.addExcelHeadersFile',{
                url: '/addExcelHeadersFile',
                templateUrl: autoImportNgTemplateLoaderTemplate51,
                controller:'addExcelHeaderCtrl'
            })
            .state('lms.addL3Products',{
                url: '/addL3Products',
                templateUrl: autoImportNgTemplateLoaderTemplate52,
                controller:'addL3ProductsCtrl'
            })
            .state('lms.addACLFile',{
                url: '/addACLFile',
                templateUrl: autoImportNgTemplateLoaderTemplate53,
                controller:'addACLCtrl'
            })
            .state('lms.addBulkMTDActivity',{
                url: '/addBulkMTDActivity',
                templateUrl: autoImportNgTemplateLoaderTemplate54,
                controller: 'addBulkMTDActivityController'
            })
            .state('lms.addBulkActivityPointMultipliers',{
                url: '/addBulkActivityPointMultipliers',
                templateUrl: autoImportNgTemplateLoaderTemplate55,
                controller:'addBulkActivityPointMultipliersCtrl'
            })
            .state('lms.addBulkActivityWeightages',{
                url: '/addBulkActivityWeightages',
                templateUrl: autoImportNgTemplateLoaderTemplate56,
                controller:'addBulkActivityWeightagesCtrl'
            })
            .state('lms.kpiMix',{
                url:'/kpiMix',
                templateUrl:autoImportNgTemplateLoaderTemplate57,
                controller:'kpiMixCtrl'
            })
            .state('lms.quote',{
                url:'/quote',
                templateUrl:autoImportNgTemplateLoaderTemplate58,
                controller:'quoteCtrl'
            })
            .state('lms.addBulkSecurityQuestions',{
                url:'/addBulkSecurityQuestions',
                templateUrl:autoImportNgTemplateLoaderTemplate59,
                controller:'addBulkSecurityQuestionsCtrl'
            })
            .state('lms.addBulkIncentives',{
                url:'/addBulkIncentives',
                templateUrl:autoImportNgTemplateLoaderTemplate60,
                controller:'addBulkIncentivesCtrl'
            })
            .state('lms.commandLine',{
                url:'/cmd',
                templateUrl:autoImportNgTemplateLoaderTemplate61,
                controller:'commandLineCtrl'
            })
            .state('lms.listConfig',{
                url:'/configs',
                templateUrl:autoImportNgTemplateLoaderTemplate62,
                controller:'listConfigCtrl'
            })
            .state('lms.manageConfig',{
                url:'/manageConfigs',
                templateUrl:autoImportNgTemplateLoaderTemplate63,
                controller:'editConfigCtrl'
            })
            .state('lms.addBulkConfig',{
                url:'/addBulkConfigs',
                templateUrl:autoImportNgTemplateLoaderTemplate64,
                controller:'bulkConfigCtrl'
            })
            .state('lms.adminReports',{
                url:'/reports',
                templateUrl: autoImportNgTemplateLoaderTemplate65,
                controller:function ($http, $scope, p0, toaster) {
                    $scope.allCycles= _.filter(p0.allAdminCycles,function(cycle){
                      return new Date(cycle.endDate) <= new Date();
                    });
                    $scope.exportReport = function () {
                        $scope.exporting = true;
                        var cycleId = $scope.selectedCycle._id;
                        $http.post('/api/adminReports/teamwiseReport',{cycleId}).then(function(result) {
                            result=result.data;
                            $scope.exporting = false;
                            if(result) toaster.pop('success','Report exported',"Report exported to uploads folder");
                            else toaster.pop('error','Error',"Error in exporting the report");
                        });
                    }
                }
            })
            .state('lms.addBulkContest',{
                url:'/addBulkContest',
                templateUrl:autoImportNgTemplateLoaderTemplate66,
                controller:'addBulkContestCtrl'
            })
            .state('lms.addBulkReward',{
                url:'/addBulkReward',
                templateUrl:autoImportNgTemplateLoaderTemplate67,
                controller:'addBulkRewardCtrl'
            })
            .state('lms.triggerCrons',{
                url:'/triggerCrons',
                templateUrl:autoImportNgTemplateLoaderTemplate68,
                controller:'triggerCronsCtrl'
            })
            .state('lms.addBulkErrorWorkItems',{
                url: '/addBulkErrorWorkItems',
                templateUrl: autoImportNgTemplateLoaderTemplate69,
                controller:'addBulkErrorWorkItemsCtrl'
            })
            .state('lms.tickets',{
                url:'/tickets/:userId/:tab/:state/:startDate/:endDate/:type/:viewCategory',
                templateUrl:autoImportNgTemplateLoaderTemplate70,
                controller:'ticketsCtrl',
                params:{
                    workflowIds:null
                },
                resolve:{
                    p1Data: function(p1){
                        return p1.fetch();
                    }
                }
            })
            .state('lms.configureTicketSubType', {
                url: '/configureTicketSubType',
                templateUrl: autoImportNgTemplateLoaderTemplate71,
                controller: 'configureTicketSubType'
            })
            .state('lms.configureTicketTypeAndPermission', {
                url: '/configureTicketTypeAndPermission',
                templateUrl: autoImportNgTemplateLoaderTemplate72,
                controller: 'configureTicketTypeAndPermission'
            })
            .state('lms.addBulkTicket', {
                url: '/addBulkTicket',
                templateUrl: autoImportNgTemplateLoaderTemplate73,
                controller: 'addBulkTicketController'
            })
            .state('lms.addBulkPortfolio', {
                url: '/addBulkPortfolio',
                templateUrl: autoImportNgTemplateLoaderTemplate74,
                controller: 'addBulkPortfolioController'
            })
            .state('lms.adminConfig', {
                url: '/adminConfigs',
                templateUrl: autoImportNgTemplateLoaderTemplate75,
                controller: 'adminConfigController'
            })
            .state('lms.doucmentTypeUpload', {
                url: '/lms/document/types',
                templateUrl: autoImportNgTemplateLoaderTemplate76,
                controller: 'documentTypesController'
            }).state('lms.setTriggers', {
                url: '/setTriggers',
                templateUrl: autoImportNgTemplateLoaderTemplate77,
                controller: 'setTriggerController'
            })
            .state('lms.logTriggers', {
                url: '/logTriggers',
                templateUrl: autoImportNgTemplateLoaderTemplate78,
                controller: 'logTriggerController'
            })
            .state('lms.uploadFlowCron', {
                    url: '/uploadFlowCron',
                    templateUrl: autoImportNgTemplateLoaderTemplate79,
                    controller: 'uploadFlowCronCtrl'
            })
            .state('lms.configureCron', {
                url: '/configureCron',
                templateUrl: autoImportNgTemplateLoaderTemplate80,
                controller: 'configureCronCtrl'
            })
            .state('lms.addBulkProductMetadata', {
                url: '/lms/product/metadata',
                templateUrl: autoImportNgTemplateLoaderTemplate81,
                controller: 'addBulkProductMetadata'
            })
            .state('lms.lbJobroleConfig', {
                url: '/lbJobroleConfig',
                templateUrl: autoImportNgTemplateLoaderTemplate82,
                controller: 'lbJobroleConfigCtrl'
            })
            .state('lms.auditLogs', {
                url: '/auditLogs',
                templateUrl: autoImportNgTemplateLoaderTemplate83,
                controller: 'auditLogsCtrl'
            })
            .state('lms.productConfiguration', {
                url: '/productConfiguration',
                templateUrl: autoImportNgTemplateLoaderTemplate84,
                controller: 'productConfigurationCtrl'
            })
           .state('lms.addBulkDeepDive', {
                url: '/deepDiveConfig',
                templateUrl: autoImportNgTemplateLoaderTemplate85,
                controller: 'addDeepDiveConfig'
            })
            .state('lms.addBulkProductColourConfig', {
                url: '/lms/product/colourConfig',
                templateUrl: autoImportNgTemplateLoaderTemplate86,
                controller: 'addBulkProductColourConfig'
            })
            .state('lms.notificationConfig', {
                url: '/lms.notification/config',
                templateUrl: autoImportNgTemplateLoaderTemplate87,
                controller: 'NotificationCtrl'
            })
            .state('lms.addIP', {
                url: '/lms/ip/add',
                templateUrl: autoImportNgTemplateLoaderTemplate88,
                controller: 'addIPCtrl'
            })
            .state('lms.addModule', {
                url: '/lms/modules/add',
                templateUrl: autoImportNgTemplateLoaderTemplate89,
                controller: 'addModuleCtrl'
            })
            .state('lms.pushNotifications',{
                url:'/lms/push-notifications',
                templateUrl:autoImportNgTemplateLoaderTemplate90,
                controller:'PushNotificationsCtrl'
            })
    });
