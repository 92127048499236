'use strict';
angular.module('bitnudgeApp')
    .controller('userSummaryModalCtrl', function ($scope, $rootScope, data, requestData, $mdDialog, toaster, Upload) {
        $scope.data = data;
        $scope.onClose = function(){
            $mdDialog.hide();
        };
        $scope.continue = function() {
            Upload.upload({
                url: '/api/users/import',
                data: requestData,
                progress: function (e) {
                }
            }).then(function (data, status, headers, config) {
                $mdDialog.hide();
                toaster.pop('success', "Bulk Employee Creation.", "Bulk Employees Created.");
            });
        }
    });
