'use strict';
angular.module('bitnudgeApp')
    .controller('playerListModalCtrl', function ($scope, $rootScope, p0, data, type, title, roleToggle, $mdDialog, $timeout) {
        $scope.list = [];
        $scope.title = title;
        $scope.type = type;
        $scope.strings = $rootScope.strings;
        var roleType;
        $scope.roleToggle = roleToggle;
        if(roleToggle){
            roleType = 'frontline';
            $scope.roleCount = _.countBy(data,'roleType');
        }
        var allDivisions = p0.allDivisions;
        var setBadgesArrayFromUsersList = function () {
            var filteredData = [];
            if(roleToggle){
                $scope.list = [];
                filteredData = _.filter(data, {roleType : roleType});
            }else{
                filteredData = data;
            }
            _.forEach(filteredData, function (user) {
                var badge = _.find($scope.list, {_id:user.badge._id});
                if(badge){
                    badge.users.push(user);
                }else{
                    user.badge.users = [];
                    user.badge.users.push(user);
                    $scope.list.push(user.badge);
                }
            });
            $timeout(function () {
                $scope.showSlick = true;
            },100);

        };


        var setKpiArrayFromUsersList = function () {
            var filteredData = [];
            if(roleToggle){
                $scope.list = [];
                filteredData = _.filter(data, {roleType : roleType});
            }else{
                filteredData = data;
            }
            _.forEach(filteredData, function (user) {
                _.forEach(user.divisions, function (myDivision) {
                   var division = _.find($scope.list,{_id:myDivision});
                    if(division)
                        division.users.push(user);
                    else{
                        var currentDivision = _.find(allDivisions,{_id:myDivision});
                        if(currentDivision){
                            var division = {users:[]};
                            division.name = currentDivision.name;
                            division.id = myDivision;
                            division.users.push(user);
                            $scope.list.push(division);
                        }
                    }
                });
            });
            $scope.showSlick = false;
            $timeout(function () {
                $scope.showSlick = true;
            },100);
        };
        if(type == 'badge'){
            setBadgesArrayFromUsersList();
        }else{
            setKpiArrayFromUsersList();
        }
        $scope.onClose = function() {
            $mdDialog.hide();
        };
        $scope.managerEnabled = false;
        $scope.switchChanged = function(changed){
            $scope.showSlick = false;
            $scope.managerEnabled = changed;
            if($scope.managerEnabled)
                roleType = 'manager';
            else
                roleType = 'frontline';
            if(type == 'badge')
                setBadgesArrayFromUsersList();
            else
                setKpiArrayFromUsersList();
        }
    });
