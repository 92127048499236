const autoImportNgTemplateLoaderTemplate1 = require('../html/prospectUserActivityTable.html');

'use strict';
angular.module('bitnudgeApp')
    .component('prospectUserActivityTable',{
        bindings: {
            activities: '<',
            pagination: '<',
            apiLoaded: '<',
            previousPage: '&',
            nextPage: '&',
            fetchData: '&',
            sortingObj: '<',
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function($scope, p0){
            $scope.strings = p0.strings;
            $scope.activities = [];
            this.$onChanges = (changes) => {
                if (changes.activities) {
                    $scope.activities = changes.activities.currentValue;
                }
                if (changes.apiLoaded) {
                    $scope.apiLoaded = changes.apiLoaded.currentValue;
                }
                if (changes.pagination) {
                    $scope.pagination = changes.pagination.currentValue;
                }
                if (changes.previousPage) {
                    $scope.previousPage = changes.previousPage.currentValue;
                }
                if (changes.nextPage) {
                    $scope.nextPage = changes.nextPage.currentValue;
                }
                if(changes.sortingObj){
                    $scope.sortingObj = changes.sortingObj.currentValue
                }
                if($scope.activities && $scope.activities.length){
                    $scope.activities.forEach(element => {
                        element.content = decodeURIComponent((element.content).replace(/\&#x2F;/g, '/'));
                    });
                }
            };
            $scope.onHeaderClick = (sortKey) => {
                $scope.sortingObj.sortKey = sortKey;
                if ($scope.sortingObj.sortOrder === 'asc') {
                    $scope.sortingObj.sortOrder = 'desc';
                } else {
                    $scope.sortingObj.sortOrder = 'asc';
                }
                this.fetchData()
            }
        }
    });
