'use strict';

angular.module('bitnudgeApp').controller('addFollowUpCtrl', function (Auth, $scope, leadsService, lead, $mdDialog, interactionTypes, isNextFollowUp, interaction, reloadLeadInfoPage, toaster) {
	const me = Auth.getCurrentUser();
	$scope.lead = lead;
	$scope.isNextFollowUp = isNextFollowUp;

	const header = isNextFollowUp ? 'Schedule Follow Up' : 'Add/Update Interaction';
	if (isNextFollowUp) {
		$scope.minDate = new Date()
	} else {
		$scope.maxDate = new Date();
	}
	$scope.interactionTypes = interactionTypes;
	$scope.selected = {};
	$scope.durationOptions = [5, 10, 15, 20, 25, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300];

	const init = () => {

		if(interaction){
			$scope.selected.interactionType = $scope.interactionTypes.find(i=> i.internalId === interaction.type);
		}
		if(!$scope.selected.interactionType){
			$scope.selected.interactionType = $scope.interactionTypes[0];
		}
		$scope.interactionOutputs = $scope.selected.interactionType.outcome;
		$scope.selected.modes = $scope.selected.interactionType.modes;

		$scope.selected.interactionOutput = $scope.interactionOutputs[0];
		$scope.selected.interactionMode = $scope.selected.interactionType.modes.length ? $scope.selected.interactionType.modes[0] : null;

		$scope.selected.duration = $scope.durationOptions[0];
	}

	$scope.onInteractionChange = (interaction) => {
		$scope.selected.interactionType = interaction;
		$scope.interactionOutputs = interaction.outcome;
		$scope.selected.modes = interaction.modes;
		$scope.selected.interactionMode = interaction.modes.length ? interaction.modes[0] : null;
		$scope.selected.interactionOutput = $scope.interactionOutputs[0];
	}

	$scope.onDateChange = (newDate, oldDate) => {
		const today = moment(new Date()).format('DD-MM-YYYY')
		const day = moment(new Date(newDate)).format('DD-MM-YYYY');
		$scope.dayStart = new Date(new Date(newDate).setHours(0, 0, 0, 0));
		$scope.dayEnd = new Date(new Date(newDate).setHours(23, 59, 59, 999));
		if (today === day) {
			if (isNextFollowUp) {
				$scope.dayStart = new Date();
			} else {
				$scope.dayEnd = new Date();
			}
		}
	}

	$scope.onClose = function () {
		$mdDialog.hide();
	};

	const getStartTime = () => {
        let dString = $scope.selected.interactionDate;
        const ds = dString.split('/');
        const d = ds[0];
        const m = ds[1];
        const y = ds[2];
        let date = new Date(`${m}/${d}/${y}`);

        const time = $scope.selected.interactionStartTime.split(':');
        const hours = parseInt(time[0]);
        const minutes = parseInt(time[1]);

		const startTime = new Date(moment(new Date(date))
			.add(hours, 'h')
            .add(minutes, 'm')
            .add(0, 's')
            .add(0, 'ms')
            .toDate());

        return startTime;
	}

	const getEndTime = () => {
		const start = getStartTime();
		let minutes = 0;
		if ($scope.selected.otherDuration) {
			minutes = $scope.selected.otherDuration;
		} else {
			minutes = $scope.selected.duration;
		}
        const endTime = new Date(moment(start)
        .add(minutes, 'm')
        .add(59, 's')
        .add(999, 'ms')
        .toDate());

        return endTime;
	}

	$scope.onSubmit = () => {
        const {interactionDate, interactionStartTime} = $scope.selected;
        if(!interactionDate || !interactionStartTime){
            return toaster.pop('warning',"Date & Time", 'Date & Time is required.');
        }
		const data = {
			attendees: [],
			entityId: lead._id,
			workflowId: lead.workflowId,
			userId: me._id,
			prerequisites: [],
			existingInteractionId: !isNextFollowUp ? (interaction && interaction._id) : null,
			futureInteractionId: isNextFollowUp ? (interaction && interaction._id) : null,
		};
		const prerequisites = [{
			name: 'category',
			value: 'lead'
		}];

		if (isNextFollowUp) {
			prerequisites.push({
				name: 'followupType',
				value: $scope.selected.interactionType.internalId,
			});
			prerequisites.push({
				name: 'followUp',
				value: getStartTime(),
			});
			prerequisites.push({
				name: 'followUpEnd',
				value: getEndTime(),
			});
		} else {
			prerequisites.push({
				name: 'interaction',
				value: $scope.selected.interactionType.internalId,
			});
			prerequisites.push({
				name: 'interactionOutput',
				value: $scope.selected.interactionOutput.internalId,
			});
			prerequisites.push({
				name: 'completedAt',
				value: getStartTime(),
			});
			prerequisites.push({
				name: 'completionEndTime',
				value: getEndTime(),
			});
			if($scope.selected.notes){
				prerequisites.push({
					name: 'notes',
					value: $scope.selected.notes,
				});
			}
			if($scope.selected.interactionMode){
				prerequisites.push({
					name:'interactionMode',
					value: $scope.selected.interactionMode.internalId
				})
			}
		}
		data.prerequisites = prerequisites;
		leadsService.saveInteraction(data).then((result) => {
			const { data } = result;
			if (data.status) {
				toaster.pop('success', header, `${header} is successful.`);
				reloadLeadInfoPage();
				$scope.onClose();
			} else if (data.errors && data.errors.length) {
				data.errors.forEach(e => {
					toaster.pop('error', header, e.message);
				})
			}
		}).catch(function (err) {
			toaster.pop('Error', err.message)
		});
    }
    
	init();
})
