const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/attachmentComponent.html');

'use strict';
angular.module('bitnudgeApp').component('attachmentComponent', {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
		attachments: '<',
		context: '<',
		selectedAttachmentId: '<',
        displayMode: '<'
	},
	controller: 'attachmentComponentCtrl'
});
