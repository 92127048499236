'use strict';
angular.module('bitnudgeApp')
    .service('p1', function (Auth,$http,$q) {

        var _userMap = {};
        var _userMapFetched = true;
        var _current = this;

        var _fetch = function(){
            var defer = $q.defer();
            if(_userMapFetched){
                defer.resolve(_userMap);
            }
            else {
                // TODO: change the way we handle this feature. loading all users is not working here
                // return _getUserHashMap().then(function (map) {
                //     _userMap = map;
                //     _userMapFetched = true;
                //     _current.userData = _userMap;
                //     defer.resolve(_userMap);
                //     //switching this Off for now
                //     // return _getUserLikes().then(function () {
                //     //     return _getUserFollows().then(function(){
                //     //         _userMapFetched = true;
                //     //         _current.userData = _userMap;
                //     //         defer.resolve(_userMap);
                //     //     });
                //     // });
                // });
            }
            return defer.promise;
        };
        var _getUserLikes = function(){
            return $http.post('/api/users/getLikesData').then(function(likes){
                likes=likes.data;
                likes.likesArr.forEach(function(userId){
                   _userMap[userId].liked = true;
                });
                for (var key in likes.likesCount) {
                    _userMap[key].likes = likes.likesCount[key];
                }
                return $q.resolve();
            });
        };
        var _getUserFollows = function(){
            return $http.post('/api/users/getFollowData').then(function(follows){
                follows=follows.data;
                follows.followsArr.forEach(function(userId){
                    _userMap[userId].followed = true;
                });
                for (var key in follows.followsCount) {
                    _userMap[key].followers = follows.followsCount[key];
                }
                return $q.resolve();
            });
        }

        var _getUserHashMap = function(){
            //$http.get('/api/users/getUsersHashMap').then(callback);
            return $http.post('/api/users/getUsersHashMap').then(function(data){
                data=data.data;
                return $q.resolve(data);
            });
        };


        this.fetch = _fetch;

        this.destroy = function(){
            // _userMapFetched = false;

            //removing this as it caused an error on logout on leaderboard page as it was still
            //getting accessed after logout had been pressed and userData had been deleted. - Devdhar Patel(8 Aug 2016)
            // delete(_current.userData);
        }


    });
