'use strict';
angular.module('bitnudgeApp')
    .controller('genericUploadController', function($http, $scope, Auth, Upload, toaster, $mdDialog, $filter, $stateParams, $state) {
        let self=this;
        $scope.fileType = "generic";
        $scope.showNewLog = false;

        //Function to submit generic excel
        $scope.onFileSelect = function() {
            $scope.showNewLog = false;
            if ($scope.genericUpload == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                let isFileNameValid = true;
                $scope.genericUpload.forEach(function (file) {
                    const fileName = $scope.genericUpload[0].name.split(".");
                    const fileType = fileName[fileName.length - 1].toLowerCase();
                    if (fileType != "csv") {isFileNameValid = false;}
                });
                let uploadData
                if (isFileNameValid) {
                    uploadData = {
                        userId: Auth.getCurrentUser()._id,
                    };
                    $scope.genericUpload.forEach(function (file, index) {
                        uploadData['file'] = file;
                    });

                    uploadData.cronTag = 'genericUpload';
                    uploadData.invocationMethod = 'manual'
                    
                    Upload.upload({
                        url: $state.params.externalApi,
                        data: uploadData,
                        progress: function(e) {}
                    }).then(function(result){
                        $scope.showNewLog = true;
                        result=result.data;
                        toaster.pop('success', "Excel uploaded", successMessage);
                    }).catch(function(res){
                        if(res.status === 310) {
                            toaster.pop('warning', "Excel could not be uploaded!!", 'Sorry!! Headers in the excel file are not appropriate!!');                    
                        }
                        $scope.showNewLog = true;
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.csv' file only.");
                }

            }
        };
    })
