const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/docUploadModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/prospectTabInfo.html');

'use strict';

angular.module('bitnudgeApp').controller('listProspectCtrl', function ($scope, $rootScope, $mdDialog, $state, $stateParams, prospectService, Auth, toaster, p0, $filter, $http, $interval) {
    $scope.me = Auth.getCurrentUser();
    $scope.config = p0.config;
    $scope.isFrontline = $rootScope.isFrontline;
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.isSeniorManager = $rootScope.isSeniorManager;
    $scope.isCloneUser = $rootScope.cloned
    $scope.permissionsAvailable = {}
    let PERMISSIONS_STORE = {}
    $rootScope.permissions.forEach( permission => {
        $scope.permissionsAvailable[permission] = true
    })
    PERMISSIONS_STORE = Object.assign({},$scope.permissionsAvailable)
               
    const setPermissions = () => {
        $scope.permissionsAvailable.createProspectUser = PERMISSIONS_STORE.createProspectUser;
}

    prospectService.setCurrentUser($scope.me._id);
    $scope.sortArray = [];
    $scope.pageName = "Prospect Agents";
    $scope.selectedProspectShow = false;
    $scope.ProspectList = [];
    $scope.seniorManagersList = [];
    $scope.filterObj = {};
    $scope.fromHB = $stateParams.fromHB;
    var originalProspectsList = [];
    $scope.filtered = [];
    var filteredLists = [];
    $scope.selectedProspect = {};
    $scope.selected = {
        cycle: {}
    };
    var currMonth = $scope.selected.cycle;
    $scope.openQuickMenu = true;
    var hideQuickMenu = false;
    $scope.filteredCount = 0;
    $scope.apiLoaded = false;
    $scope.dataLoaded = false;
    $scope.sortingObj = {
        sortKey: 'followUpDate',
        sortOrder: 'asc'
    };
    $scope.prospectsFilters = {
        noFilter: false,
        allFilter: true,
        quickFilter: false
    };
    $scope.pagination = {
        offset: 0,
        limit: 50,
        total: 0,
        disableNext: true,
        disablePrevious: true
    };

    let cycle = _.cloneDeep(p0.currentMonthlyCycle)
    $scope.selected.cycle.startDate = cycle.startDate;
    $scope.filterObj.startDate = cycle.startDate;
    $scope.selected.cycle.endDate = cycle.endDate;
    $scope.filterObj.endDate = cycle.endDate;

    var disableAllFilters = function () {
        Object.keys($scope.prospectsFilters).forEach(function (key) {
            $scope.prospectsFilters[key] = false;
        })
    }

    $scope.workflowColorCodes = []
    $scope.workflowStagesCodes = []

    var setExtraInfoOnProspectsList = function (prospects) {
        var ProspectList = JSON.parse(JSON.stringify(prospects));
        $scope.selectedProspect = ProspectList[0];
        $scope.selectedProspectShow = false;
        $scope.ProspectList = prospectService.setExtrasInfo(ProspectList, currMonth)
        $scope.ProspectList.forEach(p => {
            if (!p.userId){
                p.isUnassigned = true;
            }
        })
    };

    $scope.fetchProspects = function (user, pagination) {
        if (!user) {
            user = $scope.selected.user ? $scope.selected.user : $scope.me;
        }
        if (pagination) {
            $scope.pagination = pagination;
        }
        $scope.ProspectList = [];
        originalProspectsList = [];
        filteredLists = [];
        var promise;
        var extraParams = {
            currentMonthlyCycle: {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            }
        };
        if ($scope.selected.cycle != undefined) {
            extraParams.selectedCycle = JSON.parse(JSON.stringify($scope.selected.cycle));
        } else {
            extraParams.selectedCycle = {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            };
        }
        //set the user
        //dont send userId for the case of manager or seniorManager as the API fetches it from the authentication middleware and fethces all its frontlines' prospects
        if ($scope.me.systemRole == 'frontline') {
            extraParams.userId = $scope.me._id;
        } else if (user) {
            extraParams.userId = user._id;
        }
        $scope.filterObj.unassignedView = false;
        if ($scope.isReferralsView) {
            if ($scope.showLostProspects) {
                promise = prospectService.getProspects($scope.filterObj, extraParams, $scope.pagination, 'lost', false, $scope.sortingObj);
            } else {
                promise = prospectService.getProspects($scope.filterObj, extraParams, $scope.pagination, 'referrals', false, $scope.sortingObj);
            }
        } else if($scope.unassignedProspects){
            $scope.filterObj.unassignedView = true;
            promise = prospectService.getProspects($scope.filterObj, extraParams, $scope.pagination, 'unassigned', false, $scope.sortingObj);
        }else {
            promise = prospectService.getProspects($scope.filterObj, extraParams, $scope.pagination, 'myleads', false, $scope.sortingObj);
        }

        //set data loaded as false
        $scope.dataLoaded = false;
        $scope.apiLoaded = false;

        //make the API call
        promise
            .then(function (data) {
                $scope.filterObj.workflowIds = null
                console.log('data: ', data);
				if(data.data && data.data.status){
					var result = data.data.data;
					setExtraInfoOnProspectsList(result.prospectUsers);
					$scope.setPaginationData(result);
					originalProspectsList = JSON.parse(JSON.stringify($scope.ProspectList));
					filteredLists = JSON.parse(JSON.stringify($scope.ProspectList));
					//set data loaded to true
					$scope.dataLoaded = true;
					$scope.apiLoaded = true;
				}else{
					$scope.dataLoaded = true;
					$scope.apiLoaded = true;
					toaster.pop('failed', "Prospects list", "Failed to get the the prospects list");
				}
            })
    }

    //initialization of the page
    if ($scope.isFrontline) {
        $scope.selected.user = $scope.me;
        $scope.selected.role = $scope.me.organizationalTag.jobRole;
        $scope.fetchProspects($scope.me);
    }

    $scope.setPaginationData = function (result) {
        $scope.pagination.total = result.total;
        if (result.prospectUsers.length == 0) {
            $scope.filteredCount = 0;
        } else {
            if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
                $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
            } else {
                $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
            }
        }
        if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
            $scope.pagination.disableNext = true;
        } else {
            $scope.pagination.disableNext = false;
        }
        if ($scope.pagination.offset == 0) {
            $scope.pagination.disablePrevious = true;
        } else {
            $scope.pagination.disablePrevious = false;
        }
    }

    $scope.nextPage = function () {
        $scope.pagination.offset += $scope.pagination.limit;
        $scope.fetchProspects($scope.selected.user ? $scope.selected.user : $scope.me);
    }

    $scope.previousPage = function () {
        $scope.pagination.offset -= $scope.pagination.limit;
        $scope.fetchProspects($scope.selected.user ? $scope.selected.user : $scope.me);
    }

    $scope.onApplyFilterCall = function () {
        //set the filters on to the state params
        $scope.pagination.offset = 0;
        setStateParams();
        if ($scope.selected.user) {
            $scope.fetchProspects($scope.selected.user);
        } else {
            $scope.fetchProspects($scope.me);
        }
    }

    var resetFilters = function () {
        $scope.filterObj = {
            selectedStatuses: "",
            selectedLevels: "",
            prospectDateKey: "",
            searchParam: '',
            userId: '',
            referrals: $scope.isReferralsView,
            applyNOT: false,
            customerObj: {},
            colorCode: '',
            campaignCode:null,
            includeArchived: false
        };
    }

    var updateFollowUpDate = function () {
        $scope.ProspectList.forEach(function (p) {
            if (p.followUpMissed) {
                p.followUpDateStatus = {
                    class: "swot-red",
                    text: 'Missed '
                };
            }
        })
    };

    var usageInterval = $interval(function () {
        updateFollowUpDate();
    }, 10 * 1000);

    $scope.$on('$destroy', function () {
        if (angular.isDefined(usageInterval)) {
            $interval.cancel(usageInterval);
            usageInterval = undefined;
        }
    });

    $scope.showAllProspects = function () {
        //unset all the buttons which show filters
        disableAllFilters();
        //set the button which shows sll prospects
        $scope.prospectsFilters.noFilter = true;
        //reset filters
        resetFilters();
        $scope.filterObj.includeArchived = true;
        //call API and fetch prospects
        if ($scope.selected.user) {
            $scope.fetchProspects($scope.selected.user);
        } else {
            $scope.fetchProspects($scope.me)
        }
    };


    $scope.showReferals = function (selection) {
        //if the same referrals tab is clicked then do nothing
        if ($scope.isReferralsView === selection && !$scope.showLostProspects && !$scope.unassignedProspects) {
            return;
        }
        //set which tab was selected
        $scope.isReferralsView = selection;
        $scope.showLostProspects = false;
        $scope.unassignedProspects = false;
        $scope.tabName = selection ? 'referrals' : 'prospects';
        $scope.pagination.offset = 0;
        //set the tabname to stateParams
        if (selection) {
            $state.go('lms.listProspect', { tabName: $scope.tabName }, { notify: false });
        } else {
            $state.go('lms.listProspect', { tabName: $scope.tabName }, { notify: false });
        }

        //new requirements, fetch all the prospects when tab changes
        if ($scope.isReferralsView) {
            $scope.filterObj.applyNOT = false;
            $scope.filterObj.referrals = true;
            // $scope.fetchProspects($scope.selected.user ? $scope.selected.user : $scope.me);
        }
        setPermissions()
        $scope.showAllProspects();
    };

    $scope.showLostProspectsTab = function () {
        //if the same tab is selected then do nothing
        if ($scope.isReferralsView && $scope.showLostProspects) {
            return;
        }
        //new requirements, fetch all the prospects when tab changes
        //set the selected tab information
        $scope.isReferralsView = true;
        $scope.showLostProspects = true;
        $scope.unassignedProspects = false;
        $scope.tabName = 'lostProspects'
        $scope.pagination.offset = 0;
        //set the tabname onto the stateParams
        $state.go('lms.listProspect', { tabName: $scope.tabName }, { notify: false });

        $scope.filterObj.applyNOT = false;
        $scope.filterObj.referrals = true;
        setPermissions()
        $scope.showAllProspects();
    };

    $scope.addlProspect = function (isReferral, ev) {
        const params = { unassignedView: $scope.unassignedProspects, isReferral};
        $state.go('userFlow.addProspet', params);
    };

    var setStateParams = function () {
        var stateParams = {
            status: $scope.filterObj.selectedStatuses,
            dateField: $scope.filterObj.prospectDateKey,
            userId: $scope.filterObj.userId,
            startDate: moment($scope.selected.cycle.startDate).valueOf(),
            endDate: moment($scope.selected.cycle.endDate).valueOf(),
            systemSource: $scope.filterObj.systemSource,
            quickMenu: false,
            colorCode: $scope.filterObj.colorCode,
            tabName: $scope.tabName,
            customerObj: btoa($scope.filterObj.customerObj)
        };
        $state.go('lms.listProspect', stateParams, { notify: false });
    };

    $scope.archiveProspect = function (id, ev) {
        $scope.closeSelectedProspect();
        var archivedProspect = _.find(originalProspectsList, { _id: id });
        archivedProspect.archived = true;
        archivedProspect.showMissed = false;
        archivedProspect.lastUpdationDate = new Date();
        $scope.fetchProspects();
    };

    $scope.reactivateProspect = function(id, ev){
        $scope.closeSelectedProspect();
        const archivedProspect = _.find(originalProspectsList, { _id: id });
        archivedProspect.archived = false;
        archivedProspect.lastUpdationDate = new Date();
        $scope.fetchProspects();
    }

    $scope.showSelectedProspect = function (p) {
        $scope.selectedProspectShow = true;
        _.each($scope.ProspectList, function (val) {
            val.selectedProspect = p._id === val._id;
        });
        $scope.selectedProspect = JSON.parse(JSON.stringify(p));

    };

    $scope.showDocModal = function (prospect, ev) {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'docUploadModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            locals: {
                prospect
            },
            resolve: {
                docImage: function () {
                    return addImage;
                }
            }

        });
    };
    var addImage = function (prospect, image) {
        prospect.verificationDoc.push(image);
    };
    $scope.closeSelectedProspect = function () {
        $scope.selectedProspectShow = false;
        _.each($scope.ProspectList, function (val) {
            val.selectedProspect = false;
        });
        $scope.selectedProspect = {};
    };

    $scope.editProspect = function (prospect, shouldReOpen, ev) {
    $state.go('userFlow.editProspect', { prospectId: prospect._id });
    };

    if ($scope.isMiddleManager) {
        var myDivision = $scope.me.divisions[0];
        var reporteeJobRoleId = _.find(p0.allDivisions, { id: myDivision }).frontline;
        $http.post('/api/users/playersOfJobRoles', {userId: $scope.me._id, jobRoles:JSON.stringify(reporteeJobRoleId)}).then(function (reportees) {
            reportees = reportees.data;
            $scope.allReportees = [];
            $scope.allReportees = reportees;
            $scope.allReportees.unshift({ _id: 'all', name: 'All' });
            $scope.selectedReportee = $scope.allReportees[0];
        });
    }
    $scope.onReporteeChange = function (reportee) {
        //if the same reportee is selected then do nothing
        if ($scope.selected.user && reportee._id == $scope.selected.user._id) {
            return;
        }
        $scope.pagination.offset = 0;
        $scope.selected.user = reportee;
        $scope.selected.role = reportee.organizationalTag.jobRole;
        $scope.fetchProspects(reportee);
    };

    function applyFilters() {
        $scope.selectedLevels = $scope.filterObj.selectedLevels;
        $scope.selectedStatuses = $scope.filterObj.selectedStatuses;
        $scope.prospectDateKey = $scope.filterObj.prospectDateKey;
        $scope.startDate = new Date($scope.filterObj.startDate);
        $scope.endDate = new Date($scope.filterObj.endDate);
    }

    $scope.searchImplemented = false

    $scope.removeSearch = function () {
        $scope.filterObj.searchParam = ''
        $scope.searchImplemented = false;
        $scope.fetchProspects($scope.selected.user ? $scope.selected.user : $scope.me)
    }

    $scope.search = function () {
        $scope.searchImplemented = true
        $scope.pagination.offset = 0;
        $scope.fetchProspects($scope.selected.user ? $scope.selected.user : $scope.me);
    }
    $scope.search_enter = function (keyEvent) {
        if (keyEvent.which === 13) {
            $scope.search()
        }
    }
    $scope.showAllFilters = function () {
        //unset all the buttons which show filter modals
        disableAllFilters();
        //set the button which shows all filters as selected
        $scope.prospectsFilters.allFilter = true;

        var filterObj = _.cloneDeep($scope.filterObj);
        filterObj.startDate = new Date($scope.selected.cycle.startDate);
        filterObj.endDate = new Date($scope.selected.cycle.endDate);

        prospectService.showAllFilterModal(filterObj, $scope.selected.role, $scope.isReferralsView, $scope.onApplyFilterCall, $scope.workflowColorCodes, $scope.workflowStagesCodes, function (filters) {
            if (filters) {
                Object.keys(filters).forEach(function (key) {
                    $scope.filterObj[key] = filters[key];
                });
                $scope.selected.cycle = { startDate: $scope.filterObj.startDate, endDate: $scope.filterObj.endDate };
                $scope.onApplyFilterCall();
            }
        });
    };

    $scope.gotoHuddleBoard = function () {
        $state.go('lms.huddleBoard', { kpiId: $stateParams.kpiId });
    };

    $scope.showTabsInfo = function () {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: function ($scope, $rootScope, $mdDialog) {
                $scope.userText = $rootScope.isManager ? 'my team' : 'me';
                $scope.onClose = function () {
                    $mdDialog.hide();
                };
            }
        });
    }
});
