const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/employee/userSummaryModal.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('addBulkEmployeeCtrl',function($http, $scope, Auth, Upload, $location, toaster, $mdDialog) {
        $scope.fileTypeUsers = "users";
        $scope.fileTypeTargets = "targets";
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();

        //opening a datepicker dialog box
        $scope.open = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened = true;
        };

        $scope.maxDate = new Date();

        //Configration for Date picker.
        $scope.dateOptions = {
            formatYear: 'yy',
            modelDateFormat: "yyyy-MM-ddTHH:mm:ss",
            startingDay: 1
        };

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        $scope.onFileSelect = function() {
            var asOfDate = $scope.asOfDate.getTime();
            if(!$scope.asOfDate){
                toaster.pop('error', "Employees upload", "Select as of date.");
                return;
            }
            if(!$scope.bulkEmployee || !$scope.bulkEmployee.length){
                toaster.pop('error', "Employeesupload", "Kindly upload employees.");
                return;
            }
            var fileName = $scope.bulkEmployee[0].name.split(".");
            var fileType = fileName[fileName.length - 1];
            if($scope.targets && $scope.targets[0]){
                var targetfileName = $scope.targets[0].name.split(".");
                var targetfileType = targetfileName[targetfileName.length - 1];
            }
            var fileTypes = ['xls', 'xlsx', 'xlsm', 'xlsb', 'csv'];
            console.log('fileTypes: ', fileTypes, 'fileType: ', fileType)
            if($scope.targets && !_.includes(fileTypes, fileType)){
                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
                return;
            }
            if (fileType == "xls" || fileType == "xlsx" ||fileType == "xlsm" || fileType == "xlsb" || fileType == "csv") {
                $mdDialog.show({
                    controller: 'userSummaryModalCtrl',
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    resolve: {
                        data: function () {
                            return []
                        },
                        requestData: function () {
                            return {
                                asOfDate: asOfDate,
                                cronTag: 'emp',
                                invocationMethod: 'manual',
                                employees: $scope.bulkEmployee[0],
                                targetFile: $scope.targets ? $scope.targets[0] : null
                            }
                        }
                    }
                });
                // Upload.upload({
                //     url: '/api/users/userSummary',
                //     data: {
                //         asOfDate: asOfDate,
                //         employees: $scope.bulkEmployee[0],
                //         targetFile: $scope.targets ? $scope.targets[0] : null
                //     },
                //     progress: function (e) {
                //     }
                // }).then(function (_data, status, headers, config) {
                //     $mdDialog.show({
                //         controller: 'userSummaryModalCtrl',
                //         templateUrl: '../html/admin/employee/userSummaryModal.html',
                //         parent: angular.element(document.body),
                //         clickOutsideToClose: true,
                //         escapeToClose: true,
                //         resolve: {
                //             data: function () {
                //                 return _data.data
                //             },
                //             requestData: function () {
                //                 return {
                //                     asOfDate: asOfDate,
                //                     cronTag: 'emp',
                //                     invocationMethod: 'manual',
                //                     employees: $scope.bulkEmployee[0],
                //                     targetFile: $scope.targets ? $scope.targets[0] : null
                //                 }
                //             }
                //         }
                //     });
                //     // file is uploaded successfully
                // }, function (err) {
                //     if (err && err.data) {
                //         toaster.pop('error', "Submission.", err.data);
                //     }
                // })
            }else{
                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' Types Only.");
            }
        }
    });
