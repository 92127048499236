const autoImportNgTemplateLoaderTemplate1 = require('../html/tickets/ticketTable.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('ticketTable', function () {
        return {
            name: 'ticketTable',
            scope: {
                ticketsList: '=',
                pagination: '=',
                apiLoaded: '=',
                previousPage: '=',
                nextPage: '=',
                fetchTickets: '=',
                selected: '=',
                isCustomerView: '=',
                sortingObj: '=',
                showSelectedTicket: '=',
                unassignedView:'='
            },
            controller: 'ticketTableCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1

        };
    });
