'use strict';
angular.module('bitnudgeApp')
    .service('gpSimulation', function ($http ,$q) {
        this.checkValidity = function(){
            return $q.resolve(true);
            //Disabling for now because of release
            // return $http.post('/api/gameplans/getSimulationValidity').then(function(validity){
            //     validity=validity.data;
            //     return $q.resolve(validity);
            // });
        };

    });
