const autoImportNgTemplateLoaderTemplate1 = require('../html/hotKeys/hotKeys.html');

'use strict';
angular.module('bitnudgeApp')
    .directive('hotKeys', function(){
        return {
            name: 'hotKeys',
            scope: {},
            controller: 'hotKeysCtrl',
            restrict: 'AE',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
