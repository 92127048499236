const autoImportNgTemplateLoaderTemplate1 = require('../html/userFlow.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/main.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/dashboard.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/mgrDashboard.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/oneClickReport.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/leaderboards.html');
const autoImportNgTemplateLoaderTemplate7 = require('../html/gpSimulation.html');
const autoImportNgTemplateLoaderTemplate8 = require('../html/gpPlan.html');
const autoImportNgTemplateLoaderTemplate9 = require('../html/profile.html');
const autoImportNgTemplateLoaderTemplate10 = require('../html/notifications.html');
const autoImportNgTemplateLoaderTemplate11 = require('../html/contests.html');
const autoImportNgTemplateLoaderTemplate12 = require('../html/campaignsNew.html');
const autoImportNgTemplateLoaderTemplate13 = require('../html/leaderboardNew.html');
const autoImportNgTemplateLoaderTemplate14 = require('../html/newsAndUpdates.html');
const autoImportNgTemplateLoaderTemplate15 = require('../html/chatPage/chatPage.html');
const autoImportNgTemplateLoaderTemplate16 = require('../html/deepDive.html');
const autoImportNgTemplateLoaderTemplate17 = require('../html/analytics.html');
const autoImportNgTemplateLoaderTemplate18 = require('../html/playbook.html');
const autoImportNgTemplateLoaderTemplate19 = require('../html/badgePage.html');
const autoImportNgTemplateLoaderTemplate20 = require('../html/customizableReports.html');
const autoImportNgTemplateLoaderTemplate21 = require('../html/lmsComplianceReport.html');
const autoImportNgTemplateLoaderTemplate22 = require('../html/competitive.html');
const autoImportNgTemplateLoaderTemplate23 = require('../html/pipeline.html');
const autoImportNgTemplateLoaderTemplate24 = require('../html/pipelineAnalytics.html');
const autoImportNgTemplateLoaderTemplate25 = require('../html/customers/customer.html');
const autoImportNgTemplateLoaderTemplate26 = require('../html/customers/editCustomer.html');
const autoImportNgTemplateLoaderTemplate27 = require('../html/tickets/addTicket.html');
const autoImportNgTemplateLoaderTemplate28 = require('../html/tickets/editTicket.html');
const autoImportNgTemplateLoaderTemplate29 = require('../html/userActivities.html');
const autoImportNgTemplateLoaderTemplate30 = require('../html/alerts/alerts.html');
const autoImportNgTemplateLoaderTemplate31 = require('../html/leads/editLead_v1.html');
const autoImportNgTemplateLoaderTemplate32 = require('../html/leads/editLead_v2.html');
const autoImportNgTemplateLoaderTemplate33 = require('../html/leads/addLead_v1.html');
const autoImportNgTemplateLoaderTemplate34 = require('../html/leads/addLead_v2.html');
const autoImportNgTemplateLoaderTemplate35 = require('../html/leads/updateLeadStatus.html');
const autoImportNgTemplateLoaderTemplate36 = require('../html/portfolio/userPortfolioTrend.html');
const autoImportNgTemplateLoaderTemplate37 = require('../html/prospects/editProspect.html');
const autoImportNgTemplateLoaderTemplate38 = require('../html/prospects/updateProspectStatus.html');
const autoImportNgTemplateLoaderTemplate39 = require('../html/prospects/addProspect.html');
const autoImportNgTemplateLoaderTemplate40 = require('../html/prospectUserDashboard.html');
const autoImportNgTemplateLoaderTemplate41 = require('../html/prospectUserReport.html');
const autoImportNgTemplateLoaderTemplate42 = require('../html/brochureUsageStats.html');

'use strict';

angular.module('bitnudgeApp')
	.config(function ($stateProvider) {
		$stateProvider
			.state('userFlow', {
				url: '/userFlow',
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: 'UserFlowCtrl'
			})

			.state('userFlow.main', {
				url: '/main',
				templateUrl: autoImportNgTemplateLoaderTemplate2,
				controller: 'MainCtrl',
				authenticate: true
			})

			.state('userFlow.dashboard', {
				url: '/dashboard',
				templateUrl: autoImportNgTemplateLoaderTemplate3,
				controller: 'dashboardController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})
			//adding state for manager dashboard
			.state('userFlow.mgrDashboard', {
				url: '/mgrDashboard',
				templateUrl: autoImportNgTemplateLoaderTemplate4,
				controller: 'mgrDashboardController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.oneClickReport', {
				url: '/oneClickReport/:selectedPlayerIdForOCR',
				templateUrl: autoImportNgTemplateLoaderTemplate5,
				controller: 'oneClickReportController'
			})

			.state('userFlow.leaderboard', {
				url: '/leaderboard/:player',
				templateUrl: autoImportNgTemplateLoaderTemplate6,
				controller: 'leaderboardsController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			// .state('userFlow.hallOfFame-demo', {
			//     url: '/hallOfFame-demo',
			//     templateUrl: '../html/userFlow/hallOfFame-demo.html',
			//     controller: 'hallOfFameCtrl'
			// })
			//
			// .state('userFlow.inbox', {
			//     url: '/inbox',
			//     templateUrl: '../html/userFlow/inbox.html',
			//     controller: 'inboxCtrl'
			// })

			.state('userFlow.gameplan', {
				url: '/gameplan/:selectedPlayerId/:cycleId',
				templateProvider: function ($rootScope) {
					var targetPeriod = $rootScope.config.targetPeriod;
					return "<goal-summary target-period=" + targetPeriod + "></goal-summary>";
				},
				controller: 'goalSummaryCtrl',
				params: {
					modalMessage: null,
					showGPNotMade: null
				},
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})
			.state('userFlow.gameplanSimulation', {
				url: '/gameplan-simulation/:userId',
				templateUrl: autoImportNgTemplateLoaderTemplate7,
				controller: 'gpSimulationCtrl',
			})
			.state('userFlow.gameplanPlan', {
				url: '/gameplan-plan/:selectedPlayerId/:selectedKPIId/:cycleId',
				templateUrl: autoImportNgTemplateLoaderTemplate8,
				controller: 'gpPlanCtrl',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.gameplanAnalyze', {
				url: '/gameplan-analyze/:selectedKPIId',
				templateProvider: function ($rootScope) {
					var targetPeriod = $rootScope.config.targetPeriod;
					return "<gp-analyze target-period=" + targetPeriod + "></gp-analyze>";
				},
				controller: 'gpAnalyzeCtrl',
			})

			.state('userFlow.profile', {
				url: '/profile/:selectedUserId/:cycleId',
				templateUrl: autoImportNgTemplateLoaderTemplate9,
				controller: 'profileCtrl',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})
			// .state('userFlow.profilenew', {
			//     url: '/profilenew/:selectedUserId',
			//     templateUrl: '../html/userFlow/profile/profileNew.html',
			//     controller: 'profileNewCtrl'
			// })
			.state('userFlow.notifications', {
				url: '/notifications',
				templateUrl: autoImportNgTemplateLoaderTemplate10,
				controller: 'notificationsCtrl'
			})
			.state('userFlow.contests', {
				url: '/contests',
				templateUrl: autoImportNgTemplateLoaderTemplate11,
				controller: 'contestsController'
			})
			.state('userFlow.campaignsnew', {
				url: '/campaignsnew',
				templateUrl: autoImportNgTemplateLoaderTemplate12,
				controller: 'CampaignsNewController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})
			.state('userFlow.campaignLeaderboard', {
				url: '/campaignsnew/leaderboard/:id/:player',
				templateUrl: autoImportNgTemplateLoaderTemplate13,
				controller: 'CampaignsLeaderboardController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.newsAndUpdates', {
				url: '/newsAndUpdates',
				templateUrl: autoImportNgTemplateLoaderTemplate14,
				controller: 'newsAndUpdatesController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.chats', {
				url: '/chats/:player',
				templateUrl: autoImportNgTemplateLoaderTemplate15,
				controller: 'chatPageController',
				params: {
					player: null
				}
			})

			.state('userFlow.deepDive', {
				url: '/deepDive/:userId1/:userId2/:typeId/:cycleId',
				templateUrl: autoImportNgTemplateLoaderTemplate16,
				controller: 'deepDiveCtrl'
			})

			.state('userFlow.analytics', {
				url: '/analytics/:tabName/:myComparison/:roleType/:cycleId',
				templateUrl: autoImportNgTemplateLoaderTemplate17,
				controller: 'analyticsController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.myPlayBook', {
				url: '/myPlayBook',
				templateUrl: autoImportNgTemplateLoaderTemplate18,
				controller: 'playBookCtrl'
			})

			// .state('userFlow.myPlayBook', {
			//     url: '/myPlayBook',
			//     templateUrl: '../html/userFlow/myPlayBook.html',
			//     controller: 'myPlayBookCtrl'
			// })

			.state('userFlow.badgeCorner', {
				url: '/badgeCorner/:userId',
				templateUrl: autoImportNgTemplateLoaderTemplate19,
				controller: 'badgePageController',
				resolve: {
					p1Data: function (p1) {
						return p1.fetch();
					}
				}
			})

			.state('userFlow.customizableReports', {
				url: '/customizableReports',
				templateUrl: autoImportNgTemplateLoaderTemplate20,
				controller: 'customizableReportsCtrl'
			})

			.state('userFlow.lmsComplianceReport', {
				url: '/lmsComplianceReport',
				templateUrl: autoImportNgTemplateLoaderTemplate21,
				controller: 'lmsComplianceReportCtrl'
			})
			.state('userFlow.competitive', {
				url: '/competitiveInfo',
				templateUrl: autoImportNgTemplateLoaderTemplate22,
				controller: 'competitiveCtrl'
			})
			//as service can not be injected into the angular config module,
			// so we can't use p0 service.
			// As per the requirement we are changing it to 'submissions' from 'pipeline'
			.state('userFlow.pipeline', {
				url: '/submissions/:userId/:kpiId',
				templateUrl: autoImportNgTemplateLoaderTemplate23,
				controller: 'pipelineCtrl'
			})
			.state('userFlow.pipelineAnalytics', {
				url: '/pipelineAnalytics',
				templateUrl: autoImportNgTemplateLoaderTemplate24,
				controller: 'pipelineAnalyticsCtrl'
			})
			.state('userFlow.customer', {
				url: '/customer/:customerId/:activeTab',
				templateUrl: autoImportNgTemplateLoaderTemplate25,
				controller: 'customerCtrl'
			})
			.state('userFlow.editCustomer', {
				url: '/editCustomer/:customerId',
				templateUrl: autoImportNgTemplateLoaderTemplate26,
				controller: 'editCustomerCtrl'
			})
			.state('userFlow.addTicket', {
				url: '/addTicket/:tab/:cui',
				templateUrl: autoImportNgTemplateLoaderTemplate27,
				controller: 'addTicketController'
			})
			.state('userFlow.editTicket', {
				url: '/editTicket/:ticketId/:statusUpdate',
				templateUrl: autoImportNgTemplateLoaderTemplate28,
				controller: 'editTicketController'
			})
			.state('userFlow.activities', {
				url: '/activities',
				templateUrl: autoImportNgTemplateLoaderTemplate29,
				controller: 'userActivityCtrl',
			})
			.state('userFlow.alerts', {
				url: '/alerts',
				templateUrl: autoImportNgTemplateLoaderTemplate30,
				controller: 'alertsCtrl',
			})
			.state('userFlow.editLead_v1', {
				url: '/editLead/:leadId/:productId/:statusUpdate',
				templateUrl: autoImportNgTemplateLoaderTemplate31,
				controller: 'editLeadCtrl_v1'
			})
			.state('userFlow.editLead_v2', {
				url: '/edit/lead/:leadId/:productId/:statusUpdate',
				templateUrl: autoImportNgTemplateLoaderTemplate32,
				controller: 'editLeadCtrl_v2'
			})
			.state('userFlow.addLead_v1', {
				url: '/addLead/:tab/:number/:unassignedView/:isReferral/:referredTo',
				templateUrl: autoImportNgTemplateLoaderTemplate33,
				controller: 'addLeadCtrl_v1'
			})
			.state('userFlow.addLead_v2', {
				url: '/createLead/:tab/:number/:unassignedView/:isReferral/:referredTo',
				templateUrl: autoImportNgTemplateLoaderTemplate34,
				controller: 'addLeadCtrl_v2'
			})
			.state('userFlow.updateLead', {
				url: '/updateLeadStatus/:leadId/:productId?statusUpdate',
				templateUrl: autoImportNgTemplateLoaderTemplate35,
				controller: 'updateLeadStatusCtrl'
			})
			.state('userFlow.portfolioTrend', {
				url: '/portfolio/trend',
				templateUrl: autoImportNgTemplateLoaderTemplate36,
				controller: 'userPortfolioTrendCtrl',
			})
			.state('userFlow.editProspect', {
				url: '/edit/prospect/:prospectId',
				templateUrl: autoImportNgTemplateLoaderTemplate37,
				controller: 'editProspectCtrl'
			})
			.state('userFlow.updateProspect', {
				url: '/updateProspectStatus/:prospectId/?statusUpdate',
				templateUrl: autoImportNgTemplateLoaderTemplate38,
				controller: 'updateProspectStatusCtrl'
			})
			.state('userFlow.addProspet', {
				url: '/createProspect',
				templateUrl: autoImportNgTemplateLoaderTemplate39,
				controller: 'addProspectCtrl'
			})
			.state('userFlow.prospectUserDashboard', {
				url: '/prospectUser/dashboard',
				templateUrl: autoImportNgTemplateLoaderTemplate40,
				controller: 'prospectUserDashboardCtrl',			
			})
			.state('userFlow.prospectUserReport', {
				url: '/prospectUser/report',
				templateUrl: autoImportNgTemplateLoaderTemplate41,
				controller: 'prospectUserReportCtrl',			
			})
			.state('userFlow.brochureStats', {
				url: '/brochures/stats',
				templateUrl: autoImportNgTemplateLoaderTemplate42,
				controller: 'BrochureUsageStatsCtrl',			
			})
	});
