const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/prospectRight.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/prospectTimeline.html');

'use strict';
angular.module('bitnudgeApp')
    .component('prospectRight', {
        bindings: {
            prospect: '<',
            closeSelectedProspect: '&',
            archiveProspect: '&',
            editProspect: '&',
            unassignedProspects: '<',
            isReferralsView: '<',
            isLostProspectView:'<'
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function ($scope, $rootScope,Auth, $mdDialog, prospectService, p0, toaster) {
            let ctrl = this;
            $scope.config = $rootScope.config;
            $scope.me = Auth.getCurrentUser();
            $scope.isAgentOnBoardingVerificationUser = $rootScope.isAgentOnBoardingVerificationUser;

            $scope.isSeniorManager = $rootScope.isSeniorManager;
            $scope.isFrontline = $rootScope.isFrontline;
            this.$onChanges = (changes) => {
                if (changes.prospect) {
                    $scope.selectedProspect = changes.prospect.currentValue;
                    showCustomerDetails($scope.selectedProspect)
                }
                if (changes.unassignedProspects) {
                    $scope.unassignedProspects = changes.unassignedProspects.currentValue;
                }
                if (changes.isReferralsView) {
                    $scope.isReferralsView = changes.isReferralsView.currentValue;
                }
                if (changes.isLostProspectView) {
                    $scope.isLostProspectView = changes.isLostProspectView.currentValue;
                }
                let archiveAllowed = $rootScope.permissions.includes(p0.USER_PERMISSIONS.archiveProspect)
                $scope.archiveProspect = !$scope.isReferralsView && !$scope.unassignedProspects && !$scope.selectedProspect.archived && archiveAllowed && !$scope.isAgentOnBoardingVerificationUser
            };
            this.showTimeline = function(prospect){
                prospectService.getProspectHistory(prospect._id).then(function(response){
                    let history = response.data.data;
                    $mdDialog.show({
                        templateUrl: autoImportNgTemplateLoaderTemplate2,
                        controller: 'prospectTimelineController',
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        fullscreen: true, // Only for -xs, -sm breakpoints.
                        resolve:{
                            prospect: function(){
                                return prospect;
                            },
                            history: function (){return history},
                            historiesLoaded: function (){return true}
                        }
                    });
                }).catch(function(err){
                    console.error('Prospect get History API failed', err);
                    $scope.errors = err.data.errors;
                    toaster.pop('error',"Prospect get History failed.");
                });
            }

            this.archiveProspectHelper = function(prospect, ev) {
                showConfirm(prospect, ev);
            };
            let showConfirm = function(prospect, ev) {
                ev.stopPropagation();
                const id = prospect._id;
                let message = `Are you sure you want to archive ${prospect.name}?`;
                
                if (prospect.marketingId) {
                  const marketingId = prospect.marketingId.toUpperCase();
                  message = `Are you sure you want to archive ${prospect.name} (${marketingId})?`;
                }
                let confirm = $mdDialog.confirm()
                    .title(message)
                    .targetEvent(ev)
                    .ok('Yes')
                    .cancel('No');
                $mdDialog.show(confirm).then(function() {
                    prospectService.archiveProspect(id).then(function(data){
                        toaster.pop('success', "Prospect Archive", "Customer Archived.");
                        if(ctrl.archiveProspect){
                            ctrl.archiveProspect({id:id,ev: ev});
                        }
                    });
                });
            }
           
            const showCustomerDetails = (prospect) => {
                $scope.contactInfo = prospect;
                $scope.contactKeys = [
                    {key:'urn', name:'URN', type:'string'},
                    {key:'name', name:'Name', type:'string'},
                    {key:'contactNumber', name:'Contact number', type:'contactNumber'},
                    {key:'companyName', name:'Company', type:'string'},
                    {key:'profession', name:'Profession', type:'string'},
                    {key:'email', name:'Email', type:'string'},
                    {key:'status', name:'Status', type:'string'},
                    {key:'nationality', name:'Nationality', type:'string'},
                    {key:'marketingId', name:'Marketing Id', type:'string'},
                    {key:'agentLevelDisplayName', name:'Level', type:'string'},
                ];
                if($scope.config.showProspectStage) {
                    $scope.contactKeys.push({key:'stage', name:'Stage', type:'string'})
                }
            }

            $scope.openDMSViewer = function(prospect, ev) {
                ev.stopPropagation();
                const documentTypes = []
                if(prospect.attachedDocsCount){
                    const documents = prospect.documents;
                    if(documents){
                        Object.keys(documents).forEach(item => {
                            documents[item].forEach( document =>{
                                if(!documentTypes.includes(document.uid)){
                                    documentTypes.push(document.uid)
                                }
                            })
                        })
                    }
                    if(documentTypes.length){
                        const dmsParameters = {
                            documentTypes, //In case of select mode
                            documents: [], //In case of approve mode send document Ids
                            isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                            isApproveMode: false, //for approve mode, open DMS with approve and reject option
                            isSelectMode: true, //for selection mode, open DMS with prospect or ticket context i.e workflow
                            customer: {},
                            workflowId: prospect.workflowId,
                            context: 'prospect'
                        };
                        $rootScope.$broadcast('dms:openModalLMS', dmsParameters);
                    }
                }
            }
        }

    });
