'use strict';
angular.module('bitnudgeApp')
    .service('DashboardService', function ($http,p0, $q) {
        
        const jobRolesById = _.groupBy(p0.allJobRoles, 'id');
        console.log('jobRolesById: ', jobRolesById)
        this.getTodaysFollowupActions = ({userId, timeRange, startDate, endDate, only}) => {
            const deferred = $q.defer();
            let url = `/api/actionboard`
            
            $http.post(url,{userId, timeRange, only,startDate, endDate }).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }
        this.getRatedLeads = ({userId, limit}) => {
            const deferred = $q.defer();
            let url = `/api/actionboard/leadsByRating`;
            $http.post(url,{userId, limit}).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }
        this.emailProspectExportExcel = function(params){
			const deferred = $q.defer()
			$http
                .post(`/api/dashboard/prospectUser/counts/email`, params)
                .then(
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );
			return deferred.promise;
		}
    });

