const autoImportNgTemplateLoaderTemplate1 = require('../html/tickets/uploadFile.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/tickets/editTicket.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/tickets/reassignTicket.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/tickets/ticketTimeline.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/tickets/ticketsAllFilters.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/tickets/ticketsExport.html');

'use strict';
const {USER_PERMISSIONS} = require('../types/permissions');
angular.module('bitnudgeApp').controller('ticketsCtrl', function ($scope, $rootScope,$mdDialog,
    $q, $state, Tickets, $stateParams, Auth, toaster, p0, $timeout, $filter, $http, CurrentCycle) {
    $scope.config = p0.config;
    var loggedInUser = Auth.getCurrentUser();
    $scope.strings = p0.strings;
    var currentUser = {};

	$scope.createServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.createServiceRequest);
	$scope.exportServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.exportServiceRequest);
	$scope.viewServiceRequestList = $rootScope.permissions.includes(USER_PERMISSIONS.viewServiceRequestList);

	$scope.uploadServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.uploadServiceRequest);

	$scope.isFrontline = (loggedInUser.roleType === 'frontline');
    $scope.tabs = [];
    $scope.selected = {
        cycle: {}
    };
    $scope.selectedTab = null;
    $scope.viewCategory = {
        MYTICKETS: 'myTickets',
        UNASSIGNED: 'unassigned'
    }
    $scope.filterObj = {
        viewCategory : $scope.viewCategory.MYTICKETS
    };
    $scope.itemsList = [];
    $scope.apiLoaded = false;
    $scope.allPlayTypes = p0.allPlayTypes
    $scope.query = {order: '-creationDate',};
    $scope.myProducts = [];
    const products = p0.allProducts;
    products.forEach(function (product) {
        $scope.myProducts.push({
            productCode: product.productCode,
            _id: product._id,
            name: product.shortName
        });
    })
    $scope.ticketFilters = {
        showAll : true,
        filtered : false
    };

    $scope.sortingObj = {
        sortKey: 'requiredClosureDate',
        sortOrder: 'asc'
    };

    $scope.pagination = Tickets.getPaginationObj();

    function disableAllFilters(){
        Object.keys($scope.ticketFilters).forEach(function(key){
            $scope.ticketFilters[key] = false;
        })
        $scope.filterObj.searchParam = ""
        $scope.searchResult = false;
    }

    $scope.searchResult = false;

    $scope.searchTickets = function(param){
            $scope.pagination.offset = 0;
            $scope.searchResult = true;
            $scope.filterObj.searchParam = param
            $scope.fetchTickets(currentUser);
    }

    $scope.resetSearchTickets = function(){
            $scope.pagination.offset = 0;
            $scope.filterObj.searchParam =""
            $scope.searchResult = false;
            $scope.fetchTickets(currentUser);
    }


    var setExtraInfo = function (ticket) {

        //Any other info can be set


        if(ticket.requiredClosureDate){
            ticket.requiredClosureDate = new Date(ticket.requiredClosureDate);
        }
        if(ticket.userId && $rootScope.isMiddleManager && ticket.userId._id == loggedInUser._id)
            ticket.noHandler = true;
    }

    var setExtraInfoOnTicketsList = function(tickets){
        _.each(tickets,function(ticket){
            setExtraInfo(ticket);
        });
    }

    $scope.actions = {}
    var setTabs = function(tabs){
        $scope.tabs = [];
        //set the total count
        tabs.forEach(function(product){
            $scope.tabs.push(product);
            $scope.actions[product._id] = {
                enableUserActions: !!(product.productCode !== 'P04')
            }
        });
    }

    var setPaginationData = function(data){
        $scope.pagination.total = data.total;
        if(data.tickets.length === 0){
            $scope.filteredCount = 0;
        } else {
            if($scope.pagination.offset+$scope.pagination.limit < $scope.pagination.total){
                $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.offset+$scope.pagination.limit);
            } else {
                $scope.filteredCount = ($scope.pagination.offset+1)+'-'+($scope.pagination.total);
            }
        }

        if(($scope.pagination.offset+$scope.pagination.limit) >= $scope.pagination.total){
            $scope.pagination.disableNext = true;
        } else {
            $scope.pagination.disableNext = false;
        }
        if($scope.pagination.offset === 0){
            $scope.pagination.disablePrevious = true;
        } else {
            $scope.pagination.disablePrevious = false;
        }
    }

    var setStateParams = function (filterObj) {
        var stateParams = {
            tab: filterObj.tab,
            userId: filterObj.userId,
            ...filterObj
        };

        var highlightFilterButton = false;

        //Set filters tab as-well
        if(filterObj.state){
            highlightFilterButton = true;
            stateParams.state = filterObj.state;
        }else{
            stateParams.state = null;
        }

        if(filterObj.dateFilterType){
            highlightFilterButton = true;
            stateParams.dateFilterType = filterObj.dateFilterType;
        }else{
            stateParams.dateFilterType = null;
        }

        // disableAllFilters();

        if(highlightFilterButton){
            $scope.ticketFilters.showAll = false;
            $scope.ticketFilters.filtered = true;
        }else {
            $scope.ticketFilters.filtered = false;
            $scope.ticketFilters.showAll = true;
        }
        setTabsCount();
        $state.go('lms.tickets', stateParams, {notify: false});
    };

    var onApplyFilterCall = function(updatedFilterObj){
        //set the filters on to the state params
        $scope.filterObj = updatedFilterObj;
        $scope.pagination.offset = 0;
        $scope.fetchTickets(currentUser);
    }

    $scope.fetchTickets = function(user, pagination){
        setStateParams($scope.filterObj);
        $scope.itemsList = [];
        $scope.apiLoaded = false;
        if (!user) {
            user = currentUser;
        }
        if (pagination) {
            $scope.pagination = pagination;
        }
        $scope.filterObj.withReporting = (user._id === loggedInUser._id);
        Tickets.fetchTickets(user._id, $scope.filterObj, $scope.pagination, null, null, $scope.sortingObj).then(function(data){
            $scope.filterObj.workflowIds = null
            //set extra information on tickets
            
            const resultData = data.data.data
            console.log('resultData: ', resultData)
            Tickets.setExtraInfoOnTicketsList(resultData.tickets);
            setPaginationData(resultData);
            //set the list of tickets to the scope variable
            $scope.itemsList = resultData.tickets;
            //set data loaded to true
            $scope.apiLoaded = true;
        });
    };

    var setInitialFilters = function(user){
        $scope.filterObj = {
            tab: p0.allKpis[0]._id,
            state: null,
            type: null,
            searchParam: null,
            userId: user._id
        };
    }

    var checkAndRestoreStateParams = function(user){
        //restore filters if present in the state params
        if($stateParams && Object.keys($stateParams).length !== 0){
            // Object.keys($stateParams).forEach(function (key) {
            //     filterObj[key] = $stateParams[key];
            // });
            if(!$scope.filterObj.userId){
                $scope.filterObj.userId = user._id.toString();
            }
            if ($stateParams.state) {
                $scope.filterObj.state = $stateParams.state;
            }
            if ($stateParams.tab) {
                $scope.selectedProductFilter = $stateParams.tab;
                $scope.filterObj.tab = $scope.selectedTab || $stateParams.tab
            } else {
                $scope.selectedProductFilter = $scope.myProducts[0]._id;
                $scope.filterObj.tab = $scope.selectedTab || $scope.myProducts[0]._id;
            }
            if ($stateParams.startDate) {
                $scope.selected.cycle.startDate = new Date(parseInt($stateParams.startDate));
            }

            if($stateParams.viewCategory){
                $scope.filterObj.viewCategory = $stateParams.viewCategory
            }else{
                $scope.filterObj.viewCategory = $scope.viewCategory.MYTICKETS

            }
            if($stateParams.workflowIds) {
                $scope.filterObj.workflowIds = $stateParams.workflowIds;
            }
            $scope.filterObj.startDate = $scope.selected.cycle.startDate;

            if ($stateParams.endDate) {
                $scope.selected.cycle.endDate = new Date(parseInt($stateParams.endDate));
            }
            $scope.filterObj.endDate = $scope.selected.cycle.endDate;
        } else {
            setInitialFilters(user);
        }

        $scope.selectedTab = $scope.filterObj.tab;
    }

    var fetchTabsAndTickets = function (user) {
        var withReportee = (user._id === loggedInUser._id);
        const unassignedView = $scope.filterObj.viewCategory === $scope.viewCategory.UNASSIGNED
        Tickets.getSubTabs(user._id, withReportee, unassignedView).then( function (result) {
            console.log('result.data: ', result.data)
            var tabs = result.data;
            //Set tabs
            setTabs(tabs);
            //Set the default tab if tab was not set
            if(!$scope.filterObj.tab){
                $scope.filterObj.tab = tabs[0]._id;
            }
            $scope.selectedTab = $scope.filterObj.tab;
            //Fetch tickets
            $scope.fetchTickets(user)
        });
    }

    var init = function(user){
        $scope.selected.jobRole = user.organizationalTag.jobRole;
        $scope.apiLoaded = false;

        //Set filterObject based on state params
        checkAndRestoreStateParams(user)

        //Get tabs info of the page
        fetchTabsAndTickets(user)
    }




    /****************************************************/
    /*  All Dropdown changes are handled here ***********/
    /****************************************************/

    $scope.kpiChanged = function(kpi){
        $scope.selectedTab = kpi._id;
        $scope.pagination.offset = 0;
        $scope.filterObj.tab = kpi._id;
        $scope.showall()
        // $scope.fetchTickets(currentUser);
    }

    //if not frontline these reportee filter event handlers will set jobRole
    $scope.onReporteeChange = function (reportee) {
        $scope.selected.user = reportee;
        $scope.selected.jobRole = reportee.organizationalTag.jobRole;
        if(!currentUser || currentUser._id !== reportee._id){
            currentUser = reportee;
            $scope.filterObj.userId = reportee._id;
            init(currentUser);
        }
    };

    $scope.onPlayTypeChange = function (playType) {
        $scope.selected.playType = playType;
    };

    $scope.onDivisionChange = function (division) {
        $scope.selected.division = division;
    };

    $scope.onRoleTypeChange = function (roleType) {
        $scope.selected.roleType = roleType;
    };

    $scope.nextPage = function(){
        $scope.pagination.offset += $scope.pagination.limit;
        //What is this selected user check?
        $scope.fetchTickets(currentUser);
    }

    $scope.previousPage = function(){
        $scope.pagination.offset -= $scope.pagination.limit;
        //What is this selected user check?
        $scope.fetchTickets(currentUser);
    }

    const setTabsCount = function(){
        const withReportee = ($scope.filterObj.userId=== loggedInUser._id);
        const unassignedView = $scope.filterObj.viewCategory === $scope.viewCategory.UNASSIGNED
        Tickets.getSubTabs($scope.filterObj.userId, withReportee, unassignedView).then( function (result) {
            const tabs = result.data;
            //Set tabs
            setTabs(tabs);
        })
    }

    $scope.showall = function(){
        disableAllFilters();
        setTabsCount()
        $scope.filterObj = {
            tab: $scope.filterObj.tab,
            userId: $scope.filterObj.userId,
            viewCategory: $scope.filterObj.viewCategory
        };
        $scope.fetchTickets(currentUser)
    };

    $scope.showSelectedTicket = function(ticket){
        
        $scope.selectedTicketShow = true;
        _.each($scope.itemsList,function(val){
            val.selectedTicket = (ticket._id === val._id);
        });
        $scope.selectedTicket = JSON.parse(JSON.stringify(ticket));
        console.log('here clicked $scope.selectedTicket: ', $scope.selectedTicket);
    };

    $scope.closeSelectedTicket = function () {
        console.log('here closing ticket')
        $scope.selectedTicketShow = false;
        _.each($scope.itemsList, function (val) {
            val.selectedTicket = false;
        });
        $scope.selectedTicket = {};
    };

    /****************************************************/
    /*  All Show Modals starts here *********************/
    /****************************************************/

    //Create Ticket
    $scope.createTicket = function(ev) {
        ev.stopPropagation();
       return $state.go('userFlow.addTicket',{tab:$scope.selectedTab, cui: $scope.cui});

    };

    //Bulk ticket upload
    $scope.uploadFiles = function(name, url, ev, id) {
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'ticketUploadCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            locals: { employee: "userName"},
            fullscreen: true ,// Only for -xs, -sm breakpoints.
            resolve:{
                parameters: function () {
                    return {modalName: name, url:url};
                }
            }
        })
        .then(function(answer) {
            //Fetch tickets again
            //Re fetch tickets again.
        }, function() {
        });
    };

    //Edit Ticket
    $scope.editTicket = function(ticket) {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'editTicketController',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                parameters: function(){
                    return {
                        ticket:ticket
                    };
                }
            }
        })
        .then(function(data) {
            var ticket = data.data;
            var index = _.findIndex($scope.itemsList, {_id:ticket._id});
            if(index !== -1){
                setExtraInfo(ticket);
                $scope.itemsList[index] = ticket;
            }
        });
    };

    //Archive Ticket
    $scope.archiveTicket = function(ticket){
        $http.post('/api/tickets/archiveTicket', {ticketId: ticket._id}).then(function(response){
            response=response.data;
            if(response.err) {
                //toaster.failed
                toaster.pop('failed', "Ticket Archive", "Ticket could not be archived.");
            } else {
                //toaster.then
                //ticketsListBackup.delete(ticket)
                toaster.pop('success', "Ticket Archive", "Ticket successfully archived.");
                //TODO: Update local list
            }
        });
    };

    //Reassign Ticket
    $scope.reassignTicket = function(ticket, userId){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'reassignTicketCtrl',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                ticket: function(){
                    return ticket;
                },
                callback:   function(){
                    return showAll;
                }
            }
        });
    }

    //Show Time-line of state change
    $scope.showTimeline = function(ticket){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: 'timelineController',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                ticket: function(){
                    return ticket;
                }
            }
        });
    };

    $scope.showFilters = function(){
        disableAllFilters();
        $scope.ticketFilters.filtered = true;
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller: 'allTicketFiltersController',
            clickOutsideToClose:true,
            fullscreen: false,
            resolve: {
                filters:    function(){return   $scope.filterObj;},
                callback:   function(){return   onApplyFilterCall;}
            }
        });
    }

    $scope.exportTickets = function(ev){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate6,
            controller: 'ticketExportCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true ,// Only for -xs, -sm breakpoints.
            resolve:{
                parameters: function () {
                    return {
                        totalTickets:$scope.pagination.total,
                        filterObj:$scope.filterObj,
                        currentUser:currentUser,
                        sortingObj: $scope.sortingObj
                    };
                }
            }
        })
    };

    $scope.search_enter = function(param, keyEvent) {
        if (keyEvent.which === 13 && $scope.filterObj.searchParam && $scope.filterObj.searchParam.length)
           {    $scope.filterObj.searchParam = param
                $scope.searchTickets(param)
           }
    }

    $scope.showAssignedTickets = () => {
        $scope.filterObj.viewCategory = $scope.viewCategory.MYTICKETS
        $scope.showall()
    }

    $scope.showUnassignedTickets = () => {
        $scope.filterObj.viewCategory = $scope.viewCategory.UNASSIGNED
        $scope.showall()
    }

    $scope.onUnassignedPlaytypeChange = (playType) =>{
        $scope.selected.playType = playType ? playType : null;
        $scope.filterObj.playtypeSelected = playType ? playType._id : null
        $scope.fetchTickets(currentUser);
    }

    //Initialization method - for the frontline
    //For other roles - init method will be called inside $scope.onReporteeChange() method
    if($scope.isFrontline){
        currentUser = loggedInUser;
        init(currentUser);
    }

    function searchComponent(query, key, key2) {
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }
    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    }
});
