'use strict';
angular.module('bitnudgeApp')
    .controller('adminExportProspectsController', function($scope, p0, toaster, UtilService) {
        $scope.exportFormat = 'bnFormat';

        $scope.now = new Date();
        /** initialize the values**/
        $scope.fromDate = $scope.endDate = $scope.now;

        $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];

        var appUrl = p0.config.domain;
        var subfolder = p0.config.subfolder;
        if(subfolder){
            if(appUrl[appUrl.length -1] !== '/'){
                appUrl += '/';
            }
            if(subfolder[0] == '/'){
                subfolder = subfolder.slice(1);
            }
            appUrl += subfolder;
        }


        $scope.downloadLeads = function(){
            if(Object.prototype.toString.call($scope.fromDate) == "[object Date]"){
                $scope.fromDateTime = new Date($scope.fromDate.setHours(0,0,0,0));
            }
            if(Object.prototype.toString.call($scope.endDate) == "[object Date]"){
                $scope.endDateTime = new Date($scope.endDate.setHours(23,59,59,59));

            }
            downloadProspects();
        }

        function downloadProspects() {
            if(!$scope.fromDateTime)return alert('Please select from Date');
            if(!$scope.endDateTime)return alert('Please select end Date');
            const params = {
                fromDate: $scope.fromDateTime,
                endDate: $scope.endDateTime
            }
            console.log('params: ', params)
           return UtilService.downloadFile(appUrl+ '/api/prospectUser/exportProspects', params);
        };
    })
