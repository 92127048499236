const autoImportNgTemplateLoaderTemplate1 = require('./../html/component/attachmentPreviewComponent.html');

"use strict";
angular.module("bitnudgeApp").component("attachmentPreviewComponent", {
	templateUrl: autoImportNgTemplateLoaderTemplate1,
	bindings: {
        attachment: "<",
        displayMode: "<"
	},
	controller: "attachmentPreviewComponentCtrl"
});
