"use strict";

angular.module("bitnudgeApp").controller("documentComponentCtrl", function($scope, $rootScope, Auth, DocumentService, toaster, $mdDialog) {
    $scope.config = $rootScope.config;
    $scope.getCurrentUser = Auth.getCurrentUser;
    $scope.disableUpload = false;
    let allDocumentTypes = [];
    this.$onChanges = changes => {
        if (changes.selectMode) {
            $scope.selectMode = changes.selectMode.currentValue;
        }
        if (changes.approveMode) {
            $scope.approveMode = changes.approveMode.currentValue;
        }
        if (changes.customerMode) {
            $scope.customerMode = changes.customerMode.currentValue;
        }
        if (changes.viewMode) {
            $scope.viewMode = changes.viewMode.currentValue;
        }
        if (changes.documentIds) {
            $scope.documentIds = changes.documentIds.currentValue;
        }
        if (changes.workflowId) {
            $scope.workflowId = changes.workflowId.currentValue;
        }
        if (changes.documentTypes) {
            $scope.documentTypes = changes.documentTypes.currentValue;
        }
        if (changes.context) {
            $scope.context = changes.context.currentValue;
        }
        if (changes.cui) {
            $scope.cui = changes.cui.currentValue;
        }
        if (changes.selectedDocumentId) {
            $scope.selectedDocumentId = changes.selectedDocumentId.currentValue;
        }
        if (changes.selectedDocumentType) {
            $scope.selectedDocumentType = changes.selectedDocumentType.currentValue;
        }
        initDMSView();
    };


    const initDMSView = () => {
        //if select mode that means only document types available and we have to query based on documentTypes and workflowId
        //query db for the thumbnails for this workflow or cui and return the first active document for the carousel for each document type.
        if ($scope.selectMode) {
            $scope.selectedDocumentId = $scope.documentTypes[0];
            getCarouselThumbContent({ cui: $scope.cui, documentTypes: $scope.documentTypes, workflowId: $scope.workflowId, isWorkflowRequired: true, context: $scope.context })
        }


        if ($scope.approveMode) {
            //if approve mode that means we have file ids passed to this component, so we can directly get the thumbnails with active status for this workflowId
            //if approve mode that means we have file id passed to this component, so we can directly get the thumbnail
            $scope.selectedDocumentId = $scope.documentIds[0];
            getCarouselThumbContent({ documentIds: $scope.documentIds, workflowId: $scope.workflowId, isWorkflowRequired: true, isApproveMode: true, context: $scope.context });
        }
        if ($scope.customerMode) {
            getCarouselThumbContent({ cui: $scope.cui, isWorkflowRequired: false, context: $scope.context });
        }

        if ($scope.viewMode) {
            if ($scope.workflowId) {
                $scope.selectedDocumentId = $scope.documentTypes[0];
                getCarouselThumbContent({ documentTypes: $scope.documentTypes, workflowId: $scope.workflowId, isWorkflowRequired: true, context: $scope.context })
            } else {
                $scope.selectedDocumentId = $scope.documentIds[0];
                getCarouselThumbContent({ documentIds: $scope.documentIds, isWorkflowRequired: false, context: $scope.context });
            }
        }
    };

    $scope.$on("dms:view:refresh", (evt, data) => {
        initDMSView();
        $scope.$emit("dms:information:show", { add: true, documentType: $scope.documentType });

    });

    DocumentService.getDocumentTypes().then(types => {
        allDocumentTypes = types
    })

    const isUploadAvailableInContext = (documentTypeId) => {
        let available = false;
        allDocumentTypes.forEach(type => {
            if (type._id.toString() === documentTypeId && type.context.includes($scope.context)) {
                available = true;
            }
        })
        return available;
    }

    const getDocumentPreview = docId => {
        DocumentService.byId(docId)
            .then(document => {
                $scope.document = document;
                if ($scope.context === 'customer') {
                    if (document.uploadFromCustomerPage) {
                        $scope.disableUpload = !isUploadAvailableInContext($scope.document.documentTypeId);;
                    } else {
                        $scope.disableUpload = true;
                    }
                }
                if (DocumentService.status.positive.includes($scope.document.status)) {
                    $scope.document.showEdit = true;
                } else {
                    $scope.document.showEdit = false;
                }
                $scope.$emit("dms:information:show", { infoOnly: true });
            })
            .catch(err => {
                console.error(err.message);
                toaster.pop("error", "Error in document preview", err.message);
            });
    };

    const getCarouselThumbContent = ({ cui, documentTypes, documentIds, context, workflowId, isWorkflowRequired, isApproveMode }) => {
        DocumentService.carouselThumbnails({ cui, documentTypes, documentIds, context, workflowId, isWorkflowRequired, isApproveMode })
            .then(thumbnails => {
                $scope.documentsthumb = thumbnails.map(file => {
                    return {
                        _id: file._id,
                        thumbnail: {
                            dmsUrl: file.isThumbnailAvailable && file.thumbnail ? file.thumbnail.dmsUrl : null
                        },
                        isThumbnailAvailable: file.isThumbnailAvailable,
                        name: file.name,
                        docId: file.docId,
                        fileType: file.fileType,
                        uid: file.uid,
                        isPresent: file.isPresent,
                        status: file.status,
                        versionsCount: file.versionsCount,
                        filesCount: file.filesCount
                    }
                });

                let docExists = $scope.documentsthumb.find(thumb => {
                    if (thumb.isPresent) {
                        if (!$scope.selectMode && $scope.selectedDocumentType === thumb._id) {
                            return true
                        } else if ($scope.selectedDocumentType === thumb._id) {
                            return true
                        }
                    } else {
                        return false;
                    }
                })
                if (!docExists) {
                    docExists = $scope.documentsthumb.find(thumb => {
                        if (thumb.isPresent) {
                            if (!$scope.selectMode && $scope.selectedDocumentId === thumb.docId) {
                                return true
                            } else if ($scope.selectedDocumentId === thumb.uid) {
                                return true
                            }
                        } else {
                            return false;
                        }
                    })
                }
                if (docExists) {
                    getDocumentPreview(docExists.docId);
                    $scope.selectedDocumentType = docExists._id;
                    $scope.selectedDocumentId = docExists.docId;
                    $scope.documentType = docExists;
                } else {
                    if ($scope.selectMode) {
                        const selectedType = $scope.documentsthumb.find(thumb => {
                            return $scope.documentTypes[0] === thumb.uid
                        })
                        $scope.selectedDocumentType = selectedType._id;
                        $scope.documentType = selectedType;
                        $scope.document = {
                            documentTypeId: $scope.selectedDocumentType,
                            cui: $scope.cui
                        }
                        $scope.disableUpload = !isUploadAvailableInContext($scope.document.documentTypeId);
                    }
                    $scope.notAllowed = true;
                }
            })
            .catch(err => {
                console.error(err.message);
                toaster.pop("error", "Error in document carousel", err.message);
            });
    };


    $scope.editView = false;
    $scope.editDocumentInfo = () => {
        $scope.$emit("dms:information:show", { edit: true });
    };

    $scope.addNewDoc = () => {
        //context can be lead, ticket, customer based on which types will get filtered
        $scope.$emit("dms:information:show", { add: true, documentType: $scope.documentType });
        $scope.params = {
            addNewDocument: true,
            workflowId: $scope.workflowId,
            cui: $scope.cui,
            newUploadDocId: $scope.selectedDocumentType ? $scope.selectedDocumentType : $scope.documentTypes ? $scope.documentTypes[0] : null
        };
    };

    //to show the selected version detail, refresh the document object in parent component and it will reflect changes to all the child components
    $scope.$on("dms:version:show", (evt, data) => {
        getDocumentPreview(data.docId);
    });
    $scope.$on("dms:version:hide", (evt, data) => {
        if (!$scope.notAllowed && $scope.documentIds) {
            getDocumentPreview($scope.selectedDocumentId);
        }
    });
    $scope.$on("dms:information:show", (evt, data) => {
        if (data.edit) {
            $scope.editView = !$scope.editView;
            if ($scope.editView) {
                $scope.$broadcast("dms:history:fade", true);
            } else {
                $scope.$broadcast("dms:history:fade", false);
            }
        } else if (data.add) {
            $scope.addNewDocument = !$scope.addNewDocument;
            if ($scope.addNewDocument) {
                $scope.$broadcast("dms:select:documentType", { documentType: data.documentType })
                $scope.$broadcast("dms:history:fade", true);
            } else {
                $scope.$broadcast("dms:history:fade", false);
            }
        } else if (data.infoOnly) {
            $scope.editView = false;
            $scope.addNewDocument = false;
            $scope.notAllowed = false;
            $scope.$broadcast("dms:history:fade", false);
        }

    });
    $scope.$on("dms:carousel:click", (evt, data) => {
        if (data.docId) {
            $scope.notAllowed = false;
            $scope.selectedDocumentId = data.docId
            $scope.selectedDocumentType = data.documentTypeId
            getDocumentPreview($scope.selectedDocumentId);
        } else {
            $scope.document = {
                documentTypeId: data.documentTypeId,
                cui: $scope.cui
            }
            $scope.disableUpload = !isUploadAvailableInContext($scope.document.documentTypeId);
            $scope.selectedDocumentType = data.documentTypeId
            $scope.notAllowed = true;
            $scope.$broadcast("dms:noversion:hide", true);
        }
    })


});
