const autoImportNgTemplateLoaderTemplate1 = require('../../html/rejectDocModal.html');

'use strict';

angular.module('bitnudgeApp').controller('updateProspectStatusCtrl', function($rootScope, $scope, Auth, p0, $state, $stateParams,
    prospectService, $timeout, toaster, $window, $filter, WorkflowService, DocumentService, $mdDialog, moment) {
    
    let me = Auth.getCurrentUser();
    $scope.loggedInUser = me;
    let onCloseListener = null;
    let selectedRoleUsers = [];
    $scope.data = {};
    $scope.interactionService = { show: true, isRequired: false };
    $scope.params = {};
    $scope.mindate = new Date();
    let roleTypes = ["Loan Advisors"];
   
    let allRoleTypes = []
    const registerForEvents = function() {
        $scope.$on('workflow:prospect:addPrerequisite', function(event, opt) {
            const { existing, data } = opt;
            const {
                contact,
                workflowId,
                _id,
                userId,
                productId,
                subProduct,
                statusId,
                stage
            } = $scope.data.prospect;
			const { state } = $scope.data;
            const payload = {
                customer: { name: contact.name, contactNumber: contact.contactNumber.countryCode+''+contact.contactNumber.number },
                existing,
                docTypeId: (data && data._id) ? data._id : '',
                newPreqParams: data, // New document type params are saved here...
                userId: userId._id,
                workflowId,
                context: 'prospectUser',
                productId: productId._id,
                subProduct,
                workflowConsumerId: _id,
                stateUid: statusId,
                stageUid: stage,
				toStateUid: state.state,
            };
            WorkflowService.addPrerequisite(payload).then(function(response) {
                if (response.data.status) {
                    toaster.pop('success', "Prospect", "Request for additional information is successful.");
                } else {
                    toaster.pop('error', "Prospect", "Request for additional information is failed. Please ask administrator to recheck the workflow permissions.");
                }
            }, function(err) {
                const message = 'Add prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            })
        });

        $scope.$on('workflow:prospect:removePrerequisite', function(event, opt) {
            const { item } = opt;
            const { workflowId } = $scope.data.prospect;
            WorkflowService.removePrerequisite(workflowId, item._id, item.reference.to).then(function(response) {
                if (response.data.status) {
                    const prereq = $scope.data.state.prerequisites;
                    prereq.splice(prereq.findIndex(p => p._id === item._id), 1);
                    toaster.pop('success', "Prospect", "Additional prerequisite is removed.");
                } else {
                    toaster.pop('error', "Prospect", "Failed to remove additional prerequisite.");
                }
            }, function(err) {
                const message = 'Remove prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            });
        });

        $scope.$on('workflow:prospect:document', function(event, opt) {
            const { item } = opt;
            const { workflowId, contact, cui } = $scope.data.prospect;
            const isApproved = item.name.startsWith("approve");
            const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
            const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

            let documentTypes = otherPrerequisites.map(p => p.typeParam);
            documentTypes.unshift(item.typeParam);

            let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
            const refId = item.selectedDocId || item.rejectedDocId;
            if (refId) {
                documentIds.unshift(refId);
            }
            const dmsParameters = {
                documentTypes, //In case of select mode
                documents: documentIds, //In case of approve mode send document Ids
                isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
                isSelectMode: !isApproved, //for selection mode, open DMS with Prospect context i.e workflow
                customer: {},
                workflowId,
                //cui: cui || contact.cui,
                context: 'prospect'
            };
            $rootScope.$broadcast('dms:openModal', dmsParameters);

            if (onCloseListener) onCloseListener();
            onCloseListener = $scope.$on("dms:onCloseModal", () => {
                refreshDocumentPrereqStatus();
            });

        });

        $scope.$on('workflow:prospect:approveDirect', function(event, opt) {
            const { item } = opt;
            const { documentTypeId, selectedDocId, approved, rejected } = item;
            const { workflowId } = $scope.data.prospect;
            if (rejected) {
                toaster.pop('info', "Rejected document can't be approved.");
                return;
            }
            if (approved) {
                toaster.pop('info', "Document is already approved.");
                return;
            }
            const data = { docId: selectedDocId, documentTypeId, workflowId };
            DocumentService.approve(data)
                .then(result => {
                    const { status, data, errors } = result;
                    if (status) {
                        toaster.pop("success", data.message);
                        refreshDocumentPrereqStatus();
                    } else {
                        toaster.pop("error", "Document not approved", errors[0].message);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toaster.pop("error", "Error in document approval", err.message);
                });
        });

        $scope.$on('workflow:prospect:rejectDirect', function(event, opt) {
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "RejectDocCtrl",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(result => {
                    if (result) {
                        const { item } = opt;
                        const { documentTypeId, selectedDocId, approved, rejected } = item;
                        const { workflowId } = $scope.data.prospect;
                        let { comment, reason } = result;
                        if (!comment) {
                            comment = reason.title;
                        }
                        const data = {
                            docId: selectedDocId,
                            documentTypeId,
                            workflowId,
                            notes: comment,
                            label: reason.title
                        };
                        DocumentService.reject(data).then(result => {
                                const { status, data, errors } = result;
                                if (status) {
                                    toaster.pop("success", data.message);
                                    refreshDocumentPrereqStatus();
                                } else {
                                    toaster.pop("error", "Document not rejected", errors[0].message);
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                toaster.pop("error", "Error in document rejected", err.message);
                            });
                    }
                });
        });
    };

    const refreshDocumentPrereqStatus = () => {
        const { workflowId } = $scope.data.prospect;
        prospectService.getDocumentsCache('prospectUser', workflowId).then(function(response) {
            response = response.data;
            const { status, data, errors } = response;
            if (status) {
                DocumentService.updateDocumentPrerequisites($scope.data.state.prerequisites, data);
            }
        }).catch((err) => {
            console.error('Get documents cache failed', err);
        });
    };

    const init = function() {
        registerForEvents();
        $scope.loadingInfo = true;
		$scope.attendanceLabel = prospectService.getAttendanceLabel($rootScope.isFrontline);
        if(me && me.organizationalTag && me.organizationalTag.jobRole){
        	const myRole = p0.allJobRoles.find(role => role._id === me.organizationalTag.jobRole._id);
			if(myRole){
				$scope.showAdviserAttendance = myRole.loanAdviserAttendanceTrack;
			}else{
				$scope.showAdviserAttendance = false;
			}
        }
        
        $scope.loadingInfo = true;
        prospectService.getProspect($stateParams.prospectId).then(function(data) {
            $scope.data.prospect = data.data.data;
            $scope.interactionService.isRequired = data.data.data.statusId.toLowerCase() === 'created';
          
            prospectService.getNextStates($stateParams.prospectId, me._id).then(function(response) {
                $scope.data.nextStates = response.data.data;
                $scope.workflowNoUpdateMessage = response.data.message
                $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
                $scope.data.stateSelected = true;
                $scope.loadingInfo = false;
                refreshDocumentPrereqStatus();
            }).catch(function(err) {
                console.error('Prospect get next states API failed', err);
                toaster.pop('error', "Can't update Prospect now");
            });
            if($scope.data.prospect && $scope.data.prospect.notes){
                $scope.data.prospect.notes = $scope.data.prospect.notes.filter(note => note.notes && note.notes.length)
            }
        }).catch(function(err) {

            console.log('Get Prospect API failed', err);
            toaster.pop('error', "Can't get the Prospect");
        });
    };

    const updateWorkflow = function(prerequisites) {
        const params = {
            _id: $scope.data.prospect._id,
            userId: me._id,
            workflowId: $scope.data.prospect.workflowId,
            nextStateId: $scope.data.state.state,
            prerequisites: prerequisites
        };
        $scope.params.errors = [];
        return prospectService.gotoState(params);
    };

    const goBack = function() {
        $state.go('userFlow.editProspect', {prospectId: $stateParams.prospectId});
    };
    $scope.onCancel = function() {
        goBack();
    };

    $scope.onStateChange = function() {
        $scope.data.stateSelected = false;
        // checkInteractionRequired();
        $scope.params.errors = [];
        $timeout(function() {
            $scope.data.stateSelected = true;
        });
    };

    function searchComponent(query, key, key2) {
        //var lowercaseQuery = query.toLowerCase();
        let ismatched = false;
        const re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    };


    $scope.onSubmit = function () {
    	updateWorkflow($scope.data.state.prerequisites).then(function (response) {
    		if (response.data.status) {
    			$scope.params.errors = [];
    			toaster.pop('success', "Prospect", "Update is successful.");
    			goBack();
    		} else {
    			toaster.pop('error', "Prospect", "There were some errors in updating the prospect. Please fix and try again.");
    			$scope.params.errors = response.data.errors;
    		}
    	}).catch(function (err) {
    		console.error('Prospect goto state API failed', err);
    		$scope.params.errors = err.data.errors;
    		toaster.pop('error', "Prospect", "Update is failed.");
    	});
    };

    //#endregion
    $scope.additionalItem = {
        id:new Date().getTime(),
        isAdd: true,
        roleType: "",
        roleUser: "",
        roleUsers: [],
        roleTypes: []
    }
    $scope.additionalList = [];
    $scope.additionalList.push(angular.copy($scope.additionalItem));

    //#endregion
    $scope.changeRoleUserlist = (role, id) => {
        let filteredField = $scope.additionalList.find( field => {
            if(field.id === id){
                return field
            }
        })
        if(role === 'Manager'){
            filteredField.roleUsers = $scope.managerUsers.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
            
        }else{
            filteredField.roleUsers = $scope.loanAdvisors.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
        }
    }

    init();
});
