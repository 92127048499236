const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/employee/editEmployee.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('listEmployeeCtrl', function($http, $scope,  p0, UtilService, toaster,$mdDialog) {
        //system roles (admin, user etc.)


        var activeUserList = [];
        $scope.selected = {
            allChecked: false,
            isIndeterminate: false
        };

		$scope.asc = false;
		$scope.sortField = 'createdAt'
		$scope.sortOrder = -1;
		$scope.selected.searchKey = null;

        var appUrl = p0.config.domain;
        var subfolder = p0.config.subfolder;
        if(subfolder){
            if(appUrl[appUrl.length -1] !== '/'){
                appUrl += '/';
            }
            if(subfolder[0] == '/'){
                subfolder = subfolder.slice(1);
            }
            appUrl += subfolder;
        }

        $scope.toggle = function (user) {
            user.selected = !user.selected;
            setVariables();
        };

        var setVariables = function () {
            var selected = _.filter(activeUserList, { selected: true });

            if (activeUserList.length && selected.length === activeUserList.length) {
                $scope.selected.allChecked = true;
            } else {
                $scope.selected.allChecked = false;
            }

            if (selected.length && selected.length !== activeUserList.length) {
                $scope.selected.isIndeterminate = true;
            } else {
                $scope.selected.isIndeterminate = false;
            }
        };

        $scope.toggleAll = function() {
            var selected = _.filter(activeUserList , {selected: true});
            if(selected.length === activeUserList.length){
                activeUserList.forEach(function (user) {
                    user.selected = false;
                })
            }else{
                activeUserList.forEach(function (user) {
                    user.selected = true;
                })
            }
            setVariables();
        };

        $scope.sendEmails = function () {
            var selected = _.filter(activeUserList , {selected: true});
            if(!selected.length){
                return toaster.pop('error', "Please select at-least one user");
            }
            var ids = _.map(selected, '_id');
            $http.post("/api/users/sendInvites", {
                ids: ids
            }).then(function(rs) {
                rs = rs.data;
                selected.forEach(function (user) {
                    user.selected = false;
                });
                if(rs.length){
                    var list = '';
                    rs.forEach(function (email) {
                       list = list + email + ', ';
                    });
                    list = list.trim();
                    list = list.substring(0, list.length - 1);
                    toaster.pop('warning', "Failed to send to following emails "+ list);
                }else{
                    toaster.pop('success', "Successfully sent to all users.");
                }
            });
        };



        $scope.fetchUsers = function (flow) {
            $scope.loading = true;
            const reqBody = {
                limit: 10,
                page: flow === 'prev' ? $scope.page - 1 : flow === 'next' ? $scope.page + 1 : 1,
                sortKey: $scope.sortField,
                sortOrder: $scope.sortOrder
            }
			if ($scope.selected.searchKey && $scope.selected.searchKey !== '') {
				reqBody.searchKey = $scope.selected.searchKey;
			}
            $http.post('/api/users/userIndex/users',reqBody).then(function(result) {
                console.log(result);
                const data = result.data;
                $scope.page = data.page;
                $scope.totalRecords = data.total;
                $scope.totalPages = data.pages;
                users=data.docs;
                var usersLookup = {};
                activeUserList = [];
                users.forEach(function(user){
                    if(user.isActive) {
                        user.selected = false;
                        activeUserList.push(user);
                        usersLookup[user._id] = user.name;
                    }
                });
                users.forEach(function(user, index){
                    if(!user.children) return;
                    var activeChildCount = 0;
                    var activeChildrenArr = [];
                    user.children.forEach(function(child){
                        ++activeChildCount;
                        activeChildrenArr.push(child && child.childId && child.childId.name);
                    });
                    users[index].activeChildCount = activeChildCount;
                    users[index].activeChildrenStr = activeChildrenArr.join("\n");
                });
                $scope.userlist = users;
                $scope.loading = false;
            });   
        }

        $scope.fetchUsers();

        $scope.sortTable = function(fieldName) {
			$scope.asc = !$scope.asc;
			$scope.sortField = fieldName;
			$scope.sortOrder = $scope.asc ? 1 : -1;
			$scope.fetchUsers();
		}

        $scope.cancelSearch = function() {
            $scope.selected.searchKey = null;
            $scope.fetchUsers();
        }

        $scope.deactivateUser = function(user) {
            $http.post('/api/users/deactivateUser',{userId:user._id}).then(function(user) {
                user=user.data;
                toaster.pop('success', "User Deactivated", "User Deactivated");
            });
        };

        $scope.reactivateUser = function(user) {
            $http.post('/api/users/reactivateUser',{userId: user._id}).then(function(user) {
                user=user.data;
                toaster.pop('success', "User Reactivated", "User Reactivated");
            });
        };
        $scope.resetUser = function (user) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Do you want to reset the password of '+user.name+'.')
                .targetEvent()
                .ok('Confirm')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function(){
                $http.post("/api/users/resetPassword", {
                    userId: user._id
                }).then(function(rs) {
                    rs=rs.data;
                    toaster.pop('success', "Password has been reset for "+ user.name);
                });
            },function(){

            })
        };

        $scope.editUser = function (user) {
            $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'editEmployeeController',
            //parent: angular.element(document.body),
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                user: function () {
                    return user;
                }
            }
          })
        };

        $scope.resetIntructionForUser = function (user) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('Do you want to reset the instruction of '+user.name+'.')
                .targetEvent()
                .ok('Confirm')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function(){
                $http.post("/api/users/resetInstructions", {
                    userId: user._id
                }).then(function(rs) {
                    rs=rs.data;
                    toaster.pop('success', "Instructions have been reset for "+ user.name);
                });
            },function(){

            })
        };
        $scope.downloadEmployees = function() {
            return UtilService.downloadFile(appUrl+ '/api/users/download/employees');
        }
    });
