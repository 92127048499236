'use strict';
angular.module('bitnudgeApp')
    .controller('reassignProspectModalCtrl', function ($scope, $mdDialog, prospect, Auth, fetchProspects, $http, $state, p0, toaster) {
        $scope.prospect = prospect;
        $scope.strings = p0.strings;
        $scope.me = Auth.getCurrentUser();
        //for list of frontlines or managers
        $scope.isManagerSelected = $scope.me.roleType == 'manager' ? true : false;
        //for showing hide/show on switch
        $scope.dataLoaded = false;
        $scope.roles = ["Manager", "SeniorManager"];
        $scope.selected = {};
        $scope.selected.searchText = '';
       
        $scope.role = $scope.roles[0];

        $scope.pagination = {
            offset: 0,
            limit: 10,
            total: 0,
            disableNext: false,
            disablePrevious: false,
            page: 1,
            totalPages: 1,
            isPagination: true
        };

        $scope.onChange = (query) => {
            $scope.selected.searchText = query;
            lazyFetchUsersListForSeniorManager($scope.currRoleType);
        }
        
        const lazyFetchUsersListForSeniorManager = _.debounce(fetchUsersListForSeniorManager, 500);

        function fetchUsersListForSeniorManager(roleType) {
            $http.post('/api/users/getAllLeafJobRoles', {
                roleTypes: [_.camelCase(roleType)],
                userId: $scope.me._id,
                searchTerm: $scope.selected.searchText,
                page:$scope.pagination.page,
                limit:$scope.pagination.limit
            }).then(function (result) {
                $scope.setPaginationData(result.data.data);
                result=result.data.data.docs;
                let playtypeRoles = []
                p0.myPlayTypes.forEach(playType => 
                    playtypeRoles = [...playtypeRoles, ...playType.frontline, ...playType.managers, ...playType.seniorManagers]
                );
                playtypeRoles = _.uniq(playtypeRoles);
                result = result.filter( user => 
                    _.includes(playtypeRoles, user.organizationalTag.jobRole._id)
                );
                //insert SM in the list
                if (_.camelCase(roleType) === 'seniorManager') {
                    result = [$scope.me, ...result];
                }
                $scope.dataLoaded = true;
                 //remove the current assigned user from the list
                 _.remove(result, function (user) {
                    return user._id.toString() == prospect.userId._id.toString()
                });
                $scope.usersList = result;
            })
        }

        $scope.nextPage = function () {
            $scope.pagination.page += 1;
            fetchUsersListForSeniorManager($scope.currRoleType)
        }
    
        $scope.previousPage = function () {
            $scope.pagination.page -= 1;
            fetchUsersListForSeniorManager($scope.currRoleType)
        }

        $scope.setPaginationData = function (result) {
            $scope.pagination.total = result.total;
            if (($scope.pagination.page * $scope.pagination.limit) >= $scope.pagination.total) {
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;
            }
            if ($scope.pagination.page == 1) {
                $scope.pagination.disablePrevious = true;
            } else {
                $scope.pagination.disablePrevious = false;
            }
        }

        $scope.roleChanged = function (roleType) {
            $scope.currRoleType = roleType
            fetchUsersListForSeniorManager(roleType);
        }
        //reassign prospect to the clicked user
        $scope.onClickPlayer = function (player, event) {
            showConfirm($scope.prospect, player, event);
        };

        //modal for confirmation popup
        let showConfirm = function (prospect, player, event) {
            let confirm = null;
            if (!prospect.userId || prospect.userId.toString() == $scope.me._id.toString()) {
                //In the case of Assign prospect show this message
                let message = `Are you sure you want reassign ${prospect.name} to ${player.name}?`;
                if (prospect.marketingId) {
                    const marketingId = prospect.marketingId.toUpperCase();
                    message = `Are you sure you want reassign ${prospect.name} (${marketingId}) to ${player.name}?`;
                }
                confirm = $mdDialog.confirm()
                    .title(message)
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            } else {
                //In the case of Reassign prospect show this message
                let message =`Are you sure you want reassign ${prospect.name} from ${prospect.userId.name} to ${player.name}?`
                if (prospect.marketingId) {
                    const marketingId = prospect.marketingId.toUpperCase();
                    message =`Are you sure you want reassign ${prospect.name} (${marketingId}) from ${prospect.userId.name} to ${player.name}?`
                }
                confirm = $mdDialog.confirm()
                    .title(message)
                    .targetEvent(event)
                    .ok('Yes')
                    .cancel('No');
            }
            $mdDialog.show(confirm).then(function () {
                const postBody = {
                    urn: prospect._id,
                    userId: player._id,
                    userEmail: player.email
                }
                $http.post('/api/prospectUser/changeUser', postBody).then(function (prospect) {
                    if(prospect.data.status){
                        fetchProspects();
                        if (prospect.data.data && prospect.data.data.oldUser) {
                            toaster.success("Prospect reassigned successfully");
                        } else {
                            toaster.success("Prospect assigned successfully");
                        }
                    }else{
                        prospect.data.errors.forEach( error => toaster.error(error))
                    }
                    $mdDialog.hide();
                }, function({data}) {
                    return toaster.error("Agent Reassignment", `Agent reassignment is failed: ${data.message}`)
                });
            }, function () {
            });
        };

        $scope.cancel = function () {
            $mdDialog.hide();
        }
        if ($scope.me.roleType === 'seniorManager') {
            $scope.roleChanged('manager');
        }
    });
