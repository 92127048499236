'use strict';
angular.module('bitnudgeApp')
    .directive('whenScrolled', function($compile) {
        return {
            link: function(scope, el, attrs) {
            var rep = angular.element(document.getElementsByClassName("md-virtual-repeat-scroller"));
            rep.on('scroll', function(evt){
                if (rep[0].scrollTop + rep[0].offsetHeight >= rep[0].scrollHeight)
                    scope.$apply(attrs.whenScrolled);     
                });
            }
        }; 
    });