"use strict";

const lmsAdminSideBar = require("../html/sidebar/lmsAdminSideBar.html");
const generalSidebar = require("../html/sidebar/sidebar.html");

angular.module("bitnudgeApp").controller("UserFlowCtrl", function($scope, $location, Auth, $stateParams, $http, User, $timeout, $rootScope) {
	//Removing any modal backdrop if it exists
	$timeout(function() {
		$(".modal-backdrop").remove();
	});
	this.init = function() {
		$scope.isLoggedIn = Auth.isLoggedIn;
		$scope.isAdmin = Auth.isAdmin;
		$scope.getCurrentUser = Auth.getCurrentUser;
		$scope.user = Auth.getCurrentUser();
		if ($scope.user.organizationalTag) $scope.userRole = $scope.user.organizationalTag.jobRole;
	};

	$scope.setSidebar = function() {
		if ($scope.user.systemRole === "lmsAdmin") return lmsAdminSideBar;
		else return generalSidebar;
	};
	this.init();
	$rootScope.showRightSideBar = $rootScope.config.showRightSideBar
	$rootScope.isRightSideBarExpand = false;
	$scope.toggleRightSide = function() {
		$rootScope.isRightSideBarExpand = !$rootScope.isRightSideBarExpand;
		if(!$rootScope.isRightSideBarExpand){
			$rootScope.$emit('hideSideProductView',{})
		}
	};

	$rootScope.$on('hideSideRHSView', function(event, obj){
        $rootScope.isRightSideBarExpand = false
    })

	//userflow

	let dmsCloseListener = null;
    let attachmentCloseListener = null;

	const initDMS = (parameters) => {
		$scope.isSelectMode = parameters.isSelectMode;
		$scope.isApproveMode = parameters.isApproveMode;
		$scope.isCustomerMode = parameters.isCustomerMode;
		$scope.isViewMode = parameters.isViewMode;
		$scope.workflowId = parameters.workflowId;
		$scope.cui = parameters.cui;
		$scope.context = parameters.context;
		$scope.selectedDocumentId = parameters.selectedDocumentId;
		$scope.selectedDocumentType = parameters.selectedDocumentType;

		if ($scope.isApproveMode || $scope.isCustomerMode) {
			if (parameters.documents && !Array.isArray(parameters.documents)) {
				$scope.documentIds = [parameters.documents];
			} else {
				$scope.documentIds = parameters.documents;
			}
		}

		if ($scope.isSelectMode) {
			if (parameters.documentTypes && !Array.isArray(parameters.documentTypes)) {
				$scope.documentTypes = [parameters.documentTypes];
			} else {
				$scope.documentTypes = parameters.documentTypes;
			}
		}

		if($scope.isViewMode){
			if($scope.workflowId){
				if (parameters.documentTypes && !Array.isArray(parameters.documentTypes)) {
					$scope.documentTypes = [parameters.documentTypes];
				} else {
					$scope.documentTypes = parameters.documentTypes;
				}
			}else{
				if (parameters.documents && !Array.isArray(parameters.documents)) {
					$scope.documentIds = [parameters.documents];
				} else {
					$scope.documentIds = parameters.documents;
				}
			}

		}

		const closeModal = () => {
			$rootScope.openDMS = false;
			$scope.$broadcast("dms:onCloseModal");
		};

		dmsCloseListener = $scope.$on("dms:trigger:close", (evt) => {
			closeModal();
		});

		$scope.closeDMS = function() {
			closeModal()
			$rootScope.$broadcast("documentAdded");
			// $state.go('userFlow.customer', {customerId: $scope.cui,activeTab: 'documents'},{reload: true,inherit: false,});
		};
		$rootScope.openDMS = true;
	};

    const initAttachmentModal = (parameters) => {
        $scope.attachments = parameters.attachments;
        $scope.isDisplayMode = parameters.isDisplayMode;
        const closeModal = () => {
			$rootScope.openAttachmentModal = false;
			$scope.$broadcast("attachment:onCloseModal");
		};

		attachmentCloseListener = $scope.$on("attachment:trigger:close", (evt) => {
			closeModal();
		});

		$scope.closeAttachmentModal = function() {
			closeModal()
		};
        $rootScope.openAttachmentModal = true;
    }

	$rootScope.$on('dms:openModal', function(event, parameters){
		//De register the existing event listener
		if(dmsCloseListener){
			dmsCloseListener();
		}
		initDMS(parameters);
	});

    attachmentCloseListener = $scope.$on("attachment:trigger:close", (evt) => {
        closeModal();
    });

    $rootScope.$on('attachmentModal:openModal', function(event, parameters){
		if(attachmentCloseListener){
			attachmentCloseListener();
		}
		initAttachmentModal(parameters);
	});
});
