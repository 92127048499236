'use strict';
angular.module('bitnudgeApp')
    .controller('trendModalCtrl', function ($scope, $http, $mdDialog, Auth, p0, parameters, TrendGraph, metricOptions) {
        $scope.me = Auth.getCurrentUser();
        $scope.metricOptions = metricOptions;
        $scope.selectedTrendMetric = parameters.metric;
        $scope.graphExpanded = false;
        var activitiesYAxis= [{baseName:'Total', _id:'value', manager:false, type:'kai', fieldName:'value'},
            {baseName:'Per IFE', _id:'perIFE', manager:true, type:'kai', fieldName:'perIFE'},
            {baseName:'Per IFE per day', _id:'perIFEPerDay', manager:true, type:'kai', fieldName:'perIFEPerDay'},
            {baseName:'Per IFE per week', _id:'perIFEPerWeek', manager:true, type:'kai', fieldName:'perIFEPerWeek'}];
        var salesYAxis= [{baseName:'Actual', _id:'actual', fieldName:'achievedValue', type:'kpi'},
            {baseName:'Percentage', _id:'percentage', fieldName:'value', type:'kpi'}];
        var currentLineGraphsIds = [], allYAxisMetrics = ['value', 'perIFE', 'perIFEPerDay', 'perIFEPerWeek', 'actual', 'percentage'], otherKpis;
        $scope.addRemoveOptions = [
            {
                isActive:false,
                id:'benchmark',
                placeholder: 'Select Benchmark',
                selectedOptions: [],
                options: [],
                plural:'Benchmarks',
                checkBoxname: 'All Benchmarks',
                allSelected:false
            },
            {
                isActive:false,
                id:'peer',
                placeholder: 'Compare with Peer',
                selectedOptions: [],
                options: [],
                plural:'Peers',
                checkBoxname: 'All Peers',
                allSelected:false
            },
            {
                isActive:false,
                id:'kpi',
                placeholder: 'Compare with KPI',
                selectedOptions: [],
                options: [],
                plural:"Other KPI's",
                checkBoxname: `All KPIs`,
                allSelected:false
            }
        ];

        $scope.comparisions = [];
        $scope.tableData = [];
        $scope.lineData = {
            labels: [],
            series: [],
            seriesLegends: []
        };
        $scope.disableAddSeries = true;
        $scope.selected = {};
        $scope.vsAnd = 'vs';

        $scope.selected.kpi = parameters.kpi;

        $scope.onReporteeChange = function (reportee) {
            $scope.selected.user = reportee;
            var userKpis = getUserKpis(reportee.divisions);
            otherKpis = _.filter(userKpis, function (kpi) {
               return kpi._id != parameters.kpi._id;
            });
            setBenchmarks(reportee);
            parameters.userId = reportee._id;
            setYAxis();
            $scope.onChangeYMetric($scope.yMetric);
            init();
        };

        $scope.onDivisionChange = function (division) {};
        $scope.onPlayTypeChange = function (playType) {};

        $scope.onChangeMetric = function (metric) {
            parameters.metric = metric;
            init();
        };

        $scope.onChangeYMetric = function (yMetric) {
            $scope.addRemoveOptions.forEach(function (addRemoveOption) {
                addRemoveOption.isActive = false;
            });
            var benchmarkSeries = _.find($scope.addRemoveOptions, {id:'benchmark'});
            var kpiSeries = _.find($scope.addRemoveOptions, {id:'kpi'});
            var peerSeries = _.find($scope.addRemoveOptions, {id:'peer'});

            //There are no benchmarks SM, Daily cycles, sales achieved, activity total(for Managers)
            if($scope.selected.user.systemRole === 'seniorManager'){
                benchmarkSeries.isActive = false;
            }else if(parameters.cycleType.id != 'daily' && yMetric._id != 'value' && yMetric._id != 'actual'){
                benchmarkSeries.isActive = true;
            }else if(yMetric._id == 'value' && $scope.selected.user.systemRole == 'frontline'){
                benchmarkSeries.isActive = true;
            }

            //peer comparison for Manager and Frontline
            if($scope.selected.user.systemRole != 'seniorManager'){
                peerSeries.isActive = true;
            }

            if($scope.trendModalData){
                //Remove current lineCharts
                initializeVariables();

                //Draw ymetric lineChart
                $scope.lineData.seriesLegends[0] = {label: yMetric.name, series: 'a', _id:yMetric._id, index:0};
                $scope.lineData.series[0] = _.map($scope.trendModalData, yMetric.fieldName);
                currentLineGraphsIds[0] = yMetric._id;
                updateTable();
            }

            if(yMetric.type == 'kai'){
                if(yMetric.unit == '#'){
                    $scope.yUnit = '(# Count)';
                }else{
                    $scope.yUnit = '('+yMetric.unit+')';
                }
                if(otherKpis.length){
                    kpiSeries.isActive = true;
                    kpiSeries.options = [];
                    otherKpis.forEach(function (kpi){
                        kpiSeries.options.push({name:kpi.name+' '+parameters.metric.name, _id:kpi._id, show:false});
                    });
                }
                $scope.titleUnit = "Unit: # of "+yMetric.name;
            }else if(yMetric._id == 'actual'){
                $scope.yUnit = '('+parameters.kpi.unit+')';
                $scope.titleUnit = "Unit: "+parameters.kpi.unit;
            }else{
                $scope.yUnit = '(% Completed)';
                $scope.titleUnit = "Unit: % Completed";
            }

            if(_.find($scope.addRemoveOptions, {isActive:true})){
                $scope.disableAddSeries = false;
            }else $scope.disableAddSeries = true;

            $scope.addRemoveOptionsActive = $scope.addRemoveOptions.filter(f => f.isActive)
        };

        $scope.onBenchmarkSelect = function (allOptions) {

            deleteBenchMarkGraphs();
            
            _.each(allOptions, function (addRemoveOption) {
                if (addRemoveOption.allSelected || _.some(addRemoveOption.options, function (option) { return option.selected })) {
                    let metrics = []
                    if (addRemoveOption.allSelected) {
                        metrics = addRemoveOption.options;

                    } else {
                        metrics = _.filter(addRemoveOption.options, function (option) {
                            return option.selected;
                        });
                    }
                    _.each(metrics, function (metric) {
                        TrendGraph.fetchSeriesBenchmarks(parameters, addRemoveOption, metric).then(function (benchmarkTrend) {
                            var graphIndex = getNextIndex();
                            if (addRemoveOption.id == 'benchmark') {
                                $scope.lineData.series[graphIndex] = _.map(benchmarkTrend, metric.metric + $scope.yMetric.fieldName);
                            } else if (addRemoveOption.id == 'peer') {
                                $scope.lineData.series[graphIndex] = _.map(benchmarkTrend, $scope.yMetric.fieldName);
                            } else if (addRemoveOption.id == 'kpi') {
                                $scope.lineData.series[graphIndex] = _.map(benchmarkTrend, $scope.yMetric.fieldName);
                            }
                            $scope.lineData.seriesLegends.push({ label: metric.name, series: String.fromCharCode(97 + graphIndex), _id: metric._id, index: graphIndex });
                            updateTitle();
                            currentLineGraphsIds[graphIndex] = metric._id;
                            metric.show = true;
                            updateTable();
                        })
                    });
                }
            });
        };

        $scope.toggleGraph = function() {
            $scope.graphExpanded = !$scope.graphExpanded;
        };
        //Fetch trend graph
        function init() {
            initializeVariables();
            if(parameters.metric.metric == 'kai'){
                activitiesYAxis.forEach(function (perIFE) {
                    perIFE.name = parameters.metric.name +' '+perIFE.baseName;
                    perIFE.unit = parameters.metric.unit;
                });
            }else{
                salesYAxis.forEach(function (salesY) {
                    salesY.name = parameters.metric.name +' '+salesY.baseName;
                });
            }
            setYAxis();
            TrendGraph.fetchMetricTrend(parameters.userId, parameters.kpi._id, parameters).then( function (trend) {
                $scope.trendModalData = trend;
                $scope.showTrendChart = true;
                $scope.dialogFlex = 80;
                if(parameters.metric.metric == 'kpi'){
                    initGraph(parameters.metric.fieldName?parameters.metric.fieldName:'achievedValue');
                }else{
                    if(parameters.metric.metric == 'kai' && parameters.metric.activityType == 'percentage'){
                        initGraph('value');
                    }else{
                        initGraph('perIFE');
                    }
                }
            });
        }

        var updateTitle = function () {
            $scope.comparisions = [];
            $scope.addRemoveOptions.forEach(function (addRemoveOption) {
                if(addRemoveOption.selectedOptions.length>1){
                    $scope.comparisions.push(addRemoveOption.plural);
                }else if(addRemoveOption.selectedOptions.length == 1){
                    $scope.comparisions.push(addRemoveOption.selectedOptions[0].name);
                }
                if(addRemoveOption.selectedOptions.length && addRemoveOption.id == 'kpi'){
                    $scope.titleLeft = parameters.kpi.name;
                    $scope.vsAnd = '&';
                }else{
                    if($scope.selected.user) $scope.titleLeft = $scope.selected.user.name;
                    $scope.vsAnd = 'vs';
                }
            });
        };

        var updateTable = function () {
            $scope.table = [];
            $scope.table[0] = ['Cycle'];
            $scope.lineData.labels.forEach(function (cycleName, index) {
                $scope.table[index+1] = [cycleName];
            });
            $scope.lineData.seriesLegends.forEach(function (legend, lIndex) {
                $scope.table[0].push(legend.label);
                $scope.lineData.series[legend.index].forEach(function (value, vIndex) {
                    $scope.table[vIndex+1][lIndex+1] = parseFloat(value).toFixed(2);
                });
            });
        };

        var initializeVariables = function () {
            $scope.lineData.series = [];
            $scope.lineData.seriesLegends = [];
            currentLineGraphsIds = [];
            $scope.vsAnd ='vs';
            if($scope.selected.user) $scope.titleLeft = $scope.selected.user.name;
            for(var i=0; i<30; i++){ currentLineGraphsIds.push('');}
            $scope.addRemoveOptions.forEach(function (addRemoveOption) {
                addRemoveOption.selectedOptions = [];
                addRemoveOption.options.forEach(function (option) {
                    option.show = false;
                });
            });
            updateTitle();
        };
        initializeVariables();

        var initGraph = function (valueField) {
            if ($scope.trendModalData && $scope.trendModalData.length > 0) {
                $scope.lineData.seriesLegends[0] = {label: $scope.yMetric.name, series: 'a', _id:valueField, index:0};
                $scope.lineData.labels = _.map($scope.trendModalData, 'name');
                $scope.lineData.series[0] = _.map($scope.trendModalData, valueField);
                currentLineGraphsIds[0] = valueField;
                updateTable();
            }

            $scope.lineOptions = {
                axisX: {
                    labelOffset: {
                        x: 0,
                        y: 0
                    },
                    showGrid: true,
                    labelInterpolationFnc: function (value) {
                        return getLabel(value);
                    }
                },
                axisY: {
                    labelOffset: {
                        x: 0,
                        y: 0
                    },
                    showGrid: true,
                    labelInterpolationFnc: function (value) {
                        return parseFloat(value).toFixed(2);
                    }
                },
                chartPadding: {
                    top: 20,
                    bottom: 5,
                    left: 100,
                    right: 40
                },
                plugins: [
                    Chartist.plugins.tooltip({
                        appendToBody: true,
                        tooltipFnc: function(meta, value) {
                            return parseFloat(value).toFixed(2);
                        }
                    })
                ],
                showLine: true,
                stretch: true
            };

            if (parameters.cycleType.id === 'daily') {
                $scope.lineOptions.axisX.labelOffset.x = -8;
            } else if (parameters.cycleType.id === 'weekly') {
                $scope.lineOptions.axisX.labelOffset.x = -21;
            } else if (parameters.cycleType.id === 'monthly') {
                $scope.lineOptions.axisX.labelOffset.x = -17;
            }
        };

        var setYAxis = function () {
            if(parameters.metric.metric == 'kai'){
                if(parameters.metric.activityType == 'percentage'){
                    $scope.yAxis = _.filter(activitiesYAxis, {_id:'value'});
                    $scope.yMetric = $scope.yAxis[0];
                }
                else if($scope.selected.user.systemRole == 'frontline'){
                    $scope.yAxis = _.filter(activitiesYAxis, {manager:false});
                    $scope.yMetric = $scope.yAxis[0];
                }else{
                    if(parameters.cycleType.id == 'monthly'){
                        $scope.yAxis = activitiesYAxis;
                        $scope.yMetric = $scope.yAxis[1];
                    }else if(parameters.cycleType.id == 'weekly'){
                        $scope.yAxis = _.filter(activitiesYAxis, function (perIFE) {
                            return perIFE._id != 'perIFEPerWeek';
                        });
                        $scope.yMetric = $scope.yAxis[1];
                    }else{
                        $scope.yAxis = _.filter(activitiesYAxis, {_id:'perIFE'});
                        $scope.yMetric = $scope.yAxis[0];
                    }
                }
            }else{
                $scope.yAxis = salesYAxis;
                $scope.yMetric = $scope.yAxis[0];
            }
            $scope.onChangeYMetric($scope.yMetric);
        };

        var setBenchmarks = function (reportee) {
            $scope.disableAddSeries = true;
            $scope.benchmarks = [];
            if(reportee.systemRole == 'seniorManager') return;
                $scope.disableAddSeries = false;
                var benchmarkSeries=_.find($scope.addRemoveOptions, {id:'benchmark'});
                benchmarkSeries.options = [];
                _.forEach(reportee.leagues, function (league) {
                    benchmarkSeries.options.push({name:league.teamTypeName + ' Avg.', leagueId:league.leagueId, metric:'avg', _id:league.teamType+'avg', teamType:league.teamType, show:false});
                    benchmarkSeries.options.push({name:league.teamTypeName + ' Top 10%.', leagueId:league.leagueId, metric:'topDecile', _id:league.teamType+'topDecile', teamType:league.teamType, show:false});
                    benchmarkSeries.options.push({name:league.teamTypeName + ' Top 25%.', leagueId:league.leagueId, metric:'topQuartile', _id:league.teamType+'topQuartile', teamType:league.teamType, show:false});
                });
                var peerSeries=_.find($scope.addRemoveOptions, {id:'peer'});
                peerSeries.options = [];
                //Disabling for now because of release
                // $http.post('/api/users/getUsersOfJobRole',{jobRoleIds: [reportee.organizationalTag.jobRole._id]}).then(function (users) {
                //     users=users.data;
                //     _.remove(users, {_id:reportee._id});
                //     users.forEach(function (user) {user.show = false;});
                //     peerSeries.options = users;
                // });
        };

        var getLabel = function (value) {
            var index = $scope.lineData.labels.indexOf(value);
            if ($scope.trendModalData.length > 30) {
                if (index % 3 !== 0) return '';
            } else if ($scope.trendModalData.length > 15) {
                if (index % 2 === 0) return '';
            }
            var splits = value.split(' ');
            if (parameters.cycleType.id === 'daily') {
                return splits[1] + ' ' + splits[2];
            }
            return value;
        };

        var getNextIndex = function () {
            return currentLineGraphsIds.findIndex(function (element) {return element == '';});
        };

        var deleteBenchMarkGraphs = function () {
            var allLineGraphs = allYAxisMetrics;
            $scope.addRemoveOptions.forEach(function (option) {
                allLineGraphs = allLineGraphs.concat(_.map(option.selectedOptions, '_id'));
            });
            currentLineGraphsIds.forEach(function (value, index) {
                if(value && allLineGraphs.indexOf(value) == -1){
                    currentLineGraphsIds[index] = '';
                    $scope.lineData.series[index] = [];
                    var removedLegend = _.remove($scope.lineData.seriesLegends, function(legend) {return legend._id == value;});
                    $scope.addRemoveOptions.forEach(function (addRemoveOption) {
                        if(_.find(addRemoveOption.options, {_id:value})){
                            _.find(addRemoveOption.options, {_id:value}).show = false;
                        }
                    });
                    updateTitle();
                    updateTable();
                }
            });
        };

        var getUserKpis = function (divisionIds) {
            var kpis=[];
            _.forEach(divisionIds, function (divisionId) {
                var division = _.find(p0.allDivisions, {_id: divisionId});
                if(division) kpis = kpis.concat(division.kpis);
            });
            return kpis;
        };

        $scope.cancel = function () {
            TrendGraph.destroy();
            $mdDialog.cancel();
        };
     });
