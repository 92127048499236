'use strict';
angular.module('bitnudgeApp')
    .controller('organizationTreeCtrl', function($scope, p0) {

        //Helper methods
        var jobRoleMapping = {};
        var jobRoleChildMapping = function () {
            var jobRoles = p0.allJobRoles;
        };

        var getTitle = function (user) {
            return user.name +' ('+ user.email+')  '
                +user.organizationalTag.jobRole.name;
        };

        var recursiveReporteeMap = function (currentNode, currentUser, allUsers) {
            if(currentUser.children.length){
                currentNode.nodes = [];
                const jobRolesById = _.groupBy(p0.allJobRoles, '_id');
                var parentRole = _.find(p0.allJobRoles, {_id: currentUser.organizationalTag.jobRole._id});
                currentUser.children.forEach(function (child) {
                    var childObj = _.find(allUsers, {_id: child.childId});
                    if (childObj) {
                        let errorCount = 0, message = '';
                        if(childObj.roleType != 'frontline' && (!childObj.children.length || !childObj.leafUsers.length)){
                            errorCount++;
                            message = 'Zero reportees or frontline'
                        }
                        const childRole = jobRolesById[childObj.organizationalTag.jobRole._id][0];
                        if(childRole.parents.indexOf(parentRole._id) == -1){
                            errorCount++;
                            message = message + ' , not in same division';
                        }
                        currentNode.nodes.push({id:child.childId, title:getTitle(childObj), reportees:childObj.children.length, leafCount:childObj.leafUsers.length, errorCount:errorCount, message:message});
                        recursiveReporteeMap(currentNode.nodes[currentNode.nodes.length - 1], childObj, allUsers);
                    }
                });
            }
        };
        
        var aggregateErrorCount = function (node) {
            var count = 0;
            if(node.nodes && node.nodes.length){
                node.nodes.forEach(function (child) {
                    count = count + aggregateErrorCount(child)
                });
                node.errorCount = node.errorCount + count;
                return node.errorCount;
            }else{
                return node.errorCount;
            }
        };

        function removeClonedUser(users, allUsers, isChildren) {
            return _.filter(users, function(user) {
                var userId = isChildren ? user.childId : user;
                var userObj = _.find(allUsers, {_id: userId}); 
                return userObj && !userObj.cloned
            })
        }


        $scope.toggle = function (item) {
            item.toggle();
        };

        $scope.collapseAll = function () {
            $scope.$broadcast('angular-ui-tree:collapse-all');
        };

        $scope.expandAll = function () {
            $scope.$broadcast('angular-ui-tree:expand-all');
        };

        $scope.$watch('users', function (newValue, oldValue) {
            if(newValue){
                var treeUsers = _.filter($scope.users, {isActive:2});
                treeUsers = _.filter(treeUsers, function (user) {
                    return user.roleType != 'lmsAdmin' && !user.cloned;
                });

                treeUsers.forEach(function(user) {
                    user.children = removeClonedUser(user.children, $scope.users, true);
                    user.leafUsers = removeClonedUser(user.leafUsers, $scope.users, false);
                })

                var tree = [];
                const orgHeads = _.filter(treeUsers, function (user) {
                    return user.roleType == 'seniorManager' && !user.parent;
                });
                orgHeads.forEach((oh, i) => {
                    let errorCount = 0, message = '';
                    if((!oh.children.length || !oh.leafUsers.length)){
                        errorCount = 1;
                        message = 'Zero reportees or frontline'
                    }
                    tree.push({id:oh._id, title:getTitle(oh), reportees:oh.children.length, leafCount:oh.leafUsers.length, errorCount: errorCount, message:message});
                    recursiveReporteeMap(tree[i], oh, treeUsers);
                    aggregateErrorCount(tree[i]);
                })
                $scope.data = tree;
            }
        })
    });
