'use strict';
const { USER_PERMISSIONS } = require("../types/permissions");
angular.module('bitnudgeApp')
    .service('p0', function (Auth, $http, $q, $rootScope, Usage) {
        var _relevantCycles, _jobRoles, _kpis, _activities, _allCycles,_divisions,_myMonthlyCycleTypes = [], _playTypes, _products;
        var _dataFetched = false;
        var _this = this;
        var statusListAndPermissions;
        _this.strings = false;
        _this.day = {
            SUN_DAY: 0,
            MON_DAY: 1,
            TUES_DAY: 2,
            WEDNES_DAY: 3,
            THURS_DAY: 4,
            FRI_DAY: 5,
            SATUR_DAY: 6
        };
        var currentUser;
        var _getBatchStrings = function () {
            if(_this.strings){
                return $q.resolve(true);
            }else {
                return $http.post('/api/batch/strings').then(async function (data) {
                    _this.strings = data.data;
                    if(!$rootScope.dmsToken){
                        const dmsToken = await _this.getDmsToken();
                        _this.dmsToken = dmsToken;
                        $rootScope.dmsToken = dmsToken;
                        return $q.resolve(true);
                    }else{
                        return $q.resolve(true);
                    }
                }, function (error){
                    return $q.reject(error);
                });
            }
        };
        var _fetch = function() {
            if(_dataFetched){
                return $q.resolve(_dataFetched);
            }else {
                return _getData().then(function () {
                    _dataFetched = true;
                    Usage.setStartTime();
                    return $q.resolve(_dataFetched);
                })
            }
        };

        var _getData = function(){
			$rootScope.USER_PERMISSIONS = USER_PERMISSIONS;
            return $http.post('/api/batch/getP0Data').then(async function(data){
				data=data.data;
				$rootScope.permissions = data.permissions;

                currentUser = Auth.getCurrentUser();
                $rootScope.permissions = data.permissions;
                _this.monthlyCycleTypes = data.monthlyCycleTypes;
                var myMonthlyCycleType;
                if(currentUser.organizationalTag){
                    myMonthlyCycleType = _.find(_this.monthlyCycleTypes, {_id:currentUser.organizationalTag.jobRole.monthlyCycleTypeId});
                    _myMonthlyCycleTypes = [myMonthlyCycleType.cycleTypeCode];
                }
                _this.cloned = currentUser.cloneUser != undefined;
                _this.strings = data.strings;
                _this.quote = data.quote;
                _this.allIncentives = data.incentives;
                _this.allTeams = data.teams;
                _allCycles = data.allCycles;
                _jobRoles = data.jobRoles;
                _kpis = _.sortBy(data.kpis, ['sequence']);
                _activities = data.activities;
                _divisions = data.divisions;
                _playTypes = data.playTypes;
                _this.lastUpdatedAt = data.lastUpdatedAt;
                _this.config = data.config;
                _this.allReportees = data.allReportees;

                _this.countryList = data.countryList;
                _this.leadTypes = data.leadTypes;
                _this.referLeadTypesForFrontline = data.referLeadTypesForFrontline;
                _this.referLeadTypesForManager = data.referLeadTypesForManager;
                _this.referLeadTo = data.referLeadTo;
                _this.kpiSequence = data.kpiSequence;
                _this.srProducts = data.srProducts;
                _this.USER_PERMISSIONS = data.userPermissionTypes
                _products = data.products;
                $rootScope.lastUpdatedAtDate = $rootScope.prevDate = new Date(_this.lastUpdatedAt);
                $rootScope.cloned = _this.cloned;
                $rootScope.strings = _this.strings;
                $rootScope.products = _products;

                if(_this.config.weekEnds){
                    _setWeekEndIndexes();
                }

                if(_divisions.length) {
                    _setDivisions();
                    _setPlayTypes();
                    _getCycles();
                    _getJobRoles();
                    _setProducts();
                    _setKpis();
                    _setActivities();
                    if(_this.allIncentives.length){
                        _setIncentives();
                    }
                }
                initGlobalVarsForUser(Auth, $rootScope);



                if(!_this.dmsToken){
                    const dmsToken = await _this.getDmsToken();
                    _this.dmsToken = dmsToken;
                    $rootScope.dmsToken = dmsToken;
                }
                return $q.resolve();
            }).then(() => {
                return $http.get('/api/tickets/types').then(function (data) {
                    data = data.data.data;
                    _this.ticketTypes = data;
                }).catch((err) => {
                    console.log("Unable to fetch ticket types");
                })
            });
        };
        var _setWeekEndIndexes = function () {
            _this.config.weekEndIndexes = [];
            _this.config.weekEnds.forEach(function(weekEnd){
               _this.config.weekEndIndexes.push(_this.day[weekEnd]);
            });
        }
        var _getCycles = function () {
            var subType = 'CT01';
            if(_myMonthlyCycleTypes.length == 1)
                subType = _myMonthlyCycleTypes[0];
            _this.currentMonthlyCycle = _.find(_allCycles,{cycleType:'monthly',active:true, subType:subType});
            if(!_this.currentMonthlyCycle) return;
            _this.previousMonthlyCycle = _this.currentMonthlyCycle.previousCycleId?_.find(_allCycles,{_id:_this.currentMonthlyCycle.previousCycleId._id}): null;
            if(_this.config.weeklyCycle){
                _this.currentWeeklyCycle = _.find(_allCycles, {cycleType:'weekly',active: true});
                _this.previousWeeklyCycle =  _this.currentWeeklyCycle.previousCycleId?_.find(_allCycles, {_id:_this.currentWeeklyCycle.previousCycleId._id}):null;
            }
            console.log('_allCycles: ', _allCycles)
            _this.currentDailyCycle = _.find(_allCycles, {cycleType:'daily',active: true});
            if(_this.currentDailyCycle) {
                _this.previousDailyCycle = _this.currentDailyCycle.previousCycleId ? _.find(_allCycles, {_id: _this.currentDailyCycle.previousCycleId._id}) : null;
            }
            _this.trackDailyCycle = _.find(_allCycles,function(cycle){
                var startDate = new Date(cycle.startDate);
                var endDate = new Date(cycle.endDate);
                return startDate <= $rootScope.lastUpdatedAtDate && endDate >= $rootScope.lastUpdatedAtDate && cycle.cycleType == 'daily';
            });
            var dailyCycles = _.filter(_allCycles, {cycleType:'daily'});
            _this.liveDailyCycle = _.maxBy(dailyCycles, function(cycle) { return new Date(cycle.startDate); });
            _this.trackWeeklyCycle = _.find(_allCycles,function (cycle) {
                var startDate = new Date(cycle.startDate);
                var endDate = new Date(cycle.endDate);
                return startDate <= $rootScope.lastUpdatedAtDate && endDate >= $rootScope.lastUpdatedAtDate && cycle.cycleType == 'weekly';
            });
            _this.trackMonthlyCycle = _.find(_allCycles,function (cycle) {
                var startDate = new Date(cycle.startDate);
                var endDate = new Date(cycle.endDate);
                return startDate <= new Date($rootScope.lastUpdatedAtDate) && endDate >= new Date($rootScope.lastUpdatedAtDate) && cycle.cycleType == 'monthly' && cycle.subType == subType;
            });
            $rootScope.monthlyCycle = _this.currentMonthlyCycle;
            $rootScope.weeklyCycle = _this.currentWeeklyCycle;
            _this.allAdminCycles = _.cloneDeep(_allCycles);
            _this.campaignCycles = _.filter(_allCycles, {cycleType: 'campaign'});
            _this.allCycles = _allCycles;
            _.forEach(_this.allCycles, function (cycle) {
                cycle.businessDays = calcBusinessDays(cycle.startDate, cycle.endDate);
            });
            _this.allMonthlyCycles = _.filter(_allCycles, function(cycle){
                return cycle.cycleType == 'monthly' && _myMonthlyCycleTypes.indexOf(cycle.subType) > -1;
            });
            _this.allWeeklyCycles = _.filter(_allCycles, {cycleType: 'weekly'});
            _this.allDailyCycles = _.filter(_allCycles, {cycleType: 'daily'});

            var currentWeek = _this.currentWeeklyCycle;
            _this.nextWeeklyCycle = _.find(_allCycles, function (cycle) {
                if(cycle.previousCycleId && currentWeek){
                    return cycle.previousCycleId._id == currentWeek._id;
                }else{
                    return false;
                }
            });
        };

        _this.getCycle = function(cycleId) {
            return _.find(_allCycles, function (cycle) { return cycle._id.toString() == cycleId.toString()});
        };

        var _getJobRoles = function(){
            _this.allJobRoles = _.reverse(_jobRoles);
            _this.frontLineJobRoles = _.filter(_jobRoles, function(obj){return (obj.roleType == 'frontline' || obj.roleType == 'manager');});
            var frontlineRoles = [] ,managerRoles =[];
            _this.myPlayTypes.forEach(function (playType) {
                frontlineRoles = frontlineRoles.concat(playType.frontline);
                managerRoles = managerRoles.concat(playType.managers);
            });
            _this.myFrontlineRoles =  _.filter(_jobRoles, function(jobrole){
                return _.includes(frontlineRoles, jobrole._id)
            });
            _this.myManagerRoles =  _.filter(_jobRoles, function(jobrole){
                return _.includes(managerRoles, jobrole._id)
            });
            _this.myReporteeRoles = currentUser.roleType == 'seniorManager'? _this.myFrontlineRoles.concat(_this.myManagerRoles): _this.myFrontlineRoles;
        };

        _this.getJobRole = function(jobRoleId){
            return _.find(_jobRoles, function(jobRole){return jobRole._id.toString() == jobRoleId.toString()});
        };
        
        var _setProducts = function () {
            _this.allProducts = _products;
            _products.forEach(product => {
                if (product.mainKpi) {
                    let kpi = _.find(_kpis, (kpi) => {
                        return kpi._id.toString() === product.mainKpi.toString();
                    });
                    if (kpi) product.unit = kpi.unit;
                }
            });
            let myProducts = [];
            _this.myPlayTypes.forEach(function (playType) {
                playType.products.forEach(function (productId) {
                    let myProduct = _.find(_products, function (product) {
                        return product._id.toString() == productId.toString();
                    });
                    if (myProduct) {
                        myProducts.push(myProduct);
                    }
                });
            });
            myProducts = _.uniqBy(myProducts, '_id');
            _this.myProducts = _.sortBy(myProducts, ['sequence']);
            _this.allProducts = _.sortBy(_this.allProducts, ['sequence']);

            const userPlayTypeCode = _this.myPlayTypes.length === 1 ? _this.myPlayTypes[0].playTypeCode : 'random';
			_this.isAgentOnBoardingVerificationUser = false;
			if(userPlayTypeCode === _this.config.agentVerificationPlayType){
				_this.isAgentOnBoardingVerificationUser = true;
			}
        }

        var _setKpis = function () {
            _kpis = _.filter(_kpis, function(kpi){
                return kpi.uiLevel;
            });
            _this.allUIKpiIds = _.map(_kpis, '_id');
            _this.allUIKpis = _kpis;
            // show only BitNudge KPIs
            var bnKpis = _.filter(_kpis, {uiLevel:1});  // uiLevel 1 are bitnudge kpis
            _this.kpiIds = _.map(bnKpis,'_id');
            _this.allKpis = bnKpis;
            _this.myKpis = [], _this.myUIKpis = [], _this.myLeadKpis = [];
            _this.myPlayTypes.forEach(function (playType) {
                playType.kpis.forEach(function (kpiId) {
                    var dKpi = _.find(bnKpis, {_id:kpiId});
                    if(dKpi){
                        _this.myKpis.push(dKpi);
                    }
                    var uiKpi = _.find(_kpis, {_id:kpiId});
                    if(uiKpi){
                        _this.myUIKpis.push(uiKpi);
                    }
                });
            });
            _this.myProducts.forEach((product) => {
                if(product.mainKpi) {
                    let dKpi = _.find(bnKpis, {_id:product.mainKpi})
                    if(dKpi) {
                        _this.myLeadKpis.push(dKpi)
                    }
                }
            })
            _this.allKpis = _.sortBy(_this.allKpis, ['sequence']);
            _this.myLeadKpis = _.uniqBy(_.sortBy(_this.myLeadKpis, ['sequence']), '_id');
            _this.myKpis = _.uniqBy(_.sortBy(_this.myKpis, ['sequence']),'_id');
            _this.myUIKpis = _.uniqBy(_.sortBy(_this.myUIKpis, ['sequence']),'_id');
            _this.allDivisions.forEach(function (division) {
                var reqKpis = [];
                division.kpis.forEach(function (kpiId, index) {
                    var kpi =_.find(bnKpis,{_id:kpiId});
                    if(kpi){ reqKpis.push(kpi);}
                });
                division.kpis = reqKpis;
            });
            _this.allPlayTypes.forEach(function (playType) {
                var reqKpis = [], uiKpis = [];
                playType.kpis.forEach(function (kpiId, index) {
                    var kpi =_.find(bnKpis,{_id:kpiId});
                    if(kpi){ reqKpis.push(kpi);}
                    var uiKpi = _.find(_kpis, {_id: kpiId});
                    if(uiKpi){uiKpis.push(uiKpi);}
                });
                playType.kpis = reqKpis;
                playType.uiKpis = uiKpis;
            });
        };

        this.getKpisOfPlayTypes = function(arr){
            var finalArray = [];
            arr.forEach(function (playType) {
                var selectedPlayType = _.find(_this.allPlayTypes,{_id:playType});
                finalArray = finalArray.concat(selectedPlayType.kpis);
            });
            return finalArray;
        };
        this.getUIKpisOfPlayTypes = function(arr){
            var finalArray = [];
            arr.forEach(function (playType) {
                var selectedPlayType = _.find(_this.allPlayTypes,{_id:playType});
                finalArray = finalArray.concat(selectedPlayType.uiKpis);
            });
            finalArray = _.cloneDeep(_.uniqBy(finalArray,'_id'));
            return finalArray;
        };
        this.getLeadKpisOfPlayTypes = function(arr){
            var finalArray = [];
            arr.forEach(function (playType) {
                var selectedPlayType = _.find(_this.allPlayTypes,{_id:playType});
                finalArray = finalArray.concat(selectedPlayType.leadKpis);
            });
            finalArray = _.cloneDeep(_.uniqBy(finalArray,'_id'));
            return finalArray;
        };

        this.getKpisOfDivisions = function(arr){
            var finalArray = [];
            arr.forEach(function (division) {
                var selectedDivision = _.find(_this.allDivisions,{_id:division});
                finalArray = finalArray.concat(selectedDivision.kpis);
            });
            return finalArray;
        };

        this.getKpi = function(kpiId){
            return _.find(_kpis, function(kpi){return kpi._id.toString() == kpiId.toString()})
        };

        var _setActivities = function(){
            _activities.forEach(function(activity){
                activity.kpis = _.map(activity.kpiData,'kpiId');
                activity.names = {};
                activity.kpiData.forEach(function(kpiData){
                    activity.names[kpiData.kpiId] = kpiData.name;
                });
            });
            _this.allActivities = _activities;
            var myActivityIds = [];
            _this.myKpis.forEach(function(kpi){
                if(kpi.activities){
                    myActivityIds = myActivityIds.concat(kpi.activities);
                }
            });
            myActivityIds = _.uniqBy(myActivityIds,function(activity){return activity && activity.toString()});
            _this.myActivities = _.filter(_activities,function(activity){
                return myActivityIds.indexOf(activity._id) > -1;
            });
            var nonKpiSpecificActivities = _.filter(_activities,{kpiSpecific:false});
            _this.myActivities = _this.myActivities.concat(nonKpiSpecificActivities);
        };
        function setCycleTypes(obj){
            var rolesOfPlayType = [];
            rolesOfPlayType = rolesOfPlayType.concat(obj.frontline);
            rolesOfPlayType = rolesOfPlayType.concat(obj.managers);
            rolesOfPlayType = rolesOfPlayType.concat(obj.seniorManagers);
            var roles = _.filter(_jobRoles, function(role){
                return _.includes(rolesOfPlayType, role._id);
            });
            var cycleTypeIds = _.uniqBy(_.map(roles, 'monthlyCycleTypeId'),function(id){return id});
            obj.cycleTypeIds = cycleTypeIds;
            var cycleTypes = _.filter(_this.monthlyCycleTypes, function (cycleType) {
                return _.includes(cycleTypeIds, cycleType._id);
            });
            var cycleTypes = _.map(cycleTypes, 'cycleTypeCode');
            obj.cycleTypes = cycleTypes;
            return obj;
        }

        var _setPlayTypes = function(){
            _this.allPlayTypes = _playTypes;
            _.forEach(_this.allPlayTypes, function(playType){
               playType = setCycleTypes(playType);
            });
            _this.myPlayTypes = _.filter(_playTypes, function(playType){
                return _.includes(currentUser.playTypes, playType._id);
            });
        };
        var _setDivisions = function(){
            _this.tempAllDivisions = _.cloneDeep(_divisions);
            _this.allDivisions = _divisions;
            _.forEach(_this.allDivisions, function(division){
                division = setCycleTypes(division);
            });
            _.remove(_this.allDivisions,function(division){
                return _.includes(['LB-PF','LB-CC'],division.name);
            });
            _this.myDivisions = _.filter(_divisions,function(division){
                return _.includes(currentUser.divisions,division.id);
            });
        };
        // this.getKpi =
        this.fetch = _fetch;
        this.getBatchStrings = _getBatchStrings;
        this.getString = function () {
            return _this.strings;
        };

        this.getAdminMenuItems = function (user) {
            var defer = $q.defer();
            if(user.systemRole !== "lmsAdmin"){
                defer.resolve({menus:[], states:[]});
            }
            else if(_this.menuItems){
                defer.resolve({menus:_this.menuItems, states:_this.validStates});
            } else {
                $http.post('/api/adminMenuItem/getAdminMenuItems').then(function(result){
                    var allowedMenuItems = [];
                    var menus = result.data;
                    Object.keys(menus.data).forEach(function (key) {
                        menus.data[key].forEach(function (menu) {
                            allowedMenuItems.push(menu.state);
                        });
                    });
                    allowedMenuItems.push("settings");
                    allowedMenuItems.push("lms.adminDashboard");
                    allowedMenuItems.push("userFlow.main");
                    _this.validStates = allowedMenuItems;
                    _this.menuItems = menus.data;
                    defer.resolve({menus:_this.menuItems, states:_this.validStates});
                });
            }
            return defer.promise;
        };

        this.getDmsToken = async () => {
            if(!_this.config){
                return null;
            }
            const url = `${_this.config.DMS_EXTERNAL_URL}/api/auth/token/saleskey@nudge`;
            try{
                const result = await $http.get(url)
                return result.data.data
            }catch(err){
                console.warn('dms error', err)
                return null;
            }
        }

        this.destroy = function(){
            _dataFetched = false;
            delete(_this.currentMonthlyCycle);
            delete(_this.previousMonthlyCycle);
            delete(_this.currentWeeklyCycle);
            delete(_this.previousWeeklyCycle);
            delete(_this.allMonthlyCycles);
            delete(_this.allCycles);
            delete(_this.allWeeklyCycles);
            delete(_this.allJobRoles);
            delete(_this.frontLineJobRoles);
            delete(_this.myFrontlineRoles);
            delete(_this.myManagerRoles);
            delete(_this.kpiIds);
            delete(_this.allKpis);
            delete(_this.allActivities);
            delete(_this.allPlayTypes);
            delete(_this.myPlayTypes);
            delete(_this.allDivisions);
            delete(_this.myDivisions);
            delete(_this.dmsToken)
        };



        var initGlobalVarsForUser = function(Auth, $rootScope) {
			var me = Auth.getCurrentUser();
            $rootScope.isPlayer = (me.systemRole == "frontline" || me.systemRole == "manager");
            $rootScope.isManager = (me.systemRole == "manager" || me.systemRole == "seniorManager");

            $rootScope.config = _this.config;
            //TODO: 1. Remove this, use as $rootScope.config.operations; 2. Use specific flags rather than the generic 'operations' unless necessary
            $rootScope.operations = _this.config.operations;

            $rootScope.blackPointsExists = _.find(_this.allKpis, {hasBlackPoints: true}) ? true : false;
            $rootScope.isFrontline = (me.systemRole == "frontline");
            $rootScope.isMiddleManager = (me.systemRole == "manager");
            $rootScope.isSeniorManager = (me.systemRole == "seniorManager");

            $rootScope.calcBusinessDays = calcBusinessDays;
            $rootScope.isAgentOnBoardingVerificationUser = _this.isAgentOnBoardingVerificationUser;
            if(_this.currentMonthlyCycle) {
                setDateHelpers();
            }
        };

        var setDateHelpers = function() {
            var lastDate = new Date(_this.lastUpdatedAt);
            if(lastDate < new Date(_this.currentMonthlyCycle.startDate)){
                lastDate = new Date(_this.currentMonthlyCycle.startDate);
            }
            $rootScope.daysLeft = calcBusinessDays(lastDate, new Date(_this.currentMonthlyCycle.endDate)) - 1;
            if ($rootScope.daysLeft < 0 || !$rootScope.daysLeft) {
                $rootScope.daysLeft = 1;
            }
            $rootScope.daysTotal = $rootScope.totalBD = calcBusinessDays((new Date(_this.currentMonthlyCycle.startDate)), (new Date(_this.currentMonthlyCycle.endDate)))
            $rootScope.daysPassed = $rootScope.daysTotal - $rootScope.daysLeft;
            $rootScope.daysLeftPercentage = $rootScope.perDayLeft = 100 - parseInt(($rootScope.daysLeft / $rootScope.daysTotal) * 100);
            //$rootScope.forecastMultiplier = ($rootScope.daysPassed == 0 ? NaN : $rootScope.daysTotal / $rootScope.daysPassed);
            $rootScope.weeklyDays = 7 - (_this.config.weekEnds ? _this.config.weekEnds.length : 0);
            $rootScope.weeklyDaysPassed = lastDate.getDay() + 1;
            $rootScope.weeklyForecastMultiplier = $rootScope.weeklyDays / $rootScope.weeklyDaysPassed;
            $rootScope.weeklyDaysLeft = $rootScope.weeklyDays - $rootScope.weeklyDaysPassed;
            $rootScope.weeksPassed =  $rootScope.daysPassed / 7;
        };

        var _setIncentives = function(){
            var me = Auth.getCurrentUser();
            if(me.systemRole == "frontline"){
                _this.myIncentive = _.find(_this.allIncentives,{teamId:me.teams[0].teamId,jobroleId:me.organizationalTag.jobRole._id});
            }else if(me.systemRole == "manager"){
                const teamsUnderId = me.teamsUnder[0] && me.teamsUnder[0].teamId;
                _this.myIncentive = _.find(_this.allIncentives,{teamId:teamsUnderId,jobroleId:me.organizationalTag.jobRole._id});
            }
        }

        //Fuction to calculate the number of bussiness days between two dates
        var calcBusinessDays = function(d0, d1) { // input given as Date objects, or date strings
            if(!_this.config.weekEndIndexes) return 0;
            if(!(d0 instanceof Date)) d0 = new Date(d0);
            if(!(d1 instanceof Date)) d1 = new Date(d1);
            // var ndays = 1 + Math.round((d1.getTime() - d0.getTime()) / (24 * 3600 * 1000));
            // var nsaturdays = Math.floor((ndays + d0.getDay()) / 7);
            // return ndays - 2 * nsaturdays + (d0.getDay() == weekEndEnds) - (d1.getDay() == weekEndStarts);

            var start = new Date(d0.setHours(0,0,0,0));
            var end = new Date(d1.setHours(23,59,59,999));
            var totalDays = Math.round((end.getTime() - start.getTime()) / (24 * 3600 * 1000));
            var wholeWeeks = Math.floor(totalDays / 7);
            var days = wholeWeeks * (7 - _this.config.weekEndIndexes.length);

            if (totalDays % 7) {
                start.setDate(start.getDate() + wholeWeeks * 7);
                while (start < end) {
                    if (!_.includes(_this.config.weekEndIndexes, start.getDay())) {
                        ++days;
                    }
                    start.setDate(start.getDate() + 1);
                }
            }
            return days;
        };
    });

