'use strict';
angular.module('bitnudgeApp')
    .filter('durationFormat', function () {
        return function (value) {
            const weeks = Math.floor(value/(7*86400000));
            value = value%(7*86400000)
            const days = Math.floor(value/86400000);
            value = value%86400000;
            const hours = Math.floor(value/3600000);
            value = value%3600000;
            const minutes = Math.floor(value/60000);
            value = value%60000;
            const seconds = Math.floor(value/1000);
            return (weeks? weeks + 'w ': '') + (days? days + 'd ': '') + (hours? hours + 'h ': '') + (minutes? minutes + 'm ': '') + (seconds? seconds + 's ': '')
        }
    })
    .controller('BrochureUsageStatsCtrl', function ($scope, $http, p0, $q, UtilService, Sidebar, $rootScope) {
        let self = this;
        $scope.strings = p0.strings;
        $scope.pagination = {
            size: 50,
            from: 0,
            disableNext: true,
            disablePrevious: true
        }
        $scope.selected = {
            sortOrder: 'asc',
            searchParam: '',
            apiLoaded:false
        }
        $scope.showBrochureReadTime = $rootScope.config.showBrochureReadTime

        self.fetchData = function () {
            
            const deferred = $q.defer();
            const baseUrl = `/api/dashboard/document/usage`;
            const data = {
                from: $scope.pagination.from,
                size: $scope.pagination.size,
            }
            $http.post(baseUrl, data)
                .then(function (result) {
                    deferred.resolve(result.data.data);
                }, function (error) {
                    deferred.reject(error)
                });
            return deferred.promise;
        }

        async function init() {
            try {
                $scope.selected.apiLoaded = false;
                const { data, total } = await self.fetchData();
                console.log(data, total)
                $scope.selected.apiLoaded = true;

                if (!total || !data || !data.length) {
                    $scope.selected.errorMessage = 'No data found';
                }
                $scope.selected.data = data;
                $scope.pagination.total = total;
                $scope.pagination.disableNext = false;
                $scope.pagination.disablePrevious = false;
                if (($scope.pagination.from + $scope.pagination.size) >= total) {
                    $scope.pagination.disableNext = true;
                }
                if ($scope.pagination.from == 0) {
                    $scope.pagination.disablePrevious = true;
                }
            } catch (err) {
                console.error('err', err)
            }
        }

        $scope.nextPage = function () {
            $scope.pagination.from += $scope.pagination.size;
            init();
        }

        $scope.previousPage = function () {
            $scope.pagination.from -= $scope.pagination.size;
            init();
        }
        init()

        $scope.download = function () {
            const appUrl = Sidebar.getAppUrl();
            const baseUrl = `/api/dashboard/document/usage/download`;
            const params = {
                includeReadingTime: false,
            };
            return UtilService.downloadFile(appUrl + baseUrl, params);
        };

    })