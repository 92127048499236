'use strict';

angular.module('bitnudgeApp').controller('allFilterController', function($scope, $mdDialog, CurrentCycle, p0, leadsService, $timeout, filters, isReferralsView, onApplyFilterCall, workflowColorCodes, workflowStagesCodes, UtilService, callback) {
    $scope.filterObj = filters;
    $scope.selected = {};
    $scope.leadTypeKey = $scope.filterObj.leadTypeKey;
    $scope.applyNOT = $scope.filterObj.applyNOT;
    $scope.isReferralsView = isReferralsView;
    $scope.onApplyFilterCall = onApplyFilterCall;
    $scope.workflowColorCodes = workflowColorCodes;
    $scope.workflowStagesCodes = workflowStagesCodes;
    $scope.colorCode = $scope.filterObj.colorCode ? $scope.filterObj.colorCode.color : null
    $scope.stageCode = $scope.filterObj.stageCode ? $scope.filterObj.stageCode.name : null
    $scope.selectedLeadSource = $scope.filterObj.leadSource ? $scope.filterObj.leadSource.label : null
    $scope.thirdListFilter = []
    $scope.filterObj.selectedCampaign = $scope.filterObj.selectedCampaign;

    p0.leadTypes.forEach((leadType) => {
        let temp = {
            key: leadType.internalId,
            name: leadType.shortName
        }
        if(isReferralsView && leadType.isReferralType){
            $scope.thirdListFilter.push(temp)
        }else if(!isReferralsView && leadType.isMyLeadType){
            $scope.thirdListFilter.push(temp)
        }
    })
    fetchLeadSourcesList();

    var setTimeRange = function(monthType) {
        $scope.timeRange = UtilService.getTimeRange(monthType, 'lead');
        if ($scope.filterObj.startDate) {
            $scope.selected.cycle = _.find($scope.timeRange, function(range) {
                return moment(range.startDate).valueOf() == moment($scope.filterObj.startDate).valueOf() &&
                    moment(range.endDate).valueOf() == moment($scope.filterObj.endDate).valueOf();
            });
            if (!$scope.selected.cycle) {
                $scope.selected.cycle = _.find($scope.timeRange, {
                    _id: 'custom'
                });
                $scope.selected.cycle.startDate = new Date($scope.filterObj.startDate);
                $scope.selected.cycle.endDate = new Date($scope.filterObj.endDate);
            }
        }
        if (!$scope.selected.cycle) {
            $scope.selected.cycle = $scope.timeRange[0];
        }
    };
    setTimeRange('regular');

    let ageRange = [{
            _id: "0to25",
            from: 0,
            to: 25,
            name: "Below 25"
        },
        {
            _id: "26to35",
            from: 26,
            to: 35,
            name: "26 - 35"
        },
        {
            _id: "36to45",
            from: 36,
            to: 45,
            name: "36 - 45"
        },
        {
            _id: "46to55",
            from: 46,
            to: 55,
            name: "46 - 55"
        },
        {
            _id: "56to65",
            from: 56,
            to: 65,
            name: "56 - 65"
        },
        {
            _id: "66to75",
            from: 66,
            to: 75,
            name: "66 - 75"
        },
        {
            _id: "76to100",
            from: 76,
            to: 0,
            name: "76 & above"
        },
    ];
    $scope.ageRangeOptions = ageRange;

    $scope.dncOptions = [{
            _id: 'true',
            name: 'TRUE',
            value: true
        },
        {
            _id: 'false',
            name: 'FALSE',
            value: false
        },
    ];
    $scope.maritalStatuses = [{
            _id: 'single',
            name: 'Single'
        },
        {
            _id: 'married',
            name: 'Married'
        }, {
            _id: 'widow',
            name: 'Widow'
        }, {
            _id: 'divorced',
            name: 'Divorced'
        }, {
            _id: 'other',
            name: 'Other'
        }, {
            _id: 'unknown',
            name: 'Unknown'
        }
    ];

    $scope.filterLeadsOnDate = function(key) {
        if ($scope.filterObj.leadDateKey && $scope.filterObj.leadDateKey == key.key && $scope.filterObj.status == key.name) {
            $scope.filterObj.leadDateKey = "";
            $scope.filterObj.status = "";
            $scope.filterObj.statusCode = "";
            $scope.leadDateKey = null;
        } else {
            $scope.filterObj.leadDateKey = key.key;
            $scope.filterObj.status = key.name;
            $scope.filterObj.statusCode = key.statusCode;
        }
        if (!$scope.startDate || !$scope.endDate) {
            setDates($scope.selected.cycle);
        }
        $scope.filterObj.actNowFilterKey = null;
    };

    $scope.filterLeadsOnStatus = function(key) {
        if ($scope.filterObj.leadStatusKey && $scope.filterObj.leadStatusKey == key) {
            $scope.filterObj.leadStatusKey = "";
            $scope.leadStatusKey = null;
        } else {
            $scope.filterObj.leadStatusKey = key;
        }

        if ($scope.filterObj.leadStatusKey == 'new') {
            $scope.filterObj.leadDateKey = 'created';
            $scope.selected.cycle = CurrentCycle.cycle;
            setDates($scope.selected.cycle);
        }
        $scope.filterObj.actNowFilterKey = null;
    };

    $scope.filterLeadsOnType = function(key) {
        if ($scope.filterObj.leadTypeKey && $scope.filterObj.leadTypeKey == key) {
            $scope.filterObj.leadTypeKey = "";
            $scope.leadTypeKey = null;
        } else {
            $scope.filterObj.leadTypeKey = key;
        }
    };

    $scope.changeApplyNOT = function(value) {
        $scope.filterObj.applyNOT = value;
        if (value) {
            for (var i = 0; i < $scope.secondListFilter.length; i++) {
                if ($scope.secondListFilter[i].name == 'Follow-up')
                    $scope.secondListFilter[i].name = 'Not followed-up';
                else
                    $scope.secondListFilter[i].name = 'Not ' + $scope.secondListFilter[i].name;
            }
        } else {
            for (var i = 0; i < $scope.secondListFilter.length; i++) {
                if ($scope.secondListFilter[i].name == 'Not followed-up')
                    $scope.secondListFilter[i].name = 'Follow-up';
                else
                    $scope.secondListFilter[i].name = $scope.secondListFilter[i].name.substring(4);
            }
        }
    };

    $scope.filterLeadsOnColor = function(colorCode) {
        if ($scope.filterObj.colorCode && $scope.filterObj.colorCode.color == colorCode) {
            $scope.filterObj.colorCode = "";
            $scope.colorCode = null;
        } else {
            $scope.filterObj.colorCode = _.find(workflowColorCodes, {
                'color': colorCode
            });
        }
    }
    $scope.filterLeadsOnStages = function(stageCode) {
        if ($scope.filterObj.stageCode && $scope.filterObj.stageCode.name == stageCode) {
            $scope.filterObj.stageCode = "";
            $scope.stageCode = null;
        } else {
            $scope.filterObj.stageCode = _.find(workflowStagesCodes, {
                'name': stageCode
            });
        }
    }
    $scope.filterLeadsOnSources = function(selectedLeadSource) {
        if ($scope.filterObj.leadSource && $scope.filterObj.leadSource.label == selectedLeadSource) {
            $scope.filterObj.leadSource = "";
            $scope.selectedLeadSource = null;
        } else {
            $scope.filterObj.leadSource = _.find($scope.leadSourcesList, {
                'label': selectedLeadSource
            });
        }
    }
    $scope.$watch('selected.cycle', function(newVal, oldVal) {
        if (newVal) {
            setDates(newVal);
        }
    });

    $scope.applyFilter = function() {

        $scope.filterObj.selectedCampaignCode = _.get($scope, 'filterObj.selectedCampaign.campaignCode');
        callback($scope.filterObj);
        $mdDialog.hide();
    }

    function setDates(cycle) {
        $scope.startDate = new Date(cycle.startDate);
        $scope.endDate = new Date(cycle.endDate);
        $scope.filterObj.startDate = $scope.startDate;
        $scope.filterObj.endDate = $scope.endDate;
    }
    $scope.removeFilter = function(filter) {
        $scope.filterObj.actNowFilterKey = null;
        switch (filter) {
            case 'leadStatus':
                $scope.leadStatusKey = "";
                $scope.filterObj.leadStatusKey = "";
                break;
            case 'leadDate':
                $scope.leadDateKey = null;
                $scope.filterObj.leadDateKey = "";
                $scope.filterObj.applyNOT = false;
                $scope.applyNOT = false;
                $scope.selected.cycle = $scope.timeRange[0];
                $timeout(function() {
                    $scope.startDate = null;
                    $scope.endDate = null;
                });

                break;
            case 'leadType':
                $scope.leadTypeKey = "";
                $scope.filterObj.leadTypeKey = "";
                break;
            case 'customers':
                $scope.filterObj.customerObj = {};
                break;
            case 'campaign':
                $scope.filterObj.selectedCampaign = "";
                break;
            case 'colorCode':
                $scope.filterObj.colorCode = ""
            case 'stageCode':
                $scope.filterObj.stageCode = ""
            case 'leadSource':
                $scope.filterObj.leadSource = ""
            default:
                break;
        }
    };
    $scope.cycleChange = {
        cycle: function(cycle) {
            if ($scope.selected.cycle && cycle._id != $scope.selected.cycle._id) {
                $scope.selected.cycle = cycle;
            };
            $scope.filterObj.startDate = $scope.selected.cycle.startDate;
            $scope.filterObj.endDate = $scope.selected.cycle.endDate;
        },
        startDate: function(startDate) {
            $scope.startDate = new Date(startDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.startDate = $scope.startDate;
            }
            $scope.filterObj.startDate = $scope.startDate;
        },
        endDate: function(endDate) {
            $scope.endDate = new Date(endDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.endDate = $scope.endDate;
            }
            $scope.filterObj.endDate = $scope.endDate;
        }
    };

    $scope.firstListFilter = leadsService.getFirsterFilters();
    leadsService.setSecondFilters();
    $scope.secondListFilter = leadsService.getSecondFilters(isReferralsView);

    var leadStatus = _.find($scope.firstListFilter, {
        key: $scope.filterObj.leadStatusKey
    });
    $scope.leadStatusKey = leadStatus ? leadStatus.key : null;
    var leadDate = _.find($scope.secondListFilter, {
        key: $scope.filterObj.leadDateKey
    });
    $scope.leadDateKey = leadDate ? leadDate : null;

    $scope.onClose = function() {
        $mdDialog.hide();
    };



    function fetchLeadSourcesList() {
        leadsService.fetchLeadSourceList().then(function (response) {
            let sources = response.data && response.data.data;
            $scope.campaignOptions = response.data.campaignCodes;
            $scope.campaignOptions.unshift({
                "_id" : -1,
                "campaignCode" : "Select"
            })
            if(isReferralsView){
                $scope.leadSourcesList = sources.filter(s => s.isReferralSource);
            }else{
                $scope.leadSourcesList = sources.filter(s => s.isMyLeadSource)
            }
        }, function (err) {
            console.log(err)
        })
    }
});