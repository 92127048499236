'use strict';

angular.module('bitnudgeApp').controller('addTicketController', function($scope, $state, Auth,
    Tickets, toaster, p0, $http, $stateParams, $window) {

    $scope.strings = p0.strings;

    var me = Auth.getCurrentUser();

    $scope.data = {};

    $scope.customersList = []
    $scope.currentUser = me;
    $scope.products = p0.allProducts;
    $scope.ticketTypes = p0.ticketTypes;
    const allProducts = [...$scope.products]
    $scope.playtypes = p0.allPlayTypes
    $scope.ticketInfo = {
        creationDate: new Date(new Date().setHours(23, 59, 59, 59))
    }

    $scope.createTicket = function() {
        var ticketData = $scope.ticketInfo;
        var postData = {
            customer: {
                _id: ticketData.customer._id,
                cui: ticketData.customer.cui,
                name: ticketData.customer.name,
                contactNumber: $scope.ticketInfo.customer.contactNumber,
            },
            requiredClosureDate: new Date(ticketData.requiredClosureDate),
            notes: ticketData.notes,
            product: {
                internalId: ticketData.product._id
            },
            subProduct: {
                internalId: ticketData.subProduct.internalId
            },
            type: ticketData.type.name,
            subType: ticketData.subType && ticketData.subType.name,
        };
        if (me.roleType == 'frontline') {
            if($scope.selectedUser && me.email === $scope.ticketInfo.user.email){
                    postData.userId = me._id;
            }
        } else {
            if($scope.ticketInfo.user)
                postData.userId = $scope.ticketInfo.user._id;
        }

        if($scope.ticketInfo.playtype){
            postData.playTypes = [$scope.ticketInfo.playtype.playTypeCode];
        }else{
            postData.playTypes = $scope.playtypes.map(pt => pt.playTypeCode);
        }

        /**
         * Routing logic
         * The general structure
         *   /addTicket/:tab/:cui
         *
         *   When coming from user 360 view -> cui is present here
         *   http://localhost:3000/userFlow/addTicket//0001f8
         *   when adding sr directly -> tab is present here http://localhost:3000/userFlow/addTicket/5b4b66707772b363571a15de/
         */
        Tickets.createTicket(postData).then(function(data) {

            if (data.status == 200 && data.data.status) {
                $scope.errors = [];
                toaster.pop('success', "Ticket creation successful.");

                if($state.params.cui){
                    $state.go('userFlow.customer', { customerId: postData.customer.cui })
                }else if(postData.userId){
                    $state.go('userFlow.editTicket', {
                        ticketId: data.data.data.ticket._id,
                        viewCategory: 'myTickets'
                    });
                }else{
                    $state.go('lms.tickets', {
                        tab: postData.product.internalId,
                        viewCategory: 'unassigned'
                    });
                }
            } else {
                const errorMsg = (data.data && data.data.errors && data.data.errors[0] && data.data.errors[0].message) || 'Ticket creation failed.' 
                toaster.pop('error', errorMsg);
            }
        }, function(err) {
            console.error(err);
            toaster.pop('error', "Ticket creation failed.");
        });
    }

    Tickets.getCustomerNames(me._id, true, $stateParams.cui).then(function(response) {
        $scope.customersList = response.data.customers;
        if ($stateParams.cui) {
            $scope.customersList = _.filter($scope.customersList, (customer) => {
                return customer.cui === $stateParams.cui
            })
        }
        $scope.ticketInfo.customer = $scope.customersList[0]
        if ($scope.ticketInfo.customer && $scope.ticketInfo.customer.activeHandler) {
            $scope.taggedUser = `${$scope.ticketInfo.customer.activeHandlerDetails.fullName} (${$scope.ticketInfo.customer.activeHandlerDetails.email})`
        } else {
            $scope.taggedUser = null;
        }
    }, function(error) {
        console.log(error)
    });

    $scope.onCustomerChange = function(customer) {
        console.log('customer: ', customer)
        $scope.ticketInfo.user = null;
        if (customer && customer.activeHandler) {
            $scope.taggedUser = `${customer.activeHandlerDetails.fullName} (${customer.activeHandlerDetails.email})`
        } else {
            $scope.taggedUser = null;
        }
    }

    $scope.minDate = new Date();
    $scope.onSelectUser = function() {
        $scope.ticketInfo.playtype = null
        if(!$scope.ticketInfo.user){
            $scope.selectedUser = null
        }else{
            $scope.selectedUser = $scope.ticketInfo.user
        }
    }

    $scope.onSelectPlaytype = () => {
        $scope.selectedPlaytype = $scope.ticketInfo.playtype && $scope.ticketInfo.playtype._id
    }

    Tickets.fetchFiltersList().then(function(result) {
        var filters = result.data.filters;
        console.log('filters: ', filters);
        $scope.types = filters[0];
        $scope.ticketInfo.product = $scope.products[0]
        $scope.ticketInfo.type = $scope.types[0]
    }, function(err) {
        console.log(err);
    })

    const filterProductSubProducts = () => {
        if($scope.ticketInfo.subType){
            $scope.products = allProducts.filter(product => product._id.toString() === $scope.ticketInfo.subType.product.toString())
            $scope.ticketInfo.product = $scope.products[0]
            $scope.ticketInfo.subProduct = $scope.ticketInfo.product.subProducts[0]
        }
    }

    $scope.onSelectType = function(type) {
        $scope.subTypes = type.subTypes;
        $scope.ticketInfo.subType = $scope.subTypes[0];
    }

    $scope.onSelectProduct = function() {
        $scope.subProducts = $scope.ticketInfo.product && $scope.ticketInfo.product.subProducts ? $scope.ticketInfo.product.subProducts : []
        $scope.ticketInfo.subProduct = $scope.subProducts[0]
    }

    $scope.onSubmit = function(form) {
        if (form.$valid) {
            createTicket($scope.ticket)
        }
    }

    $scope.onCancel = function() {
        // var ticketParams={
        //     tab:$state.params.tab
        // }
        // $state.go('lms.tickets',ticketParams);
        $window.history.back();
    }

    // $scope.userInputChange = (email) => {
    //     $scope.selectedUser = email;
    // }

    function searchComponent(query, key, key2) {
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    }

    if (me.roleType === 'manager') {
        $scope.allRoles = [{name:$scope.strings.MANAGER.value.toUpperCase(), id:'manager'}, {id:'frontline',name:$scope.strings.FRONTLINE.value.toUpperCase()}];
    }else if (me.roleType === 'seniorManager'){
        $scope.allRoles = [{id:'seniorManager',name:$scope.strings.SENIORMANAGER.value.toUpperCase()},{name:$scope.strings.MANAGER.value.toUpperCase(), id:'manager'}, {id:'frontline',name:$scope.strings.FRONTLINE.value.toUpperCase()} ];
    } else {
        $scope.allRoles = [ {id:'frontline',name:$scope.strings.FRONTLINE.value.toUpperCase()}]
    }
    $scope.selectedRole = $scope.allRoles[0];

    function fetchUsersListForSeniorManager(roleType) {
        if (roleType === 'manager') {
            //get all the managers of the loggedin seniormanager
            let myPlayTypes = p0.myPlayTypes;
            let managerIds = []
            myPlayTypes.forEach(function (playType) {
                managerIds = _.concat(managerIds, playType.managers)
            });
            managerIds  = _.map(managerIds, function(id){
                return id.toString();
            });

            /*Show only managers who has fronline under him. do not show dummy  managers in the list*/
            let managerRoles = _.filter(p0.allJobRoles, function(role){
                return role.hasFrontline && _.includes(managerIds, role._id.toString());
            });
            managerIds = _.map(managerRoles, function(role){
                return role._id.toString();
            });

            $http.post('/api/users/getAllLeafJobRoles', {
                reporteeJobRoles: managerIds,
                userId: me._id
            }).then(function (result) {
                result=result.data.data;
                $scope.dataLoaded = true;
                $scope.myTeam = result;
            })
        } else if (roleType === 'seniorManager') {
            var myPlayTypes = p0.myPlayTypes;
            var smIds = []
            myPlayTypes.forEach(function (playType) {
                smIds = _.concat(smIds, playType.seniorManagers)
            });
            smIds  = _.map(smIds, function(id){
                return id.toString();
            });
            $http.post('/api/users/getAllLeafJobRoles', {
                reporteeJobRoles: smIds,
                userId: me._id
            }).then(function (result) {
                result = result.data.data;
                $scope.dataLoaded = true;
                $scope.myTeam = result;
            })
        }
        else {
            //get all the frontlines of the loggedin seniormanager
            $http.post('/api/users/getAllLeaf',{userId:me._id}).then(function (result) {
                result = result.data.data;
                
                 //get all the frontline roles of the loggedin seniormanager
                let myPlayTypes = p0.myPlayTypes;
                let frontlineRoles = []
                myPlayTypes.forEach(function (playType) {
                    frontlineRoles = _.concat(frontlineRoles, playType.frontline)
                });
                frontlineRoles  = _.map(frontlineRoles, function(id){
                    return id.toString();
                });
                
                result = result.filter(function (user) {
                    return _.includes(frontlineRoles, user.organizationalTag.jobRole._id.toString());
                })
                $scope.dataLoaded = true;
                $scope.myTeam = result;
            })
        }
    }

     //on user toggle change update the userlist
     $scope.changeUsers = function () {
         if(!$scope.selectedRole){
            return;
         }
        let roleType = $scope.selectedRole.id;
        if (me.roleType === 'frontline') {
            $scope.myTeam = [me];
            return;
        } else if (me.roleType === 'manager') {
            if (roleType === 'manager') {
                $scope.myTeam = [me];
                return;
            } else {
                Tickets.getMyTeam().then(function (response) {
                    $scope.myTeam = response.data;
                }, function (err) {
                    console.log(err)
                })
            }
        } else {
            fetchUsersListForSeniorManager(roleType)
        }
    }

    $scope.changeUsers()
});