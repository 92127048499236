angular.module('bitnudgeApp')
    .controller('bulkExcelUploadCtrl', function ($http, $scope, Auth, Upload, $state, toaster, p0, UtilService, Sidebar)
    {
        var excels = [
            {name:'Formula', _id:'formula', url:'/api/upload', fileName:'formulae',cronTag:'uploadFormulas'},
            {name:'PlayTypes', _id:'playType', url:'/api/upload', fileName:'playTypes',cronTag:'uploadPlayTypes'},
            { name: 'MonthlyCycleType', _id: 'monthlyCycleType', url: '/api/upload' , fileName:'monthlyCycleTypes',cronTag:'uploadMonthlyCycleTypes'},
            { name: 'Admin Menu Item', _id: 'adminMenuItem', url: '/api/upload' , fileName:'menuItems',cronTag:'uploadAdminMenuItems'},
            { name: 'Customer Handlers', _id: 'addCustomerHandler', url: '/api/upload' , fileName:'customerhandlers',cronTag:'uploadCustomerHandlers'},
            { name: 'User Group Management', _id: 'userGroupManagement', url: '/api/upload' , fileName:'uploadAdminUsers', cronTag:'uploadAdminUsers'},
            { name: 'Permission Role Group', _id: 'permissionRoleGroup', url: '/api/upload', fileName:'uploadPermissionGroups',cronTag:'uploadPermissionGroups'},
            { name: 'workflows', _id: 'importWorkflows',
            url: '/api/workflows/importWorkflows', fileName:'workflow', showExport:true },
            { name: 'Interaction Types', _id: 'interactionTypes',
            url: '/api/interactions/types/import', fileName:'interactionTypes', showExport:false },
            {name:'Lead Types', _id:'leadTypes', url:'/api/leads/types/import', fileName:'leadTypes'},
            {name:'Prospect Types', _id:'prospectUserTypes', url:'/api/prospect-user-types/import', fileName:'prospectUserTypes'},
            {name:'Lead Sub Types', _id:'leadSubTypes', url:'/api/leads/subtypes/import', fileName:'leadSubTypes'},
            {name:'Lead Sources', _id:'leadSources', url:'/api/leads/sources/import', fileName:'leadSources'},
            {name:'Sub Products', _id:'subProducts', url:'/api/products/subproducts/import/excel', fileName:'subProducts'},
            {name:'Sub Products', _id:'subProducts', url:'/api/products/subproducts/import/excel', fileName:'subProducts'},
            { name: 'Incentive Transactions', _id: 'importIncentiveTransactions', url: '/api/upload', fileName:'importIncentiveTransactions',cronTag:'importIncentiveTransactions'},
            { name: 'Service Requests Products', _id: 'srProductUpload', url: '/api/upload', fileName:'srProducts',cronTag:'srProductUpload'},
            { name: 'Glossary File', _id: 'glossaryFileUpload', url: '/api/upload', fileName:'glossaryFile',cronTag:'glossaryFileUpload'},
            { name: 'Excel Header File', _id: 'excelHeaderFileUpload', url: '/api/file-header/import', fileName:'excelHeaderFile',cronTag:'ExcelHeaderFileUpload'},
            { name: 'L3 Products', _id: 'l3ProductsUpload', url: '/api/upload', fileName:'L3Products',cronTag:'l3ProductsUpload'},
            { name: 'Metric Map', _id: 'metricMapUpload', url: '/api/upload', fileName:'metricMapUpload',cronTag:'MetricMapUpload'},
        ];

        $scope.file = {};
        $scope.excel = _.find(excels, {_id:$state.params.excel});
        if(!$scope.excel){
            toaster.pop('error', "Incorrect state", "No page found for the state specified.");
        }

        $scope.downloadWorkflows = function() {
            let baseUrl =  Sidebar.getAppUrl()+'/api/workflows/exportWorkflow';
            UtilService.downloadFile(baseUrl);
        };

        $scope.onFileSelect = function () {
            console.log('$scope.excel: ', $scope.excel)
            if ($scope.file.bulkUpload === undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");
            } else {
                var fileName = $scope.file.bulkUpload[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType === "xls" || fileType === "xlsx" || fileType === "csv") {
                    console.log('$scope.excel: ', $scope.excel)
                    Upload.upload({
                        url: $scope.excel.url,
                        data: {userId: Auth.getCurrentUser()._id, cronTag:$scope.excel.cronTag,invocationMethod:"manual"},
                        file: $scope.file.bulkUpload[0],
                    }).then(function (res) {
                        if (res.data.status === false)
                            toaster.pop({
                                type: 'error',
                                title: "Excel upload failed",
                                body: $scope.excel.name + " upload failed.</br>" + res.data.errors.map(err => (err.message || err).replace('\n', '</br>')).join('</br>'),
                                bodyOutputType: 'trustedHtml'
                            });
                        else
                            toaster.pop('success', "Excel uploaded", $scope.excel.name + " upload successfully finished.");
                    }).catch(function(err){
                        console.error('Excel upload API Failed ', err);
                        toaster.pop('error', "Excel uploaded", $scope.excel.name+" upload failed.");
                    });
                } else {
                    toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }
            }
        }
    });
