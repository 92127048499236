'use strict';
angular.module('bitnudgeApp').controller('timelineController', function ($scope, $mdDialog, moment, ticket,Tickets) {
    $scope.ticket = ticket;
    $scope.allNotes = ticket.allNotes;
    $scope.historiesLoaded = false;
    Tickets.getTicketHistory(ticket).then(function(result){
        $scope.historiesLoaded = true;
        $scope.histories = result.data.data;
        $scope.histories.forEach(element => {
            if(element.notes && element.eventParams){
                let tempNotes = element.notes.split('#')
                tempNotes.forEach((iter,i) => {
                    if(element.eventParams[iter]){
                        tempNotes[i] = moment(element.eventParams[iter]).format("MMM DD, YYYY")
                    }
                })
                tempNotes = tempNotes.join(' ')
                element.notes = tempNotes
            }
        });
    },function(error){
        console.log(error)
    })
 

    $scope.cancel = function () {
        $mdDialog.hide();
    }
});