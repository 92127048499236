'use strict';
//
angular.module('bitnudgeApp')
    .factory('User', function ($resource) {
        return $resource('/api/users/:id/:controller', { id: '@_id' }, {
                changePassword: { method: 'POST', params: { controller: 'password' } },
                get:            { method: 'POST',  params: { id: 'me' }, transformResponse: function (data) {
                    return angular.fromJson(data).data || angular.fromJson(data);
                }}
            }
        );
    });
