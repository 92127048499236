const autoImportNgTemplateLoaderTemplate1 = require('../html/multi-selector.html');

'use strict';
angular.module('bitnudgeApp')
    .component('multiSelector', {
        bindings: {
            options: '<',
            onChange: '&',
            selectDefault: '@',
            field: '@',
            label: '@',
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function ($scope) {
            $scope.onSelect = function (value) {
                if($scope.selectOpened){
                    this.$ctrl.onChange({ selected: $scope.selectedOptions });
                }
            };
            this.$onChanges = (changes) => {
                if(changes.options.currentValue){
                    $scope.options = changes.options.currentValue;
                }
                if(changes.selectDefault.currentValue && $scope.options.length){
                    $scope.selectedOptions = $scope.options[0]
                }
                if(changes.field.currentValue){
                    $scope.field = changes.field.currentValue;
                }
                if(changes.label.currentValue){
                    $scope.label = changes.label.currentValue;
                }
            }
        },
    });
