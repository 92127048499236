const autoImportNgTemplateLoaderTemplate1 = require('../../html/rejectDocModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../../html/prospects/interactions/addFollowUp.html');
const autoImportNgTemplateLoaderTemplate3 = require('../../html/prospects/editProspectInfo.html');
const autoImportNgTemplateLoaderTemplate4 = require('../../html/prospects/addProspectNotes.html');
const autoImportNgTemplateLoaderTemplate5 = require('../../html/prospects/editDataModal.html');
const autoImportNgTemplateLoaderTemplate6 = require('../../html/lms/reassignProspectModal.html');
const autoImportNgTemplateLoaderTemplate7 = require('../../html/lms/prospectAdditionalInfo.html');
const autoImportNgTemplateLoaderTemplate8 = require('../../html/lms/prospectAdditionalInfo.html');

'use strict';

angular.module('bitnudgeApp').controller('editProspectCtrl', function($rootScope, $scope, $sce, Auth, p0, $state, $stateParams,
    prospectService, $timeout, toaster, $window, $filter, WorkflowService, DocumentService, $mdDialog, HotKeyService) {

    let me = Auth.getCurrentUser();
    $scope.loggedInUser = me;
    let onCloseListener = null;
    let selectedRoleUsers = [];
    $scope.data = {};
    $scope.interactionService = { show: true, isRequired: false };
    $scope.params = {};
    $scope.mindate = new Date();
    let roleTypes = [];
    $scope.showInfoRHS = false;
    $scope.secondTopTabs = {
        EFORMS: 'eForms',
        INTERACTIONS: 'interactions',
        ALL: 'all'
    }
    $scope.topTabs = {
        USERACTION: 'userAction',
        PROSPECTHISTORY: 'prospectHistory'
    }
    $scope.rhsTabs={
        EFORMTAB: 'eFormTab',
        CONTENTSHARED: 'contentSharedTab'
    }
    $scope.rhbActiveTab = null
    let allRoleTypes = []
    $scope.topActiveTab = 'prospectHistory';
    $scope.secondActiveTab = 'all'
    $scope.contactKeys = [
        {key:'urn', name:'URN', type:'string'},
        {key:'name', name:'Name', type:'string'},
        {key:'contactNumber', name:'Contact number', type:'contactNumber'},
        {key:'companyName', name:'Company', type:'string'},
        {key:'profession', name:'Profession', type:'string'},
        {key:'email', name:'Email', type:'string'},
        {key:'status', name:'Status', type:'string'},
        {key:'stage', name:'Stage', type:'string'},
        {key:'nationality', name:'Nationality', type:'string'},
        {key:'marketingId', name:'Marketing Id', type:'string'},
        {key:'level', name:'Level', type:'string'},
    ];
    $scope.strings = p0.strings;
    $scope.interactionAttachments = [];

    $scope.validProspectEditDate = false;
    if ($rootScope.config.AGENT_EDIT_DATES && $rootScope.config.AGENT_EDIT_DATES.length === 2) {
        const startDayOfTheMonth = parseInt($rootScope.config.AGENT_EDIT_DATES[0]);
        const endDayOfTheMonth = parseInt($rootScope.config.AGENT_EDIT_DATES[1]);
        const currentDayOfTheMonth = new Date().getDate();
        if (currentDayOfTheMonth <= endDayOfTheMonth && startDayOfTheMonth <= currentDayOfTheMonth) {
            $scope.validProspectEditDate = true;
        }
    }

    const formatProspectHistories = (histories) => {
        $scope.formattedHistories= []
        let tempFormat = histories.reduce(function (r, a) {
            let temp = a.timestamp;
            temp = new Date(temp)
            temp = temp.setHours(0,0,0,0);
            temp = new Date(temp)
            r[temp] = r[temp] || [];
            r[temp].push(a);
            return r;
        }, Object.create(null));
        Object.keys(tempFormat).forEach( historyKey => {
            let obj = {
                timestamp: historyKey,
                histories : tempFormat[historyKey]
            }
                $scope.formattedHistories.push(obj)
        })
    }

    let getContactInfo = function(p) {
        let camelCase = $filter('camelCaseToHuman');
        $scope.contactInfo = _.cloneDeep(p);
        $scope.contactInfo.status = p.status;
        $scope.contactInfo.stage = p.stage;
		$scope.contactInfo.createdAt = p.createdAt;
		$scope.contactInfo.expiryTime = p.expiryTime;
        $scope.contactInfo.urn = p.urn;
		$scope.contactInfo.userId = p.userId.email;
        $scope.contactInfo.userName = p.userId.name;
        $scope.contactInfo.userContactNumber = p.userId.contactNumber;
        $scope.contactInfo.note = p.note;
    };

    const registerForEvents = function() {
        $scope.$on('workflow:prospect:addPrerequisite', function(event, opt) {
            const { existing, data } = opt;
            const {
                contact,
                workflowId,
                _id,
                userId,
                productId,
                subProduct,
                statusId,
                stage
            } = $scope.data.prospect;
			const { state } = $scope.data;
            const payload = {
                customer: { name: contact.name, contactNumber: contact.contactNumber.countryCode+''+contact.contactNumber.number },
                existing,
                docTypeId: (data && data._id) ? data._id : '',
                newPreqParams: data, // New document type params are saved here...
                userId: userId._id,
                workflowId,
                context: 'prospect',
                productId: productId._id,
                subProduct,
                workflowConsumerId: _id,
                stateUid: statusId,
                stageUid: stage,
				toStateUid: state.state,
            };
            WorkflowService.addPrerequisite(payload).then(function(response) {
                if (response.data.status) {
                    toaster.pop('success', "Prospect", "Request for additional information is successful.");
                } else {
                    toaster.pop('error', "Prospect", "Request for additional information is failed. Please ask administrator to recheck the workflow permissions.");
                }
            }, function(err) {
                const message = 'Add prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            })
        });

        $scope.$on('workflow:prospect:removePrerequisite', function(event, opt) {
            const { item } = opt;
            const { workflowId } = $scope.data.prospect;
            WorkflowService.removePrerequisite(workflowId, item._id, item.reference.to).then(function(response) {
                if (response.data.status) {
                    const prereq = $scope.data.state.prerequisites;
                    prereq.splice(prereq.findIndex(p => p._id === item._id), 1);
                    toaster.pop('success', "Prospect", "Additional prerequisite is removed.");
                } else {
                    toaster.pop('error', "Prospect", "Failed to remove additional prerequisite.");
                }
            }, function(err) {
                const message = 'Remove prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            });
        });

        $scope.$on('workflow:prospect:document', function(event, opt) {
            const { item } = opt;
            const { workflowId } = $scope.data.prospect;
            const isApproved = item.name.startsWith("approve");
            const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
            const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

            let documentTypes = otherPrerequisites.map(p => p.typeParam);
            documentTypes.unshift(item.typeParam);

            let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
            const refId = item.selectedDocId || item.rejectedDocId;
            if (refId) {
                documentIds.unshift(refId);
            }
            const dmsParameters = {
                documentTypes, //In case of select mode
                documents: documentIds, //In case of approve mode send document Ids
                isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
                isSelectMode: !isApproved, //for selection mode, open DMS with  prospect context i.e workflow
                customer: {},
                workflowId,
                //cui: cui || contact.cui,
                context: 'prospect'
            };
            $rootScope.$broadcast('dms:openModal', dmsParameters);

            if (onCloseListener) onCloseListener();
            onCloseListener = $scope.$on("dms:onCloseModal", () => {
                getProspectHistoryFunc();
                refreshDocumentPrereqStatus();
            });

        });

        $scope.$on('workflow:prospect:approveDirect', function(event, opt) {
            const { item } = opt;
            const { documentTypeId, selectedDocId, approved, rejected } = item;
            const { workflowId } = $scope.data.prospect;
            if (rejected) {
                toaster.pop('info', "Rejected document can't be approved.");
                return;
            }
            if (approved) {
                toaster.pop('info', "Document is already approved.");
                return;
            }
            const data = { docId: selectedDocId, documentTypeId, workflowId };
            DocumentService.approve(data)
                .then(result => {
                    const { status, data, errors } = result;
                    if (status) {
                        toaster.pop("success", data.message);
                        getProspectHistoryFunc();
                        refreshDocumentPrereqStatus();
                    } else {
                        toaster.pop("error", "Document not approved", errors[0].message);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toaster.pop("error", "Error in document approval", err.message);
                });
        });

        $scope.$on('workflow:prospect:rejectDirect', function(event, opt) {
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "RejectDocCtrl",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(result => {
                    if (result) {
                        const { item } = opt;
                        const { documentTypeId, selectedDocId, approved, rejected } = item;
                        const { workflowId } = $scope.data.prospect;
                        let { comment, reason } = result;
                        if (!comment) {
                            comment = reason.title;
                        }
                        const data = {
                            docId: selectedDocId,
                            documentTypeId,
                            workflowId,
                            notes: comment,
                            label: reason.title
                        };
                        DocumentService.reject(data).then(result => {
                                const { status, data, errors } = result;
                                if (status) {
                                    toaster.pop("success", data.message);
                                    getProspectHistoryFunc();
                                    refreshDocumentPrereqStatus();
                                } else {
                                    toaster.pop("error", "Document not rejected", errors[0].message);
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                toaster.pop("error", "Error in document rejected", err.message);
                            });
                    }
                });
        });
    };

    const refreshDocumentPrereqStatus = () => {
        const { workflowId } = $scope.data.prospect;
        prospectService.getDocumentsCache('prospectUser', workflowId).then(function(response) {
            response = response.data;
            const { status, data, errors } = response;
            if (status) {
                DocumentService.updateDocumentPrerequisites($scope.data.state.prerequisites, data);
            }
        }).catch((err) => {
            console.error('Get documents cache failed', err);
        });
    };

    const getProspectHistoryFunc = () => {
        $scope.loadingHistoryInfo = true
        prospectService.getProspectHistory($stateParams.prospectId).then(function(response) {
            const histories = response.data.data;
            histories.forEach(history => {
                if (history.thumbnail) {
                    history.thumbnail.dmsId = DocumentService.toBase64(history.thumbnail.dmsUrl)
                }
            });
            $scope.histories = histories
            formatProspectHistories($scope.histories)
            
            $scope.loadingHistoryInfo = false;
            $scope.loadingInfo = false;
        });
    };

    const init = function() {
        registerForEvents();
		$scope.attendanceLabel = prospectService.getAttendanceLabel($rootScope.isFrontline);
        if(me && me.organizationalTag && me.organizationalTag.jobRole){
        	const myRole = p0.allJobRoles.find(role => role._id === me.organizationalTag.jobRole._id);
			if(myRole){
				$scope.showAdviserAttendance = myRole.loanAdviserAttendanceTrack;
			}else{
				$scope.showAdviserAttendance = false;
			}
		}
        prospectService.getInteractions().then(function(response) {
            $scope.interactions = response.data.data;

            $scope.interactions = $scope.interactions.filter( interaction => {
                const {callInteraction, meetingInteraction, chatInteraction} = $scope.loggedInUser.organizationalTag.jobRole.permissions;
                if(interaction.internalId === "call" && callInteraction){
                    return true
                }else if(interaction.internalId === "meeting" && meetingInteraction){
                    return true
                }else if(interaction.internalId === "chat" && chatInteraction){
                    return true
                }else if(interaction.internalId === "email"){
                    //email interaction is added, there was no requirement for permission
                    return true
                }else if(interaction.internalId === "share"){
                    //share interaction will not visible on dropdown
                    return false
                }
            })

        });
        $scope.loadingInfo = true;
        $scope.isLoadingDocEform = true;
        prospectService.getProspect($stateParams.prospectId).then(function(data) {
            $scope.data.prospect = data.data.data;
            prospectService.getInteractionAttachments({ workflowId: data.data.data.workflowId, type: "meeting"}).then(function(response) {
                $scope.interactionAttachments = response.data.data.attachments;
            }).catch(function(err) {
                console.error('Failed to fetch interaction attachements', err);
                toaster.pop('error', "Couldn't fetch attachments");
            });
            $scope.interactionService.isRequired = data.data.data.statusId.toLowerCase() === 'created';
            const {secondRecentInteraction} = $scope.data.prospect;
            if(secondRecentInteraction && secondRecentInteraction.status === 'missed'){
                $scope.data.prospect.missedInteraction = secondRecentInteraction;
            }
            prospectService.getNextStates($stateParams.prospectId).then(function(response) {
                $scope.data.nextStates = response.data.data;
                $scope.workflowNoUpdateMessage = response.data.message
                if ($stateParams.statusUpdate === 'true') {
                    $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
                    $scope.data.stateSelected = true;
                    $scope.params.updateStatus = true;
                }
            }).catch(function(err) {
                console.error('Prospect get next states API failed', err);
                toaster.pop('error', "Can't update prospect now");
            });

            prospectService.getDocEformInfo($stateParams.prospectId).then(function(response){
                $scope.data.prospect.docEformInfo = response.data.data;
                if (!$scope.data.prospect.docEformInfo) {
                    return;
                }
                Object.keys($scope.data.prospect.docEformInfo).forEach( key => {
                    if(key === 'applicationForm') return;
                    $scope.data.prospect.docEformInfo[key].forEach(info => {
                        if(info.type === 'eform'){
                            const {docsUploadedCount,docsRequiredCount} = info.details
                            info.percentageCompleted = docsUploadedCount/docsRequiredCount
                            if(info.percentageCompleted){
                                info.percentageCompleted = info.percentageCompleted*100
                            }
                        }
                        if(info.type === 'document'){
                            const {thumbnailUrl} = info
                            info.thumbnailUrl = `${p0.config.saleskeyDomain}/api${thumbnailUrl}`
                        }
                    })
                })
                $scope.isLoadingDocEform = false;
            }).catch(function(err){
                $scope.isLoadingDocEform = false;
                console.error('Prospect documents eform info API failed', err);
                toaster.pop('error','error in fetching the doc info')
            })
            
            getContactInfo($scope.data.prospect);
            if($scope.data.prospect && $scope.data.prospect.notes){
                $scope.data.prospect.notes = $scope.data.prospect.notes.filter(note => note.notes && note.notes.length)
            }
        }).catch(function(err) {
            console.error('Get prospect API failed', err);
            toaster.pop('error', "Can't get the prospect");
        });
        getProspectHistoryFunc();
        prospectService.getFutureFollowUps($stateParams.prospectId).then(response => {
            if (response.data.status) {
                let followUps = response.data.followUps;
                let dayStart = new Date().setHours(0, 0, 0, 0);
                let dayEnd = new Date().setHours(23, 59, 59, 999);

                _.forEach(followUps, (followUp) => {
                    let date = new Date(followUp.scheduledAt);
                    if (dayStart <= date && date <= dayEnd) {
                        followUp.isToday = true;
                    }
                });
                $scope.followUps = _.sortBy(followUps, 'scheduledAt');
                
            }
        })
    };

    $scope.openSalesKeyLink = function(linkCode){
        HotKeyService.getReviewToken(linkCode).then(result => {
            if (result.status) {
                const token = result.data
                const url = `${p0.config.saleskeyDomainExternal}/c/${linkCode}?token=${token}`;
                console.log(url)
                $window.open(url, '_blank');
            }else{
                toaster.pop('error', result.message)
            }
        })
    }

    const updateWorkflow = function(prerequisites) {
        const params = {
            _id: $scope.data.prospect._id,
            userId: me._id,
            workflowId: $scope.data.prospect.workflowId,
            nextStateId: $scope.data.state.state,
            prerequisites: prerequisites
        };
        $scope.params.errors = [];
        return prospectService.gotoState(params);
    };

    const saveInteraction = function() {
        let attendees = []
        if($scope.interactionService.adviserAttendance){
            attendees = $scope.additionalList.map ( list => {
                if(list.roleUser && list.roleUser._id){
                    return list.roleUser._id
                }
            })
            if(attendees.length === 0){
                toaster.pop('error', "Meeting Interaction ", "Atleast on attendee is required");
                return;
            }
        }
        const interactionData = {
            _id: $scope.data.prospect._id,
            workflowId: $scope.data.prospect.workflowId,
            userId: me._id,
            prerequisites: [
                { name: 'interaction', value: $scope.interactionService.interaction.internalId },
                { name: 'interactionOutput', value: $scope.interactionService.interactionOutput.internalId },
                { name: 'notes', value: $scope.interactionService.notes ? $scope.interactionService.notes : "" },
				{ name: 'loanAdviserPresence', value: !!$scope.interactionService.adviserAttendance },
                { name: 'category', value: 'prospect' }
            ],
            attendees
        };
        // /** if follow-up not set then set tomorrow as follow-up **/
        // if(!$scope.interactionService.followupDate){
        //     let tmrw = new Date();
        //     tmrw = new Date().setDate(new Date().getDate() + 1);
        //     $scope.interactionService.followupDate = new Date(tmrw);
        // }
        let followUp = $scope.interactionService.followupDate;
        if (followUp) {
            /** check if the user selected the follow-up from previously created list, if yes then do not create again **/
            let isFollowUpExists = _.find($scope.followUps, (date) => {
                return new Date(date.scheduledAt).getTime() === new Date(followUp).getTime();
            });

            if (!isFollowUpExists) {
                interactionData.prerequisites.push({ name: 'followUp', value: $scope.interactionService.followupDate });

                if ($scope.interactionService.followUpType) {
                    interactionData.prerequisites.push({ name: 'followupType', value: $scope.interactionService.followUpType.internalId })
                    if ($scope.interactionService.address) interactionData.prerequisites.push({ name: 'address', value: $scope.interactionService.address })
                }
                let followupEndDate = $scope.interactionService.followupEndDate;
                if(followupEndDate){
                    interactionData.prerequisites.push({ name: 'followUpEnd', value: $scope.interactionService.followupEndDate })
                }
            }
        }

        let completedAt = $scope.interactionService.completedAt;
        if (completedAt) {

            interactionData.prerequisites.push({ name: 'completedAt', value: $scope.interactionService.completedAt });
            
            let completionEndTime = $scope.interactionService.completionEndTime;
            if(completionEndTime){
                interactionData.prerequisites.push({ name: 'completionEndTime', value: $scope.interactionService.completionEndTime })
            }
        }

        if ($scope.data.prospect.cui) {
            interactionData.cui = $scope.data.prospect.cui
        }

        return prospectService.saveInteraction(interactionData);
    };

    const goBack = function() {
        $state.go('lms.listProspect')
    };
    $scope.onCancel = function() {
        goBack();
    };

    $scope.onStateChange = function() {
        $scope.data.stateSelected = false;
        checkInteractionRequired();
        $scope.params.errors = [];
        $timeout(function() {
            $scope.data.stateSelected = true;
        });
    };

    function checkInteractionRequired() {
        $scope.interactionService.show = true;
        //Prospect auto feature, do not show any interaciton fields from workflow. Keep the existing interaction form.
        // if ($scope.data.state && $scope.data.state.prerequisites && $scope.data.state.prerequisites.length) {
        //     if (_.find($scope.data.state.prerequisites, { name: 'interaction' })) {
        //         $scope.interactionService.show = false;
        //     }
        // }
    }

    $scope.updateCheckBox = function() {
        if ($scope.params.updateStatus) {
            $scope.data.state = $scope.data.nextStates[0];
            $scope.data.stateSelected = true;
        } else {
            $scope.data.state = null;
            $scope.data.stateSelected = false;
        }
        checkInteractionRequired();
    };

    function searchComponent(query, key, key2) {
        //var lowercaseQuery = query.toLowerCase();
        let ismatched = false;
        const re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    };

    $scope.selectInteraction = function() {
        $scope.interactionOutputs = $scope.interactionService.interaction ? $scope.interactionService.interaction.outcome : [];
        $scope.interactionService.interactionOutput = $scope.interactionOutputs[0];
    };


    $scope.onSubmit = function() {
        if ($scope.interactionService.show && $scope.interactionService.interaction) {
            return saveInteraction().then(function(result) {
                if (result.data.status) {
                    if ($scope.params.updateStatus) {
                        updateStatus();
                    } else {
                        //Only interaction update case
                        goBack();
                        toaster.pop('success', "Interaction", "Interaction successfully saved.");
                    }
                } else {
                    const message = result.data.errors[0].message || "Interaction update failed";
                    toaster.pop('error', "Interaction", message);
                }
            }, function(error) {
                console.log(error);
                toaster.pop('error', "Prospect", "Update is failed.");
            });
        } else {
            if ($scope.params.updateStatus) {
                updateStatus();
            }
        }

        function updateStatus() {
            updateWorkflow($scope.data.state.prerequisites).then(function(response) {
                if (response.data.status) {
                    $scope.params.errors = [];
                    toaster.pop('success', "Prospect", "Update is successful.");
                    goBack();
                } else {
                    toaster.pop('error', "Prospect", "There were some errors in updating the prosepct. Please fix and try again.");
                    $scope.params.errors = response.data.errors;
                }
            }).catch(function(err) {
                console.error('Prospect goto state API failed', err);
                $scope.params.errors = err.data.errors;
                toaster.pop('error', "Prospect", "Update is failed.");
            });
        }
    };

    $scope.setFollowUp = function(date) {
        $scope.interactionService.followupDate = new Date(date);
        $scope.interactionService.followupEndDate = new Date(date);
    };

    $scope.gotoCustomer = function(cui) {
        if (cui) {
            $state.go('userFlow.customer', { customerId: cui });
        }
    };
    //#endregion
    $scope.additionalItem = {
        id:new Date().getTime(),
        isAdd: true,
        roleType: "",
        roleUser: "",
        roleUsers: [],
        roleTypes: []
    }
    $scope.additionalList = [];
    $scope.additionalList.push(angular.copy($scope.additionalItem));
    
    //#endregion
    $scope.changeRoleUserlist = (role, id) => {
        let filteredField = $scope.additionalList.find( field => {
            if(field.id === id){
                return field
            }
        })
        if(role === 'Manager'){
            filteredField.roleUsers = $scope.managerUsers.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
            
        }else{
            filteredField.roleUsers = $scope.loanAdvisors.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
        }
    }

    $scope.onFollowupDateChange = (fDate) => {
        $scope.minFollowUpEndDate = new Date(fDate);
    }

    $scope.onInteractionDateChange = (intrDate) => {
        $scope.minInteractionEndTime = new Date(intrDate);
    }

    $scope.openAddFollowUpModal = (isNextFollowUp, interaction) => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'addProspectFollowUpCtrl',
            clickOutsideToClose: false,
            resolve: {
                prospect: () => {
                    return $scope.data.prospect
                },
                interactionTypes: () => {
                    return $scope.interactions;
                },
                isNextFollowUp: () => {
                    return isNextFollowUp;
                },
                interaction: () => {
                    return interaction;
                },
                reloadProspectInfoPage: () => {
                    return init;
                }
            }
        });
    },

    $scope.openEditProspectInfoModal = () => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'editProspectInfoCtrl',
            clickOutsideToClose: false,
            resolve: {
                prospect: () => {
                    return $scope.data.prospect
                },
                reloadProspectInfoPage: () => {
                    return init;
                }
            }
        });
    },

    $scope.openAddNotesModal = () => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: 'addProspectNotesCtrl',
            clickOutsideToClose: false,
            resolve: {
                prospect: () => {
                    return $scope.data.prospect
                },
                reloadProspectInfoPage: () => {
                    return init;
                }
            }
        });
    }

    $scope.openEditDataModal = (url) =>{
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller: function DialogController($scope, $mdDialog) {
                $scope.onClose = function() {
                   $mdDialog.hide();
                }
                $scope.url = $sce.trustAsResourceUrl(url)
             },
            clickOutsideToClose: false,
        });
    }

    $scope.editApplicationForm = (urn, form) =>{
        if(form && form.linkCode){
            HotKeyService.getReviewToken(form.linkCode).then(result => {
				if (result.status) {
                    const token = result.data
                    
                    const url = `${p0.config.saleskeyDomainExternal}/c/${form.linkCode}?token=${token}`;
                    console.log(url)
                    $scope.openEditDataModal(url);
				}else{
					toaster.pop('error', result.message)
				}
			})
        }else{
            // create new link and open it
            prospectService.getApplicationFormLink(urn).then(result => {
                const { linkCode, token } = result.data.data
                const url = `${p0.config.saleskeyDomainExternal}/c/${linkCode}?token=${token}`;
                console.log(url)
                $scope.openEditDataModal(url);
            })
        }

    }
    
    $scope.showMoreNotesHandler = () => {
        $scope.showMoreNotes =  !$scope.showMoreNotes
    }

    $scope.changeSecondTopTab = (tabName) => {
        $scope.secondActiveTab = tabName;
        if($scope.secondActiveTab === $scope.secondTopTabs.EFORMS){
            const histories = $scope.histories.filter(history=> history.historyType === 'interaction' && history.type === 'share')
            formatProspectHistories(histories)
        }else if($scope.secondActiveTab === $scope.secondTopTabs.INTERACTIONS){
            const histories = $scope.histories.filter(history=> history.historyType === 'interaction')
            formatProspectHistories(histories)
        }else{
            formatProspectHistories($scope.histories)
        }
    }
    $scope.changeTopTab = (tabName) => {
        $scope.topActiveTab = tabName
    }

    $scope.showAttachedDocuments = (p, ev) => {
            ev.stopPropagation();
            const documentTypes = []
            p.attachedDocsCount= p.documents.selected.length+p.approvedDocuments.length
            if(p.attachedDocsCount){
                const documents = p.documents;
                if(p.approvedDocuments){
                    documents.approved = p.approvedDocuments
                }
                if(documents){
                    Object.keys(documents).forEach(item => {
                        documents[item].forEach( document =>{
                            if(!documentTypes.includes(document.uid)){
                                documentTypes.push(document.uid)
                            }
                        })
                    })
                }
                if(documentTypes.length){
                    const dmsParameters = {
                        documentTypes, //In case of select mode
                        documents: [], //In case of approve mode send document Ids
                        isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                        isApproveMode: false, //for approve mode, open DMS with approve and reject option
                        isSelectMode: true, //for selection mode, open DMS with prospect context i.e workflow
                        customer: {},
                        workflowId: p.workflowId,
                        context: 'prospect'
                    };
                    $rootScope.$broadcast('dms:openModal', dmsParameters);
                }
            }
    }

    $scope.showOtherDocuments = (attachments, ev) => {
        ev.stopPropagation();
        if(Array.isArray(attachments) && attachments.length){
            const dmsParameters = {
                        isDisplayMode: true,
                        context: 'prospect',
                        attachments: attachments
            }
            $rootScope.$broadcast('attachmentModal:openModal', dmsParameters);
        }
    }

    $scope.reassignProspect = function(prospect, ev){
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate6,
            controller: 'reassignProspectModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                prospect: function(){
                    return prospect;
                },
                fetchProspects: function () {
                    return init;
                }
            }
        })
        .then(function(answer) {
        }, function() {
        });
    };

    $scope.updateProspectStatus = (p) => {
        $state.go('userFlow.updateProspect', {prospectId: p._id});
    }

    $scope.showSharedContent = (tabName) =>{
        $scope.rhbActiveTab = tabName;
        filterRHSData()
    }

    $scope.showInfoRHSHandler = (tabName) => {
        $scope.showInfoRHS = true;
        $scope.rhbActiveTab = tabName;
        filterRHSData()
    }

    $scope.closeInfoRHSHandler = () => {
        $scope.showInfoRHS = false;
    }

    const filterRHSData = () => {
        if($scope.showInfoRHS && $scope.rhbActiveTab === $scope.rhsTabs.EFORMTAB){
            $scope.contentInfoList = $scope.data.prospect.docEformInfo.wipEforms
        }else if($scope.showInfoRHS && $scope.rhbActiveTab === $scope.rhsTabs.CONTENTSHARED){
            $scope.contentInfoList = $scope.data.prospect.docEformInfo.docsShared
        }

    }

    $scope.showAdditionalInfo = function(p, ev){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate7,
            controller: function($scope, prospect, $mdDialog){
                $scope.contact = prospect;
                $scope.header= 'Prospect Info (E-form)'
                $scope.contactKeys = [
                    {key:'urn', name:'URN', type:'string'},
                    {key:'name', name:'Name', type:'string'},
                    {key:'contactNumber', name:'Contact number', type:'contactNumber'},
                    {key:'companyName', name:'Company', type:'string'},
                    {key:'profession', name:'Profession', type:'string'},
                    {key:'email', name:'Email', type:'string'},
                    {key:'status', name:'Status', type:'string'},
                    {key:'stage', name:'Stage', type:'string'},
                    {key:'nationality', name:'Nationality', type:'string'},
                    {key:'marketingId', name:'Marketing Id', type:'string'},
                    {key:'agentLevelDisplayName', name:'Level', type:'string'},
                ];
                $scope.onClose = () => {
                    $mdDialog.hide();
                }
            },
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                prospect: function(){
                    return p;
                }
            }
        });
    };

    $scope.showAgentData = function (p, ev) {
        prospectService.getUserById(p.createdAgentId).then(function (agent) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate8,
                controller: function ($scope, agent, $mdDialog) {
                    $scope.contact = agent;
                    $scope.header = `${p0.strings.FRONTLINE.value} Data`;
                    $scope.contactKeys = [
                        { key: 'name', name: 'Name', type: 'string' },
                        { key: 'email', name: 'Marketing Id', type: 'string' },
                        { key: 'contactNumber', name: 'Mobile number', type: 'string' },
                        { key: 'branch', name: 'Branch', type: 'string' },
                        { key: 'subBranchName', name: 'Sub Branch', type: 'string' },
                        { key: 'pos', name: 'POS ID', type: 'string' },
                        { key: 'function', name: 'Function', type: 'string' },
                        { key: 'supervisor1', name: 'Supervisor 1', type: 'string' },
                        { key: 'idType', name: 'ID Type', type: 'string' },
                        { key: 'idNumber', name: 'ID Number', type: 'string' },
                        { key: 'dateOfBirth', name: 'Birth Date', type: 'string' },
                        { key: 'city', name: 'City', type: 'string' },
                        { key: 'subDistrict', name: 'Sub-District', type: 'string' },
                        { key: 'district', name: 'District', type: 'string' },
                        { key: 'zipCode', name: 'Zipcode', type: 'string' },
                        { key: 'rt', name: 'RT', type: 'string' },
                        { key: 'rw', name: 'RW', type: 'string' },
                        { key: 'address', name: 'Address', type: 'string' },
                        { key: 'business', name: 'Company/Business Name', type: 'string' },
                        { key: 'bankId', name: 'Bank  Name', type: 'string' },
                        { key: 'bankAccountNo', name: 'No of Bank Account', type: 'string' },
                        { key: 'bankAccountName', name: 'Account Owner Name', type: 'string' },
                    ];
                    $scope.onClose = () => {
                        $mdDialog.hide();
                    }
                },
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: true, // Only for -xs, -sm breakpoints.
                resolve: {
                    agent: function () {
                        return agent;
                    }
                }
            });
        })
    };

    init();
});
