/* eslint-disable */
"use strict";

angular.module("bitnudgeApp").controller("attachmentPreviewComponentCtrl", function($scope, $rootScope) {
    $scope.zoom = 1;
    this.$onChanges = changes => {
        if (changes.displayMode) {
            $scope.displayMode = changes.displayMode.currentValue;
        }
        if (changes.attachment) {
            $scope.attachment = changes.attachment.currentValue;
        }
    };

    $scope.zoomOutContent = event => {
        if ($scope.zoom <= 3 && $scope.zoom > 0.5) {
            $scope.zoom = $scope.zoom - 0.5;
        }
    };
    $scope.zoomInContent = event => {
        if ($scope.zoom >= 0.5 && $scope.zoom < 3) {
            $scope.zoom = $scope.zoom + 0.5;
        }
    };

    $scope.selectThumbnail = fileId => {
        $scope.preview = $scope.docthumbnails.find(doc => {
            return doc._id === fileId;
        });
    };

});