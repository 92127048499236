'use strict';
angular.module('bitnudgeApp').controller('reassignTicketCtrl', function ($scope,Auth,p0, $mdDialog, ticket, callback, frontlineList, $http, $rootScope, toaster) {
    $scope.ticket = ticket;
    $scope.me = Auth.getCurrentUser(); 
    $scope.playerList = _.cloneDeep(frontlineList);
    //for list of frontlines or managers
    $scope.isManagerSelected = false;
    //for showing hide/show on switch
    $scope.userToggleSwitch = true;
    $scope.dataLoaded = false;
    $scope.assignedUser =  ticket.userId ? _.remove($scope.playerList, {_id: ticket.userId._id}) : null;

    $scope.roles = ["Manager", "Frontline"];
    if($rootScope.isSeniorManager){
        $scope.roles.unshift("Senior Manager")
    }
    $scope.role = $scope.roles[0];

    function fetchUsersList() {
        $scope.dataLoaded = false;
        if ($rootScope.isMiddleManager) {
            //If the logged in user is manager then only show frontlines and hide user toggle switch
            fetchUsersListForManager()
        }
        else {
            //If the logged in user is senior manager and up then they can assign a ticket to frontline or a manager SMs under him
            fetchUsersListForSeniorManager($scope.role)

        }
    }


    function fetchUsersListForManager() {
        $scope.userToggleSwitch = false;
        //get all the children of the logged in manager
        $http.post('api/users/me/children', {userId: $scope.me._id}).then(function (result) {
            result=result.data.data;
            //remove the current assigned user from the list
            _.remove(result, function (user) {
                return ticket.userId && user._id.toString() == ticket.userId._id.toString()
            });
            if(ticket.userId && ticket.userId._id.toString() !== $scope.me._id.toString()){
                result.unshift($scope.me)
            }
            //usersList is shown on modal List
            $scope.dataLoaded = true;
            $scope.usersList = result;
        });
    }
    function fetchUsersListForSeniorManager(roleType) {
        if (roleType.toLowerCase() === 'manager') {
            //get all the managers of the loggedin seniormanager
            var myPlayTypes = p0.myPlayTypes;
            var managerIds = []
            myPlayTypes.forEach(function (playType) {
                managerIds = _.concat(managerIds, playType.managers)
            });
            managerIds  = _.map(managerIds, function(id){
                return id.toString();
            });
          
            $http.post('/api/users/getAllLeafJobRoles', {
                reporteeJobRoles: managerIds,
                userId: $scope.me._id
            }).then(function (result) {
                result=result.data.data;
                $scope.dataLoaded = true;
                 //remove the current assigned user from the list
                 _.remove(result, function (user) {
                    return user._id.toString() == ticket.userId._id.toString()
                });
                $scope.usersList = result;
            })
        } else if (roleType.toLowerCase() === 'senior manager') {
            var myPlayTypes = p0.myPlayTypes;
            var smIds = []
            myPlayTypes.forEach(function (playType) {
                smIds = _.concat(smIds, playType.seniorManagers)
            });
            smIds  = _.map(smIds, function(id){
                return id.toString();
            });
            $http.post('/api/users/getAllLeafJobRoles', {
                reporteeJobRoles: smIds,
                userId: $scope.me._id
            }).then(function (result) {
                result = result.data;
                if(result.success){
                    result = result.data;
                    _.remove(result, function (user) {
                        return ticket.userId && user._id.toString() == ticket.userId._id.toString()
                    });
                    $scope.dataLoaded = true;
                    $scope.usersList = result;
                    console.log(result, $scope.usersList)
                }
            })
        }
        else {
            //get all the frontlines of the loggedin seniormanager
            $http.post('/api/users/getAllLeaf', {userId: $scope.me._id}).then(function (result) {
                result = result.data.data;
                
                 //get all the frontline roles of the loggedin seniormanager
                var myPlayTypes = p0.myPlayTypes;
                var frontlineRoles = []
                myPlayTypes.forEach(function (playType) {
                    frontlineRoles = _.concat(frontlineRoles, playType.frontline)
                });
                frontlineRoles  = _.map(frontlineRoles, function(id){
                    return id.toString();
                });
                
                result = result.filter(function (user) {
                    return _.includes(frontlineRoles, user.organizationalTag.jobRole._id.toString());
                })
                //remove the current assigned user from the list
                _.remove(result, function (user) {
                    return ticket.userId && user._id.toString() == ticket.userId._id.toString()
                });
                $scope.dataLoaded = true;
                $scope.usersList = result;
            })
        }

    }

    $scope.roleChanged = function (roleType) {
        fetchUsersListForSeniorManager(roleType);
    }

    $scope.onClickPlayer = function(player){
        showConfirm($scope.ticket, player);
    };
    //Create generic reassign flow
    var showConfirm = function(ticket, player) {
        let confirm;
        if(ticket.userId){
            confirm = $mdDialog.confirm()
            .title('Are you sure you want reassign this ticket from '+ ticket.userId.name +' to '+player.name +'?')
            .ok('Yes')
            .cancel('No');
        }else{
            confirm = $mdDialog.confirm()
            .title('Are you sure you want reassign this ticket to '+player.name +'?')
            .ok('Yes')
            .cancel('No');
        }

        $mdDialog.show(confirm).then(function() {
            $http.post('/api/tickets/reassignTicket', {user: player, ticketId:ticket._id}).then(function(response){
                response=response.data;
                ticket.userId = player;
                toaster.pop('success',"Ticket reassign successful.");
                callback()
                $mdDialog.hide();
            });
        });
    };
    fetchUsersList()
    $scope.cancel = function () {
        $mdDialog.hide();
    }
});
